import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { useState, useEffect,useContext } from "react";
import { apiService } from "../../../services/api.services";
import { validateService } from "../../../services/validate.services";
import { bUrl, allowedImageTypes ,gKey} from "../../../config/config"
import SuccessPopup from "../../modal/SuccessPopup"
import Errorpopup from "../../modal/Errorpopup"
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
import GooglePlacesAutocomplete,{ geocodeByAddress, getLatLng,geocodeByLatLng } from 'react-google-places-autocomplete';

import { context } from "../../../GlobalContext/GlobalContext";
import default_imag from "../../../assets/images/trucker-image.png"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const ct = require("countries-and-timezones");
const TruckerEditProfile = () => {
    const [value, setValue] = useState(null);
    const timezone = ct.getTimezone(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      const COUNTRY_CODE = "ph";
    
    const globalData = useContext(context);
    const [logo, setlogo] = useState();
    const [image, setimage] = useState(default_imag);
    const [imageErr, setimageErr] = useState("");
    const [isLoader, setIsLoader] = useState(false)
    const [show, setShow] = useState(false);
    const [showErr, setShowErr] = useState(false);
    const [ errmessage1 , seterrmessage1] = useState("Error")
    const [ errmessage2 , seterrmessage2] = useState("")
    const handleCloseErr = () => {
    setShowErr(false);
      }
      const handleShowErr = () => setShowErr(true);
    const [message1, setmessage1] = useState("")
    const [message2, setmessage2] = useState("")
    const navigate = useNavigate();
    const [redirectlink, setredirectlink] = useState("/")
    const handleShow = () => setShow(true);
    const handleClose = () => {
        window.location.href ="/profile"
        // navigate("/profile")
        setShow(false);
    }
    const [profileFields, setprofileFields] = useState({
        first_name: "",
        last_name: "",
        business_name: "",
        trade_name: "",
        ltfrb_number: "",
        marine_insurance: "",
        dti_sec_number: "",
        permit_number: "",
        phone:"",
        business_address: "",
        latitude:"",
        longitude:"",
        business_logo: "",
        image: ""
    });
    const [profileFieldsErrs, setprofileFieldsErrs] = useState({
        first_name: "",
        last_name: "",
        business_name: "",
        trade_name: "",
        ltfrb_number: "",
        marine_insurance: "",
        dti_sec_number: "",
        permit_number: "",
        phone:"",
        business_address: "",
        business_logo: "",
    });
    useEffect(() => {
        if(show){
        const close = (e) => {
        
          if (e.key === 'Enter') {
           
             handleClose()
          }
        // }
        }
        window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
        }
         
    },[show])

    useEffect(() => {
        if(value){
            try{
             geocodeByAddress(value.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng },Status) =>
            
            setprofileFields({
                ...profileFields,
                business_address: value.label,
                  latitude: lat,
                  longitude: lng,   })  
                  
                  ); 
            }catch(e){
                // console.log("=========",e)

            }
        }

    }, [value]);
    const getData = (lat, lng,addressadded) => {
        console.log({ lat: lat, lng: lng },"=====")
        geocodeByLatLng({ lat: lat, lng: lng }).then(
            (response) => {
                // console.log(response[0],"=====")
                const address = response[0].formatted_address;
                // console.log(address,"====addressaddressaddressaddress=")
                setValue({label:addressadded,value:response[0]})

            })
        }
    const handlelogoChange = (file) => {
        let valid = true
        const selectedImage = file;
        if (selectedImage) {
            if (allowedImageTypes.includes(selectedImage.type)) {
                const reader = new FileReader();
                reader.onload = () => {
                    setlogo(reader.result);
                };
                reader.readAsDataURL(selectedImage);
            } else {
                valid = false

                setprofileFieldsErrs({
                    ...profileFieldsErrs,
                    'business_logo': "Invalid file type. Please select a JPEG or PNG image."
                })
            }
        }
        return valid;
    };
    const handleImageChange = (file) => {
        const selectedImage = file;
        let valid = true
        if (selectedImage) {
            if (allowedImageTypes.includes(selectedImage.type)) {
                valid = true
                const reader = new FileReader();
                reader.onload = () => {
                    setimage(reader.result);
                };
                reader.readAsDataURL(selectedImage);
                setimageErr("")
            } else {
                valid = false
                setimageErr("Invalid file type. Please select a JPEG or PNG image.")

            }
        }
        return valid
    };

    const onInputChange = (field, value) => {
        if (field == "business_logo") {
            let valid = handlelogoChange(value)
            if (valid) {
                setprofileFields({
                    ...profileFields,
                    [field]: value,

                });
            }

        } else if (field == "image") {
            let valid = handleImageChange(value)
            if (valid) {
                setprofileFields({
                    ...profileFields,
                    [field]: value,

                });
            }
        } else {
            setprofileFields({
                ...profileFields,
                [field]: value,

            });
        }



    }
    const get_business_profile = () => {
        setIsLoader(true)
        //  get_bank()
        apiService.get_business_profile().then((res) => {
            // console.log("===get_business_profile=====", res.data.data.business_address)

             let data = res.data.data
             if(res.data.data.latitude!="" && res.data.data.latitude!=null){
                getData(res.data.data.latitude,res.data.data.longitude,res.data.data.business_address)
                // setValue({label:res.data.data.business_address})
             }
            
            data.first_name = res.data.data.user.first_name
            data.last_name = res.data.data.user.last_name
            data.image = res.data.data.user.image
            data.id = res.data.data.user.id
           if(res.data.data.user.image!=null){
            setimage(bUrl +res.data.data.user.image)
           }
           
            setlogo(bUrl +res.data.data.business_logo)
            let user_name = res.data.data.user.first_name+" "+res.data.data.user.last_name
             globalData.setuser_name(user_name )
             localStorage.setItem("user_name", res.data.data.user.first_name+" "+res.data.data.user.last_name)
             delete data["user"];
             delete data["business_logo"];
             delete data["image"];
            // console.log("===dat====", data)
            setprofileFields(data)
            setIsLoader(false)
        })
            .catch((err) => {
                console.log("get user detail profile catch", err);
                setIsLoader(false)
                //   Swal.fire("Error", "Something went wrong.", "error");
                //  console.log("contact us api error", err.response.data.message);
            });
    }
    useEffect(() => {
        get_business_profile()

    }, [])
    const handleKeyDownName = (event) => {
        console.log("=====event====",event.which)
        if (event.which === 32) {
          event.preventDefault(); // Prevent space key action
        }
        if (event.key === 'Enter' || event.which === 13) {
            event.preventDefault(); // Prevent the default behavior (page refresh)
            // Handle your logic here
          }
      };
    const handleSubmit = () => {
        // e.preventDefault();
        let formValidated = validateService.validateRetailerstep1(setprofileFieldsErrs, profileFields,"trade")

        if (formValidated) {
            
            setIsLoader(true)
            apiService.update_user_profile(profileFields).then((res) => {
                setIsLoader(false)
                globalData.setuser_name(res.data.data.user.first_name+" "+res.data.data.user.last_name )
                localStorage.setItem("user_name", res.data.data.user.first_name+" "+res.data.data.user.last_name)
                if(res.data.data.user.image!=null){
                    globalData.setuser_image(bUrl+res.data.data.user.image )
                    localStorage.setItem("user_image", bUrl+res.data.data.user.image)
                }
                setmessage1("Success!")
                setmessage2("Profile updated successfully!")
                handleShow()
            })
                .catch((err) => {
                    handleShowErr()
        
                    seterrmessage2(err.response.data.message)
                    setIsLoader(false)
                    //   Swal.fire("Error", "Something went wrong.", "error");
                    console.log("contact us api error", err.response.data.message);
                });
        }

    }
    return (
        <>
            <>
                {isLoader && <Loader />}
                <div className="overflow-x">
                <section className="my-profile-area">
                    <Container>
                        <div className="manage-truck-area ">
                            <div className="my-profile-top">
                                <h3>Edit Profile</h3>

                            </div>
                            <div className="trucker-image-area">
                                
                                {!image ?
                                    <img src={require("../../../assets/images/trucker-image.png")} alt="img" /> :
                                    <img src={image} alt="img" />}
                                <div className="profile-edit-area">
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                    <Form.Control type="file" onChange={e => onInputChange('image', e.target.files[0])} />
                                </div>
                            </div>
                            <p className="errors" style={{textAlign:"center"}}>{imageErr}</p>
                            <div className="stepper-form-area">
                                <Form>
                                    <Row>
                                        <Col md={6}>

                                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control type="text" maxLength={30} onKeyDown={handleKeyDownName}  value={profileFields.first_name} placeholder="Enter First Name" onChange={e => onInputChange('first_name', e.target.value)} />
                                                <span className="errors">{profileFieldsErrs.first_name}</span>
                                            </Form.Group>

                                        </Col>
                                        <Col md={6}>

                                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control maxLength={30} onKeyDown={handleKeyDownName}  type="text" value={profileFields.last_name} placeholder="Enter Last Name" onChange={e => onInputChange('last_name', e.target.value)} />
                                                <span className="errors">{profileFieldsErrs.last_name}</span>
                                            </Form.Group>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>

                                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                                <Form.Label>Business Name</Form.Label>
                                      
                                                <Form.Control type="text" value={profileFields.business_name} placeholder="Enter Business Name" onChange={e => onInputChange('business_name', e.target.value)} />
                                                <span className="errors">{profileFieldsErrs.business_name}</span>
                                            </Form.Group>

                                        </Col>
                                        {/* <Col md={6}>

                                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                                <Form.Label>Trade Name</Form.Label>
                                                <Form.Control type="text" value={profileFields.trade_name} placeholder="Enter Retailer Name" onChange={e => onInputChange('trade_name', e.target.value)} />
                                                <span className="errors">{profileFieldsErrs.trade_name}</span>
                                            </Form.Group>

                                        </Col> */}
                                               <Col md={6}>
                                               <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Label>Contact No.</Form.Label>
                      <PhoneInput
                        country={
                          COUNTRY_CODE ? COUNTRY_CODE.toLocaleLowerCase() : "ph"
                        }
                        className={
                        //   contactUsFieldsErrors.phone
                        //     ? "border-red phone-input"
                             "phone-input "
                        }
                        placeholder="Enter contact number"
                        value={profileFields.phone}
                        onChange={(e) => {
                          setprofileFields((prevObj) => {
                            return {
                              ...prevObj,
                              phone: e,
                            };
                          });

                        //   if (e.trim() === "") {
                        //     setContactUsFieldsErrors((prevObj) => {
                        //       return {
                        //         ...prevObj,
                        //         phone: true,
                        //       };
                        //     });
                        //   } else if (e.trim().length < 10) {
                        //     setContactUsFieldsErrors((prevObj) => {
                        //       return {
                        //         ...prevObj,
                        //         phone: true,
                        //       };
                        //     });
                        //   } else {
                        //     setContactUsFieldsErrors((prevObj) => {
                        //       return {
                        //         ...prevObj,
                        //         phone: false,
                        //       };
                        //     });
                        //   }
                        }}
                      />
                    <span className="errors">{profileFieldsErrs.phone}</span>
                    </Form.Group>
                    </Col>
                                    </Row>
                                  
                     
                                    <Row>
                             
                                        <Col md={6}>

                                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                                <Form.Label>Business Address</Form.Label>
                                                <GooglePlacesAutocomplete
                                            apiKey={gKey}
                                            className="form-control"
                                            onFail={error => console.log(error)}
                                            onNotFound={() => console.log('no results')}
                                        
                                            selectProps={{
                                                value,
                                                onChange: setValue,
                                                    }}
                                                    
                                />
                                                {/* <Form.Control type="text" value={profileFields.business_address} placeholder="Enter Business Address" onChange={e => onInputChange('business_address', e.target.value)} /> */}
                                                <span className="errors">{profileFieldsErrs.business_address}</span>
                                            </Form.Group>

                                        </Col>
                                        <Col md={6}>

                                             
                                            <Form.Group className="mb-4  truck-logo-upload-field" controlId="formBasicEmail">
                                                <Form.Label>Upload Business Logo</Form.Label>
                                                <div className="upload-box-area">
                                                <div className="logo-upload-box">
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                                    <Form.Control type="text" />
                                                    <Form.Control type="file" className="upload-item" onChange={e => onInputChange('business_logo', e.target.files[0])} />
                                                </div>
                                                {/* <i className="fa fa-plus" aria-hidden="true"></i> */}
                                                {/* <span className="errors">{profileFieldsErrs.business_logo}</span> */}
                                                {logo ?
                                                <div className="upload-image-preview">
                                                    <div className="first-preview-image">
                                                        <img src={ logo} alt={`Preview logo`} className="image-preview" />
                                                    </div>

                                                </div> : null}
                                            <span className="errors">{profileFieldsErrs.business_logo}</span>
                                            </div>
                                            </Form.Group>


                                     

                                          

                                        </Col>
                                    </Row>
                                    <Button type="button" onClick={handleSubmit} variant="unset" className="edit-profile-update-btn">update</Button>
                                </Form>
                            </div>
                        </div>
                    </Container>

                    </section>
                    </div>

                <SuccessPopup show={show} handleClose={handleClose} message1={message1} message2={message2} redirectlink={redirectlink} />
                <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} redirectlink={redirectlink}/>
            </>
        </>
    );
};
export default TruckerEditProfile;