import Footer from "./Footer";
import Header from "./Header";
import AuthHeader from "./AuthHeader";
import { useLocation } from "react-router";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_test_51NLdGwEXsyM13gE7qaBxt1Vs0YKWZxeYWehQ51atu3HFd8j0k3jQqQBhT94vNI9Nc8UHVHCwe943tmmT8h9zDEFc00OSfy5vsS');
const CommonLayout = (props) => {
  const location = useLocation();
  const token = localStorage.getItem("web_token");
  console.log("===path===",location.pathname)
  if(token && (location.pathname === "/register" ||
  location.pathname === "/login" ||
  location.pathname === "/forgot-password" ||
  location.pathname === "/create-password/:token") ){
    if(localStorage.getItem("is_profile_completed")=="yes"){
      window.location.href ="/"
    }else{
      window.location.href ="/create_profile"
    }
   
  }

  return (
    <>

      {
        location.pathname === "/create_profile" || !token ?
          <Header headerClass={props.headerClass ? props.headerClass : ""} />
          :
          <AuthHeader />
      }
  <Elements stripe={stripePromise}>
      {props.children}
      </Elements>
      {location.pathname === "/register" ||
        location.pathname === "/login" ||
        location.pathname === "/forgot-password" ||
        location.pathname === "/create-password/:token" ?
        null :
        <Footer />}
    </>
  );
};

export default CommonLayout;
