import { useState,useEffect,useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import { Navigation,Autoplay} from 'swiper/modules';
import { SwiperSlide, Swiper } from "swiper/react";
import moment from "moment";
import { apiService } from "../../../../services/api.services";
import Nodata from "../Dashboard/Nodata";
import Loader from "../../../common/Loader";
import { context } from "../../../../GlobalContext/GlobalContext";
const Home = () => {
    const [show, setShow] = useState(false);
    const globalData = useContext(context);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loadList, setloadList] = useState()
    const [ isLoader , setIsLoader] = useState(false)
    const [TotalloadList, setTotalloadList] = useState(0)
    useEffect(() => {
        my_loads()
        dashboardData()
       }, []);
    const my_loads = ()=>{
         setIsLoader(true)
        apiService.my_loads(1,"accepted").then((res) => {
            
            setloadList(res.data.data)
         console.log("======lllllll",res.data.data)
             setIsLoader(false)
         })
           .catch((err) => {
            setIsLoader(false)
           });
       }
       const dashboardData = () => {
      
        apiService.dashboardData().then((res) => {
            console.log("=======dashboardData======",res.data.data)
            setTotalloadList(res.data.data.load)
           
        //   console.log(res.data.data)
          }).catch((err) => {
            //   setIsLoader(false)
            });
             
         }
    return (
        <>
   
            <div className="dashboard-hero-banner-area">
                <Row>
                    <Col md={4}>
                        <div className="dashboard-area-left">
                            <h1>Completed<br />
                                <span>Loads</span><br />
                                {/* {TotalloadList?TotalloadList:"Loading.."} */}
                                {TotalloadList}
                                </h1>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="w-dashboard-mid">
                          <img src={require("../../../../assets/images/t-dashboard-mid.png")} alt="img" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="dashboard-area-right">
                            <Button type="button" variant="unset" className="find-insurance-btn" onClick={handleShow}>Truck Bans</Button>
                            <Button type="button" variant="unset" className="active-request" onClick={handleShow}>Diesel P.O. Request</Button>
                            <Button type="button" variant="unset" className="find-insurance-btn" onClick={handleShow} >City Passes</Button>
                        </div>
                    </Col>
                </Row>
            </div>
            <p className="note-dashboard">*To ensure the safety and security of your payments, products, and services, we kindly request that you refrain from conducting transactions outside of the DistriX platform.</p>
            <div className="recent-loads-area ">
                <div className="recent-loads-top-area ">
                    <h3>Recent <span>upcoming loads</span></h3>
                    {loadList && loadList.length==0?null:
                    <Button type="button" variant="unset">View more</Button>}
                </div>
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={50}
                    slidesPerView={3}
                    
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => {
                        
                    } }
                    navigation
                    breakpoints={{
                        // when window width is >= 640px
                        0: {
                          slidesPerView: 1,
                        },
                        480: {
                          slidesPerView: 1,
                        },
                        575: {
                          slidesPerView: 1,
                        },
                        // when window width is >= 768px
                        768: {
                          slidesPerView: 2,
                        },
                        991: {
                          slidesPerView: 3,
                        },
                        1400: {
                          slidesPerView: 3,
                        }
                      }}
                >
                     {isLoader && <Loader />}
                       {loadList && loadList.length==0?
                            <Nodata message1={"No Loads Available"} message2={""}/>:
                            <>
                      {loadList && loadList.map((list, index) => {
                    return(<SwiperSlide>
                        <div className="upcoming-loads-content">
                            <h6>{list.load_type=="schedule"?list.schedule.primary_client?.name:list.order.product_sku[0].business_profile.business_name}</h6>
                            <ul>
                                <li><i className="fa fa-map-marker" aria-hidden="true"></i> {list.load_type=="schedule"?list.schedule.primary_client.address[0].address:list.order.product_sku[0].business_profile.business_address}</li>
                                <li><i className="fa fa-map-marker" aria-hidden="true"></i> { list.load_type=="schedule"?list.schedule.destination:list?.order.address.address}</li>
                                { list.load_type=="schedule"?null: <li><i className="fa fa-calendar" aria-hidden="true"></i> {moment(list.order.estimated_packing).format('DD MMMM YYYY')} <span><i className="fa fa-clock-o" aria-hidden="true"></i>{moment(list.order.estimated_packing).format('hh:mm')}</span></li>}
                            </ul>
                            <div className="upcoming-loads-content-bottom">
                                {/* <Button type="button" variant="unset">View load <i className="fa fa-angle-right" aria-hidden="true"></i></Button> */}
                                <p>₱ {list.load_type=="schedule"?list.schedule.delivery_charge:list.order.delivery_charge}</p>
                            </div>
                        </div>
                    </SwiperSlide>)})}
                  </>}
                </Swiper>
            </div>

            <div className="recent-loads-area pt-0">
                <div className="recent-loads-top-area">
                    <h3>Community: <span>Truckers</span></h3>
                </div>
                <div className="join-group-links-left">
                    <ul>
                        <li>
                        <p>Join Our Community of Like Minded Truckers. <a href="https://tinyurl.com/4nvh8w8s" target="blank">Click Here!</a></p>
                      
                        </li>
                        <li>
                        <p>For Tutorials On How to Effectively Use the Platform:  <a href="https://tinyurl.com/yjs3dhjt" target="blank">Try It!</a></p>
                      
                        </li>
                    </ul>
                </div>
            </div>

            <Modal className="success-box feature-available" show={show} onHide={handleClose}  centered>
                <Modal.Header>
                    <Modal.Title>
                        <img src={require("../../../../assets/images/chemark.png")} alt="img" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Feature will be available soon</h5>
                </Modal.Body>
                <Modal.Footer>
                   
                    <Button variant="primary" onClick={handleClose}>
                     Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Home