import { Button } from "react-bootstrap";

const ComingSoon = () => {
    return (
        <>
            <div className="manage-truck-area coming-soon-page">
                <div className="coming-soon-page-content">
                    <h2>We're Coming Soon</h2>
                    <p>We're working hard to finish the development of this site.</p>
                    <Button type="button" variant="unset">Go to Hompage</Button>
                </div>
            </div>
        </>
    );
};
export default ComingSoon;