import { Button, Col, Container, Row ,Modal,Form} from "react-bootstrap";
import { useEffect, useState, useContext } from "react";
import { apiService } from "../../services/api.services";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import { bUrl,retailer_type,wholeseller_type} from "../../config/config"
import Loader from "./Loader";
import SuccessPopup from "../modal/SuccessPopup";
import moment from "moment";
import Swal from "sweetalert2";
import Select from "react-select";

const OrderDetail = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get('delivery');
    const [showdelete, setShowdelete] = useState(false);
    const [show, setshow] = useState(false);
    const [showdelivery, setshowdelivery] = useState(false);
    const [message1, setmessage1] = useState("Success");
    const [message2, setmessage2] = useState("");
    const handleClose=()=> setshow(false)
    const [estimateDelivery, setestimateDelivery] = useState(null)
    const [estimatePacking, setestimatePacking] = useState(null)
    const [truckType, setTruckType] = useState("1")
    const [showErr, setShowErr] = useState(false);
    const [errmessage1, seterrmessage1] = useState("Error")
    const [errmessage2, seterrmessage2] = useState("")
    const [statusErr, setstatusErr] = useState("")
    const [type, settype] = useState("pending");
    const handleClosedelete = () => setShowdelete(false);
    const handleShowdelete = (id) => {
      setShowdelete(true);

  }
  const change_delivery = ()=>{
    setIsLoader(true)
    let data = {
        estimated_delivery : estimateDelivery?new Date(estimateDelivery):null,
        estimated_packing : estimatePacking?new Date(estimatePacking):null,
        delivery_type : truckType,
        // delivery_status:delivery_status
    }
    apiService.change_delivery(data,orderId).then((res) => {
        console.log("======cancel===",res.data)
        setIsLoader(false)
        setmessage1("Success")
        setmessage2(res.data.message)
        handleClose()
        setshow(true)
     
        get_order_detail()
         }).catch((err) => {
            console.log(err);
           setIsLoader(false)
           Swal.fire("Error", err.response.data.message, "error");
          //  console.log("contact us api error", err.response.data.message);
         });
 }
  const   cancel_orders = (id)=>{
    setIsLoader(true)
      apiService.cancel_order(id,"reject").then((res) => {
          console.log("======cancel===",res.data)
            setmessage1("Success")
                setmessage2(res.data.message)
                setshow(true)
          setIsLoader(false)
          get_order_detail()
           }).catch((err) => {
              console.log(err.response)
              seterrmessage1("Error")
              seterrmessage2(err?.response?.data?.message?err?.response?.data.message:"Something went wrong")
              setShowErr(true)
            
             setIsLoader(false)
           //   Swal.fire("Error", "Something went wrong.", "error");
            //  console.log("contact us api error", err.response.data.message);
           });
           
       }
  const handleClosedelivery = () => setshowdelivery(false);
  const handleShowdelivery = (id) => {
    setshowdelivery(true);

}
  const options = [
    { value: "confirm", label: "Confirm" },
    { value: "cancel", label: "Cancel" },

    
];
const acceptReject = (value, id) => {
    if (value == "cancel") {
        cancel_orders(id)
    }
    if (value == "confirm") {
        setorderId(id)
        handleShowdelivery()
        
    }

}
  const statusChange = (value, id) => {
    setIsLoader(true)
    let data ={
        delivery_status : value
    }
    apiService.change_status(data,id).then((res) => {
        console.log("======cancel===",res.data)
        setIsLoader(false)
        setmessage1("Success")
        setmessage2(res.data.message)
        setshow(true)
     
        get_order_detail()
         }).catch((err) => {
            console.log(err);
           setIsLoader(false)
           Swal.fire("Error", err.response.data.message, "error");
          //  console.log("contact us api error", err.response.data.message);
         });

}
    let history = useNavigate();
    const statusOptions2 =(id) => {
        if(id==5){
            return ([
               
                { value: 6, label: "Ready to Ship" },
                { value: 7, label: "Shipped" },
        
            ])
        }
       else if(id ===6){
            return ([
            { value: 7, label: "Shipped" },
        
            ])
        }else{
            return ([
                { value: 5, label: "Order Packed" },
                { value: 6, label: "Ready to Ship" },
                { value: 7, label: "Shipped" },
        
            ])
        }
      
     
    
}
    const statusOptions =(id) => {
        if(id==2){
            return ([
               
                { value: 5, label: "Order Packed" },
                { value: 6, label: "Ready to Ship" },
                { value: 7, label: "Shipped" },
                { value: 8, label: "Delivered" },
        
            ])
        }
        if(id ===5){
            return ([
               { value: 6, label: "Ready to Ship" },
                { value: 7, label: "Shipped" },
                { value: 8, label: "Delivered" },
        
            ])
        }
        if(id ===6){
            return ([
              
                { value: 7, label: "Shipped" },
                { value: 8, label: "Delivered" },
        
            ])
        }
        if(id ===7){
            return ([
              { value: 8, label: "Delivered" },
        
            ])
        }
    
}

    const [isLoader, setIsLoader] = useState(true)
    const id = useParams()
    const [orderId, setorderId] = useState(id)
    const   confirm_orders = ()=>{
        if(truckType==1 && estimatePacking==""){
            setstatusErr("Please select your Packing date")
        }
       else if(truckType==2 && estimateDelivery==""){
            setstatusErr("Please select your estimated delivery date")
        }else{
        setIsLoader(true)
        handleClosedelivery()
        let data = {
            estimated_delivery : estimateDelivery?new Date(estimateDelivery):null,
            estimated_packing : estimatePacking?new Date(estimatePacking):null,
            delivery_type : truckType,
            // delivery_status:delivery_status
        }
          apiService.confirm_orders(orderId,data).then((res) => {
              console.log("======cancel===",res.data)
              setmessage1("Success")
              setmessage2(res.data.message)
              setshow(true)
              setstatusErr("")
              
              setIsLoader(false)
              get_order_detail()
               }).catch((err) => {
                seterrmessage1("Error")
                seterrmessage2(err?.response?.message?err?.response?.message:"Something went wrong")
                setShowErr(true)
                 setIsLoader(false)
               //   Swal.fire("Error", "Something went wrong.", "error");
                //  console.log("contact us api error", err.response.data.message);
               });
            }
               
           }
    const [orderData, setorderData] = useState();
    useEffect(() => {
        get_order_detail()
    }, [id]);
    const get_order_detail = () => {

        apiService.get_order_detail(id.id).then((res) => {
            console.log("===orderDetail=======", res.data)
            setorderData(res.data)
            setIsLoader(false)
        })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.clear();
                    window.location.href = "/login"
                }
                setIsLoader(false)
                //   Swal.fire("Error", "Something went wrong.", "error");
                //  console.log("contact us api error", err.response.data.message);
            });
    }

    const confirm = () => {
        setIsLoader(true)
        handleClosedelete()
        apiService.confirm_delivery(id.id).then((res) => {
            console.log("===orderDetail=======", res.data)
            setmessage2("Order Confirmed")
            get_order_detail()
            setshow(true)
            setIsLoader(false)
        })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.clear();
                    window.location.href = "/login"
                }
                setIsLoader(false)
                handleClosedelete()
                //   Swal.fire("Error", "Something went wrong.", "error");
                //  console.log("contact us api error", err.response.data.message);
            });
    }

    return (
        <>

            {isLoader && <Loader />}

            <section className="order-history-area">
                <Container>
                    <div className="back-btn">
                        <a onClick={() => history(-1)}><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back</a>
                    </div>

                    <div className="manage-truck-area mt-0">

                        <div className=" product-management-area ">
                            <div className="product-management-top">
                                <h3>Order Detail</h3>
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                            </div>
                        </div>

                        <div className="order-detail-area">

                            <h6 className="mb-1">{localStorage.getItem("user_type") === retailer_type?orderData?.wholesaler_business_profile.business_name:orderData?.retailer_business_profile.business_name}</h6>
                            <p>Created Date: <span>{moment(orderData?.created_at).format('DD MMMM YYYY')}</span></p>
                            <p className="mb-0"><img src={require("../../assets/images/location-icon.png")} alt="img" />{orderData?.wholesaler_business_profile.business_address}</p>
                            <p className="mb-0"><img src={require("../../assets/images/location-icon2.png")} alt="img" />{orderData?.retailer_business_profile.business_address}</p>
                            {orderData?.load_accepted && orderData?.delivery_type == "1" ?
                                <>
                                    <h6 className="mt-4 mb-1">Delivery Partner Details</h6>
                                    <div className="truck-detail-bottom">
                                        <p>Type: <span>{orderData?.loaded_truck.type.name}</span></p>
                                        <p>Plate No:  <span>{orderData?.loaded_truck.plate_number} </span></p>
                                        <p>Driver’s name:  <span>{orderData?.loaded_truck.driver.name}h</span></p>
                                        <p>Delivery Date: <span><i class="fa fa-calendar" aria-hidden="true"></i></span> {moment(orderData?.delivery_date).format('DD MMMM YYYY')} </p>
                                        {/* <p><span><i class="fa fa-clock-o" aria-hidden="true"></i></span> 11:00 am </p> */}
                                    </div></>
                                : null}
                            <div className=" order-management-content-right">
                            {localStorage.getItem("user_type") === retailer_type?
                                <>
                                {(paramValue=="confirm" || orderData?.delivery_status.id == 8 
                                ) && localStorage.getItem("user_type") === retailer_type?
                                <>
                                 {orderData?.delivery_confirmed?
                                 <Button type="button" variant="unset"> Received</Button>:

                                <Button type="button" variant="unset" onClick={handleShowdelete}>Confirm Delivery</Button>}

                                </>
                                 :
                             
                                <>
                                {orderData?.delivery_status.id == 4 ?
                                    <Button type="button" variant="unset" className="cancel-btn">Canceled</Button> :
                                    <>
                                        {orderData?.delivery_status.id == 8 ?
                                            <Button type="button" variant="unset">Delivered</Button> :
                                            <>
                                                {orderData?.delivery_status.id == 5 || orderData?.delivery_status.id == 6 || orderData?.delivery_status.id == 7 ?
                                                    <Button type="button" variant="unset" className="ready-to-ship btn btn-unset">{orderData?.delivery_status.status}</Button> :
                                                    <>
                                                        <Button type="button" variant="unset" className="shipped btn btn-unset">Pending</Button>
                                                    </>}

                                            </>}

                                    </>}
                                    </>}

                                    </>:null}
                                    {localStorage.getItem("user_type") === wholeseller_type?
                                    <div className="wholeseller-options">
                                    {orderData?.delivery_status.id==1?
                                    <Form.Group
                                                    className="mb-2"
                                                    controlId="exampleForm.ControlInput1"
                                                >

                                                    <Select options={options}
                                                     value={options.filter(function(option) {
                                                        return option.value === orderData?.delivery_status.id;
                                                      })}
                                                        onChange={e => acceptReject(e.value, orderData.id)}
                                                        placeholder="Change Status"
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                height: "50px",
                                                                background: "transparent",
                                                                borderRadius: "15px",
                                                                border: "none",
                                                                fontWeight: "600",
                                                                boxShadow: "none",
                                                                color: "#000",

                                                                "&:focus": {
                                                                    borderColor: "none",
                                                                },
                                                                "&:hover": {
                                                                    borderColor: "none",
                                                                },
                                                            }),
                                                            singleValue: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                color: "#000",
                                                            }),
                                                            // menuList: (baseStyles, state) => ({

                                                            // })
                                                        }} />
                                                </Form.Group>:<>
                                    {orderData?.delivery_type=="1" && orderData?.delivery_status.id == 7 ?
                                        <>
                                        {orderData?.delivery_status.id == 5?
                                        <div>
                                         
                                           <Button type="button" variant="unset" >Packed</Button>
                                       </div>:<>
                                       {orderData?.delivery_status.id == 6?
                                             <div >
                                             <Button type="button" variant="unset" className="ready-to-ship">Ready to ship</Button>
                                         </div>:
                                            <>
                                             {orderData?.delivery_status.id == 7?
                                             <div >
                                               <Button type="button" variant="unset" className="shipped">Shipped</Button></div>
                                               :
                                               <>

                                               </>}


                                            </>}

                                       </>}
                                       </>
                                       
                                       :
                                       
                                            <div>
                                                 {orderData?.delivery_status.id == 8 ?
                                                <>
                                                
                                                <Button type="button" variant="unset"> {orderData?.delivery_confirmed?"Delivery Confirmed":"Delivered By Trucker"}</Button> <br></br>
                                                {orderData?.delivery_confirmed?null:<span className="confirm-text">Confirmation Pending from retailer</span>}
                                                </>
                                                
                                                :
                                                    <>
                                                     {orderData?.delivery_status.id == 4 ?
                                                     <Button type="button" variant="unset" className="cancel-btn">Canceled</Button>:
                                                     <></>
                                                    //  <Button type="button" variant="unset" className="cancel-btn">Rejected</Button>
                                                     }

                                                 
                                                    </>
                                                    
                                                    }
                                                      {orderData?.delivery_status.id !== 8 ?
                                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1" >
                                             <Select options={orderData?.delivery_type=="1"?statusOptions2(orderData?.delivery_status.id):statusOptions(orderData?.delivery_status.id)}
                                                        
                                                       onChange={e => statusChange(e.value, orderData.id)}
                                                        placeholder={orderData?.delivery_status.status}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                height: "50px",
                                                                background: "transparent",
                                                                borderRadius: "15px",
                                                                border: "none",
                                                                fontWeight: "600",
                                                                boxShadow: "none",
                                                                color: "#000",

                                                                "&:focus": {
                                                                    borderColor: "none",
                                                                },
                                                                "&:hover": {
                                                                    borderColor: "none",
                                                                },
                                                            }),
                                                            singleValue: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                color: "#000",
                                                            }),
                                                            
                                                        }} />
                                                </Form.Group>:null}
                                                
                                            </div>

                                            }
                                                 
                                                
                                  </>}



                                                </div>:null}

                                    
                            </div>
                        </div>
                        {orderData && orderData.quantity.map((item, index) => {
                            return (<div className="manage-truck-detail-box">
                                <Row>
                                    <Col md={10}>
                                        <div className="manage-truck-detail-left order-management-content">
                                            <div className="completed-orders-detail-area order-detail-content-area">
                                                <div className="completed-order-left">
                                                    <img src={bUrl + item.product_sku.images[0].image} alt="img" />
                                                </div>
                                                <div className="completed-order-right">
                                                    <h6 className="mb-1">{item.product_sku.product.name}</h6>
                                                    <div className="truck-detail-bottom">
                                                        <p>Number of units: <span>{item.product_sku.item_per_unit}/{item.units}</span></p>
                                                        <p>Weight
                                                            : <span>{item.product_sku.weight}kg, </span> Height: <span>{item.product_sku.height}ft, </span> Width: <span>{item.product_sku.width}ft,</span>Length: <span>{item.product_sku.length}ft</span></p>
                                                        <p>Price per unit:  <span>{item.product_sku.price}/-</span></p>
                                                        <p>Quantity: <span>{item.quantity}</span></p>
                                                        <p>Total CBM: <span>{item.order.order_cbm}</span></p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div className=" order-management-content-right order-detail-price">
                                            <p>₱ {item.product_sku.price * item.quantity}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>)
                        })}


                        <p className="total-amount-area">Total Amount: <span>₱ {orderData?.order_price}</span></p>
                    </div>
                </Container>
                <SuccessPopup show={show} handleClose={handleClose} message1={message1} message2={message2} redirectlink={""} />
                <Modal className="success-box trash-box" show={showdelete} onHide={handleClosedelete} centered>
                <Modal.Header >
                    <Modal.Title>
                      Confirm ?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to confirm this?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>confirm()}>
                       YES
                    </Button>
                    <Button className="trash-no-btn" variant="primary" onClick={handleClosedelete}>
                      NO
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal className="success-box choose-option-area" show={showdelivery} onHide={handleClosedelivery} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Choose Delivery Option</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={truckType=="1"?"distrix-truck-area":"own-truck-area"}>
                        <Button type="button" variant="unset" onClick={() => setTruckType("1")}>DistriX’s truck</Button>
                        {
                            truckType === "1" ?
                                <Form.Group className="mt-2 " controlId="formBasicPassword">
                                    <label htmlFor="">Packed by</label>
                                    <input className="form-control" type="date" value={moment(estimatePacking).format('YYYY-MM-DD')} selected={estimatePacking} onChange={(e) => setestimatePacking(e.target.value)} dateFormat="YYYY-MM-DD" />
                                    <p className="errors">{statusErr}</p>
                                </Form.Group>
                                : ""
                        }
                    </div>
                    <div className={truckType=="2"?"distrix-truck-area":"own-truck-area"}>
                        <Button type="button" variant="unset" onClick={() => setTruckType("2")}> Own Truck</Button>
                        {
                            truckType === "2" ?
                                <Form.Group className="mt-2 " controlId="formBasicPassword">
                                    <label htmlFor="">Delivered by</label>
                                    <input className="form-control" type="date"  value={moment(estimateDelivery).format('YYYY-MM-DD')} selected={estimateDelivery} onChange={(date) => setestimateDelivery(date)} dateFormat="YYYY-MM-DD"/>
                              <p className="errors">{statusErr}</p>
                                </Form.Group>
                                : ""
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        truckType &&
                        <Button variant="secondary" onClick={()=>{type=="pending"?confirm_orders():change_delivery()}}>
                            Submit
                        </Button>
                    }

                </Modal.Footer>
            </Modal>
            </section>
        </>
    );
};
export default OrderDetail;