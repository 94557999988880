import { Button, Col, Row } from "react-bootstrap"
import { useState, useEffect } from "react";
import { Navigation } from 'swiper/modules';
import { SwiperSlide, Swiper } from "swiper/react";
import { apiService } from "../../../../services/api.services";
import Loader from "../../../common/Loader";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Nodata from "../Dashboard/Nodata";
const Home = ({ setKey }) => {
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState(false)
    const [orderList, setorderList] = useState();
    const [totalProduct, settotalProduct] = useState(0);
    const [totalOrder, settotalOrder] = useState(0);

    useEffect(() => {
        get_orders()
        dashboardData()

    }, []);
    const orderDetail = (id) => {
        window.location.href = "/order-detail/" + id
    }
    const viewall = (id) => {
        setKey("order_management")
        navigate("/dashboard?tab=order_management")
    }

    const get_orders = () => {
        setIsLoader(true)

        apiService.get_orders("pending", 1).then((res) => {

            setorderList(res.data.data)
            setIsLoader(false)

        }).catch((err) => {
            setIsLoader(false)
        });

    }
    const dashboardData = () => {

        apiService.dashboardData().then((res) => {
            console.log("=======dashboardData======", res.data.data)
            settotalProduct(res.data.data.product)
            settotalOrder(res.data.data.order)
            //   console.log(res.data.data)
        }).catch((err) => {
            //   setIsLoader(false)
        });

    }

    return (
        <>
            <div className="dashboard-hero-banner-area w-dashboard-area">
                <Row>
                    <Col md={4}>
                        <div className="dashboard-area-left">
                            <h1> Number of<br />
                                <span> Products</span><br />
                                {totalProduct}
                                {/* {totalProduct?totalProduct:"Loading...."} */}
                            </h1>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="w-dashboard-mid">
                            <img src={require("../../../../assets/images/w-dashboard-mid.png")} alt="img" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="dashboard-area-left dashboard-area-right">
                            <h1>
                                <span>Today’s New
                                    orders</span><br />
                                {totalOrder}
                                {/* {totalOrder?totalOrder:"Loading...."} */}
                            </h1>
                        </div>
                    </Col>
                </Row>
            </div>
            <p className="note-dashboard">*To ensure the safety and security of your payments, products, and services, we kindly request that you refrain from conducting transactions outside of the DistriX platform.</p>
            <div className="recent-loads-area">
                <div className="recent-loads-top-area">
                    <h3>Newly<span> Received Orders</span></h3>
                    {orderList && orderList.length > 0 ?
                        <Button type="button" variant="unset" onClick={viewall}>View more</Button> : null}
                </div>
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={50}
                    slidesPerView={3}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => {

                    }}
                    navigation
                    breakpoints={{
                        // when window width is >= 640px
                        0: {
                            slidesPerView: 1,
                        },
                        480: {
                            slidesPerView: 1,
                        },
                        575: {
                            slidesPerView: 1,
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 2,
                        },
                        991: {
                            slidesPerView: 3,
                        },
                        1400: {
                            slidesPerView: 3,
                        }
                    }}
                >
                    {isLoader && <Loader />}
                    {orderList && orderList.length == 0 ?
                        <Nodata message1={"No New Order Received"} message2={""} /> :
                        <>
                            {orderList && orderList.length > 0 && orderList.map((orderData, index) => {
                                return (<SwiperSlide>
                                    <div className="upcoming-loads-content">
                                        <h6>{orderData.product_sku[0].product.name}</h6>
                                        <ul>
                                            <li><i className="fa fa-map-marker" aria-hidden="true"></i> {orderData.address.address + "," + orderData.address.city + "," + orderData.address.state + "," + orderData.address.pincode}</li>
                                            <li><i className="fa fa-calendar" aria-hidden="true"></i> {moment(orderData?.created_at).format('DD MMMM YYYY')}  <span><i className="fa fa-clock-o" aria-hidden="true"></i>{moment(orderData?.created_at).format('hh:mm A')}</span></li>
                                        </ul>
                                        <div className="upcoming-loads-content-bottom">
                                            <Button type="button" variant="unset" onClick={() => orderDetail(orderData.id)}>View Order <i className="fa fa-angle-right" aria-hidden="true"></i></Button>
                                            <p>₱ {orderData.order_price}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>)
                            })}
                        </>}

                </Swiper>
            </div>

            <div className="recent-loads-area pt-0">
                <div className="recent-loads-top-area">
                    <h3>Community: <span>Suppliers & Wholesalers</span></h3>
                </div>
                <div className="join-group-links-left">
                    <ul>
                        <li>
                            <p>Join Our Community of Like Minded Suppliers & Wholesalers.  <a href="https://tinyurl.com/2cnxxzry" target="blank">Click Here!</a></p>
                        </li>
                        <li>
                            <p>For Tutorials On How to Effectively Use the Platform:   <a href="https://tinyurl.com/yc4ts953" target="blank">Try It!</a></p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Home