import { useState,useEffect,useContext } from "react";
import { Container, Form } from "react-bootstrap";
import { Stepper } from "react-form-stepper";
import Step1 from "./Stepper/Step1"
import Step2 from "./Stepper/Step2"
import Step3 from "./Stepper/Step3"
import Loader from "../../common/Loader";
import { apiService} from "../../../services/api.services";
import { validateService} from "../../../services/validate.services";
import { useNavigate } from "react-router-dom";
import { context } from "../../../GlobalContext/GlobalContext";
import swal from "sweetalert2";
import SuccessPopup from "../../modal/SuccessPopup"
import Errorpopup from "../../modal/Errorpopup"
import {allowedImageTypes, bUrl,gKey} from "../../../config/config"
import GooglePlacesAutocomplete,{ geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

const TruckerCreateProfile = () => {
  const globalData = useContext(context);
  const [activeStep, setActiveStep] = useState(localStorage.getItem("profile_step")-1);
  const [ isLoader , setIsLoader] = useState(false)
  const [show, setShow] = useState(false);
  const [ message1 , setmessage1] = useState("")
  const [ message2 , setmessage2] = useState("")
  const [ redirectlink , setredirectlink] = useState("/")
  const [ truck_types , settruck_types] = useState([])
  const [ weight_units , setweight_units] = useState([])
  const [ imagesTruck , setimagesTruck] = useState([])
  const [images, setImages] = useState([]);
  const [logo, setlogo] = useState();
  const [govtId, setgovtId] = useState();
  const [showErr, setShowErr] = useState(false);
  const [ errmessage1 , seterrmessage1] = useState("Error")
  const [ errmessage2 , seterrmessage2] = useState("")
  const [ resend_btn , setresendbt] = useState(false)
  const [value, setValue] = useState(null);
  const handleCloseErr = () => {
    setShowErr(false);
  }
  const handleShowErr = () => setShowErr(true);
  const navigate = useNavigate();
  const handleShow = () => setShow(true);
  const handleClose = () => {
    navigate(redirectlink)
    setShow(false);
  }
  const steps = [
    { label: 'Create Profile', onClick: () => setActiveStep(0) },
    { label: 'Add Bank Detail', onClick: () => setActiveStep(1) },
    { label: 'Add Trucks', onClick: () => setActiveStep(2) },
  ];
console.log("======",globalData.profileStep)
  useEffect(() => {
    
    console.log(localStorage.getItem("profile_step"),"=====profile_step=")
    get_business_profile()
    get_truck_types()
    get_weight_units()
  }, []);
  const [profileFields, setprofileFields] = useState({
    first_name: "",
    last_name: "",
    business_name:"",
    trade_name: "",
    ltfrb_number: "",
    marine_insurance: "",
    dti_sec_number: "",
    permit_number: "",
    business_address: "",
    phone:"",
    latitude:"",
    longitude:"",
    business_logo: "",
    govt_id:"",
    investment:0
   });
   const [profileFieldsErrs, setprofileFieldsErrs] = useState({
    first_name: "",
    last_name: "",
    business_name:"",
    trade_name: "",
    ltfrb_number: "",
    marine_insurance: "",
    dti_sec_number: "",
    permit_number: "",
    business_address: "",
    phone:"",
    business_logo: "",
    govt_id:"",
    investment:""
   });

     
  const [bankFields, setbankFields] = useState({
         account_number :"",
        // routing_number: "",
        account_holder_name :"",
        bank_id : ""
   });
   const [bankFieldsErrs, setbankFieldsErrs] = useState({
    account_number :"",
    // routing_number: "",
    account_holder_name :"",
    bank_name : ""
   });
   const [truckFields, settruckFields] = useState({
    type: "",
    plate_number: "",
    model_year:"",
    capacity: "",
    weight_unit: 1,
    length: 0,
    width: 0,
    height: 0,
    total_cbm: "",
    make: "",
    color: "",
    driver_name: "",
    investment:0,
    images:[]
   });

   
   const [truckFieldsErr, settruckFieldsErr] = useState({
    type: "",
    plate_number: "",
    model_year:"",
    capacity: "",
    weight_unit: "",
    length: "",
    width: "",
    height: "",
    total_cbm: "",
    make: "",
    color: "",
    driver_name: "",
    images:""
   });


   

   const handleImageChange = (file) => {
    let valid = true
    const selectedImage = file;
    if (selectedImage) {
      if (allowedImageTypes.includes(selectedImage.type)) {
        valid = true
        setprofileFieldsErrs({
          ...profileFieldsErrs,
         'business_logo':""
        })
       
      const reader = new FileReader();
      reader.onload = () => {
        setlogo(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }else{
      valid = false
    
      setprofileFieldsErrs({
        ...profileFieldsErrs,
       'business_logo':"Invalid file type. Please select a JPEG or PNG image."
      })
      
    }
    }
    return valid;
  };
  const handleIdChange = (file) => {
    let valid = true
    const selectedImage = file;
    if (selectedImage) {
      if (allowedImageTypes.includes(selectedImage.type)) {
        valid = true
        setprofileFieldsErrs({
          ...profileFieldsErrs,
         'business_logo':""
        })
       
      const reader = new FileReader();
      reader.onload = () => {
        setgovtId(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }else{
      valid = false
    
      setprofileFieldsErrs({
        ...profileFieldsErrs,
       'govt_id':"Invalid file type. Please select a JPEG or PNG image."
      })
      
    }
    }
    return valid;
  };
  const handleImagesChange = (file) => {
    let valid = true
    const selectedImage = file;
    
    if (selectedImage) {
      if(images.length==5){
        valid = false
        settruckFieldsErr({
          ...truckFieldsErr,
         'images':"You can upload maximum 5 images."
        })
      }else if(allowedImageTypes.includes(selectedImage.type)) {
        valid = true
      const reader = new FileReader();
      reader.onload = () => {
        // setlogo(reader.result);
        setImages(obj => {
          return [
            ...obj,
            reader.result
          ]
        })
   
      };
      reader.readAsDataURL(selectedImage);
    }
  else{
    valid = false
    
    settruckFieldsErr({
      ...truckFieldsErr,
     'images':"Invalid file type. Please select a JPEG or PNG image."
    })
  }
}
return valid
  };
   const handleInputchangestep1 = (field,value)=>{
    if(field == "business_logo"){
     let valid =  handleImageChange(value)
     if(valid){
     
      setprofileFields({
        ...profileFields,
          [field]: value,
       
      });
     }
    }else if(field == "govt_id"){
      let valid =  handleIdChange(value)
     if(valid){
     
      setprofileFields({
        ...profileFields,
          [field]: value,
       
      });
     }
      
    }
    else{
      setprofileFields({
        ...profileFields,
          [field]: value,
       
      });
    }
   
   
    
   }
   const handleInputchangestep2 = (field,value)=>{
    setbankFields({
      ...bankFields,
        [field]: value,
    });
   }
   const handleInputchangestep3 = (field,value)=>{
    settruckFields({
          ...truckFields,
            [field]: value,
         
        });
   }
  useEffect(() => {
    if(show){
    const close = (e) => {
    
      if (e.key === 'Enter') {
       
         handleClose()
      }
    // }
    }
    window.addEventListener('keydown', close)
  return () => window.removeEventListener('keydown', close)
    }
   
     
},[show])

   const handleFilesUpload = (files) => {
    if ((files.length) > 5) {
      filesValidated = false

      settruckFieldsErr({
        ...truckFieldsErr,
       'images':"You can't upload more than 5 files."
      })
      return false;
    }else{
    // console.log("handlefile upload---------", files)
    let imagedata = []
    {files.map((file,i)=>{
     let valid =   handleImagesChange(file)
   
     if(valid){
      
      setimagesTruck(obj => {
        return [
          ...obj,
          file
        ]
      })
     }
      // imagedata.push(file)
     

    })}
  }
    
    let filesValidated = true
    if ((files.length) > 10) {
      filesValidated = false
      swal("Error", "You can't upload more than 10 files", "error")
      return false;
    }
    files.map(file => {
      if (file.type.slice(0, 5) != "image" && file.type.slice(0, 5) != "video") {
        filesValidated = false
        swal("Error", "Only video(s) and image(s) are allowed to upload.", "error")
        return false;
      }
      if (file.type.slice(0, 5) == "video" && file.size > 15000000) {
        filesValidated = false
        swal("Error", "Video file can't be more than 15 MB.", "error")
        return false;
      }
    })
  
  }
  const removeImageAtIndex = (indexToRemove) => {
    const updatedImages = imagesTruck.filter((_, index) => index !== indexToRemove);
    const updatedshowImages = images.filter((_, index) => index !== indexToRemove);
    setimagesTruck(updatedImages);
    setImages(updatedshowImages);
  };
  const get_truck_types = ()=>{
    apiService.truck_types().then((res) => {
     console.log("===type",res.data)
     
     let type = []
        console.log(res.data)
        {res.data.map((item, index) => (
         type.push({ value: item.id, label: item.name })
         ))}
         settruck_types(type)

      })
       .catch((err) => {
        
         setIsLoader(false)
       //   Swal.fire("Error", "Something went wrong.", "error");
        //  console.log("contact us api error", err.response.data.message);
       });
   }
   const get_weight_units = ()=>{
    apiService.weight_units().then((res) => {
     let type = []
        console.log(res.data)
        {res.data.map((item, index) => (
          
         type.push({ value: item.id, label: item.name })
         
         ))}

         setweight_units(type)
        //  settruck_types(type)

      })
       .catch((err) => {
        
         setIsLoader(false)
       //   Swal.fire("Error", "Something went wrong.", "error");
        //  console.log("contact us api error", err.response.data.message);
       });
   }
   const get_business_profile = ()=>{
    get_bank()
    apiService.get_business_profile().then((res) => {
     console.log("===get_business_profile",res.data.data)
     let data = res.data.data
     data.first_name= res.data.data.user.first_name
     data.last_name= res.data.data.user.last_name
     data.id= res.data.data.user.id
     setlogo(bUrl+data.business_logo)
     delete data["business_logo"];
     delete data["user"];
     
     setprofileFields(data)
     
    

      })
       .catch((err) => {
     
         setIsLoader(false)
       //   Swal.fire("Error", "Something went wrong.", "error");
        //  console.log("contact us api error", err.response.data.message);
       });
   }
   
   const get_bank = ()=>{
    apiService.get_bank().then((res) => {
     let data = {
      account_number :"",
      routing_number: "",
      account_holder_name :"",
      bank_name : ""
     }  
     data.account_holder_name = res.data.data[0].account_holder_name;
     data.routing_number= res.data.data[0].routing_number;
     data.account_number ="*** **** **** "+res.data.data[0].last4;
     data.bank_name = res.data.data[0].bank_name;
     setbankFields(data)
     
    })
       .catch((err) => {
       
         setIsLoader(false)
      
       });
   }
   
   function getSectionComponent() {

    switch (activeStep) {
      case 0: return <Step1  setprofileFields = {setprofileFields}  profileFieldsErrs={profileFieldsErrs} profileFields={profileFields} onInputChange={handleInputchangestep1} logo={logo}  govtId={govtId}/>;
      case 1: return <Step2  bankFieldsErrs={bankFieldsErrs} bankFields={bankFields} onInputChange={handleInputchangestep2} />;
      case 2: return <Step3 truckType={truck_types} truckFields={truckFields}  truckFieldsErr={truckFieldsErr}  onInputChange={handleInputchangestep3}  onFileChange={handleFilesUpload} images={images} weight_units={weight_units} removeImageAtIndex={removeImageAtIndex}/>;
      default: return null;
    }
  }

   const handleSubmit = ()=>{
    // e.preventDefault();
  
    if(activeStep==0){
      
    let formValidated =  validateService.validateTruckerstep1(setprofileFieldsErrs,profileFields)
      getSectionComponent()
     if (formValidated ) {
      setIsLoader(true)
      apiService.user_profile(profileFields).then((res) => {
        setIsLoader(false)
       setActiveStep(activeStep+1)
       globalData.setuser_name(res.data.user.first_name+" "+res.data.user.last_name)
       localStorage.setItem("user_name",res.data.user.first_name+" "+res.data.user.last_name)
       localStorage.setItem("profile_step",2)
       })
        .catch((err) => {
          
        //  console.log("====ssss",err.response.status)
         if(err.response.status==401){
          localStorage.clear();
          window.location.href="/login"
         }
         seterrmessage1("Error")
        seterrmessage2(err.response.data.message)
        handleShowErr()
          setIsLoader(false)
        //   Swal.fire("Error", "Something went wrong.", "error");
          console.log("contact us api error", err.response.data.message);
        });
    }
    }else if(activeStep==1){
     
      let formValidated =  validateService.validaeTruckerstep2(setbankFieldsErrs,bankFields)
      
      if(formValidated){
        setIsLoader(true)
        apiService.add_bank(bankFields).then((res) => {
          setActiveStep(activeStep+1)
          localStorage.setItem("profile_step",3)
          setIsLoader(false)
          })
           .catch((err) => {
            seterrmessage1("Error")
        seterrmessage2(err.response.data.message)
        handleShowErr()
             setIsLoader(false)
           //   Swal.fire("Error", "Something went wrong.", "error");
             console.log("contact us api error", err.response.data.message);
           });
       }
      getSectionComponent()
      
    }else{
      truckFields.images = imagesTruck;
      console.log("======truckFields=====",truckFields)
      let formValidated =  validateService.validateTruckerstep3(settruckFieldsErr,truckFields,imagesTruck)
      console.log("======truckFieldsErr=====",truckFieldsErr)
      if(formValidated){
        setIsLoader(true)
      apiService.add_truck(truckFields,imagesTruck).then((res) => {
        setmessage1("Congratulations")
        setmessage2("Profile created successfully")
        setredirectlink("/dashboard?tab=dashboard")
        handleShow()
        setIsLoader(false)
        })
         .catch((err) => {
          seterrmessage1("Error")
        seterrmessage2(err.response.data.message)
        handleShowErr()
           setIsLoader(false)
         //   Swal.fire("Error", "Something went wrong.", "error");
           console.log("contact us api error", err.response.data.message);
         });
    // }
        }
     
      
    }
     }

   

  return (
   
    <div className="create-profile-area">
       {isLoader && <Loader/>}
      <Container>
        <div className="create-profile-content-area">
          <Stepper
            steps={steps}
            activeStep={activeStep}
          />
          <div>
            {getSectionComponent()}

            <div className="skip-next-btn">
            {/* {
              activeStep === 1 ?
                <button className="skip-btn" onClick={() => setActiveStep(activeStep + 1)}>Skip</button>
                :
                ""
            } */}
            {activeStep !== steps.length - 1
              && <button className="next-btn" onClick={() => handleSubmit()}>Next</button>
            }
             {activeStep ==2
              && <button className="submit-btn" onClick={() => handleSubmit()}>Submit</button>
            }
            </div>


          </div>
        </div>
      </Container>
      <SuccessPopup show={show} handleClose={handleClose} message1={message1} message2={message2} redirectlink={redirectlink}/>
      <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} redirectlink={redirectlink}/>
    </div>
  );
};
export default TruckerCreateProfile;