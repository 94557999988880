import { useState,useEffect,useContext } from "react";
import { Container, Form } from "react-bootstrap";
import { Stepper } from "react-form-stepper";
import Step1 from "./Stepper/Step1"
import Step2 from "./Stepper/Step2"

import Loader from "../../common/Loader";
import { apiService} from "../../../services/api.services";
import { validateService} from "../../../services/validate.services";
import { useNavigate } from "react-router-dom";
import { context } from "../../../GlobalContext/GlobalContext";
import swal from "sweetalert2";
import SuccessPopup from "../../modal/SuccessPopup"
import Errorpopup from "../../modal/Errorpopup"
import {allowedImageTypes, bUrl} from "../../../config/config"

const TruckerCreateProfile = () => {
  const globalData = useContext(context);
  const [activeStep, setActiveStep] = useState(localStorage.getItem("profile_step")-1);
  const [ isLoader , setIsLoader] = useState(false)
  const [show, setShow] = useState(false);
  const [ message1 , setmessage1] = useState("")
  const [ message2 , setmessage2] = useState("")
  const [ redirectlink , setredirectlink] = useState("/")
  const [logo, setlogo] = useState();
  const [showErr, setShowErr] = useState(false);
  const [ errmessage1 , seterrmessage1] = useState("Error")
  const [ errmessage2 , seterrmessage2] = useState("")
  const [ resend_btn , setresendbt] = useState(false)
  const handleCloseErr = () => {
    setShowErr(false);
  }
  const handleShowErr = () => setShowErr(true);
  const navigate = useNavigate();
  const handleShow = () => setShow(true);
  const handleClose = () => {
    // window.location.href = link
    navigate(redirectlink)
    setShow(false);
  }
  const steps = [
    { label: 'Create Profile', onClick: () => setActiveStep(0) },
    { label: 'Add Bank Detail', onClick: () => setActiveStep(1) },
  
  ];
console.log("======",globalData.profileStep)
  useEffect(() => {
    
    console.log(localStorage.getItem("profile_step"),"=====profile_step=")
    get_business_profile()
   
  }, []);
  const [profileFields, setprofileFields] = useState({
    first_name: "",
    last_name: "",
    business_name:"",
    trade_name: "",
    phone:"",
    business_address: "",
    latitude:"",
    longitude:"",
    business_logo: "",
    investment:0
    
    });
   const [profileFieldsErrs, setprofileFieldsErrs] = useState({
    first_name: "",
    last_name: "",
    business_name:"",
    trade_name: "",
    phone:"",
    business_address: "",
    business_logo: "",
   
   });

     
  const [bankFields, setbankFields] = useState({
         account_number :"",
        // routing_number: "",
        account_holder_name :"",
        bank_id : ""
   });
   const [bankFieldsErrs, setbankFieldsErrs] = useState({
    account_number :"",
    routing_number: "",
    account_holder_name :"",
    bank_name : ""
   });
  


   const handleImageChange = (file) => {
    let valid = true
    const selectedImage = file;
    if (selectedImage) {
      if (allowedImageTypes.includes(selectedImage.type)) {
        valid = true
        setprofileFieldsErrs({
          ...profileFieldsErrs,
         'business_logo':""
        })
       
      const reader = new FileReader();
      reader.onload = () => {
        setlogo(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }else{
      valid = false
    
      setprofileFieldsErrs({
        ...profileFieldsErrs,
       'business_logo':"Invalid file type. Please select a JPEG or PNG image."
      })
      
    }
    }
    return valid;
  };
 
   const handleInputchangestep1 = (field,value)=>{
    if(field == "business_logo"){
     let valid =  handleImageChange(value)
     if(valid){
     
      setprofileFields({
        ...profileFields,
          [field]: value,
       
      });
     }
    }else{
      setprofileFields({
        ...profileFields,
          [field]: value,
       
      });
    }
   
   
    
   }
   const handleInputchangestep2 = (field,value)=>{
    setbankFields({
      ...bankFields,
        [field]: value,
    });
   }





  
   const get_business_profile = ()=>{
    get_bank()
    apiService.get_business_profile().then((res) => {
     console.log("===get_business_profile",res.data.data)

     let data = res.data.data
     data.first_name= res.data.data.user.first_name
     data.last_name= res.data.data.user.last_name
     data.id= res.data.data.user.id
     setlogo(bUrl+data.business_logo)
     delete data["business_logo"];
     delete data["user"];
     globalData.setuser_name(res.data.data.user.first_name+" "+res.data.data.user.last_name)
     localStorage.setItem("user_name",res.data.data.first_name+" "+res.data.data.last_name)
     setprofileFields(data)
     
    

      })
       .catch((err) => {
     
         setIsLoader(false)
       //   Swal.fire("Error", "Something went wrong.", "error");
        //  console.log("contact us api error", err.response.data.message);
       });
   }
   
   const get_bank = ()=>{
    apiService.get_bank().then((res) => {
     let data = {
      account_number :"",
      routing_number: "",
      account_holder_name :"",
      bank_name : ""
     }  
     data.account_holder_name = res.data.data[0].account_holder_name;
     data.routing_number= res.data.data[0].routing_number;
     data.account_number ="*** **** **** "+res.data.data[0].last4;
     data.bank_name = res.data.data[0].bank_name;
     setbankFields(data)
     
    })
       .catch((err) => {
       
         setIsLoader(false)
      
       });
   }
   
   function getSectionComponent() {

    switch (activeStep) {
      case 0: return <Step1 setprofileFields = {setprofileFields} profileFieldsErrs={profileFieldsErrs} profileFields={profileFields} onInputChange={handleInputchangestep1} logo={logo} />;
      case 1: return <Step2  bankFieldsErrs={bankFieldsErrs} bankFields={bankFields} onInputChange={handleInputchangestep2} />;
      default: return null;
    }
  }
  useEffect(() => {
      if(show){
      const close = (e) => {
    
        if (e.key === 'Enter') {
         
           handleClose()
        }
      // }
      }
      window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
      }
       
  },[show])
   const handleSubmit = ()=>{
    // e.preventDefault();
  
    if(activeStep==0){
      
    let formValidated =  validateService.validatewholesellerstep1(setprofileFieldsErrs,profileFields,"trade")
      getSectionComponent()
      if (formValidated ) {
      setIsLoader(true)
      apiService.user_profile(profileFields).then((res) => {
        console.log("====ssss",res.data)
        setIsLoader(false)
        globalData.setuser_name(res.data.user.first_name+" "+res.data.user.last_name)
        localStorage.setItem("user_name",res.data.user.first_name+" "+res.data.user.last_name)
       setActiveStep(activeStep+1)
       localStorage.setItem("profile_step",2)
       })
        .catch((err) => {
          
         console.log("====ssss",err.response.status)
         if(err.response.status==401){
          localStorage.clear();
          window.location.href="/login"
         }
         seterrmessage1("Error")
        seterrmessage2(err.response.data.message)
        handleShowErr()
          setIsLoader(false)
        //   Swal.fire("Error", "Something went wrong.", "error");
          console.log("contact us api error", err.response.data.message);
        });
    }
    }else if(activeStep==1){
     
       let formValidated =  validateService.validaeTruckerstep2(setbankFieldsErrs,bankFields)
      
       if(formValidated){
        setIsLoader(true)
        apiService.add_bank(bankFields).then((res) => {
          setmessage1("Congratulations")
        setmessage2("Profile created successfully")
        setredirectlink("/dashboard")
        handleShow()
          localStorage.setItem("profile_step",3)
          setIsLoader(false)
          })
           .catch((err) => {
            seterrmessage1("Error")
        seterrmessage2(err.response.data.message)
        handleShowErr()
             setIsLoader(false)
           //   Swal.fire("Error", "Something went wrong.", "error");
             console.log("contact us api error", err.response.data.message);
           });
        }
      getSectionComponent()
      
    }
        
     }

   

  return (
   <>
    {isLoader && <Loader/>}
    <div className="overflow-x">
    <div className="create-profile-area">
      
      <Container>
        <div className="create-profile-content-area">
          <Stepper
            steps={steps}
            activeStep={activeStep}
          />
          <div >
            {getSectionComponent()}

            <div className="skip-next-btn">
            {/* {
              activeStep === 1 ?
                <button className="skip-btn" onClick={() => setActiveStep(activeStep + 1)}>Skip</button>
                :
                ""
            } */}
            {activeStep !== steps.length - 1
              && <button className="next-btn" onClick={() => handleSubmit()}>Next</button>
            }
             {activeStep ==1
              && <button className="submit-btn" onClick={() => handleSubmit()}>Submit</button>
            }
            </div>


          </div>
        </div>
      </Container>
      <SuccessPopup show={show} handleClose={handleClose} message1={message1} message2={message2} redirectlink={redirectlink}/>
      <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} redirectlink={redirectlink}/>
        </div>
        </div>
    </>
  );
};
export default TruckerCreateProfile;