import { Button, Container, Table } from "react-bootstrap";
import Pagination from "../../../common/Pagination";
import Transaction from "../../../common/transactions";
const TruckerTransactionManagement = () => {
    return (
        <>
        <Transaction  type={true}/>
            {/* <div className=" trucker-transaction-management">
        
                    <div className="manage-truck-area">
                        <div className=" product-management-area">
                            <div className="product-management-top">
                                <h3>Transaction Management</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                        </div>
                        <div className="product-management-detail">
                            <Table >
                                <thead>
                                    <tr className='table-content-top'>
                                        <th>Truck Details</th>
                                        <th>Amount</th>
                                        <th>Date</th>
                                        <th className='status'>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='table-content-order'>
                                        <td className="transaction-management-truck-management"><h6>Adam Smith</h6>
                                        <p>Type: <span>CEB1163</span></p></td>
                                        <td>30,000</td>
                                        <td>15-07-2023</td>
                                        <td className='status'><Button type='button' variant='unset'>Paid</Button></td>
                                    </tr>
                                    <tr className='table-content-order'>
                                        <td className="transaction-management-truck-management"><h6>Adam Smith</h6>
                                        <p>Type: <span>CEB1163</span></p></td>
                                        <td>30,000</td>
                                        <td>15-07-2023</td>
                                        <td className='status'><Button type='button' variant='unset'>Paid</Button></td>
                                    </tr>
                                    <tr className='table-content-order'>
                                        <td className="transaction-management-truck-management"><h6>Adam Smith</h6>
                                        <p>Type: <span>CEB1163</span></p></td>
                                        <td>30,000</td>
                                        <td>15-07-2023</td>
                                        <td className='status'><Button type='button' variant='unset'>Paid</Button></td>
                                    </tr>
                                    <tr className='table-content-order'>
                                        <td className="transaction-management-truck-management"><h6>Adam Smith</h6>
                                        <p>Type: <span>CEB1163</span></p></td>
                                        <td>30,000</td>
                                        <td>15-07-2023</td>
                                        <td className='status'><Button type='button' variant='unset'>Paid</Button></td>
                                    </tr>
                                    <tr className='table-content-order'>
                                        <td className="transaction-management-truck-management"><h6>Adam Smith</h6>
                                        <p>Type: <span>CEB1163</span></p></td>
                                        <td>30,000</td>
                                        <td>15-07-2023</td>
                                        <td className='status'><Button type='button' variant='unset'>Paid</Button></td>
                                    </tr>
                                    <tr className='table-content-order'>
                                        <td className="transaction-management-truck-management"><h6>Adam Smith</h6>
                                        <p>Type: <span>CEB1163</span></p></td>
                                        <td>30,000</td>
                                        <td>15-07-2023</td>
                                        <td className='status'><Button type='button' variant='unset'>Paid</Button></td>
                                    </tr>
                                    <tr className='table-content-order'>
                                        <td className="transaction-management-truck-management"><h6>Adam Smith</h6>
                                        <p>Type: <span>CEB1163</span></p></td>
                                        <td>30,000</td>
                                        <td>15-07-2023</td>
                                        <td className='status'><Button type='button' variant='unset'>Paid</Button></td>
                                    </tr>
                                    <tr className='table-content-order'>
                                        <td className="transaction-management-truck-management"><h6>Adam Smith</h6>
                                        <p>Type: <span>CEB1163</span></p></td>
                                        <td>30,000</td>
                                        <td>15-07-2023</td>
                                        <td className='status'><Button type='button' variant='unset'>Paid</Button></td>
                                    </tr>
                                    <tr className='table-content-order'>
                                        <td className="transaction-management-truck-management"><h6>Adam Smith</h6>
                                        <p>Type: <span>CEB1163</span></p></td>
                                        <td>30,000</td>
                                        <td>15-07-2023</td>
                                        <td className='status'><Button type='button' variant='unset'>Paid</Button></td>
                                    </tr>
                                    <tr className='table-content-order'>
                                        <td className="transaction-management-truck-management"><h6>Adam Smith</h6>
                                        <p>Type: <span>CEB1163</span></p></td>
                                        <td>30,000</td>
                                        <td>15-07-2023</td>
                                        <td className='status'><Button type='button' variant='unset'>Paid</Button></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
            </div> */}
        </>
    )
}
export default TruckerTransactionManagement;