import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
    return (
        <>
            <section className="privacy-policy">
                <Container>
                    <div className="privacy-policy-content-area">
                        <h2>PRIVACY POLICY</h2>
                        <ul>
                            <h5>1. Introduction</h5>
                            <p>Welcome to DistriX AI ("we," "our," or "us"). We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide when you use our services and website.</p>

                        </ul>
                        <ul className="pb-0">
                            <h5>2. Information We Collect
                            </h5>
                            <p>We may collect various types of information when you use our services:</p>

                            <p> (a) Personal Information
                                <ul className="pt-2 ms-4">
                                    <p>We may collect personal information that you voluntarily provide to us, including:</p>
                                    <li><b>Contact Information:</b> Such as your name, email address, postal address, phone number, and other similar identifiers.</li>
                                    <li><b>Account Information:</b> If you create an account with us, we may collect information like your username and password.</li>
                                    <li><b>Payment Information:</b>If you make purchases through our platform, we may collect payment card details and billing information.</li>
                                </ul>
                            </p>

                            <p> (b) Usage Information
                                <ul className="pt-2 ms-4">
                                    <p>We automatically collect certain information about your usage of our services, including:</p>
                                    <li><b>Device Information: </b> Such as your IP address, device type, operating system, and browser.</li>
                                    <li><b>Usage Data:</b> Information about your interactions with our website and services, such as the pages you visit, the features you use, and the actions you take.</li>
                                </ul>
                            </p>
                            <p>
                                (c) Cookies and Tracking Technologies
                                <ul className="pt-2 ms-4">
                                    <p>We use cookies and similar tracking technologies to collect information about your browsing activity. For more information on how we use cookies, please refer to our Cookie Policy.</p>
                                    <p></p>
                                </ul>
                            </p>

                        </ul>
                        <ul className="pt-0">
                            <h5>3. How We Use Your Information
                            </h5>
                            <p>We may collect various types of information when you use our services:</p>
                            <ul className="pt-2 ms-4">
                                <li><b>Providing and Improving Services:</b> To provide and maintain our services, improve their quality, and develop new features.</li>
                                <li><b>Communication:</b> To communicate with you, including responding to your inquiries, sending updates, newsletters, and promotional materials.
                                </li>
                                <li><b>Analytics:</b>To analyze usage patterns and trends, which helps us enhance user experience and optimize our services.</li>
                                <li><b>Marketing:</b>To personalize and deliver targeted advertising and promotional content, where applicable and with your consent.</li>
                            </ul>
                        </ul>

                        <ul className="pt-0">
                            <h5>4. Information Sharing
                            </h5>
                            <p>We do not sell or rent your personal information to third parties. However, we may share your information with:</p>
                            <ul className="pt-2 ms-4">
                                <li><b>Service Providers:</b> We may engage third-party service providers to assist us in providing our services, and they may have access to your information, but only to the extent necessary for them to perform their functions.</li>
                                <li><b>Legal Requirements:</b> We may disclose your information if required to do so by law, in response to a subpoena, court order, or other legal process.
                                </li>
                            </ul>
                        </ul>

                        <ul className="pt-0">
                            <h5>5. Your Choices
                            </h5>
                            <p>You have certain rights and choices regarding your information:
                            </p>
                            <ul className="pt-2 ms-4">
                                <li><b>Opt-Out:</b> You may opt out of receiving promotional communications from us by following the instructions in our emails.</li>
                                <li><b>Cookies:</b>  You can disable cookies in your browser settings, although this may affect your experience using our website. For more information, please refer to our Cookie Policy.

                                </li>
                            </ul>
                        </ul>

                        <ul className="pt-0">
                            <h5>6. Security
                            </h5>
                            <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                            </p>
                        </ul>

                        <ul className="pt-0">
                            <h5>7. Changes to this Privacy Policy
                            </h5>
                            <p>We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page, and the date of the latest revision will be indicated at the top of the policy.
                            </p>
                        </ul>

                        <ul className="pt-0">
                            <h5>8. Contact Us
                            </h5>
                            <p>If you have any questions, concerns, or requests related to this Privacy Policy or our data practices, please contact us at hello@distrix.ai</p>
                        </ul>
                    </div>
                </Container>
            </section>
        </>
    )
}
export default PrivacyPolicy;