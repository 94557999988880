import { Button, Col, Row } from "react-bootstrap";

const PageNotFound = () => {
    return (
        <>
           <div className="error-page">
                <Row className="justify-content-center">
                    <Col md={7}>
                        <div className="error-page-content">
                            <h2>Opps!</h2>
                            <h5>404 - PAGE NOT FOUND</h5>
                            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                            <Button type="button" variant="unset"><a href="/">GO TO HOMEPAGE</a></Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default PageNotFound;