import { Accordion, Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import { apiService } from "../../../services/api.services";
import { useParams } from "react-router-dom";
import Loader from "../../common/Loader";
import { validateService } from "../../../services/validate.services";
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Errorpopup from "../../modal/Errorpopup"
import SuccessPopup from "../../modal/SuccessPopup"
import '@stripe/stripe-js';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
// import { CLIENT_SECRET } from "../../../config/config"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { gKey, bUrl } from "../../../config/config"
import GooglePlacesAutocomplete,{ geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { context } from "../../../GlobalContext/GlobalContext";
import CardSection from "./CardSection";

const Checkout = (event) => {
    const navigate = useNavigate();
    const [value, setValue] = useState(null);
    const globalData = useContext(context);
    const stripe = useStripe();
    const elements = useElements();
    // const options = {
    //     // passing the client secret obtained from the server
    //     clientSecret: CLIENT_SECRET,
    // };
    const [show, setShow] = useState(false);
     const [totalCbm, settotalCbm] = useState();
    const [cartErrors, setcartErrors] = useState()
    const [message1, setmessage1] = useState("Success")
    const [message2, setmessage2] = useState("")
    const [showErr, setShowErr] = useState(false);
    const [errmessage1, seterrmessage1] = useState("Error")
    const [errmessage2, seterrmessage2] = useState("")
    const [cardErr, setcardErr] = useState("")
    const [showdelete, setShowdelete] = useState(false);
    const [ delproductId , setdelproductId] = useState()
    const handleClosedelete = () => setShowdelete(false);
    const handleShowdelete = (id) => {
      setShowdelete(true);
      setdelproductId(id)
  }
    const handleClose = () => {
        window.location.href = "/product-listing"
        setShow(false);
    }
    const pageDetail = (id,deleted) => {
        if(deleted){
             handleShowErr()
             seterrmessage2("Product Removed by Owner")

        }else{
            let link = "/product-detail/" + id
            navigate(link)
        }
        
    }
    const handleShow = () => setShow(true);

    const handleCloseErr = () => {
        setShowErr(false);
    }
    const handleShowErr = () => setShowErr(true);

    const [showadd, setShowadd] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const handleCloseADD = () => setShowadd(false);
    const handleShowADD = () => setShowadd(true);

    const [showCard, setShowCard] = useState(false);

    const handleCloseCard = () => setShowCard(false);
    const handleShowCard = () => setShowCard(true);
    const [cart_id, setcart_id] = useState()
    const [quantity, setQuantity] = useState(1)
    const id = useParams()
    const [addresses, setaddresses] = useState([])
    const [cartList, setcartList] = useState([])
    const [cardList, setcardList] = useState([])
    const [subtotal, setsubtotal] = useState("")
    const [total, settotal] = useState("")
    const [cardId, setCardId] = useState("")
    const [addressId, setaddressId] = useState("")
    const [deliverycharge, setdeliverycharge] = useState("")
    const [orderWeight, setorderWeight] = useState("")
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleClose()
        }
      };
      if (event.key === 'Enter') {
        handleClose()
    }
    const [addressFields, setaddressFields] = useState({
        first_name: "",
        last_name: "",
        address: "",
        latitude:"",
        longitude:"",
        city: "",
        state: "",
        pincode: "11111",
        country: "Philippines",
        save_address: false,
    });
    const [addressFieldsErr, setaddressFieldsErr] = useState({
        first_name: "",
        last_name: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        country: "",
        save_address: false,
    });
    const [cardFields, setCardFields] = useState({
        name: "",
        number: "",
        exp_month: 8,
        exp_year: 2027,
        cvc: "",
    });

    const [isLoader, setIsLoader] = useState(false)
    useEffect(() => {
        get_address()
       
        get_card()
    }, [id]);
    function cahngeAddress(address,cbm){
        settotalCbm(cbm)
        setaddressId(address)
        get_cart(address)

    }
    function changeVal(val,productFields) {
        console.log("====productFields===",productFields)
              
   
        if (val == '-' ) {
            if(productFields.quantity-1>=productFields.product_sku?.min_order){
                // setQuantity(quantity)
                update_cart(productFields.quantity-1,productFields.id,productFields.product_sku.id)
            }
           
        } else {
            if(productFields.quantity+1<=productFields.product_sku?.current_stock){
            // setQuantity(quantity+1)
            update_cart(productFields.quantity+1,productFields.id,productFields.product_sku.id)
            }
        }
  
    }
    const update_cart = (qut,cart_id,pid)=>{
        setIsLoader(true)
        console.log("====qut",qut)
        const pkInteger = parseInt(pid); 
        let data={
            product_sku:pkInteger,
            quantity:qut
        }
        apiService.update_cart(data,cart_id).then((res) => {
            get_cart(addressId)
            // setin_cart(true)
             setQuantity(res.data.quantity)

            setcart_id(res.data.id)
         setIsLoader(false)
    
          }).catch((err) => {
             setIsLoader(false)
           
           });
    }
    const deleted_from_cart = ()=>{
        handleClosedelete()
        setIsLoader(true)
        apiService.deleted_from_cart(delproductId).then((res) => {
           
            get_cart(addressId)
            // setin_cart(true)
            // setcart_id(res.data.id)
         setIsLoader(false)
         globalData.setcart_count(parseInt(globalData.cart_count)-1)
         localStorage.setItem("cart_count",parseInt(globalData.cart_count)-1)
    
          }).catch((err) => {
             setIsLoader(false)
           
           });
       }
    const get_cart = (address) => {
        setIsLoader(true)
        apiService.get_cart(address).then((res) => {
            globalData.setcart_count(parseInt(res.data.data.length))
            localStorage.setItem("cart_count",parseInt(res.data.data.length))
            let cartErrorsData = []
            {res.data.data.map((cart,index)=>{
                let cartErrorse = {}
                 if(cart.min_order>cart.product_sku.current_stock || cart.product_sku.current_stock == 0 || cart.product_sku.is_deleted){
          
                     cartErrorse.name = cart.product_sku.product.name
                     cartErrorse.message = " product no longer available delete item from cart."
                  
                 cartErrorsData.push(cartErrorse)
               }else if(cart.quantity>cart.product_sku.current_stock){
                 cartErrorse.name = cart.product_sku.product.name
                 cartErrorse.message =cart.product_sku.product.name+" have "+cart.product_sku.current_stock +" in stock but your selected quantity is "+cart.quantity+".Delete quantity from cart "
                 cartErrorsData.push(cartErrorse)
              }
              if(res.data.data.length==index+1){
                setcartErrors(cartErrorsData)
              }
               

            })}
            
            setcartList(res.data.data)
            setdeliverycharge(res.data.delivery_charge)
            setorderWeight(res.data.order_weight)
            setsubtotal(res.data.order_price)
            settotalCbm(res.data.order_cbm)
            settotal(res.data.total)
            setIsLoader(false)
            console.log("===dcat", res.data)
        })
            .catch((err) => {
                handleShowErr()
                seterrmessage2(err?.response?.data?.message)
                // seterrmessage1(err?.response?.data?.message)
                setIsLoader(false)

            });
    }
    const get_address_detail = (id) => {
        setIsLoader(true)
        apiService.address_detail(id).then((res) => {
            setaddressFields(res.data)
            setShowadd(true)
            console.log("===detaile", res.data)
            setIsLoader(false)
        })
            .catch((err) => {
                handleShowErr()
                seterrmessage2(err?.response?.data?.message)
                setIsLoader(false)

            });
    }
    const get_card = () => {
        setIsLoader(true)
        apiService.get_card().then((res) => {
            if (res.data.data.length > 0) {
                setCardId(res.data.data[0].id)
            }
            console.log("=========>>>>", res.data.data)
            setcardList(res.data.data)
            setIsLoader(false)
        }).catch((err) => {
            handleShowErr()
            seterrmessage2(err?.response?.data?.message)
            setIsLoader(false)

        });
    }

    const get_address = () => {
        setIsLoader(true)
        apiService.get_address().then((res) => {
            setaddresses(res.data.data)
            if (res.data.data.length > 0) {
                get_cart(res.data.data[0].id)
                
               
                setaddressId(res.data.data[0].id)
            }else{
                get_cart("")
            }

            console.log("===detaile", res.data.data)
            setIsLoader(false)
        })
            .catch((err) => {
                handleShowErr()
                seterrmessage2(err?.response?.data?.message)
                setIsLoader(false)

            });
    }
    useEffect(() => {
        if(value){
             geocodeByAddress(value.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) =>
            setaddressFields({
                ...addressFields,
                address: value.label,
                  latitude: lat,
                  longitude: lng,   })  ); 
        }
    }, [value]);
    const delete_address = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoader(true)
                apiService.delete_address(id).then((res) => {
                    setaddresses(res.data.data)
                    console.log("===detaile", res.data.data)
                    get_address()
                    setIsLoader(false)
                }).catch((err) => {
                    handleShowErr()
                    seterrmessage2(err?.response?.data?.message)
                    setIsLoader(false)

                });
            } else if (result.isDenied) {
                // Swal.fire('Changes are not saved', '', 'info')
            }
        })



    }
    const add_address = () => {
        console.log("====addressFields====",addressFields)
        let formValidated = validateService.addressValidation(setaddressFieldsErr, addressFields)
        if (formValidated) {

            setIsLoader(true)
            apiService.add_address(addressFields).then((res) => {
                console.log("===detaile", res.data)
                setaddressId(res.data.data.id)
                setIsLoader(false)
                setaddressFields({
                    first_name: "",
                    last_name: "",
                    address: "",
                    city: "",
                    state: "",
                    pincode: "",
                    country: "",
                    save_address: false,
                });
                get_address()
                handleCloseADD()
            })
                .catch((err) => {
                    handleShowErr()
                    seterrmessage2(err?.response?.data?.message)
                    setIsLoader(false)

                });
        }
    }

    const delete_card = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoader(true)
                apiService.delete_card(id).then((res) => {

                    console.log("===detaile", res.data.data)
                    get_card()
                    setIsLoader(false)
                }).catch((err) => {
                    handleShowErr()
                    seterrmessage2(err?.response?.data?.message)
                    setIsLoader(false)
                });
            } else if (result.isDenied) {
                // Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }
    const add_card = async () => {
        setIsLoader(true)
        console.log(stripe)
        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        console.log("====result", result)
        if (result && result.error) {

            setcardErr(result.error.message)


        } else {
            setcardErr("")
            let data = {
                token: result.token.id
            }
            //   if(cardFields.saved){
            apiService.add_card(data).then((res) => {
                setIsLoader(false)
                console.log("===detaile", res.data)
                setShowCard(false)
                setCardFields({
                    name: "",
                    number: "",
                    exp_month: "",
                    exp_year: "2027",
                    cvc: "",
                    saved: false
                });
                get_card()
            })
                .catch((err) => {
                    console.log(err.response)
                    handleShowErr()
                    seterrmessage2(err?.response?.data?.message)

                    setIsLoader(false)

                });
        }
        // }else{
        //     setIsLoader(false)
        //    setShowCard(false)
        //     setCardId(result.token.id)
        // }
        // }
    }
    const productpage = () => {
        window.location.href = "/product-listing"
    }
    const placeorder = () => {
      
        console.log("=====",cartErrors.name)
        if( cartErrors.name && cartErrors.name!=""){
            seterrmessage2(cartErrors)
            handleShowErr()
        }else{
        if (addressId ) {
           
            setIsLoader(true)
            var arrObjIds = cartList.map(elements => {
                return elements.id;
            });

            let data = {
                "address": addressId,
                "cart_order": arrObjIds,
                "order_price": parseFloat(subtotal),
                "delivery_charge": parseFloat(deliverycharge),
                "order_weight":orderWeight,
                "order_cbm":totalCbm,
                // "card": cardId
            }
           
            apiService.checkout(data).then((res) => {
                console.log("===detaile=====", res.data.data.url)
                // window.open(res.data.data.url);
              window.location.href = res.data.data.url;
                // setIsLoader(false)
                // setmessage2(res.data.message)
                // handleShow()

                //  get_card()
            })
                .catch((err) => {
                    handleShowErr()
                    seterrmessage2(err?.response?.data?.message)
                    setIsLoader(false)
                    get_cart(addressId)

                });
        } else {
            if (!addressId) {
                handleShowErr()
                seterrmessage2("Please add your delivery address first")

            }
            // if (!cardId) {
            //     handleShowErr()
            //     seterrmessage2("Please add your card detail first")

            // }
        }
    }
    }
    return (


        <>
            {isLoader && <Loader />}
            <section className="checkout-area">
                {/* <Elements stripe={stripePromise} options={options}> */}
                <Container>
                    <h2 className="checkout-heading mb-1">Checkout</h2>
                    <p className="checkout-note"><span>*</span>We prioritize safety and security. Your payment remains on our platform until your order/s are delivered/received. Payments are released only upon transaction completion.</p>
                    <Row>
                        <Col md={12} xl={7}>
                            <div className="checkout-left-area">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header><h6>DELIVERY ADDRESS</h6>
                                            <p>Select a billing address from your address book or enter a new address.</p>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Form>

                                                {addresses && addresses.length > 0 && addresses.map((address, index) => (

                                                    <div key={`default-${index}`} className="delivery-address-area">
                                                        <Form.Check // prettier-ignore
                                                            type={"radio"}
                                                            id={`default-radio`}
                                                            name="address"
                                                            checked={addressId == address.id}
                                                            onClick={(e) => e.target.checked ? cahngeAddress(address.id,address.order_cbm) : null}
                                                            label={`${address.first_name + " " + address.last_name}, ${address.address}, ${address.city}, ${address.state} ${address.pincode}, ${address.country}`}
                                                        />
                                                        <div className="edit-delete">
                                                            <p className="edit" onClick={() => get_address_detail(address.id)}><i class="fa fa-pencil-square-o" aria-hidden="true"></i>Edit</p>
                                                            <p className="delete" onClick={() => delete_address(address.id)}>   <i className="fa fa-trash-o" aria-hidden="true" ></i>Delete</p>
                                                        </div>
                                                    </div>
                                                ))}

                                                {/* {['radio'].map((type) => (
                                                    <div key={`default-${type}`} className="delivery-address-area">
                                                        <Form.Check // prettier-ignore
                                                            type={type}
                                                            id={`default-${type}`}
                                                            label={`Add New Address`}
                                                            name="address"
                                                        />


                                                    </div>
                                                ))} */}
                                            </Form>
                                            <p className="add-card" onClick={handleShowADD}>+ Add New Address</p>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    {/* <Accordion.Item eventKey="1">
                                        <Accordion.Header><h6>PAYMENT METHOD</h6>
                                       </Accordion.Header> */}
                                        {/* <Accordion.Body> */}
                                            {/* <div className="checkout-cards-area">
                                                <Row className="mt-3">


                                                    {cardList && cardList.length > 0 && cardList.map((card, index) => (

                                                        <Col md={12} lg={6} xl={4} onClick={() => setCardId(card.id)}>
                                                            <div className="credit-cards-area">
                                                                <div className="edit-delete card-number-delete-btn">
                                                                    <p className="delete" onClick={() => delete_card(card.id)}>   <i className="fa fa-trash-o" aria-hidden="true" ></i>Delete</p>
                                                                </div>
                                                                <Form>
                                                                    {['radio'].map((type) => (
                                                                        <div key={`default-${type}`} className="card-number-area">
                                                                            <Form.Check 
                                                                                type={"radio"}
                                                                                id={`default-radio`}
                                                                                name="address"
                                                                                checked={cardId == card.id}

                                                                            />
                                                                            <p><span>****</span> <span>****</span><span>****</span><span>{card.last4}</span></p>
                                                                        </div>
                                                                    ))}
                                                                </Form>
                                                            </div>
                                                        </Col>))}

                                                    {cardList && cardList.length > 0 && cardList.map((card, index) => (
                                                        <Col md={6} onClick={() => setCardId(card.id)}>
                                                            <div className="credit-cards-area">
                                                                {cardId == card.id ?
                                                                    <div className="selected-check">
                                                                        <img src={require("../../../assets/images/selected-check.png")} alt="img" />
                                                                    </div> : null}
                                                                <img src={require("../../../assets/images/card.png")} alt="img" />
                                                                <p><span>****</span> <span>****</span><span>****</span><span> {card.last4}</span></p>
                                                                <div className="name-date">
                                                                    <h6>{card.brand}</h6>
                                                                    <h6>{card.exp_month}/ {card.exp_year}</h6>
                                                                </div>
                                                            </div>
                                                        </Col>))}

                                                </Row>
                                            </div> */}
                                            {/* <p className="add-card" onClick={handleShowCard}>+ Add New card</p> */}

                                        {/* </Accordion.Body> */}
                                     {/* </Accordion.Item> */}
                                </Accordion> 
                            </div>
                        </Col>
                        <Col md={12} xl={5}>

                            <div className="checkout-right-area">
                                {cartList.length > 0 ?
                                    <>
                                        <div className="checkout-product-detail">
                                            <div className="product-detail-multiple-boxes">
                                                {cartList && cartList.length > 0 && cartList.map((cart, index) => (

                                                    <div className={cart.product_sku.current_stock==0 || cart.quantity>cart.product_sku.current_stock || cart.product_sku.current_stock<cart.product_sku.min_order || cart.product_sku.is_deleted?"checkout-product-detail-box disabled":"checkout-product-detail-box"}>
                                                        <img src={bUrl + cart.images[0].image} alt="img" style={{cursor:"pointer"}} onClick={()=>pageDetail(cart.product_sku.id,cart.product_sku.is_deleted)}/>
                                                        <div className="product-detail-left">
                                                            <h5 style={{cursor:"pointer"}} onClick={()=>pageDetail(cart.product_sku.id,cart.product_sku.is_deleted)}>{cart.product_sku.product.name}</h5>
                                                            <div className="product-quantity-area">
                                                                <h6>₱ {cart.product_sku.price}</h6>
                                                                <div className="checkout-quantity-trash-area">
                                                                <form className="product-quantity checkout-product-quantity">
                                                                    <h6>Quantity:</h6>
                                                                    <p class="qty">
                                                                        <button type="button" class={cart.quantity==cart.product_sku.min_order?"qtyminus disabled":"qtyminus"} aria-hidden="true" onClick={() => changeVal("-",cart)}>-</button>
                                                                        <input type="number" name="qty" id="qty" min="1" max="10" step="1" value={cart.quantity} />
                                                                        <button type="button" class={cart.quantity>=cart.product_sku.current_stock || cart.current_stock==0?"qtyminus disabled":"qtyminus"} aria-hidden="true" onClick={() => changeVal("+",cart)}>+</button>
                                                                    </p>
                                                                    </form>
                                                                   <p className="checkout-quantity-trash" style={{cursor:"pointer"}} onClick={()=>handleShowdelete(cart.id)}> <i class="fa fa-trash" aria-hidden="true"></i>Delete</p>
                                                                    </div>
                                      
                                                            </div>
                                                            <p style={{textDecoration:"none"}} className="checkout-quantity-trash">{cart.product_sku.current_stock==0 || cart.product_sku.is_deleted?"Item no longer available":null}</p>
                                                        </div>
                                                    </div>



                                                ))}
                                            </div>
                                        </div>
                                        <div className="product-amount">
                                            <h6>Total Amount</h6>
                                            <p><span>SubTotal</span><span>₱ {subtotal}</span></p>
                                            <p><span>Delivery Fee</span><span>₱ {deliverycharge}</span></p>
                                            <p className="grand-total"><span>Grand Total</span><span>₱ {total}</span></p>
                                            <Button type="button" variant="unset" onClick={placeorder}>CONTINUE</Button>
                                        </div>
                                    </>
                                    :
                                    <div className="product-amount">
                                        <h4>No Items in cart</h4>
                                        <p></p>
                                        <br></br>
                                        <Button type="button" variant="unset" onClick={productpage}>Continue Shoping</Button>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* </Elements> */}
            </section>

            {/* modal-add-address */}

            <Modal show={showadd} onHide={handleCloseADD} centered className="add-address-box">
                <Modal.Header closeButton >
                    <Modal.Title>{addressFields.id ? "Edit" : "Add New"} Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="add-address-area mt-4 " >
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="text" maxLength={30} placeholder="First Name" value={addressFields.first_name} onChange={(e) => setaddressFields((prevObj) => { return { ...prevObj, first_name: e.target.value }; })} />
                            </Form.Group>
                            <p className="errors">{addressFieldsErr.first_name}</p>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="text" maxLength={30} placeholder="Last Name"
                                    value={addressFields.last_name} onChange={(e) => setaddressFields((prevObj) => { return { ...prevObj, last_name: e.target.value }; })} />
                            </Form.Group>
                            <p className="errors">{addressFieldsErr.last_name}</p>
                        </Col>

                        <Col md={12}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                            <GooglePlacesAutocomplete
                                            apiKey={gKey}
                                            className="form-control"
                                            onFail={error => console.log(error)}
                                            onNotFound={() => console.log('no results')}
                                            selectProps={{
                                                placeholder:"Enter Address",
                                                value,
                                                onChange: setValue,
                                            }}
                                />
                                {/* <Form.Control type="text" maxLength={50} placeholder="Address"
                                    value={addressFields.address}
                                    onChange={(e) => setaddressFields((prevObj) => { return { ...prevObj, address: e.target.value }; })} /> */}
                            </Form.Group>
                            <p className="errors">{addressFieldsErr.address}</p>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="text"
                                    value={addressFields.city}
                                    placeholder="City" maxLength={30} onChange=
                                    {(e) => setaddressFields((prevObj) => { return { ...prevObj, city: e.target.value }; })} />
                            </Form.Group>
                            <p className="errors">{addressFieldsErr.city}</p>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="text" maxLength={30} placeholder="State/Province"
                                    value={addressFields.state}
                                    onChange={(e) => setaddressFields((prevObj) => { return { ...prevObj, state: e.target.value }; })} />
                            </Form.Group>
                            <p className="errors">{addressFieldsErr.state}</p>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="text" maxLength={30} placeholder="Country" value={addressFields.country} onChange={(e) => setaddressFields((prevObj) => { return { ...prevObj, country: e.target.value }; })} />
                            </Form.Group>
                            <p className="errors">{addressFieldsErr.country}</p>
                        </Col>
                        {/* <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="text" maxLength={10} placeholder="Postal Code"
                                    value={addressFields.pincode}
                                    onChange={(e) => setaddressFields((prevObj) => { return { ...prevObj, pincode: e.target.value }; })} />
                            </Form.Group>
                            <p className="errors">{addressFieldsErr.pincode}</p>
                        </Col> */}
                        {!addressFields.id ?
                            <Form.Group className="mb-4" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Save in address book" onChange={(e) => setaddressFields((prevObj) => { return { ...prevObj, save_address: e.target.checked }; })} />
                            </Form.Group> : null}

                        <Button type="button" variant="unset" onClick={() => add_address()}> SAVE</Button>
                    </Row>
                </Modal.Body>

            </Modal>


            {/* modal-add-card */}

            <Modal show={showCard} onHide={handleCloseCard} centered className="add-address-box add-card-popup">
                <Modal.Header closeButton>
                    <Modal.Title>Add New Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CardSection />
                    <Row className="add-address-area mt-3">
                        {/* <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="text" placeholder="Card holder name" onChange={(e) => setCardFields((prevObj) => { return { ...prevObj, name: e.target.value }; })} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="number" placeholder="Card number" onChange={(e) => setCardFields((prevObj) => { return { ...prevObj, number: e.target.value }; })} />
                            </Form.Group>
                        </Col> */}

                        {/* <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <DatePicker className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="MM/yyyy" />
                              

                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="text" placeholder="CVV"
                                    onChange={(e) => setCardFields((prevObj) => { return { ...prevObj, cvc: e.target.value }; })} />
                            </Form.Group>
                        </Col> */}
                        <p className="errors">{cardErr}</p>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check onChange={(e) => setCardFields((prevObj) => { return { ...prevObj, saved: e.target.checked }; })} type="checkbox" label="Card details are securely saved for future transactions. CVV is not stored by us." />
                        </Form.Group>
                        <Button type="button" variant="unset" onClick={add_card}>SAVE</Button>
                    </Row>
                </Modal.Body>

            </Modal>
            <SuccessPopup show={show} handleClose={handleClose} message1={message1} message2={message2} />
            <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} />
            <Modal className="success-box trash-box" show={showdelete} onHide={handleClosedelete} centered>
                <Modal.Header >
                    <Modal.Title>
                        <img src={require("../../../assets/images/trash.png")} alt="img" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={deleted_from_cart}>
                       YES
                    </Button>
                    <Button className="trash-no-btn" variant="primary" onClick={handleClosedelete}>
                      NO
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default Checkout;