import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Transaction from "../../../common/transactions";

const TransactionManagement = () => {
    return (
        <>
        <Transaction type={true} />
            
        </>
    );
};
export default TransactionManagement