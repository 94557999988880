import { Button, Modal } from "react-bootstrap";
import { retailer_type } from "../../config/config"
const ContactPopup = ({ showContact, handleCloseContact, email, phone, emailSecond, phoneSecond, userType }) => {
    return (
        <>
            <Modal className="success-box contact-popup" show={showContact} onHide={handleCloseContact} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Reach Us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="reachus-content">
                        <div className="contact-content-area-box">
                        {localStorage.getItem("user_type")==retailer_type?
                        <h5>Wholesaler's Contact: </h5>:
                        <h5>Retailer's Contact: </h5>}
                            <p>{email}</p>
                            <p>{phone ? "+" + phone : "Not Added"}</p>
                        </div>
                        <div className="contact-content-area">
                            {/* <h6>Contact Number</h6> */}
                        </div>
                        {emailSecond ?
                            <>
                                <div className="contact-content-area-box mt-3">
                                {localStorage.getItem("user_type")==retailer_type? <h5>Trucker's Contact: </h5>:<h5>Wholesaler's Contact: </h5>}
                                    {/* <h6>{secontType == "w" ? "Wholeseller Email" : <>{secontType == "t" ? "Trucker Email" : null}</>}</h6> */}
                                    <p>{emailSecond}</p>
                                    <p>{phoneSecond ? "+" + phoneSecond : "Not Added"}</p>
                                </div>
                                <div className="contact-content-area">
                                    {/* <h6>{secontType == "w" ? "Wholeseller Contact Number" : <>{secontType == "t" ? "Trucker Contact Number" : null}</>}</h6> */}
                                </div>
                            </> : null}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseContact}>
                        OK
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
};
export default ContactPopup;