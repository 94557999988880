import { useState,useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { apiService} from "../../../../services/api.services";
import {useParams } from "react-router-dom";
import GooglePlacesAutocomplete,{ geocodeByAddress, getLatLng,geocodeByLatLng } from 'react-google-places-autocomplete';
import {gKey}  from "../../../../config/config"
import moment from "moment";
import Loader from "../../../common/Loader";
import Nodata from "../Dashboard/Nodata";
import SuccessPopup from "../../../modal/SuccessPopup"
import Errorpopup from "../../../modal/Errorpopup"
import { validateService} from "../../../../services/validate.services";
const AddLoads = () => {
    const id = useParams()
    const [value, setValue] = useState(null);
    const [ isLoader , setIsLoader] = useState(false)
    const [allClients, setallClients] = useState();
    const [allClientsLocation, setallClientsLocation] = useState();
    const [truckList, settruckList] = useState()
    const [pickupAddress, setpickupAddress] = useState()
    const [driver, setdriver] = useState()
    const [typetruck, settypetruck] = useState()
    const [loadId, setloadId] = useState(id.id)
    const [ message1 , setmessage1] = useState("Success")
    const [ message2 , setmessage2] = useState("")
    const [showSucc, setshowSucc] = useState(false);
    const [ errmessage1 , seterrmessage1] = useState("Error")
    const [ errmessage2 , seterrmessage2] = useState("")
    const [showErr, setShowErr] = useState(false);
    let truckType;
    let truckDriver;
    let pickupLocation;
    const [loadFields, setloadFields] = useState({
        primary_client: "",
        destination: "",
        latitude: "",
        longitude: "",
        pickup_time: "",
        delivery_charge: "",
        truck: "",
        ddr:""
       });
       const [loadFieldsErr, setloadFieldsErr] = useState({
        primary_client: "",
        destination: "",
        latitude: "",
        longitude: "",
        pickup_time: "",
        delivery_charge: "",
        truck: "",
        ddr:""
       });
       const handleShowSucc = () => setshowSucc(true);
       const handleCloseSucc = () => {
         setshowSucc(false);
         window.location.href = "/dashboard?tab=loads_management"
           }
           const handleCloseErr = () => {
            setShowErr(false);
              }
        const handleShowErr = () => setShowErr(true);
        useEffect(() => {
                if(value){
                    try{
                    geocodeByAddress(value.label)
                    .then(results => getLatLng(results[0]))
                    .then(({ lat, lng },Status) =>
                    setloadFields({
                        ...loadFields,
                        destination: value.label,
                        latitude: lat,
                        longitude: lng,   })  
                       ); 
                    }catch(e){
                        console.log("=========",e)

                    }
                }

    }, [value]);
    useEffect(() => {
        get_schedule()
    }, [id]);
    const getData = (lat, lng, addressadded) => {
        console.log({ lat: lat, lng: lng }, "=====")
        geocodeByLatLng({ lat: lat, lng: lng }).then(
            (response) => {
                console.log(response[0], "=====")
                const address = response[0].formatted_address;
                console.log(address, "====addressaddressaddressaddress=")
                setValue({ label: addressadded, value: response[0] })

            })
    }
    const get_schedule = () => {
        setIsLoader(true)
        apiService.get_schedule(id.id).then((res) => {
            console.log("===get_schedule=======", res.data)
            let data = {};
            data.primary_client = res.data.primary_client.id
            data.destination = res.data.destination
            data.pickup_time =  res.data.pickup_time
            data.delivery_charge = res.data.delivery_charge
            data.truck = res.data.loaded_truck.id
            data.ddr = res.data.ddr

            getData(res.data.latitude,res.data.longitude,res.data.destination)
          
            setloadFields(data)
            setIsLoader(false)
        })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.clear();
                    window.location.href = "/login"
                }
                setIsLoader(false)
                //   Swal.fire("Error", "Something went wrong.", "error");
                //  console.log("contact us api error", err.response.data.message);
            });
    }
       const onSelectInputChange = (field,value,ev)=>{
        // console.log("======evvvvv",ev)
        if(field=="primary_client"){
            setpickupAddress(ev.address)
        }else{
        setdriver(ev.truckdata.driver.name)
        settypetruck(ev.truckdata.type.name)
        }
       setloadFields({
              ...loadFields,
                [field]: value,
              });
       }
       function dateChange(datep){
        let dDate =  new Date(datep)
         onInputChange("pickup_time",dDate)
      }
       const onInputChange = (field,value)=>{
        console.log("========pppppTTTTT====",value)
        setloadFields({
              ...loadFields,
                [field]: value,
             
            });
       }
       useEffect(() => {
        get_client_list()
        get_truck_list()
       
       }, []);
    const   get_client_list = ()=>{
        setIsLoader(true)
        apiService.get_client_list("").then((res) => {
            setIsLoader(false)
            let type = []
            let type1 = []
            console.log(res.data)
            {res.data.data.map((item, index) => (
                //  console.log("=====iiiiiii===",item),
          
             type.push({ value: item.id, label: item.name,address:item.address[0].address })
             
              
             ))}
            setallClientsLocation(type1)
            setallClients(type)
         
           }).catch((err) => {
             
             
            //   Swal.fire("Error", "Something went wrong.", "error");
             //  console.log("contact us api error", err.response.data.message);
            });
       }
       const get_truck_list = () => {
        setIsLoader(true)
        apiService.truck_list().then((res) => {
            console.log("======trucks==tytyty===>>>", res.data)
            let type = []
            console.log(res.data)
            {res.data.data.map((item, index) => (
             type.push({ value: item.id, label: item.plate_number,truckdata:item })
             ))}
             setIsLoader(false)
             settruckList(type)
        }).catch((err) => {
            setIsLoader(false)
             });
    }
    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
      }
    const schedule = () => {
        
       let formValidated =  validateService.loadValidation(setloadFieldsErr,loadFields)
    //    if(! dateIsValid(loadFields.pickup_time)){
    //     setloadFieldsErr((obj) => {
    //         return {
    //           ...obj,
    //           pickup_time:"Select valid date"
    //         };
    //       });
    //    }
     
       if(formValidated ){
        // return;
        setIsLoader(true)
        apiService.schedule(loadFields,loadId).then((res) => {
            console.log("======trucks==tytyty===>>>", res.data)
            setmessage1("Success")
        
            setmessage2(res.data.message)
            handleShowSucc()
           
            setIsLoader(false)
         
           
        }).catch((err) => {

                setIsLoader(false)
                //   Swal.fire("Error", "Something went wrong.", "error");
                //  console.log("contact us api error", err.response.data.message);
            });
        }
    }
    return (
        <>
                   {isLoader && <Loader/>}
            <section className="add-loads-section">
                <Container>
                    <div className="manage-truck-area add-truck-area">
                    <a href="/dashboard?tab=loads_management" ><span className="backBtn" style={{cursor:"pointer"}}>   <i class="fa fa-arrow-left" aria-hidden="true"></i> Back</span>  </a> 
                        {/* <a href="/dashboard?tab=loads_management" >Load management /<span>Add Schedule/Load</span></a> */}
                        <h3 className="mb-1">{loadId?"Update":"Add "} Schedule/Load</h3>
                        <p>Uploading your schedule allows you to get matching backhauls and automate your billing process.</p>
                        <div className="stepper-form-area">
                            <Form className="mt-3">

                                <Row>

                                    <Col md={6}>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlInput1"
                                        >
                                              
                                            <Form.Label>Client Name</Form.Label>
                                            <Select options={allClients} 
                                            value={allClients && allClients.filter(function (option) {
                                                 if(option.value ===loadFields.primary_client){
                                                    pickupLocation =option.address
                                                     console.log(option.address)
                                                //    option.address
                                                }
                                                return option.value ===loadFields.primary_client;
                                              })}
                               
                                             onChange={e => onSelectInputChange("primary_client",e.value,e)}
                                            />
                                            <p className="errors">{loadFieldsErr.primary_client}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label>Pickup Location</Form.Label>
                                           
                                            <Form.Control type="text" placeholder="Enter Pickup location" disabled 
                                             value={pickupLocation?pickupLocation:pickupAddress}></Form.Control>
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label>Destination</Form.Label>
                                            <GooglePlacesAutocomplete
                                            apiKey={gKey}
                                            className="form-control"
                                            onFail={error => console.log(error)}
                                            onNotFound={() => console.log('no results')}
                                           
                                            selectProps={{
                                                placeholder:"Enter Address",
                                                value,
                                                onChange: setValue,
                                                    }}
                                                    value={loadFields.destination}
                                                    
                                />
                                             <p className="errors">{loadFieldsErr.destination}</p>
                                            {/* <Form.Control type="text" placeholder="Enter Destination" /> */}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-4 " controlId="formBasicPassword">
                                            <label htmlFor="">Delivery Date</label>
                                            <input className="form-control" type="date" dateFormat="YYYY-MM-DD hh:mm A"  value 
                                            ={moment(loadFields.pickup_time).format('YYYY-MM-DD')} onChange={(e)=>dateChange(e.target.value)}/>
                                            {/* <input className="form-control" type="date" /> */}
                                            <p className="errors">{loadFieldsErr.pickup_time}</p>
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlInput1"
                                        >
                                            <Form.Label>Truck</Form.Label>
                                            <Select options={truckList} 
                                              onChange={e => onSelectInputChange("truck",e.value,e)}
                                              value={truckList && truckList.filter(function (option) {
                                                // truckType= 
                                                if(option.value ===loadFields.truck){
                                                    truckType= option.truckdata.type.name
                                                    truckDriver = option.truckdata.driver.name
                                                    console.log("=====option====",option.truckdata
                                                    )
                                                }
                                               
                                                return option.value ===loadFields.truck;
                                              })}
                                            />
                                              <p className="errors">{loadFieldsErr.truck}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label>Type</Form.Label>
                                            <Form.Control type="text" disabled placeholder="Type of truck" value={truckType?truckType:typetruck} />
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label>Trip Rate</Form.Label>
                                            <Form.Control type="number" placeholder="Trip Rate" 
                                            onChange={e => onInputChange("delivery_charge",e.target.value)}
                                            value={loadFields.delivery_charge}
                                            />
                                              <p className="errors">{loadFieldsErr.delivery_charge}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label>Driver</Form.Label>
                                            <Form.Control type="text" placeholder="Driver" value={truckDriver?truckDriver:driver} disabled/>
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label>DDR / Trip Ticket No.</Form.Label>
                                            <Form.Control type="text" placeholder="Trip DDR / Trip Ticket No." 
                                            onChange={e => onInputChange("ddr",e.target.value)}
                                            value={loadFields.ddr}
                                            />
                                              <p className="errors">{loadFieldsErr.ddr}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        {/* <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label>Driver</Form.Label>
                                            <Form.Control type="text" placeholder="Driver" value={driver} disabled/>
                                        </Form.Group> */}
                                    </Col>

                                </Row>

                                
                            </Form>
                            <Button className="addtruck-btn" variant="unset" type="button" onClick={()=>schedule()}>{loadId?"Update":"add "} schedule load</Button>
                        </div>
                    </div>
                </Container>
            </section>
            <SuccessPopup show={showSucc} handleClose={handleCloseSucc} message1={message1} message2={message2} />
            <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} />
          
        </>
    );
};
export default AddLoads;