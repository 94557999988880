import { Button, Col, Container, Row, Form } from "react-bootstrap";

import { useState,useContext } from "react";
import {EMAILREGEX} from "../../config/config";
import { apiService} from "../../services/api.services";
import { validateService} from "../../services/validate.services";
import { context } from "../../GlobalContext/GlobalContext";
import { useParams } from "react-router-dom";
import Loader from "../common/Loader";
import { useNavigate } from "react-router-dom";
import SuccessPopup from "../modal/SuccessPopup"
import Errorpopup from "../modal/Errorpopup"

const CreatePassword = () => {
    const [ isLoader , setIsLoader] = useState(false)
    const [show, setShow] = useState(false);
    const [showPass1, setShowPass1] = useState(false);
    const [showPass2, setShowPass2] = useState(false);
    const [ message1 , setmessage1] = useState("")
    const [ message2 , setmessage2] = useState("")
    const [ redirectlink , setredirectlink] = useState("/")
    const navigate = useNavigate();
    const [showErr, setShowErr] = useState(false);
    const [ errmessage1 , seterrmessage1] = useState("Error")
    const [ errmessage2 , seterrmessage2] = useState("")
    const handleCloseErr = () => {
    setShowErr(false);
      }
      const handleShowErr = () => setShowErr(true);
    const handleClose = (link) => {
      navigate(link)
      setShow(false);
    }
    const handleShow = () => setShow(true);
    const globalData = useContext(context);
    const token = useParams()
    console.log("======token====",token)
    const [loginFields, setloginFields] = useState({
       
        password: "",
        cpassword: "",
       
       });
       
       const [loginFieldsErrs, setloginFieldsErrs] = useState({
       
        password: "",
        cpassword: "",
       
       });
       const handleSubmit = (e) => {
        e.preventDefault();
       let formValidated =  validateService.forgotpasswordvalidate(setloginFieldsErrs,loginFields)
       console.log(formValidated)
        if (formValidated) {
          let data = {
            password: loginFields.password,
            token: token.address,
            
          };
          setIsLoader(true)
          apiService.reset_password(data).then((res) => {
              setIsLoader(false)
              setmessage1("Success")
              setmessage2(res.data.message)
              setredirectlink("/login")
              handleShow()
                setloginFields({
                    password: "",
                    cpassword: "",
                });
                setloginFieldsErrs({
                  
                    password: false,
                    passwordErr:"",
                    cpassword: false,
                    cpasswordErr:"",
                   
                  });
             
            })
            .catch((err) => {
              setIsLoader(false)
              handleShowErr()
        
              seterrmessage2(err.response.data.message)
            //   Swal.fire("Error", "Something went wrong.", "error");
              console.log("contact us api error", err);
            });
        }
      };
    return (
        <>
          {isLoader && <Loader/>}
            <section className="registration-area">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="registration-left">
                                <div className="dot-vector">
                                    <img src={require("../../assets/images/dot-vector.png")} />
                                </div>
                                <h4>Create New Password</h4>
                               
                                <Form>
                                    <Form.Group className="mb-4" controlId="formBasicEmail">
                                        <Form.Label>New Password</Form.Label>
                                        <div className="pass-view">
                                        <Form.Control type={showPass1 ? "text" :"password"} placeholder="Enter New Password"
                                         onChange={(e) => {
                                            console.log(e)
                                          setloginFields((prevObj) => {
                                            return {
                                              ...prevObj,
                                              password: e.target.value,
                                            };
                                          });
                                       }}
                                         />
                                         {!showPass1 ? <i class="fa fa-eye" aria-hidden="true" onClick={() => setShowPass1(!showPass1)}></i> :
                                          <i class="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPass1(!showPass1)}></i>}
                                        </div>
                                        <p className="errors">{loginFieldsErrs.password}</p>
                                    </Form.Group>

                                    <Form.Group className="mb-4" controlId="formBasicEmail">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <div className="pass-view">
                                        <Form.Control type={showPass2 ? "text" :"password"} 
                                         onChange={(e) => {
                                          setloginFields((prevObj) => {
                                            return {
                                              ...prevObj,
                                              cpassword: e.target.value,
                                            };
                                          });
                                       }}
                                        placeholder="Enter Confirm Password"
                                         />
                                         {!showPass2 ? <i class="fa fa-eye" aria-hidden="true" onClick={() => setShowPass2(!showPass2)}></i> :
                                          <i class="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPass2(!showPass2)}></i>}
                                          </div>
                                        <p className="errors">{loginFieldsErrs.cpassword}</p>
                                    </Form.Group>

                                
                                  
                                    <Button variant="unset" type="button" onClick={handleSubmit}>
                                    Reset password
                                    </Button>
                                   
                                </Form>
                            </div>
                        </Col>
                        <Col md={6}></Col>
                    </Row>
                </Container>
                <SuccessPopup show={show} handleClose={handleClose} message1={message1} message2={message2} redirectlink={redirectlink}/>
                <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} redirectlink={redirectlink}/>
            </section>

        </>
    );
};
export default CreatePassword;