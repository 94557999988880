import { Button } from "react-bootstrap";

const Nodata = ({message1,message2}) => {
    return (
        <>
            <div className="manage-truck-area coming-soon-page mt-0">
                <div className="coming-soon-page-content">
                    <h2>{message1}</h2>
                    <p>{message2}</p>
                    {/* <Button type="button" variant="unset">Go to Hompage</Button> */}
                </div>
            </div>
        </>
    );
};
export default Nodata;