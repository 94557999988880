import { useState, useEffect, useContext, useRef } from "react";
import { Button, Col, Container, Row, Modal, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { apiService } from "../../../services/api.services";
import { bUrl, length_unit } from "../../../config/config"
import Loader from "../../common/Loader";
import { useNavigate } from "react-router-dom";
import { context } from "../../../GlobalContext/GlobalContext";
import SimpleImageSlider from "react-simple-image-slider";
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import Errorpopup from "../../modal/Errorpopup"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SuccessPopup from "../../modal/SuccessPopup";
import ContactPopup from "../../modal/ContactPopup";
import TextareaAutosize from 'react-textarea-autosize';
const ProductDetail = () => {
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1)
    const [uploadedimages, setuploadedimages] = useState([]);
    const [images, setimages] = useState();
    const [previewImage, setpreviewImage] = useState();
    const [isLoader, setIsLoader] = useState(false)
    const [productFields, setproductFields] = useState()
    const [in_cart, setin_cart] = useState(false)
    const [can_add, setcan_add] = useState(false)
    const [delete_all, setdelete_all] = useState(true)
    const [cart_id, setcart_id] = useState()
    const globalData = useContext(context);
    const [swiper, setSwiper] = useState()
    const ProImage = useRef([])
    const id = useParams()
    const [showErr, setShowErr] = useState(false);
    const [errmessage1, seterrmessage1] = useState("Error")
    const [errmessage2, seterrmessage2] = useState("")
    const [showContact, setShowContact] = useState(false);
    const handleCloseContact = () => setShowContact(false);
    const handleShowContact = () => setShowContact(true);


    const handleCloseErr = () => {
        setShowErr(false);
    }
    const handleShowErr = () => setShowErr(true);
    const [showdelete, setShowdelete] = useState(false);
    // const [ delproductId , setdelproductId] = useState()
    const [showconfirm, setShowconfirm] = useState(false);
    // const [ delproductId , setdelproductId] = useState()
    const handleCloseconfirm = () => setShowconfirm(false);
    const handleShowconfirm = (id) => {
        setShowconfirm(true);
        //   setdelproductId(id)
    }
    const handleClosedelete = () => setShowdelete(false);
    const handleShowdelete = (id) => {
        // setShowdelete(true);
        navigate("/product-listing")
        //   setdelproductId(id)
    }
    // console.log("=====id====",id)
    function changeValQty(qt) {
        console.log("=====quantity====", qt)
        setQuantity(qt)
    }
    function bulkchange() {
        if (quantity < productFields?.min_order) {
            setQuantity(productFields?.min_order)
            seterrmessage2("Quantity should be greater than minimum order")
            handleShowErr()
        } else if (quantity > productFields?.current_stock) {
            setQuantity(productFields?.current_stock)
            seterrmessage2("Quantity should be less than Current stock")
            handleShowErr()
        } else {

            if (in_cart) {

                update_cart(quantity)

            } else {
                add_to_cart(quantity)
            }
        }
    }

    function changeVal(val) {
        if (in_cart) {
            if (val == '-') {
                if (quantity - 1 >= productFields?.min_order) {

                    update_cart(quantity - 1)
                }
                // else{
                //   deleted_from_cart()  
                //   setin_cart(false)
                // }

            } else {
                if (quantity + 1 <= productFields?.current_stock) {
                    update_cart(quantity + 1)
                }

            }
        } else {
            if (val == '-') {
                if (quantity != 1 && quantity - 1 >= productFields?.min_order) {
                    setQuantity(quantity - 1)
                }

            } else {
                if (quantity + 1 <= productFields?.current_stock) {
                    setQuantity(quantity + 1)
                }
            }
        }
    }
    const checkout = () => {
        navigate("/checkout")

    }
    useEffect(() => {
        get_product_detail(id.id)
    }, [id]);
    const get_product_detail = (id) => {
        setIsLoader(true)
        apiService.get_product_detail(id).then((res) => {
            console.log("===detaile", res.data)
            console.log("===image", res.data.images)
            setcan_add(res.data.can_add)
            if (res.data.in_cart.length > 0) {
                setin_cart(true)

                setcart_id(res.data.in_cart[0].id)
                setQuantity(res.data.in_cart[0].quantity)
            } else {
                setQuantity(res.data.min_order)
            }

            setpreviewImage(bUrl + res.data.images[0].image)

            let imgdata = []
            {
                res.data.images.map((img, index) => {

                    imgdata.push({ url: bUrl + img.image })

                })
            }
            console.log("======img===", imgdata)
            setuploadedimages(imgdata)
            setimages(imgdata)
            //  ProImage.current = imgdata
            let data = res.data
            data.name = res.data.product.name
            data.category = res.data.product.category.name
            data.subcategory = res.data.product.subcategory.name
            setproductFields(data)
            setIsLoader(false)

        })
            .catch((err) => {
                console.log(err.response.status, "==productErr==")
                if (err.response.status == 404) {
                    navigate("/product-not-found")

                }
                setIsLoader(false)

            });
    }
    const deleted_from_cart = () => {
        setIsLoader(true)

        handleClosedelete()
        apiService.deleted_from_cart(cart_id).then((res) => {
            setin_cart(false)
            setcart_id(res.data.id)
            setIsLoader(false)

            globalData.setcart_count(parseInt(globalData.cart_count) - 1)
            localStorage.setItem("cart_count", parseInt(globalData.cart_count) - 1)

        }).catch((err) => {
            handleClosedelete()
            setIsLoader(false)

        });
    }

    const update_cart = (qut) => {
        setIsLoader(true)
        console.log("====qut", qut)
        const pkInteger = parseInt(id.id);
        let data = {
            product_sku: pkInteger,
            quantity: qut
        }
        apiService.update_cart(data, cart_id).then((res) => {
            setin_cart(true)
            setQuantity(res.data.quantity)

            setcart_id(res.data.id)
            setIsLoader(false)

        }).catch((err) => {
            setIsLoader(false)

        });
    }


    const confirmpop = () => {
        if (delete_all) {
            setIsLoader(true)
            handleCloseconfirm()
            apiService.deleted_all_cart().then((res) => {

                globalData.setcart_count(0)
                localStorage.setItem("cart_count", 0)
                add_to_cart()

            }).catch((err) => {
                setIsLoader(false)

            });

        } else {
            checkout()
        }

    }
    const add_to_cart = () => {

        setIsLoader(true)
        const pkInteger = parseInt(id.id);
        let data = {
            product_sku: pkInteger,
            quantity: quantity
        }
        apiService.add_to_cart(data).then((res) => {
            setin_cart(true)
            setcart_id(res.data.id)
            setIsLoader(false)
            globalData.setcart_count(parseInt(localStorage.getItem("cart_count")) + 1)
            localStorage.setItem("cart_count", parseInt(localStorage.getItem("cart_count")) + 1)


        }).catch((err) => {
            setIsLoader(false)

        });

    }
    return (
        <>
            {isLoader && <Loader />}
            <section className="product-listing-top-area">
                <Container>
                    <div className="product-listing-banner-area">
                        <Row className="align-items-center">
                            <Col md={6} xl={7}>
                                <div className="product-listing-banner-area-left">
                                    <h2>today’s offer<br></br>
                                        <span> All product & accessories</span>
                                    </h2>
                                    <p>Discover endless possibilities with our vast selection of products from our trusted wholesalers, and supercharge your retail ventures!</p>
                                </div>
                            </Col>
                            <Col md={6} xl={5}>
                                <div className="product-listing-banner-area-right">
                                    <img src={require("../../../assets/images/product-listing-banner-right.png")} alt="img" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section className="product-detail-area">
                <Container>
                    <div className="prodiuct-detail-box">
                        <p>Product / <span>{productFields?.name}</span></p>
                    </div>
                    <Row className="mt-4">
                        <Col md={7}>
                            <div className="product-image-left">
                                {images ?
                                    <>
                                        <Swiper
                                            slidesPerView={1}
                                            onSwiper={(swiperObj) => {
                                                setSwiper(swiperObj)
                                            }}
                                            modules={[Navigation, Pagination]}
                                            pagination={{ enabled: true }}
                                        >
                                            {
                                                images.map((i, tabIndex) => (
                                                    <SwiperSlide>
                                                        <img src={i.url} />
                                                    </SwiperSlide>
                                                ))
                                            }
                                        </Swiper>
                                        <button className="tab-left-arrow-btn" onClick={() => swiper.slidePrev()}><i class="fa fa-angle-left" aria-hidden="true"></i></button>
                                        <button className="tab-right-arrow-btn" onClick={() => swiper.slideNext()}><i class="fa fa-angle-right" aria-hidden="true"></i></button>
                                    </>
                                    // <SimpleImageSlider
                                    //     style={{ margin: '0 auto', marginTop: '50px' }}
                                    //     width={700}
                                    //     height={504}
                                    //     images={images}
                                    //     showBullets={true}
                                    //     NavStyle={1}
                                    //     slideDuration={0.5}
                                    //     autoPlayDelay={2}
                                    //     showNavs={true}
                                    //     loop={true}
                                    // />
                                    : null}
                                {/* <img src={previewImage} alt="img" /> */}
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className="product-detail-right">
                                <h3>{productFields?.name}</h3>
                                {/* <h6>{productFields?.name}</h6> */}
                                {/* <p>{productFields?.description}</p> */}
                                <h6>{productFields?.product?.category?.name}</h6>
                                <div className="product-availability-order">
                                    <p>Availability: <span>{productFields?.current_stock} {productFields?.units}</span></p>
                                    <p>Minimum Order: <span>{productFields?.min_order} {productFields?.units}</span></p>

                                </div>
                            

                                <h4>₱ {productFields?.price}/<span>{productFields?.units}</span></h4>
                                <form className="product-quantity">
                                    <h6>Quantity:</h6>
                                    <p class="qty">
                                        <button type="button" class={quantity == productFields?.min_order ? "qtyminus disabled" : "qtyminus"} aria-hidden="true" onClick={() => changeVal("-")}>-</button>
                                        <input type="number" maxLength={4} name="qty" onBlur={() => bulkchange()} onChange={(e) => {
                                            if (e.target.value.length <= 4) {
                                                changeValQty(e.target.value)
                                            } else {
                                                return false;
                                            }
                                        }}

                                            value={quantity} />
                                        <button type="button" class={quantity == productFields?.current_stock ? "qtyminus disabled" : "qtyminus"} aria-hidden="true" onClick={() => changeVal("+")}>+</button>
                                    </p>
                                </form>
                                <p className="product-availability-order">Width: <span>{productFields?.width} {length_unit}</span>Height: <span>{productFields?.height} {length_unit}</span>Length: <span>{productFields?.length} {length_unit}</span>Weight: <span>{productFields?.weight}{" KG"}</span></p>
                                <div className="product-availability-order">
                                    {productFields?.item_per_unit ?
                                        <p>Item/s Per Unit: <span>{productFields?.item_per_unit} </span></p> : null}
                                    {productFields?.suggested_selling_price ?
                                        <p>Suggested Retail Price: <span>₱ {productFields?.suggested_selling_price} </span></p> : null}
                                </div>
                                {/* <p>Potential Revenue/Unit: <span>22/Box</span></p> */}
                                {productFields?.current_stock == 0 || productFields?.current_stock < productFields?.min_order ?
                                    <p className="out_of_stok">*OUT OF STOCK</p> :
                                    <>
                                        {in_cart ?
                                            <>
                                                <Button type="button" variant="unset" className="mt-2" onClick={() => checkout()}>Go to Checkout</Button>
                                                <Button type="button" variant="unset" className="mt-2" onClick={() => handleShowdelete()}>Continue Shopping</Button>
                                            </> :
                                            <Button type="button" variant="unset" className="mt-2" onClick={() => { can_add ? add_to_cart() : handleShowconfirm() }}>Add to cart</Button>}
                                    </>}
                                    {in_cart ?
                                <div className="chat-now-btn-area">
                                    <div className="brand-logo-name">
                                        <img src={bUrl+productFields?.business_profile.business_logo} />
                                    </div>
                                    <div className="brand-name-area">
                                        <h6>{productFields?.business_profile.business_name}</h6>
                                        {/* <p>Active 2 Minutes ago</p> */}
                                    </div>
                                    <Button type="button" variant="unset" onClick={handleShowContact}><i class="fa fa-phone-square" aria-hidden="true"></i>Contact</Button>
                                </div>:null}
                            </div>
                        </Col>
                    </Row>
               <div className="product-description-area">
                            <h6>Product Description</h6>
                            <p>{productFields?.description?productFields?.description:"N/A"}
                                </p> </div> 
                {productFields?.retail_tip &&  productFields?.retail_tip!="undefined"?
                    <div className="product-description-area">
                        <h6>Retail Tips</h6>
                        <TextareaAutosize defaultValue={productFields?.retail_tip} minRows={3} maxRows={50} readOnly />
                        {/* <Form.Control
                            as="textarea"
                            rows={50}
                            readOnly
                            value={productFields?.retail_tip} /> */}
                        {/* <p> {productFields?.retail_tip}</p> */}
                    </div>:null}


                    {/* <div className="product-multiple-images">
                        <div className="product-images">

                        </div>
                        {uploadedimages.length>0 && uploadedimages.map((image, index) => (
                    <div className="product-images">
                <img key={index} src={bUrl+image.image} alt="img" onClick={()=>setpreviewImage(bUrl+image.image)} style={{cursor:"pointer"}}/>
                        </div>
                        ))}
                    </div> */}
                </Container>
                <Modal className="success-box trash-box" show={showdelete} onHide={handleClosedelete} centered>
                    <Modal.Header >
                        <Modal.Title>
                            <img src={require("../../../assets/images/trash.png")} alt="img" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={deleted_from_cart}>
                            YES
                        </Button>
                        <Button className="trash-no-btn" variant="primary" onClick={handleClosedelete}>
                            NO
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal className="success-box trash-box" show={showconfirm} onHide={handleCloseconfirm} centered>
                    <Modal.Header >
                        <Modal.Title>
                            <div className="confirm_title">
                                <h3>Confirm</h3>
                                <h6 >You are adding a product from a different wholesaler, what would you like to do to the existing products from your cart?</h6>
                                {/* <img src={require("../../../assets/images/trash.png")} alt="img" /> */}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="confirmation-options">

                        <Form.Check
                            type={"radio"}
                            id={`default-radio1`}
                            name="confirm"
                            checked={delete_all}
                            onClick={(e) => setdelete_all(e.target.checked)}
                            label={`Discard the existing Cart Items.`}
                        />
                        <Form.Check
                            type={"radio"}
                            id={`default-radio2`}
                            name="confirm"
                            checked={!delete_all}
                            onClick={(e) => setdelete_all(!e.target.checked)}
                            label={`Complete the existing Cart order first.`}
                        />


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={confirmpop}>
                            YES
                        </Button>
                        <Button className="trash-no-btn" variant="primary" onClick={handleCloseconfirm}>
                            NO
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} />
            </section>

            <ContactPopup showContact={showContact} handleCloseContact={handleCloseContact} email={productFields?.product.user.email} phone={productFields?.business_profile.phone}/>
        </>
    );
};
export default ProductDetail;