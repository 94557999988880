import { Button, Col, Container, Modal, Row, Form } from "react-bootstrap";
import { retailer_type } from "../../config/config"
import {apiService} from "../../services/api.services"
import {validateService} from "../../services/validate.services"

const EditBank = ({ bankFields,setbankFields,bankFieldsErrs,setbankFieldsErrs,setmessage1,setredirectlink,setmessage2,handleShow,handleShowErr,setIsLoader,seterrmessage2,seterrmessage1,handlebankModalClose,bankModal,bankList,bankId}) => {

    const updateBank = ()=>{
        bankFields.bank_id = bankFields.bank_id
        delete bankFields["id"];

    console.log("=======bankFields===",bankFields)
    let formValidated =  validateService.validaeTruckerstep2(setbankFieldsErrs,bankFields)
     
    if(formValidated){
     setIsLoader(true)
     if (bankId != undefined && bankId != null){
         apiService.update_bank(bankFields,bankId).then((res) => {
            handlebankModalClose()
           setmessage1("Success")
         setmessage2("Bank detail updated")
         setredirectlink("/profile")
         handleShow()
           localStorage.setItem("profile_step",3)
           setIsLoader(false)
           })
            .catch((err) => {
             seterrmessage1("Error")
         seterrmessage2(err.response.data.message)
         handleShowErr()
              setIsLoader(false)
            //   Swal.fire("Error", "Something went wrong.", "error");
              console.log("contact us api error", err.response.data.message);
            });
         }
     } else {
        apiService.add_bank(bankFields).then((res) => {
            handlebankModalClose()
           setmessage1("Success")
         setmessage2("Bank detail updated")
         setredirectlink("/profile")
         handleShow()
           localStorage.setItem("profile_step",3)
           setIsLoader(false)
           })
            .catch((err) => {
             seterrmessage1("Error")
         seterrmessage2(err.response.data.message)
         handleShowErr()
              setIsLoader(false)
            //   Swal.fire("Error", "Something went wrong.", "error");
              console.log("contact us api error", err.response.data.message);
            });
     }
   
    }


return(
    <Modal show={bankModal} onHide={handlebankModalClose} centered className="add-address-box">
    <Modal.Header closeButton>
        <Modal.Title>Edit Bank</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Row className="add-address-area mt-4 " >
            <Col md={12}>
                
                <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Account Holder Name</Form.Label>
                    <Form.Control type="text" placeholder="Account Holder Name" value={bankFields?.account_holder_name} onChange={(e) => setbankFields((prevObj) => { return { ...prevObj, account_holder_name: e.target.value }; })} />
                </Form.Group>
                <p className="errors">{bankFieldsErrs.account_holder_name}</p>
            </Col>
            <Col md={12}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Bank Name</Form.Label>
                <select className="form-control" value={bankFields?.bank_id} onChange={(e) => setbankFields((prevObj) => {  return { ...prevObj, bank_id: e.target.value}; })}>
                <option value="">Select Bank</option>
  {bankList && bankList.map(item => {
      return (<option key={item.id} value={item.id} label={item.label}>{item.label}</option>);
  })}
</select>
<span className="errors">{bankFieldsErrs.bank_name}</span>
                </Form.Group>
              
            </Col>
            <Col md={12}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Account Number</Form.Label>
                <Form.Control type="text"
                        value={bankFields?.account_number}
                        placeholder="Enter bank account" onChange=
                        {(e) => setbankFields((prevObj) => { return { ...prevObj, account_number: e.target.value }; })} />

               </Form.Group>
                <p className="errors">{bankFieldsErrs.account_number}</p>
                </Col>

          
           
        
            <Button type="button" variant="unset" onClick={() => updateBank()}> SAVE</Button>
        </Row>
    </Modal.Body>

</Modal>
)


};
export default EditBank;