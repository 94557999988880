import { Container } from "react-bootstrap";
import { useState,useEffect } from "react";
import Loader from "../../common/Loader";
import { apiService } from "../../../services/api.services";
import moment from "moment";
import ReactPaginate from "react-paginate";
const Notification = () => {
    const [ isLoader , setIsLoader] = useState(true)
    const [total_page, settotal_page] = useState()
    const [page, setpage] = useState(1);
    const [notification, setnotification] = useState();
   
    useEffect(() => {
        get_noti(page)
    }, []);
    const   get_noti = (page)=>{
        setIsLoader(true)
        apiService.notifications(page).then((res) => {
            console.log("======setnotification===",res.data)
            setnotification(res.data.data)
            settotal_page(res.data.total_page)
          }).catch((err) => {
              setIsLoader(false)
            });
             
         }
         const handlePageClick = (event) => {
            setpage(event.selected+1)
            get_noti(event.selected+1)
        }
    return (
        <>
            <section className="notification-area">
                <Container>
                    <div className="manage-truck-area">
                        <div className=" product-management-area">
                            <div className="product-management-top">
                                <h3>Notifations</h3>
                            </div>
                        </div>
                        { notification && notification.map((noti,index)=>{
                        return(<div className="notification-content-area">
                            <h5>{noti.title}: {noti.message}</h5>
                            <p>{moment(noti?.created_at).format('hh:mm A DD MMMM YYYY')} </p>
                        </div>)})}
                       
                      
                      
                       
                       
                        
                        
                    </div>
                </Container>
            </section>
            {notification && notification.length>0?
                <div className="pagination-area">
                    <ReactPaginate
                        nextLabel=">"

                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={total_page}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    //   forcePage={page}
                    />
                </div>:null}
        </>
    );
};
export default Notification;