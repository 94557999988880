import { useState, useEffect } from "react";
import { Col, Row, Tab, Tabs, Form, Button, ButtonToolbar, OverlayTrigger, Tooltip, Modal, Accordion } from "react-bootstrap"
import Select from "react-select";
import { apiService } from "../../../../services/api.services";
import Loader from "../../../common/Loader";
import Pagination from "../../../common/Pagination";

import moment from "moment";
import Nodata from "../Dashboard/Nodata";
import SuccessPopup from "../../../modal/SuccessPopup"
import Errorpopup from "../../../modal/Errorpopup"
import ReactPaginate from "react-paginate";
import ContactPopup from "../../../modal/ContactPopup";
import Swal from "sweetalert2";
const OrderManagement = () => {
    const [message1, setmessage1] = useState("")
    const [message2, setmessage2] = useState("")
    const [showErr, setShowErr] = useState(false);
    const [errmessage1, seterrmessage1] = useState("Error")
    const [errmessage2, seterrmessage2] = useState("")
    const [showsucces, setshowsucces] = useState(false);
    const [show, setShow] = useState(false);
    const handleCloseErr = () => {
        setShowErr(false);
      }
      const handleCloseSuccess = () => {
        setshowsucces(false);
      }
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleCloseSuccess()
        }
      };
    const handleClose = () => {
        setShow(false)
        setTruckType(1)
    };
    const handleShow = () => {
        setShow(true);
    }
    const [isLoader, setIsLoader] = useState(true)
    const [page, setpage] = useState(1);
    const [orderList, setorderList] = useState();
    const [type, settype] = useState("pending");
    const [truckType, setTruckType] = useState("1")
    const [total_page, settotal_page] = useState()
    const [orderId, setorderId] = useState()
    const [delivery_status, setdelivery_status] = useState(5)
    const [statusErr, setstatusErr] = useState("")
    const [contactEmail, setcontactEmail] = useState("")
    const [contactPhone, setcontactPhone] = useState("")
    const [contactEmail_2, setcontactEmail_2] = useState("")
    const [contactPhone_2, setcontactPhone_2] = useState("")
    // const [delivery_type, setdelivery_type] = useState(1)
    const [estimateDelivery, setestimateDelivery] = useState(null)
    const [estimatePacking, setestimatePacking] = useState(null)
    const handlePageClick = (event) => {

        setpage(event.selected+1)
        get_orders(type,event.selected+1)
    }
    useEffect(() => {
        get_orders(type, page)
        get_deliverystatus()
    }, []);
    const cahngeorderType = (ordertype, pageno) => {

        setpage(pageno)
        settype(ordertype)
        setstatusErr("")
        get_orders(ordertype, pageno)
    }
    const get_orders = (type, page) => {
        setorderList()
        setIsLoader(true)
        apiService.get_orders(type, page).then((res) => {
         
            setorderList(res.data.data)
            setIsLoader(false)
            settotal_page(res.data.total_page)
          }).catch((err) => {
              setIsLoader(false)
            });
             
         }
         const get_deliverystatus = () => {
            //  setIsLoader(true)
             apiService.delivery_status().then((res) => {
               
                console.log("======status===", res.data)
            //    setIsLoader(false)
             }).catch((err) => {
                //   setIsLoader(false)
                });
                 
             }
         const   cancel_orders = (id)=>{
          setIsLoader(true)
            apiService.cancel_order(id,"reject").then((res) => {
                console.log("======cancel===",res.data)
                  setmessage1("Success")
                      setmessage2(res.data.message)
                      setshowsucces(true)
                setIsLoader(false)
                get_orders(type,page)
                 }).catch((err) => {
                    console.log(err.response)
                    seterrmessage1("Error")
                    seterrmessage2(err?.response?.data?.message?err?.response?.data.message:"Something went wrong")
                    setShowErr(true)
                  
                   setIsLoader(false)
                 //   Swal.fire("Error", "Something went wrong.", "error");
                  //  console.log("contact us api error", err.response.data.message);
                 });
                 
             }
             const deliveryChange = (orderdata) => {

                   setTruckType(orderdata.delivery_type)
                 console.log(orderdata.estimated_packing)
                   if(orderdata.delivery_type=="1"){
                    setestimatePacking(new Date(orderdata.estimated_packing))
                   }else{
                    setestimateDelivery(new Date(orderdata.estimated_delivery))
                   }
                 
                    setorderId(orderdata.id)
                    handleShow()
                    
                
        
            }
             const change_delivery = ()=>{
                setIsLoader(true)
                let data = {
                    estimated_delivery : estimateDelivery?new Date(estimateDelivery):null,
                    estimated_packing : estimatePacking?new Date(estimatePacking):null,
                    delivery_type : truckType,
                    // delivery_status:delivery_status
                }
                apiService.change_delivery(data,orderId).then((res) => {
                    console.log("======cancel===",res.data)
                    setIsLoader(false)
                    setmessage1("Success")
                    setmessage2(res.data.message)
                    handleClose()
                    setshowsucces(true)
                 
                    get_orders(type,page)
                     }).catch((err) => {
                        console.log(err);
                       setIsLoader(false)
                       Swal.fire("Error", err.response.data.message, "error");
                      //  console.log("contact us api error", err.response.data.message);
                     });
             }
             const statusChange = (value, id) => {
                setIsLoader(true)
                let data ={
                    delivery_status : value
                }
                apiService.change_status(data,id).then((res) => {
                    console.log("======cancel===",res.data)
                    setIsLoader(false)
                    setmessage1("Success")
                    setmessage2(res.data.message)
                    setshowsucces(true)
                 
                    get_orders(type,page)
                     }).catch((err) => {
                        console.log(err);
                       setIsLoader(false)
                       Swal.fire("Error", err.response.data.message, "error");
                      //  console.log("contact us api error", err.response.data.message);
                     });

            }
             
             const   confirm_orders = ()=>{
                if(truckType==1 && estimatePacking==""){
                    setstatusErr("Please select your Packing date")
                }
               else if(truckType==2 && estimateDelivery==""){
                    setstatusErr("Please select your estimated delivery date")
                }else{
                setIsLoader(true)
                handleClose()
                let data = {
                    estimated_delivery : estimateDelivery?new Date(estimateDelivery):null,
                    estimated_packing : estimatePacking?new Date(estimatePacking):null,
                    delivery_type : truckType,
                    // delivery_status:delivery_status
                }
                  apiService.confirm_orders(orderId,data).then((res) => {
                      console.log("======cancel===",res.data)
                      setmessage1("Success")
                      setmessage2(res.data.message)
                      setshowsucces(true)
                      setstatusErr("")
                      setIsLoader(false)
                      get_orders(type,page)
                       }).catch((err) => {
                        seterrmessage1("Error")
                        seterrmessage2(err?.response?.message?err?.response?.message:"Something went wrong")
                        setShowErr(true)
                         setIsLoader(false)
                       //   Swal.fire("Error", "Something went wrong.", "error");
                        //  console.log("contact us api error", err.response.data.message);
                       });
                    }
                       
                   }
    const options = [
        { value: "confirm", label: "Confirm" },
        { value: "cancel", label: "Cancel" },

        
    ];
    const [showContact, setShowContact] = useState(false);

    const handleCloseContact = () => setShowContact(false);
    const orderDetail =(id)=>{
    window.location.href =    "/order-detail/"+id
    }
    const handleShowContact = (email,phone,email2,phone2) => {
        
        setcontactEmail(email)
        setcontactPhone(phone)
        setcontactEmail_2(email2)
        setcontactPhone_2(phone2)
        setShowContact(true);
    }
    const acceptReject = (value, id) => {
        if (value == "cancel") {
            cancel_orders(id)
        }
        if (value == "confirm") {
            setorderId(id)
            handleShow()
            
        }

    }
 
    const options2 = [
        { value: 5, label: "Order Packed" },
        { value: 6, label: "Ready to Ship" },
        { value: 7, label: "Shipped" },

    ];
    const statusOptions2 =(id) => {
        if(id==5){
            return ([
               
                { value: 6, label: "Ready to Ship" },
                { value: 7, label: "Shipped" },
        
            ])
        }
       else if(id ===6){
            return ([
            { value: 7, label: "Shipped" },
        
            ])
        }else{
            return ([
                { value: 5, label: "Order Packed" },
                { value: 6, label: "Ready to Ship" },
                { value: 7, label: "Shipped" },
        
            ])
        }
      
     
    
}
    const statusOptions =(id) => {
        if(id==2){
            return ([
               
                { value: 5, label: "Order Packed" },
                { value: 6, label: "Ready to Ship" },
                { value: 7, label: "Shipped" },
                { value: 8, label: "Delivered" },
        
            ])
        }
        if(id ===5){
            return ([
               { value: 6, label: "Ready to Ship" },
                { value: 7, label: "Shipped" },
                { value: 8, label: "Delivered" },
        
            ])
        }
        if(id ===6){
            return ([
              
                { value: 7, label: "Shipped" },
                { value: 8, label: "Delivered" },
        
            ])
        }
        if(id ===7){
            return ([
              { value: 8, label: "Delivered" },
        
            ])
        }
    
}
    const tooltip = (
        <Tooltip id="tooltip" >
            Estimated Delivery Date
        </Tooltip>
    )
    const tooltip2 = (
        <Tooltip id="tooltip2" >
            Order will be packed and ready for shipping by
        </Tooltip>
    )
    function handleSelect(key) {
        cahngeorderType(key, 1)

    }
    return (
        <>
            <div className="manage-truck-area">
                <div className=" product-management-area">
                    <div className="product-management-top">
                        <h3>Order Management</h3>
                        <p>Quickly accept new orders and easily monitor its progress.</p>
                    </div>
                </div>
                <div className="order-management-tab-area">
                    <Tabs
                        defaultActiveKey="pending"
                        id="uncontrolled-tab-example"
                        className="mb-4"
                        onSelect={handleSelect}
                    >
                        <Tab eventKey="pending" title="New Orders"  >
                        {isLoader && <Loader/>}
                        {orderList && orderList.length==0?
                            <Nodata message1={"No Data Found"} message2={""}/>:
                            <>
                            {orderList && orderList.length>0 && orderList.map((orderData, index) => {
                                console.log("=======orderData====",orderData)
                                return (<div className="manage-truck-detail-box">
                                    <Row>
                                        <Col md={10}>
                                            <div className="manage-truck-detail-left order-management-content">
                                               <h6>{orderData.product_sku[0].product.name}</h6>
                                                 <p>Quantity: <span>{orderData.quantity[0].quantity}</span></p>

                                                 {/* <p >Delivery Fee: <span>₱ {orderData?.delivery_charge} </span></p>  */}
                                                <p><img src={require("../../../../assets/images/location-icon.png")} />{orderData.address.address + "," + orderData.address.city + "," + orderData.address.state + "," + orderData.address.pincode}</p>

                                                <div className="truck-detail-bottom">
                                                    <p>Order ID: <span>{orderData.id}</span></p>
                                                    {/* <p>Quantity: <span>05</span></p>         moment(orderData?.created_at).format('MM/DD/YYYY'); */}
                                                    <p >Order Created: <span><i class="fa fa-calendar" aria-hidden="true"></i></span>{moment(orderData?.created_at).format('DD MMMM YYYY')} </p>
                                                </div>
                                                <p >Delivery Fee: <span>₱ {orderData?.delivery_charge} </span></p> 
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <div className=" order-management-content-right">
                                                <Form.Group
                                                    className="mb-2"
                                                    controlId="exampleForm.ControlInput1"
                                                >

                                                    <Select options={options}
                                                     value={options.filter(function(option) {
                                                        return option.value === orderData.delivery_status.id;
                                                      })}
                                                        onChange={e => acceptReject(e.value, orderData.id)}
                                                        placeholder="Status"
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                height: "50px",
                                                                background: "transparent",
                                                                borderRadius: "15px",
                                                                border: "none",
                                                                fontWeight: "600",
                                                                boxShadow: "none",
                                                                color: "#000",

                                                                "&:focus": {
                                                                    borderColor: "none",
                                                                },
                                                                "&:hover": {
                                                                    borderColor: "none",
                                                                },
                                                            }),
                                                            singleValue: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                color: "#000",
                                                            }),
                                                            // menuList: (baseStyles, state) => ({

                                                            // })
                                                        }} />
                                                </Form.Group>
                                                <h6 onClick={handleShow}>₱ {orderData.order_price}</h6>
                                                <p className="view-more" onClick={()=>orderDetail(orderData.id)}>View More</p>
                                                <div className="contact-btn-area">
                                                    <div className="chat-now-btn-area">
                                                        <Button type="button" variant="unset" onClick={()=>handleShowContact(orderData.user.email,orderData.retailer_business_profile.phone)}><i class="fa fa-phone-square" aria-hidden="true"></i>Contact</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>)
                            })}
                            </>}
                            

                        </Tab>
                        <Tab eventKey="progress" title="In-progress Orders" >
                        {isLoader && <Loader/>}
                        {orderList && orderList.length==0?
                            <Nodata message1={"No Data Found"} message2={""}/>:
                            <>
                            {orderList && orderList.map((orderData, index) => {
                                console.log(orderData)
                                return (<div className="manage-truck-detail-box">
                                    <Row>
                                        <Col md={10}>
                                            <div className="manage-truck-detail-left order-management-content">
                                            <h6>{orderData.product_sku[0].product.name}</h6>
                                                 <p>Quantity: <span>{orderData.quantity[0].quantity}</span></p>
                                                <p><img src={require("../../../../assets/images/location-icon.png")} />{orderData.address.address + "," + orderData.address.city + "," + orderData.address.state + "," + orderData.address.pincode}</p>
                                                <p>Delivery Option: <span>{orderData.delivery_type=="1"?"Distrix's Truck":"Own Truck"} </span></p>   
                                                <p >Delivery Fee: <span>₱ {orderData?.delivery_charge} </span></p>  
                                                <div className="truck-detail-bottom">
                                                    <p>Order ID: <span>{orderData.id}</span></p>
                                                    {orderData?.estimated_delivery==null?
  
                                                    <>{orderData?.estimated_packing==null?"":<p >Estimate Packing Date: <span><i class="fa fa-calendar" aria-hidden="true"></i></span>{moment(orderData?.estimated_packing).format('DD MMMM YYYY')} </p>}</>:
                                                    

                                         <p >Estimate Delivery Date: <span><i class="fa fa-calendar" aria-hidden="true"></i></span>{moment(orderData?.estimated_delivery).format('DD MMMM YYYY')} </p>}

                                                </div>

                                            </div>
                                        </Col>
                                        <Col md={2}>
                                        <div className=" order-management-content-right">
                                        
                                      
                                        {orderData.delivery_type=="1" && orderData.delivery_status.id == 7 ?
                                        <>
                                        {orderData.delivery_status.id == 5?
                                        <div>
                                         
                                           <Button type="button" variant="unset" >Packed</Button>
                                       </div>:<>
                                       {orderData.delivery_status.id == 6?
                                             <div >
                                             <Button type="button" variant="unset" className="ready-to-ship">Ready to ship</Button>
                                         </div>:
                                            <>
                                             {orderData.delivery_status.id == 7?
                                             <div >
                                               <Button type="button" variant="unset" className="shipped">Shipped</Button></div>
                                               :
                                               <>

                                               </>}


                                            </>}

                                       </>}
                                       </>
                                       
                                       :
                                       
                                            <div>
                                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1" >
                                             <Select options={orderData.delivery_type=="1"?statusOptions2(orderData.delivery_status.id):statusOptions(orderData.delivery_status.id)}
                                                        
                                                       onChange={e => statusChange(e.value, orderData.id)}
                                                        placeholder={orderData.delivery_status.status}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                height: "50px",
                                                                background: "transparent",
                                                                borderRadius: "15px",
                                                                border: "none",
                                                                fontWeight: "600",
                                                                boxShadow: "none",
                                                                color: "#000",

                                                                "&:focus": {
                                                                    borderColor: "none",
                                                                },
                                                                "&:hover": {
                                                                    borderColor: "none",
                                                                },
                                                            }),
                                                            singleValue: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                color: "#000",
                                                            }),
                                                            
                                                        }} />
                                                </Form.Group>
                                                
                                            </div>

                                            }
                                           
                                                 <h6 >₱ {orderData.order_price}</h6>
                                              
                                                 <p className="view-more" onClick={()=>orderDetail(orderData.id)}>View More</p>
                                                 <div className="delivery_options">
                                            
                                                     <div className="contact-btn-area">
                                                    <div className="chat-now-btn-area">
                                                        <Button type="button" variant="unset" onClick={()=>handleShowContact(orderData.user.email,orderData.retailer_business_profile.phone,orderData.loaded_truck.user.email,orderData.loaded_truck.business_profile.phone)}><i class="fa fa-phone-square" aria-hidden="true"></i>Contact</Button>
                                                    </div>
                                                </div>
                                                {orderData.delivery_status.id==2 ||  orderData.delivery_status.id==5 || orderData.delivery_status.id==6?
                                                
                                                <div className="upcoming-loads-content-bottom">
                                               <Button type="button" className="btn btn-unset " id="delivery_opt" onClick={()=>deliveryChange(orderData)}>Change Delivery Option</Button>
                                              </div>
                                               :null}
                                                </div>

                                       
                                                 
                                            </div>
                                        </Col>
                                    </Row>
                                </div>)
                            })}
                            </>}
                        
                        </Tab>
                        <Tab eventKey="past" title="Past Orders" >
                        {isLoader && <Loader/>}
                        {orderList && orderList.length==0?
                            <Nodata message1={"No Data Found"} message2={""}/>:
                            <>
                            {orderList && orderList.map((orderData, index) => {
                                return (<div className="manage-truck-detail-box">
                                    <Row>
                                        <Col md={10}>
                                            <div className="manage-truck-detail-left order-management-content">
                                            <h6>{orderData.product_sku[0].product.name}</h6>
                                                 <p>Quantity: <span>{orderData.quantity[0].quantity}</span></p>
                                                <p><img src={require("../../../../assets/images/location-icon.png")} />{orderData.address.address + "," + orderData.address.city + "," + orderData.address.state + "," + orderData.address.pincode}</p>

                                                <div className="truck-detail-bottom">
                                                    <p>Order ID: <span>{orderData.id}</span></p>
                                                    
                                                    <p>Delivery Option: <span>{orderData.delivery_type=="1"?"Distrix's Truck":"Own Truck"} </span></p>        
                                                    <p >Delivery Fee: <span>₱ {orderData?.delivery_charge} </span></p> 
                                                    {orderData?.delivery_date == null?
                                                    <p >Order Placed: <span><i class="fa fa-calendar" aria-hidden="true"></i></span>{moment(orderData?.created_at).format('DD MMMM YYYY')} </p>
                                                   : <p >Delivered: <span><i class="fa fa-calendar" aria-hidden="true"></i></span>{moment(orderData?.delivery_date).format('DD MMMM YYYY')} </p>}
                                                </div>

                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <div className=" order-management-content-right">
                                                
                                                {orderData.delivery_status.id == 8 ?
                                                <>
                                                
                                                <Button type="button" variant="unset"> {orderData.delivery_confirmed?"Delivery Confirmed":"Delivered By Trucker"}</Button> 
                                                {orderData.delivery_confirmed?null:<span className="confirm-text">Confirmation Pending from retailer</span>}
                                                </>
                                                
                                                :
                                                    <>
                                                     {orderData.delivery_status.id == 4 ?
                                                     <Button type="button" variant="unset" className="cancel-btn">Canceled</Button>:
                                                     <Button type="button" variant="unset" className="cancel-btn">Rejected</Button>}

                                                 
                                                    </>
                                                    
                                                    }
                                              
                                                <p className="view-more" onClick={()=>orderDetail(orderData.id)}>View More</p>
                                                <div className="contact-btn-area">
                                                    <div className="chat-now-btn-area">
                                                        <Button type="button" variant="unset" onClick={()=>handleShowContact(orderData.user.email,orderData.retailer_business_profile.phone,orderData.loaded_truck.user.email,orderData.loaded_truck.business_profile.phone)}><i class="fa fa-phone-square" aria-hidden="true"></i>Contact</Button>
                                                    </div>
                                                </div>
                                                <h6>₱ {orderData.order_price}</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>)
                            })}
                           </>}
                          
                        </Tab>
                    </Tabs>
                </div>
                {orderList && orderList.length>0?
                

<Pagination handlePageClick={handlePageClick}
page={page}
total_page={total_page} />:null}
            </div>
            <SuccessPopup show={showsucces} handleClose={handleCloseSuccess} message1={message1} message2={message2} handleKeyDown={handleKeyDown}/>
        <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} />
    
            {/* modal */}
            <Modal className="success-box choose-option-area" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Choose Delivery Option</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={truckType=="1"?"distrix-truck-area":"own-truck-area"}>
                        <Button type="button" variant="unset" onClick={() => setTruckType("1")}>DistriX’s truck</Button>
                        {
                            truckType === "1" ?
                                <Form.Group className="mt-2 " controlId="formBasicPassword">
                                    <label htmlFor="">Packed by</label>
                                    <input className="form-control" type="date" value={moment(estimatePacking).format('YYYY-MM-DD')} selected={estimatePacking} onChange={(e) => setestimatePacking(e.target.value)} dateFormat="YYYY-MM-DD" />
                                    <p className="errors">{statusErr}</p>
                                </Form.Group>
                                : ""
                        }
                    </div>
                    <div className={truckType=="2"?"distrix-truck-area":"own-truck-area"}>
                        <Button type="button" variant="unset" onClick={() => setTruckType("2")}> Own Truck</Button>
                        {
                            truckType === "2" ?
                                <Form.Group className="mt-2 " controlId="formBasicPassword">
                                    <label htmlFor="">Delivered by</label>
                                    <input className="form-control" type="date"  value={moment(estimateDelivery).format('YYYY-MM-DD')} selected={estimateDelivery} onChange={(date) => setestimateDelivery(date)} dateFormat="YYYY-MM-DD"/>
                              <p className="errors">{statusErr}</p>
                                </Form.Group>
                                : ""
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        truckType &&
                        <Button variant="secondary" onClick={()=>{type=="pending"?confirm_orders():change_delivery()}}>
                            Submit
                        </Button>
                    }

                </Modal.Footer>
            </Modal>

            <ContactPopup showContact={showContact} handleCloseContact={handleCloseContact} email={contactEmail} phone={contactPhone} emailSecond={contactEmail_2} phoneSecond={contactPhone_2} secontType={"t"}/>
        </>
    )
}
export default OrderManagement