import { Col, Form, Row } from "react-bootstrap"
import { useState, useEffect } from "react";

import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { gKey } from "../../../../config/config"
import { TooltipCommon } from "../../../common/tooltip";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const ct = require("countries-and-timezones");
const Step1 = ({ setprofileFields, profileFieldsErrs, profileFields, onInputChange, logo,govtId }) => {
    const timezone = ct.getTimezone(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    //   const COUNTRY_CODE = timezone.countries[0];
    const COUNTRY_CODE = "ph";


    const [value, setValue] = useState(null);
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default behavior (page refresh)
            // Handle your logic here
        }
    };
    const handleNumberChange = (event, maxDigits) => {
        const inputValue = event.target.value;
        // Change this to your desired maximum length
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default behavior (page refresh)
            // Handle your logic here
        }
        if (inputValue.length >= maxDigits && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };
    useEffect(() => {
        if (value) {
            geocodeByAddress(value.label)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) =>
                    setprofileFields({
                        ...profileFields,
                        business_address: value.label,
                        latitude: lat,
                        longitude: lng,
                    }));
        }
    }, [value]);
    const handleKeyDownName = (event) => {
        console.log("=====event====", event.which)
        if (event.which === 32) {
            event.preventDefault(); // Prevent space key action
        }
        if (event.key === 'Enter' || event.which === 13) {
            event.preventDefault(); // Prevent the default behavior (page refresh)
            // Handle your logic here
        }
    };
    // console.log("=====profileFieldsErrs==step=",profileFieldsErrs)
    return (
        <>
        
        <div className="overflow-x">
            <div className="stepper-form-area">
                <Row>
                    <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control maxLength={30} onKeyDown={handleKeyDownName} type="text" value={profileFields.first_name} placeholder="Enter First Name" onChange={e => onInputChange('first_name', e.target.value)} />
                                <span className="errors">{profileFieldsErrs.first_name}</span>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control onKeyDown={handleKeyDownName} maxLength={30} type="text" value={profileFields.last_name} placeholder="Enter Last Name" onChange={e => onInputChange('last_name', e.target.value)} />
                                <span className="errors">{profileFieldsErrs.last_name}</span>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Trucking Name</Form.Label>
                                <Form.Control onKeyDown={handleKeyDown} maxLength={30} type="text" value={profileFields.business_name} placeholder="Enter Trucking Name" onChange={e => onInputChange('business_name', e.target.value)} />
                                <span className="errors">{profileFieldsErrs.business_name}</span>
                            </Form.Group>
                        </Form>
                    </Col>
                    {/* <Col md={6}>
                    <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Trade Name</Form.Label>
                                <Form.Control onKeyDown={handleKeyDown} maxLength={50} type="text" value={profileFields.trade_name} placeholder="Enter Trade Name"  onChange={e => onInputChange( 'trade_name', e.target.value)}/>
                                <span className="errors">{profileFieldsErrs.trade_name}</span>
                            </Form.Group>
                        </Form>
                    </Col> */}
                    <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>LTFRB Number</Form.Label>
                                <Form.Control onKeyDown={handleKeyDown} maxLength={30} type="text" value={profileFields.ltfrb_number} placeholder="Enter LTFRB Number" onChange={e => onInputChange('ltfrb_number', e.target.value)} />
                                <span className="errors">{profileFieldsErrs.ltfrb_number}</span>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>

                    <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Marine Insurance</Form.Label>
                                <Form.Control onKeyDown={handleKeyDown} maxLength={30} type="text" value={profileFields.marine_insurance} placeholder="Enter Marine Insurance" onChange={e => onInputChange('marine_insurance', e.target.value)} />
                                <span className="errors">{profileFieldsErrs.marine_insurance}</span>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>DTI/Sec Number</Form.Label>
                                <Form.Control onKeyDown={handleKeyDown} maxLength={30} type="text" value={profileFields.dti_sec_number} placeholder="Enter DTI/Sec Number" onChange={e => onInputChange('dti_sec_number', e.target.value)} />
                                <span className="errors">{profileFieldsErrs.dti_sec_number}</span>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>

                    <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Mayor's Permit Number</Form.Label>
                                <Form.Control onKeyDown={handleKeyDown} maxLength={30} type="text" value={profileFields.permit_number} placeholder="Enter Permit Number" onChange={e => onInputChange('permit_number', e.target.value)} />
                                <span className="errors">{profileFieldsErrs.permit_number}</span>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">

                                <Form.Label>Contact No.</Form.Label>
                                <PhoneInput
                                    country={
                                        COUNTRY_CODE ? COUNTRY_CODE.toLocaleLowerCase() : "ph"
                                    }
                                    className={
                                        //   contactUsFieldsErrors.phone
                                        //     ? "border-red phone-input"
                                        "phone-input "
                                    }
                                    placeholder="Enter contact number"
                                    value={profileFields.phone}
                                    onChange={(e) => {
                                        setprofileFields((prevObj) => {
                                            return {
                                                ...prevObj,
                                                phone: e,
                                            };
                                        });

                                        //   if (e.trim() === "") {
                                        //     setContactUsFieldsErrors((prevObj) => {
                                        //       return {
                                        //         ...prevObj,
                                        //         phone: true,
                                        //       };
                                        //     });
                                        //   } else if (e.trim().length < 10) {
                                        //     setContactUsFieldsErrors((prevObj) => {
                                        //       return {
                                        //         ...prevObj,
                                        //         phone: true,
                                        //       };
                                        //     });
                                        //   } else {
                                        //     setContactUsFieldsErrors((prevObj) => {
                                        //       return {
                                        //         ...prevObj,
                                        //         phone: false,
                                        //       };
                                        //     });
                                        //   }
                                    }}
                                />
                                <span className="errors">{profileFieldsErrs.phone}</span>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>

                    <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Business Address</Form.Label>
                                <GooglePlacesAutocomplete
                                    apiKey={gKey}

                                    className="form-control"
                                    onFail={error => console.log(error)}
                                    onNotFound={() => console.log('no results')}
                                    selectProps={{
                                        placeholder: "Enter Address",
                                        value,
                                        onChange: setValue,
                                    }}
                                />
                                {/* <Form.Control onKeyDown={handleKeyDown} maxLength={50} type="text" value={profileFields.business_address} placeholder="Enter Business Address" onChange={e => onInputChange( 'business_address', e.target.value)}/> */}
                                <span className="errors">{profileFieldsErrs.business_address}</span>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label >Capital Investment <TooltipCommon text={"To ensure our system can offer you a precise estimate of your Return On Investment (ROI), kindly input the initial capital you invested when you launched your trucking business. This should encompass all costs, such as the purchase price of your truck, permits, and any other related expenses. This information is crucial for an accurate projection of your investment's performance."}><i role="button" className="fa fa-info-circle"></i></TooltipCommon></Form.Label>
                                <Form.Control onKeyDown={(e) => handleNumberChange(e, 20)} maxLength={20} onWheel={() => document.activeElement.blur()} type="number" value={profileFields.investment} placeholder="Enter  Your Capital Investment" onChange={e => onInputChange('investment', e.target.value)} />

                                <span className="errors">{profileFieldsErrs.investment}</span>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4  truck-logo-upload-field" controlId="formBasicEmail">
                                <Form.Label> Upload Company Logo</Form.Label>
                                <div className="upload-box-area">
                                    <div className="logo-upload-box">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <Form.Control type="text" />
                                        <Form.Control type="file" className="upload-item" onChange={e => onInputChange('business_logo', e.target.files[0])} />
                                    </div>
                                    {/* <i className="fa fa-plus" aria-hidden="true"></i> */}

                                    {logo ?
                                        <div className="upload-image-preview">
                                            <div className="first-preview-image">
                                                <img src={logo} alt={`Preview logo`} className="image-preview" />
                                            </div>

                                        </div> : null}

                                </div>
                            </Form.Group>
                            <p className="errors">{profileFieldsErrs.business_logo}</p>
                        </Form>
                    </Col>
                    <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4  truck-logo-upload-field" controlId="formBasicEmail">
                                <Form.Label> Upload Government ID</Form.Label>
                                <div className="upload-box-area">
                                    <div className="logo-upload-box">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <Form.Control type="text" />
                                        <Form.Control type="file" className="upload-item upload-government-id" onChange={e => onInputChange('govt_id', e.target.files[0])}/>
                                    </div>
                                    {/* <i className="fa fa-plus" aria-hidden="true"></i> */}
                                    {govtId ?
                                    <div className="upload-image-preview">
                                        <div className="first-preview-image">
                                            <img src={govtId} alt={`Preview logo`} className="image-preview" />
                                        </div>
                                    </div>:null}
                                </div>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                {/* <Row className="align-items-center">
                    <Col md={10}>
                            <Form.Group className="mb-4 upload-area" controlId="formBasicEmail">
                                <Form.Label>Upload Trucking Logo</Form.Label>
                                <Form.Control onKeyDown={handleKeyDown} type="text" placeholder="Upload Trucking Logo" />
                                <Form.Control onKeyDown={handleKeyDown} type="file" className="upload-item"  onChange={e => onInputChange( 'business_logo', e.target.files[0])}/>
                              
                               
                                <i class="fa fa-plus" aria-hidden="true"></i>
                              
                            </Form.Group>
                            </Col>
                            <Col md={2}>
                            {logo?
                            <div className="upload-image-preview">
                                  <div className="first-preview-image">
                                  <img src={logo} alt={`Preview logo`} className="image-preview" />
                                    </div>
                                 
                                </div>:null}
                         
                            </Col>
                        </Row> */}


                </div>
                </div>
        </>
    )
}

export default Step1