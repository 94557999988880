
import ReactPaginate from "react-paginate";
const Pagination = ({handlePageClick,total_page,page}) => {
  return (<div className="pagination-area">
                  
 <ReactPaginate
               nextLabel=">"
               onPageChange={handlePageClick}
               pageRangeDisplayed={5}
               marginPagesDisplayed={2}
               pageCount={total_page}
               previousLabel="<"
               pageClassName="page-item"
               pageLinkClassName="page-link"
               previousClassName="page-item"
               previousLinkClassName="page-link"
               nextClassName="page-item"
               nextLinkClassName="page-link"
               breakLabel="..."
               breakClassName="page-item"
               breakLinkClassName="page-link"
               containerClassName="pagination"
               activeClassName="active"
               renderOnZeroPageCount={null}
               forcePage={page-1}
           />
</div>  )
};
export default Pagination;