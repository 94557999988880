import { Button, Container, Table } from "react-bootstrap";
import Pagination from "../../common/Pagination";
import Transaction from "../../common/transactions";
const TransactionManagement = () => {
    return (
        <section className="order-history-area">
        <Container>
        
        <Transaction type={false}/>
          
      </Container>
      </section>
    )
}
export default TransactionManagement;