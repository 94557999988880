import { useState, useEffect } from "react";
import { Button, Col, Container, Modal, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { validateService } from "../../../services/validate.services";
import { apiService } from "../../../services/api.services";
import { bUrl, gKey } from "../../../config/config"
import Errorpopup from "../../modal/Errorpopup"
import SuccessPopup from "../../modal/SuccessPopup"
import EditBank from "../../modal/EditBank"
import { type } from "@testing-library/user-event/dist/type";
import Swal from 'sweetalert2';
import Loader from "../../common/Loader";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
const Profile = () => {
    const [addressModal, setAddressModal] = useState(false);
    const [bankModal, setbankModal] = useState(false);
    const [showPass1, setShowPass1] = useState(false);
    const [showPass2, setShowPass2] = useState(false);
    const [showPass3, setShowPass3] = useState(false);
    const handleAddModalClose = () => setAddressModal(false);
    const handlebankModalClose = () => setbankModal(false);
    const handleAddModalShow = () => setAddressModal(true);
    const [addresses, setaddresses] = useState([])
    const [bankId, setbankId] = useState()
    const [bankList, setbankList] = useState(null);
    const [addressId, setaddressId] = useState("")
    const [value, setValue] = useState(null);
    const [addressFields, setaddressFields] = useState({
        first_name: "",
        last_name: "",
        address: "",
        latitude: "",
        longitude: "",
        city: "",
        state: "",
        pincode: "11111",
        country: "Philippines",
        save_address: true,
    });
    const [addressFieldsErr, setaddressFieldsErr] = useState({
        first_name: "",
        last_name: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        country: "",
        save_address: false,
    });
    const [bankFieldsErrs, setbankFieldsErrs] = useState({
        account_number: "",
        // routing_number: "",
        account_holder_name: "",
        bank_name: ""
    });
    useEffect(() => {
        get_banks()
    }, []);
    const editBank = (id) => {
        setbankId(id)
        setbankModal(true)
    }
    const get_banks = () => {
        apiService.get_banks().then((res) => {
            console.log("=======bank list====>", res.data.data)
            setbankList(res.data.data)

        }).catch((err) => { });

    }
    let history = useNavigate();

    function EditProfile() {
        history("/edit_profile");
    }
    function transaction() {
        history("/transaction-management");
    }

    useEffect(() => {
        if (value) {
            geocodeByAddress(value.label)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) =>
                    setaddressFields({
                        ...addressFields,
                        address: value.label,
                        latitude: lat,
                        longitude: lng,
                    }));
        }
    }, [value]);
    useEffect(() => {
        get_business_profile()
        get_address()
    }, [])
    const [isLoader, setIsLoader] = useState(false)

    const [showpass, setShowpass] = useState(false);
    const [profileFields, setprofileFields] = useState();
    const [bankFields, setbankFields] = useState();
    const [bankFieldshow, setbankFieldswhow] = useState();

    const handleClosepass = () => setShowpass(false);
    const handleShowpass = () => setShowpass(true);
    const [show, setShow] = useState(false);
    const [showErr, setShowErr] = useState(false);
    const [errmessage1, seterrmessage1] = useState("Error")
    const [errmessage2, seterrmessage2] = useState("")
    const handleCloseErr = () => {
        setShowErr(false);
    }
    const handleShowErr = () => setShowErr(true);
    const [message1, setmessage1] = useState("")
    const [message2, setmessage2] = useState("")
    const navigate = useNavigate();
    const [redirectlink, setredirectlink] = useState("/")
    const handleShow = () => setShow(true);

    const handleClose = () => {
        window.location.href = ("/profile")
        // setShow(false);
    }
    const [passwordFields, setpasswordFields] = useState({
        old_password: "",
        new_password: "",
        cpassword: "",
    })
    const [passwordFieldsErr, setpasswordFieldsErr] = useState({
        oldpassword: "",
        password: "",
        cpassword: "",
    })
    const handleInputChange = (field, value) => {
        setpasswordFields({
            ...passwordFields,
            [field]: value,

        });
    }

    const handleChangePassword = () => {
        console.log("=====passwordFields====", passwordFields)
        let validform = validateService.resetpasswordvalidate(setpasswordFieldsErr, passwordFields)
        if (validform) {
            apiService.change_password(passwordFields).then((res) => {
                handleClosepass()
                setmessage1("Success")
                setmessage1("Password changed successfully")
                handleShow()


            }).catch((err) => {
                handleShowErr()
                seterrmessage2(err.response.data.message)
                setIsLoader(false)
            });
        }

    }
    const get_business_profile = () => {
        // get_bank()
        apiService.get_business_profile().then((res) => {
            console.log("===get_business_profile", res.data.data.bank_detail)
            setprofileFields(res.data.data)
            setbankFields(res.data.data.bank_detail)
            setbankFieldswhow(res.data.data.bank_detail)
        }).catch((err) => {

            setIsLoader(false)
            //   Swal.fire("Error", "Something went wrong.", "error");
            //  console.log("contact us api error", err.response.data.message);
        });
    }

    const get_address = () => {
        setIsLoader(true)
        apiService.get_address().then((res) => {
            setaddresses(res.data.data)
            console.log("===detaile", res.data.data)
            setIsLoader(false)
        })
            .catch((err) => {
                setIsLoader(false)

            });
    }
    const delete_address = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoader(true)
                apiService.delete_address(id).then((res) => {
                    setaddresses(res.data.data)
                    console.log("===detaile", res.data.data)
                    get_address()
                    setIsLoader(false)
                }).catch((err) => {
                    setIsLoader(false)

                });
            } else if (result.isDenied) {
                // Swal.fire('Changes are not saved', '', 'info')
            }
        })



    }


    const get_address_detail = (id) => {
        setIsLoader(true)
        apiService.address_detail(id).then((res) => {
            setaddressFields(res.data)
            setAddressModal(true)
            console.log("===detaile", res.data)
            setIsLoader(false)
        })
            .catch((err) => {
                setIsLoader(false)

            });
    }
    const add_address = () => {
        let formValidated = validateService.addressValidation(setaddressFieldsErr, addressFields)
        if (formValidated) {

            setIsLoader(true)
            apiService.add_address(addressFields).then((res) => {
                console.log("===detaile", res.data)
                setIsLoader(false)
                setaddressFields({
                    first_name: "",
                    last_name: "",
                    address: "",
                    city: "",
                    state: "",
                    pincode: "",
                    country: "",
                    save_address: true,
                });
                setAddressModal(false)
                get_address()
            })
                .catch((err) => {
                    setIsLoader(false)

                });
        }
    }

    return (
        <>
            {isLoader && <Loader />}
            <section className="my-profile-area">
                <Container>
                    <div className="manage-truck-area ">
                        <div className="my-profile-top">
                            <h3>My Profile</h3>
                            <div className="my-profile-top-right">
                                <Button type="button" variant="unset" className="edit-profile-btn me-2" onClick={transaction}>
                                    Transactions
                                </Button>
                                <Button type="button" variant="unset" className="change-password-btn" onClick={handleShowpass}>
                                    Change Password
                                </Button>
                                <Button type="button" variant="unset" className="edit-profile-btn" onClick={EditProfile}>
                                    Edit Profile
                                </Button>
                            </div>
                        </div>
                        <div className="trucker-image-area">
                            <img src={profileFields && profileFields?.user.image ? bUrl + profileFields.user?.image : require("../../../assets/images/trucker-image.png")} alt="img" />

                        </div>
                        <Row className="mt-5">
                            <Col md={4}>
                                <div className="trucker-info-detail">
                                    <p>First Name: </p>
                                    <h6>{profileFields?.user?.first_name}</h6>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="trucker-info-detail">
                                    <p>Last Name:  </p>
                                    <h6>{profileFields?.user?.last_name}</h6>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="trucker-info-detail">
                                    <p>Business Name:  </p>
                                    <h6>{profileFields?.business_name}</h6>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="trucker-info-detail">
                                    <p>Email:  </p>
                                    <h6>{profileFields?.user?.email}</h6>
                                </div>
                            </Col>
                            {/* <Col md={4}>
                                <div className="trucker-info-detail">
                                    <p>Retailer Name:  </p>
                                    <h6>{profileFields?.trade_name}</h6>
                                </div>
                            </Col> */}
                            <Col md={4}>
                                <div className="trucker-info-detail">
                                    <p>Business Logo: </p>
                                    <div className="upload-image-preview">
                                        <div className="first-preview-image">
                                            <img src={bUrl + profileFields?.business_logo} alt="img" />
                                        </div></div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="trucker-info-detail">
                                    <p>Business Address:  </p>
                                    <h6>{profileFields?.business_address}</h6>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="trucker-info-detail mb-2">
                                    <p>Contact Number:</p>
                                    <h6>+{profileFields?.phone}</h6>
                                </div>
                            </Col>

                            <Col md={12}>

                                <div className="checkout-cards-area">
                                    <Row className="mt-3">
                                        {console.log("======bankFields====", bankFields)}

                                        {/* {cardList && cardList.length > 0 && cardList.map((card, index) => ( */}

                                        <Col md={12} lg={12} xl={12}>
                                            <div className="credit-cards-area manage-card-area">
                                                <div className="card-detail-top">
                                                    <h5>Bank Detail</h5>
                                                    <h6 onClick={() => editBank(bankFieldshow?.id)} style={{ cursor: "pointer" }}><i class="fa fa-pencil-square-o" aria-hidden="true"></i>Edit Bank Detail</h6>
                                                </div>
                                                <Form>

                                                    {/* <div key={`default-${type}`} className="card-number-area"> */}

                                                    <p>Account Holder Name: <span>{bankFieldshow?.account_holder_name}</span></p>
                                                    <p>Account No: <span>{bankFieldshow?.account_number}</span></p>
                                                    <p>Bank Name: <span>

                                                        {bankList?.map(function (option) {
                                                            if (option.id == bankFieldshow?.bank_id) {
                                                                return option.label
                                                            }
                                                        })}
                                                    </span></p>
                                                    {/* </div> */}

                                                </Form>
                                            </div>
                                        </Col>

                                        {/* ))} */}



                                    </Row>
                                </div>

                            </Col>

                            <Col md={12} className="mb-5">
                                <div className="manage-addresses-area">
                                    <h5>Manage Addresses</h5>

                                    <div className="addresses-box-area">
                                        <Row>
                                            {addresses && addresses.length > 0 && addresses.map((address, index) => (
                                                <Col md={12} lg={6} className="mb-3">
                                                    <div className="addresses-box">
                                                        <div className="edit-delete addresses-edit-delete">
                                                            <p className="edit" onClick={() => get_address_detail(address.id)}><i class="fa fa-pencil-square-o" aria-hidden="true" ></i>Edit</p>
                                                            <p className="delete" onClick={() => delete_address(address.id)}>   <i className="fa fa-trash-o" aria-hidden="true" ></i>Delete</p>
                                                        </div>
                                                        <Form>
                                                            {['radio'].map((type) => (
                                                                <div key={`default-${type}`} className="">
                                                                    <Form.Check // prettier-ignore
                                                                        type={type}
                                                                        id={`default-${type}`}
                                                                        label={`${address.first_name + " " + address.last_name}`}
                                                                    />
                                                                    <p>{` ${address.address}, ${address.city}, ${address.state} ${address.pincode}, ${address.country}`}</p>


                                                                </div>
                                                            ))}
                                                        </Form>
                                                    </div>
                                                </Col>))}


                                            <p className="add-card" onClick={handleAddModalShow}>+ Add New address</p>
                                        </Row>
                                    </div>

                                </div>
                            </Col>

                        </Row>
                    </div>
                </Container>
            </section>

            {/* modal */}
            <Modal className="success-box change-password-box" show={showpass} onHide={handleClosepass} centered>
                <Modal.Header closeButton >
                    <Modal.Title>
                        Change Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="registration-left change-password-content p-0">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Current Password</Form.Label>
                                <div className="pass-view">
                                    <Form.Control type={showPass1 ? "text" :"password"} placeholder="Enter Current Password" onChange={(e) => handleInputChange("old_password", e.target.value)} />
                                    {!showPass1 ? <i class="fa fa-eye" aria-hidden="true" onClick={() => setShowPass1(!showPass1)}></i> :
                                        <i class="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPass1(!showPass1)}></i>}
                                </div>
                                <p className="errors">{passwordFieldsErr.oldpassword}</p>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>New Password</Form.Label>
                                <div className="pass-view">
                                    <Form.Control type={showPass2 ? "text" :"password"} placeholder="Enter New Password" onChange={(e) => handleInputChange("new_password", e.target.value)} />
                                    {!showPass2 ? <i class="fa fa-eye" aria-hidden="true" onClick={() => setShowPass2(!showPass2)}></i> :
                                        <i class="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPass2(!showPass2)}></i>}
                                </div>
                                <p className="errors">{passwordFieldsErr.password}</p>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Confirm New Password</Form.Label>
                                <div className="pass-view">
                                    <Form.Control type={showPass3 ? "text" :"password"} placeholder="Enter Confirm Password" onChange={(e) => handleInputChange("cpassword", e.target.value)} />
                                    {!showPass3 ? <i class="fa fa-eye" aria-hidden="true" onClick={() => setShowPass3(!showPass3)}></i> :
                                        <i class="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPass3(!showPass3)}></i>}
                                </div>
                                <p className="errors">{passwordFieldsErr.cpassword}</p>
                            </Form.Group>
                        </Form>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleChangePassword}>
                        update
                    </Button>

                </Modal.Footer>
            </Modal>
            <SuccessPopup show={show} handleClose={handleClose} message1={message1} message2={message2} redirectlink={redirectlink} />
            <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} redirectlink={redirectlink} />


            {/* modal-add-address */}

            <Modal show={addressModal} onHide={handleAddModalClose} centered className="add-address-box">
                <Modal.Header closeButton>
                    <Modal.Title>Add New Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="add-address-area mt-4 " >
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="text" placeholder="First Name" value={addressFields.first_name} onChange={(e) => setaddressFields((prevObj) => { return { ...prevObj, first_name: e.target.value }; })} />
                            </Form.Group>
                            <p className="errors">{addressFieldsErr.first_name}</p>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="text" placeholder="Last Name"
                                    value={addressFields.last_name} onChange={(e) => setaddressFields((prevObj) => { return { ...prevObj, last_name: e.target.value }; })} />
                            </Form.Group>
                            <p className="errors">{addressFieldsErr.last_name}</p>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <GooglePlacesAutocomplete
                                    apiKey={gKey}
                                    className="form-control"
                                    onFail={error => console.log(error)}
                                    onNotFound={() => console.log('no results')}
                                    selectProps={{
                                        placeholder: "Enter Address",
                                        value,
                                        onChange: setValue,
                                    }}
                                />
                            </Form.Group>
                            <p className="errors">{addressFieldsErr.address}</p>
                        </Col>

                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="text"
                                    value={addressFields.city}
                                    placeholder="City/Town" onChange=
                                    {(e) => setaddressFields((prevObj) => { return { ...prevObj, city: e.target.value }; })} />
                            </Form.Group>
                            <p className="errors">{addressFieldsErr.city}</p>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="text" placeholder="State/Province"
                                    value={addressFields.state}
                                    onChange={(e) => setaddressFields((prevObj) => { return { ...prevObj, state: e.target.value }; })} />
                            </Form.Group>
                            <p className="errors">{addressFieldsErr.state}</p>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="text" placeholder="Country" value={addressFields.country} onChange={(e) => setaddressFields((prevObj) => { return { ...prevObj, country: e.target.value }; })} />
                            </Form.Group>
                            <p className="errors">{addressFieldsErr.country}</p>
                        </Col>
                        <Col md={6}>
                            {/*  <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="text" placeholder="Postal Code"
                                    value={addressFields.pincode}
                                    onChange={(e) => setaddressFields((prevObj) => { return { ...prevObj, pincode: e.target.value }; })} />
                            </Form.Group>
                            <p className="errors">{addressFieldsErr.pincode}</p>*/}
                        </Col>

                        <Button type="button" variant="unset" onClick={() => add_address()}> SAVE</Button>
                    </Row>
                </Modal.Body>

            </Modal>



            <EditBank bankFields={bankFields} setbankFields={setbankFields} bankFieldsErrs={bankFieldsErrs} setbankFieldsErrs={setbankFieldsErrs} setmessage1={setmessage1} setredirectlink={setredirectlink} setmessage2={setmessage2} handleShow={handleShow} handleShowErr={handleShowErr} handlebankModalClose={handlebankModalClose} bankModal={bankModal} bankList={bankList} bankId={bankId} setIsLoader={setIsLoader} isLoader={isLoader} seterrmessage1={seterrmessage1} seterrmessage2={seterrmessage2} />
        </>
    );
};
export default Profile;