import { useState } from "react";
import { Button, Col, Row,Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import AddNewProduct from "./AddNewProduct";
import { useEffect } from "react";
import { apiService } from "../../../../services/api.services";
import Nodata from "../Dashboard/Nodata";
import Loader from "../../../common/Loader";
import Pagination from "../../../common/Pagination";
import { bUrl, allowedImageTypes, length_unit } from "../../../../config/config"
const ProductManagement = () => {
    const handlePageClick = (event) => {
        setpage(event.selected+1)
        get_product_list(event.selected+1)
    }

    const [showAdd, setShowAdd] = useState(false)
    const [productId, setproductId] = useState()
    const [productList, setproductList] = useState()
    const [total_count, settotal_count] = useState()
    const [total_page, settotal_page] = useState()
    const [page, setpage] = useState(1)
    const [ isLoader , setIsLoader] = useState(true)
    const [showdelete, setShowdelete] = useState(false);
    const [ delproductId , setdelproductId] = useState()
    const handleClosedelete = () => setShowdelete(false);
    const handleShowdelete = (id) => {
      setShowdelete(true);
      setdelproductId(id)
  }
    useEffect(() => {
        return () => {
            setShowAdd(false)
        }
    }, [])
    const deleteProduct = ()=>{
        setIsLoader(true)
        apiService.delete_product(delproductId).then((res) => {
         console.log("===product",res.data)
         get_product_list(page)
         handleClosedelete()
         
         })
           .catch((err) => {
            handleClosedelete()
            
             setIsLoader(false)
           //   Swal.fire("Error", "Something went wrong.", "error");
            //  console.log("contact us api error", err.response.data.message);
           });
       }
    const get_product_list = (page)=>{
        setIsLoader(true)
        apiService.get_product_list(page).then((res) => {
         console.log("===trucks",res.data)
         setproductList(res.data.data)
         settotal_count(res.data.total_count)
         settotal_page(res.data.total_page)
         setIsLoader(false)
         })
           .catch((err) => {
            
             setIsLoader(false)
           //   Swal.fire("Error", "Something went wrong.", "error");
            //  console.log("contact us api error", err.response.data.message);
           });
       }
       useEffect(() => {
        if(showAdd==false){
            setproductId()
            get_product_list(page)
        }
       
       
       }, [showAdd]);
       const editTruck=(id)=>{
        setproductId(id)
        setShowAdd(true)
    }

    function renderItems(list,index) {
        return (
            <div className="manage-truck-detail-box">
                <Row>


                    <Col md={10}>
                        <div className="manage-truck-detail-left">
                            <h6>{list.product.name}</h6>

                            <div className="truck-detail product-detail">
                                <p>Category:  <span>{list.product.category.name}</span></p>
                                <p>Subcategory: <span>{list.product.subcategory.name}</span></p>
                                <p>Min Order:  <span>{list.min_order}</span></p>
                                <p>Current Stock:  <span>{list.current_stock}</span></p>
                                {/* <p>Add stock:   <span>{list.add_stock}</span></p>
                                <p>Deduct Stock: <span>{list.deduct_stock}</span></p> */}
                            </div>
                            <div className="truck-detail-bottom">
                                <p> Unit:  <span>{list.units}</span></p>
                                <p className="truck-weight-area">Weight: <span>{list.weight} kg, </span> Length: <span>{list.length} {length_unit},</span> Width: <span>{list.width} {length_unit},</span>Height: <span>{list.height} {length_unit}</span></p>
                                <p>Price:  <span>₱  {list.price}/unit</span></p>
                                <p>Net Payout:  <span>₱  {list.net_payout}</span></p>
                                
                            </div>
                            <div className="truck-images product-images">
                            {list.images.map((file,index)=>{
                                return(<img   src={bUrl+file.image} alt="img" /> )})}
                            
                            </div>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="manage-truck-detail-right">
                            <Button className="edit-btn" variant="unset" type="button" onClick={()=>editTruck(list.id)}>Edit</Button>
                            <Button className="delete-btn" variant="unset" type="button" onClick={()=>handleShowdelete(list.id)}>Delete</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
    
    return (
        <>
           {isLoader && <Loader/>}

            {
                showAdd ?
                    <AddNewProduct setShowAdd={setShowAdd} product_id={productId}/>
                    :
                    <>
                        <div className="manage-truck-area">
                            <div className=" product-management-area">
                                <div className="product-management-top">
                                    <h3 >Product <span>Management</span></h3>
                                    <p> Streamline Product Management: Upload, Edit, and Optimize Your Products with Ease</p>
                                </div>
                                <Button type="button" variant="unset" onClick={() => setShowAdd(true)}>+ Add new products</Button>
                            </div>
                            {productList && productList.length==0? <Nodata message1={"No Data Found"} message2={"You need to add data."}/>:
                            <>
                                 {productList && productList.map((list,index)=>{
                                    return renderItems(list,index)
                                })
                            }
                          </>

                          
                          
                           }
                            {productList && productList.length>0?
            

<Pagination handlePageClick={handlePageClick}
page={page}
total_page={total_page} />:null}
                        </div >
                    </>
            }

<Modal className="success-box trash-box" show={showdelete} onHide={handleClosedelete} centered>
                <Modal.Header >
                    <Modal.Title>
                        <img src={require("../../../../assets/images/trash.png")} alt="img" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={deleteProduct}>
                       YES
                    </Button>
                    <Button className="trash-no-btn" variant="primary" onClick={handleClosedelete}>
                      NO
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default ProductManagement;