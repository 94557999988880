import { useState, useEffect } from "react";
import { apiService } from "../../../../services/api.services";
import { bUrl, length_unit } from "../../../../config/config"
import Nodata from "../Dashboard/Nodata";
import Loader from "../../../common/Loader";
import Pagination from "../../../common/Pagination";


const { Button, Row, Col, Modal } = require("react-bootstrap")
const { default: ReactPaginate } = require("react-paginate")

const Trucks = ({ setKey, settruckId }) => {
    const [showdelete, setShowdelete] = useState(false);
    const [isLoader, setIsLoader] = useState(false)
    const [truckList, settruckList] = useState()
    const [message2, setmessage2] = useState("")
    const [deltruckId, setdeltruckId] = useState()
    const [page, setpage] = useState(1)
    const [total_count, settotal_count] = useState()
    const [total_page, settotal_page] = useState()
    const handlePageClick = (event) => {
        console.log(event.selected)
        setpage(event.selected + 1)
        get_truck_list(event.selected + 1)

    }
    const handleClosedelete = () => setShowdelete(false);
    const handleShowdelete = (id) => {
        setShowdelete(true);
        setdeltruckId(id)
    }
    const editTruck = (id) => {
        settruckId(id)
        setKey('add')
    }
    const deleteTruck = () => {
        apiService.delete_truck(deltruckId).then((res) => {
            console.log("===trucks", res.data)
            get_truck_list(page)

        })
            .catch((err) => {

                setIsLoader(false)
                //   Swal.fire("Error", "Something went wrong.", "error");
                //  console.log("contact us api error", err.response.data.message);
            });
    }
    const get_truck_list = (page) => {
        setIsLoader(true)
        apiService.get_truck_list(page).then((res) => {
            console.log("===trucks", res.data)
            setIsLoader(false)
            settruckList(res.data.data)
            settotal_count(res.data.total_count)
            settotal_page(res.data.total_page)
        }).catch((err) => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = "/login"
            }
                setIsLoader(false)
                //   Swal.fire("Error", "Something went wrong.", "error");
                //  console.log("contact us api error", err.response.data.message);
            });
    }
    useEffect(() => {
        get_truck_list(page)
    }, []);
    return (
        <>
            {isLoader && <Loader />}
            <div className="manage-truck-area">
                <div className="recent-loads-top-area manage-truck-top-area">
                    <div>
                        <h3>Manage Truck</h3>
                        <p>Turbocharge your logistics game! Add your trucks to unlock the full potential of matching backhauls and backloads.</p>
                    </div>
                    <Button type="button" variant="unset" onClick={() => setKey('add')}>+ Add Truck</Button>
                </div>

                {truckList && truckList.map((list, index) => {
                    // console.log("=====>list========",list)
                    return (<div className="manage-truck-detail-box">
                        <Row>
                            <Col md={8}>
                                <div className="manage-truck-detail-left">
                                    <h6>{list.driver?.name}<span> (Driver’s name)</span></h6>
                                    <p>Type: <span>{list.type?.name}</span></p>
                                    <div className="truck-detail">
                                        <p>Plate No: <span>{list.plate_number}</span></p>
                                        <p>Model year: <span>{list.model_year}</span></p>
                                        <p>Capacity: <span>{list.capacity} {list.weight_unit?.name}</span></p>
                                    </div>
                                    <div className="truck-detail-bottom">
                                        <p>Width: <span>{list.width} {length_unit},</span> Length: <span>{list.length} {length_unit}</span></p>
                                        <p>Total CBM: <span>{list.total_cbm}</span></p>
                                        <p>Make: <span>{list.make}</span></p>
                                        <p>Color: <span>{list.color}</span></p>
                                    </div>
                                    <div className="truck-images">
                                        {list.images.map((file, index) => {
                                            return (<img src={bUrl + file.image} alt="img" />)
                                        })}

                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="manage-truck-detail-right">
                                    <Button className="edit-btn" variant="unset" type="button" onClick={() => editTruck(list.id)}>Edit</Button>
                                    <Button className="delete-btn" variant="unset" type="button" onClick={() => handleShowdelete(list.id)}>Delete</Button>
                                </div>
                            </Col>
                        </Row> </div>)
                })}


                {truckList && truckList.length > 0 ?
                   

<Pagination handlePageClick={handlePageClick}
page={page}
total_page={total_page} /> : null}
            </div>

            {/* modal */}
            <Modal className="success-box trash-box" show={showdelete} onHide={handleClosedelete} centered>
                <Modal.Header >
                    <Modal.Title>
                        <img src={require("../../../../assets/images/trash.png")} alt="img" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={deleteTruck}>
                        YES
                    </Button>
                    <Button className="trash-no-btn" variant="primary" onClick={handleClosedelete}>
                        NO
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Trucks