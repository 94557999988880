import axios from "axios"
import { useState,useContext } from "react";
import { baseUrl} from "../config/config";

const token = localStorage.getItem("web_token")

const jsonAuthHeaders = {
    headers: {
        "Authorization": `Token ${token}`
    }
}
const jsonFileHeaders = {
    headers: {
        "Content-Type": "application/json",
        
    }
}

export const apiService = {
    register,
    login,
    change_password,
    reset_password,
    user_groups,
    forgot_password,
    user_profile,
    add_bank,
    truck_types,
    add_truck,
    get_business_profile,
    get_bank,
    weight_units,
    get_truck_detail,
    delete_truck_image,
    get_truck_list,
    delete_truck,
    update_user_profile,
    resendLink,
    get_sub_category,
    get_category,
    add_product,
    get_product_list,
    delete_product_image,
    get_product_detail,
    delete_product,
    get__all_product_list,
    add_to_cart,
    update_cart,
    deleted_from_cart,
    get_cart,
    add_address,
    get_address,
    delete_address,
    address_detail,
    update_address,
    add_card,
    get_card,
    checkout,
    delete_card,
    add_client,
    get_client,
    del_client,
    get_client_list,
    deleted_all_cart,
    get_orders,
    cancel_order,
    confirm_orders,
    notifications_read,
    notifications,
    delivery_status,change_status,
    accept_load,
    find_load,
    truck_list,
    my_loads,
    schedule,
    change_load_status,
    get_order_detail,
    dashboardData,
    notification_count,
    change_delivery,
    payment_success,
    get_banks,
    update_bank,
    transactions,
    confirm_delivery,
    revenue_transactions,
    get_schedule
   }

function register(params){
    return axios.post(`${baseUrl}/user/`, params, jsonFileHeaders);
}
function login(params){
    return axios.post(`${baseUrl}/login/`, params, jsonFileHeaders);
}
function change_password(params){
    return axios.put(`${baseUrl}/user/change_password/`, params, jsonAuthHeaders);
}
function reset_password(params){
    return axios.put(`${baseUrl}/user/reset_password/`, params, jsonFileHeaders);
}
function forgot_password(params){
    return axios.put(`${baseUrl}/user/forgot_password/`, params, jsonFileHeaders);
}

function user_groups(){
    return axios.get(`${baseUrl}/user_groups/`, jsonFileHeaders);
}

function user_profile(params){
    let data= new FormData()
    console.log(params,"=======params===")
    data.append("first_name",params.first_name)
    data.append("last_name",params.last_name)
    data.append("business_name",params.business_name)
    data.append("trade_name",params.trade_name)
    data.append("ltfrb_number",params.ltfrb_number)
    data.append("marine_insurance",params.marine_insurance)
    data.append("dti_sec_number",params.dti_sec_number)
    data.append("permit_number",params.permit_number)
    data.append("phone",params.phone)
    data.append("business_address",params.business_address)
    data.append("latitude",params.latitude)
    data.append("longitude",params.longitude)
    data.append("investment",params.investment)
    if(params.id){
        data.append("user",params.id)
        if(params.business_logo!=="" && params.business_logo!=undefined){
            data.append("business_logo",params.business_logo)
        }
        if(params.govt_id!=="" && params.govt_id!=undefined){
            data.append("govt_id",params.govt_id)
        }
        return axios.put(`${baseUrl}/user_profile/update_profile/`,data, jsonAuthHeaders);
    }else{
        data.append("business_logo",params.business_logo)
        if(params.govt_id!=="" && params.govt_id!=undefined){
            data.append("govt_id",params.govt_id)
        }
        
        return axios.post(`${baseUrl}/user_profile/`,data, jsonAuthHeaders);
    }
    
   
}
function update_user_profile(params){
    console.log("=====params====",params)
    let data= new FormData()
  
    data.append("first_name",params.first_name)
    data.append("last_name",params.last_name)
    data.append("business_name",params.business_name)
    data.append("trade_name",params.trade_name)
    data.append("ltfrb_number",params.ltfrb_number)
    data.append("marine_insurance",params.marine_insurance)
    data.append("dti_sec_number",params.dti_sec_number)
    data.append("permit_number",params.permit_number)
    data.append("business_address",params.business_address)
    data.append("phone",params.phone)
    data.append("latitude",params.latitude)
    data.append("longitude",params.longitude)
    // data.append("user",params.id)
    data.append("investment",params.investment)
    if(params.business_logo!=="" && params.business_logo!=undefined){
        data.append("business_logo",params.business_logo)
    }
    if(params.image!==""&& params.image!=undefined){
        data.append("image",params.image)
    }
   
    if(params.govt_id!=="" && params.govt_id!=undefined){
        data.append("govt_id",params.govt_id)
    }

    return axios.put(`${baseUrl}/user_profile/update_profile/`,data, jsonAuthHeaders);
}

function add_bankold(data){
    return axios.post(`${baseUrl}/user_profile/add_bank/`, data, jsonAuthHeaders);
}
function add_bank(data){
    return axios.post(`${baseUrl}/bank/`, data, jsonAuthHeaders);
}
function update_bank(data,id){
    return axios.put(`${baseUrl}/bank/${id}/`, data, jsonAuthHeaders);
}

function get_business_profile(){
    return axios.get(`${baseUrl}/user_profile/get_profile/`, jsonAuthHeaders);
}
function get_bank(){
    return axios.get(`${baseUrl}/get_bank_details/`, jsonAuthHeaders);
}
function truck_types(){
    return axios.get(`${baseUrl}/truck_types/`, jsonFileHeaders);
}
function weight_units(){
    return axios.get(`${baseUrl}/weight_units/`, jsonFileHeaders);
}
function add_truck(params,images,id){
 
    let data= new FormData()
    data.append("type",params.type)
    data.append("plate_number",params.plate_number)
    data.append("model_year",params.model_year)
    data.append("capacity",params.capacity)
    data.append("weight_unit",params.weight_unit)
    data.append("length",params.length)
    data.append("width",params.width)
    data.append("height",params.height)
    data.append("investment",params.investment)
    // data.append("total_cbm",params.total_cbm)
    data.append("make",params.make)
    data.append("color",params.color)
    data.append("driver_name",params.driver_name)
    if(images.length>0){
        images.map((image,index)=>{
            data.append("images",image)
        })
    }
    
   if(id){
//    console.log("=======iiiii======",id)
    // const pkInteger = parseInt(id, id); 
    // data.append("id",id)
    return axios.put(`${baseUrl}/truck/${id}/`,data, jsonAuthHeaders);

   }else{
    return axios.post(`${baseUrl}/truck/`,data, jsonAuthHeaders);

   }
 
}


function get_truck_detail(id){
    console.log("========>>>>>",id)
    return axios.get(`${baseUrl}/truck/`+id+`/`, jsonAuthHeaders);
}
function delete_truck_image(param){
    let data= new FormData()
    data.append("id",param.id)
    // console.log("========>>>>>",param)
    return axios.post(`${baseUrl}/truck/delete_image/`,data, jsonAuthHeaders);
}
function get_truck_list(page){

    return axios.get(`${baseUrl}/truck/my_trucks?page=`+page, jsonAuthHeaders);
}
function delete_truck(id){

     return axios.delete(`${baseUrl}/truck/`+id+`/`, jsonAuthHeaders);
}


function resendLink(data){
    return axios.post(`${baseUrl}/user/resent_verification_link/`, data, jsonFileHeaders);
}


function get_category(){
    return axios.get(`${baseUrl}/category/?has_sub_cat=true`, jsonAuthHeaders);
}

function get_sub_category(catid){
    return axios.get(`${baseUrl}/subcategory/`+catid, jsonAuthHeaders);
}

function add_product(params,images,id){
   
    let data= new FormData()
    data.append("name",params.name)
    data.append("category",params.category)
    data.append("subcategory",params.subcategory)
    data.append("min_order",params.min_order)
    data.append("units",params.units)
    data.append("description",params.description)
    data.append("item_per_unit",params.item_per_unit)
    data.append("suggested_selling_price",params.suggested_selling_price)
    data.append("retail_tip",params.retail_tip)
    
    //  data.append("weight_unit",params.weight_unit)
    data.append("length",params.length)
    data.append("width",params.width)
    data.append("height",params.height)
    data.append("weight",params.weight)
    data.append("current_stock",params.current_stock)
    data.append("add_stock",params.add_stock)
    data.append("deduct_stock",params.deduct_stock)
    data.append("price",params.price)
    data.append("net_payout",params.net_payout)
    if(images.length>0){
        images.map((image,index)=>{
            data.append("images",image)
        })
    }
   
   if(id){
    console.log("=====data===",data)
    const pkInteger = parseInt(id, 10); 
    // data.append("id",id)
    return axios.put(`${baseUrl}/product/${pkInteger}/`,data, jsonAuthHeaders);

   }else{
    return axios.post(`${baseUrl}/product/`,data, jsonAuthHeaders);

   }
 
}



function get_product_detail(id){
    console.log("========>>>>>",id)
    return axios.get(`${baseUrl}/product/`+id+`/`, jsonAuthHeaders);
}
function delete_product_image(param){
    let data= new FormData()
    data.append("id",param.id)
    // console.log("========>>>>>",param)
    return axios.post(`${baseUrl}/product/delete_image/`,data, jsonAuthHeaders);
}
function get__all_product_list(page,selected_cat,selected_subcat,filter,search){

    return axios.get(`${baseUrl}/product?page=`+page+"&category="+selected_cat+"&filter="+filter+"&subcategory="+selected_subcat+"&search="+search, jsonAuthHeaders);
}

function delete_product(id){

    return axios.delete(`${baseUrl}/product/`+id+`/`, jsonAuthHeaders);
}

function get_product_list(page){

    return axios.get(`${baseUrl}/product/wholeseller?page=`+page, jsonAuthHeaders);
}


function add_to_cart(data){
    return axios.post(`${baseUrl}/order_cart/`, data, jsonAuthHeaders);
}
function update_cart(data,id){
    return axios.put(`${baseUrl}/order_cart/`+id+"/", data, jsonAuthHeaders);
}
function deleted_from_cart(id){
    return axios.delete(`${baseUrl}/order_cart/`+id+"/", jsonAuthHeaders);
}
function deleted_all_cart(id){
    return axios.delete(`${baseUrl}/order_cart/delete_all_cart/`, jsonAuthHeaders);
}

function get_cart(id){
    return axios.get(`${baseUrl}/order_cart/?address=`+id, jsonAuthHeaders);
}
function add_address(data){
    if(data.id){
        return axios.put(`${baseUrl}/address/`+data.id+"/", data, jsonAuthHeaders);
    }else{
        return axios.post(`${baseUrl}/address/`, data, jsonAuthHeaders);
    }
   
}
function delete_address(id){
    return axios.delete(`${baseUrl}/address/`+id+"/",  jsonAuthHeaders);
}
function get_address(){
    return axios.get(`${baseUrl}/address/`,  jsonAuthHeaders);
}
function address_detail(id){
    return axios.get(`${baseUrl}/address/`+id+"/",  jsonAuthHeaders);
}
function update_address(data,id){
    return axios.put(`${baseUrl}/address/`+id+"/", data, jsonAuthHeaders);
}
function add_card(data){
    return axios.post(`${baseUrl}/card/`, data, jsonAuthHeaders);
}
function get_card(){
    return axios.get(`${baseUrl}/card/`, jsonAuthHeaders);
}
function delete_card(id){
    return axios.delete(`${baseUrl}/card/`+id+"/", jsonAuthHeaders);
}
function checkout(data){
    return axios.post(`${baseUrl}/order/`, data, jsonAuthHeaders);
}
function add_client(data){
    if(data.id){
        return axios.put(`${baseUrl}/primary_clients/`+data.id+"/", data, jsonAuthHeaders);
    }else{
        return axios.post(`${baseUrl}/primary_clients/`, data, jsonAuthHeaders);
    }
    
}
function get_client(id){
    return axios.get(`${baseUrl}/primary_clients/`+id+"/", jsonAuthHeaders);
}

function del_client(id){
    return axios.delete(`${baseUrl}/primary_clients/`+id+"/", jsonAuthHeaders);
}
function get_client_list(page){
    return axios.get(`${baseUrl}/primary_clients/?page=`+page, jsonAuthHeaders);
}
function get_orders(type,page){
    return axios.get(`${baseUrl}/order/my_orders/?type=`+type+"&page="+page, jsonAuthHeaders);
}
function cancel_order(id,type){
    console.log("======>>",jsonAuthHeaders)
    return axios.put(`${baseUrl}/order/${id}/cancel_order/?type=`+type,{}, jsonAuthHeaders);
}
function get_order_detail(id){
    console.log("======>>",jsonAuthHeaders)
    return axios.get(`${baseUrl}/order/${id}/`, jsonAuthHeaders);
}
function confirm_orders(id,data){
    console.log("=====data=>>",data)
    return axios.put(`${baseUrl}/order/${id}/accept_order/`,data, jsonAuthHeaders);
}
function notifications(page){
    return axios.get(`${baseUrl}/notification/?page=`+page, jsonAuthHeaders);
}
function notification_count(){
    return axios.get(`${baseUrl}/notification/count/`, jsonAuthHeaders);
}
function notifications_read(){
    return axios.get(`${baseUrl}/notification/read_all/`, jsonAuthHeaders);
}
function delivery_status(){
    return axios.get(`${baseUrl}/delivery_status/`, jsonAuthHeaders);
}

function change_status(data,id){
    console.log("=====data=>>",data)
    return axios.put(`${baseUrl}/order/${id}/change_status/`,data, jsonAuthHeaders);
}
function find_load(page){
 
    return axios.get(`${baseUrl}/order/find_load/?page=`+page, jsonAuthHeaders);
}
function accept_load(data,id){
    
    return axios.put(`${baseUrl}/order/${id}/accept_load/`,data, jsonAuthHeaders);
}
function my_loads(page,type){
 
    return axios.get(`${baseUrl}/load/my_loads/?page=`+page+"&type="+type, jsonAuthHeaders);
}
function change_delivery(data,id){
 
    return axios.put(`${baseUrl}/order/${id}/change_delivery/`,data, jsonAuthHeaders);
}
function truck_list(){
 return axios.get(`${baseUrl}/truck/my_trucks`, jsonAuthHeaders);
}


function schedule(data,id){
    if(id){
        return axios.put(`${baseUrl}/schedule/${id}/`, data, jsonAuthHeaders);
    }else{
        return axios.post(`${baseUrl}/schedule/`, data, jsonAuthHeaders);
    }
    
}
function get_schedule(id){
    return axios.get(`${baseUrl}/schedule/`+id, jsonAuthHeaders);
}
function change_load_status(data,id){
    return axios.put(`${baseUrl}/load/${id}/`, data, jsonAuthHeaders);
}

function dashboardData(){

    return axios.get(`${baseUrl}/dashboard`, jsonAuthHeaders);
}
function payment_success(id){

    return axios.get(`${baseUrl}/order/${id}/payment_success/`, jsonAuthHeaders);
}


function get_banks(){

    return axios.get(`${baseUrl}/banks`, jsonAuthHeaders);
}

function transactions(page,start_date,end_date){

    return axios.get(`${baseUrl}/transaction/my_transaction/?page=`+page+'&start_date='+start_date+'&end_date='+end_date, jsonAuthHeaders);
}
function revenue_transactions(page,start_date,end_date,id){

    return axios.get(`${baseUrl}/transaction/my_transaction/?page=`+page+'&start_date='+start_date+'&end_date='+end_date+'&type=revenue&client='+id, jsonAuthHeaders);
}

function confirm_delivery(id){

    return axios.put(`${baseUrl}/order/${id}/confirm_delivery/`,{}, jsonAuthHeaders);
}


