import { Col, Form, Row, Dropdown } from "react-bootstrap"
import Select from "react-select";
import { length_unit } from "../../../../config/config"

const Step3 = ({ truckType, truckFieldsErr, onInputChange, onFileChange, images, weight_units, removeImageAtIndex, truckFields }) => {

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default behavior (page refresh)
            // Handle your logic here
        }
    };
    const handleNumberChange = (event, maxDigits) => {
        const inputValue = event.target.value;
        // Change this to your desired maximum length
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default behavior (page refresh)
            // Handle your logic here
        }
        if (inputValue.length >= maxDigits && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };
    return (
        <>
            <div className="overflow-x">
                <div className="stepper-form-area">
                    < Form>
                        <Row>

                            <Col md={6}>

                                <Form.Group
                                    className="mb-4"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Truck Type</Form.Label>
                                    <Select
                                        // value={registerFields.user_type}
                                        onChange={e => onInputChange('type', e.value)}


                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                background: "transparent",
                                                height: "50px",
                                                borderRadius: "30px",
                                                border: "1px solid #00000029",
                                                boxShadow: "none",

                                                "&:focus": {
                                                    borderColor: "#00000029",
                                                },
                                                "&:hover": {
                                                    borderColor: "#00000029",
                                                },
                                            }),
                                        }} options={truckType} />



                                    <span className="errors">{truckFieldsErr.type}</span>
                                </Form.Group>

                            </Col>
                            <Col md={6}>

                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Plate No.</Form.Label>
                                    <Form.Control onKeyDown={handleKeyDown} type="text" maxLength={15} placeholder="Enter Plate No." onChange={e => onInputChange('plate_number', e.target.value)} />
                                    <span className="errors">{truckFieldsErr.plate_number}</span>
                                </Form.Group>

                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>

                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Model Year </Form.Label>
                                    <Form.Control onKeyDown={(e) => handleNumberChange(e, 4)} onWheel={() => document.activeElement.blur()} maxLength={4} type="number" placeholder="Enter Model Year" onChange={e => onInputChange('model_year', e.target.value)} />
                                    <span className="errors">{truckFieldsErr.model_year}</span>
                                </Form.Group>

                            </Col>

                            <Col md={6}>
                                <div className="capacity-area">
                                    <Form.Group className="mb-4" controlId="formBasicEmail">
                                        <Form.Label>Capacity</Form.Label>
                                        <Form.Control onKeyDown={(e) => handleNumberChange(e, 20)} value={truckFields.capacity} onWheel={() => document.activeElement.blur()} maxLength={10} type="number" placeholder="Enter Capacity" onChange={e => onInputChange('capacity', e.target.value)} />
                                        <p className="errors">{truckFieldsErr.capacity}</p>
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-3 capacity-select-area"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Select options={weight_units}
                                            onChange={e => onInputChange('weight_unit', e.value)}
                                            value={weight_units.filter(function (option) {
                                                return option.value === truckFields.weight_unit;
                                            })}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    background: "transparent",
                                                    height: "50px",
                                                    borderRadius: "0",
                                                    border: "none",
                                                    borderLeft: "1px solid #00000029",
                                                    boxShadow: "none",

                                                    "&:focus": {
                                                        borderColor: "#00000029",
                                                    },
                                                    "&:hover": {
                                                        borderColor: "#00000029",
                                                    },
                                                }),
                                            }} />
                                    </Form.Group>

                                    {/* <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Gram
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Kilo gram</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Gram</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                                </div>

                                {/* <Form.Group className="mb-4" controlId="formBasicEmail">
                                 <Form.Label>Capacity</Form.Label>
                                 <Form.Control onKeyDown={handleKeyDown}  onWheel={() => document.activeElement.blur()}  maxLength={10} type="number" placeholder="Enter Capacity" onChange={e => onInputChange( 'capacity', e.target.value)}/>
                            </Form.Group> */}
                            </Col>

                        </Row>
                        <Row>
                            <Col md={6}>

                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Length({length_unit})</Form.Label>
                                    <Form.Control onKeyDown={(e) => handleNumberChange(e, 10)} type="number" onWheel={() => document.activeElement.blur()} maxLength={10} placeholder="Enter Length" onChange={e => onInputChange('length', e.target.value)} />
                                    <span className="errors">{truckFieldsErr.length}</span>
                                </Form.Group>

                            </Col>
                            <Col md={6}>

                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Width({length_unit})</Form.Label>
                                    <Form.Control onKeyDown={(e) => handleNumberChange(e, 10)} type="number" onWheel={() => document.activeElement.blur()} maxLength={10} placeholder="Enter Width" onChange={e => onInputChange('width', e.target.value)} />
                                    <span className="errors">{truckFieldsErr.width}</span>
                                </Form.Group>

                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>

                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Height({length_unit})</Form.Label>
                                    <Form.Control onKeyDown={(e) => handleNumberChange(e, 10)} type="number" onWheel={() => document.activeElement.blur()} maxLength={10} placeholder="Enter Width" onChange={e => onInputChange('height', e.target.value)} />
                                    <span className="errors">{truckFieldsErr.width}</span>
                                </Form.Group>

                            </Col>
                            {/* <Col md={6}>
                     
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Total CBM</Form.Label>
                                <Form.Control onKeyDown={handleKeyDown}  type="number" onWheel={() => document.activeElement.blur()} placeholder="Enter Total CBM" onChange={e => onInputChange( 'total_cbm', e.target.value)}/>
                                <span className="errors">{truckFieldsErr.total_cbm}</span>
                            </Form.Group>
                       
                    </Col> */}
                            <Col md={6}>

                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Make</Form.Label>
                                    <Form.Control onKeyDown={handleKeyDown} type="text" placeholder="Enter Make" onChange={e => onInputChange('make', e.target.value)} />
                                    <span className="errors">{truckFieldsErr.make}</span>
                                </Form.Group>

                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>

                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Color</Form.Label>
                                    <Form.Control onKeyDown={handleKeyDown} type="text" placeholder="Enter Color" onChange={e => onInputChange('color', e.target.value)} />
                                    <span className="errors">{truckFieldsErr.color}</span>
                                </Form.Group>

                            </Col>
                            <Col md={6}>

                                <Form.Group className="mb-4 " controlId="formBasicEmail">
                                    <Form.Label>Driver's Name</Form.Label>
                                    <Form.Control onKeyDown={handleKeyDown} type="text" placeholder="Enter Driver's Name" onChange={e => onInputChange('driver_name', e.target.value)} />
                                    <span className="errors">{truckFieldsErr.driver_name}</span>

                                </Form.Group>

                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>

                                <Form.Group className="mb-2 upload-area" controlId="formBasicEmail">
                                    <Form.Label>Upload Trucks Photo</Form.Label>
                                    <div className="upload-trucks-photo">
                                        <Form.Control type="text" readOnly placeholder="Upload Trucks Photo" />

                                        <Form.Control type="file" onKeyDown={handleKeyDown} multiple={true} className="upload-item" onChange={e => onFileChange([...e.target.files])} />
                                        {/* <Form.Control type="file" className="upload-item" /> */}
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                    </div>
                                    <p className="errors">{truckFieldsErr.images}</p>

                                </Form.Group>
                                {/* <Form.Group className="mb-4 upload-area" controlId="formBasicEmail">
                                <Form.Label>Upload Trucks Photo</Form.Label>
                                <Form.Control type="text" placeholder="Upload Trucks Photo" />
                                <Form.Control onKeyDown={handleKeyDown}  type="file" multiple={true} className="upload-item" onChange={e => onFileChange([...e.target.files])}/>
                                <i className="fa fa-plus" aria-hidden="true"></i>
                                <span className="errors">{truckFieldsErr.images}</span>
                            </Form.Group> */}
                                <div className="upload-image-preview">

                                    {images.length > 0 && images.map((image, index) => (
                                        <div className="first-preview-image">
                                            <img key={index} src={image} alt={`Preview ${index}`} className="image-preview" />
                                            <i className="fa fa-trash" aria-hidden="true" onClick={() => removeImageAtIndex(index)}></i>


                                        </div>
                                    ))}
                                    {/* <i className="fa fa-picture-o" aria-hidden="true"></i> */}


                                </div>

                            </Col>
                        </Row>
                    </Form>
                    {/* <button className="submit-btn">Submit</button> */}
                </div>
            </div>

        </>
    )
}

export default Step3