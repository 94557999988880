import { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { apiService } from "../../../../services/api.services";
import { validateService } from "../../../../services/validate.services";
import swal from "sweetalert2";
import Loader from "../../../common/Loader";
import SuccessPopup from "../../../modal/SuccessPopup"
// import { useParams } from "react-router-dom";
import { bUrl, allowedImageTypes, length_unit } from "../../../../config/config"
import Errorpopup from "../../../modal/Errorpopup"
const AddTruck = ({ setKey, truck_id }) => {

  // const id = useParams()
  const [isLoader, setIsLoader] = useState(false)
  const [message1, setmessage1] = useState("")
  const [message2, setmessage2] = useState("")
  const [redirectlink, setredirectlink] = useState("/")
  const [truck_types, settruck_types] = useState([])
  const [weight_units, setweight_units] = useState([])
  const [imagesTruck, setimagesTruck] = useState([])
  const [images, setImages] = useState([]);
  const [uploadedimages, setuploadedimages] = useState([]);
  const [showErr, setShowErr] = useState(false);
  const [errmessage1, seterrmessage1] = useState("Error")
  const [errmessage2, seterrmessage2] = useState("")
  const handleCloseErr = () => {
    setShowErr(false);
  }
  const handleShowErr = () => setShowErr(true);
  const [truckFields, settruckFields] = useState({
    type: "",
    plate_number: "",
    model_year: "",
    capacity: "",
    weight_unit: "",
    length: "",
    width: "",
    height: "",
    total_cbm: "",
    make: "",
    color: "",
    driver_name: "",
    images: []
  });


  const [truckFieldsErr, settruckFieldsErr] = useState({
    type: "",
    plate_number: "",
    model_year: "",
    capacity: "",
    weight_unit: "",
    length: "",
    width: "",
    height: "",
    total_cbm: "",
    make: "",
    color: "",
    driver_name: "",
    images: ""
  });
  useEffect(() => {
    if (truck_id) {

      get_truck_detail(truck_id)
    }


  }, [truck_id]);
  useEffect(() => {
    get_truck_types()
    get_weight_units()
  }, []);
  const onInputChange = (field, value) => {
    settruckFields({
      ...truckFields,
      [field]: value,

    });
  }
  const handleImagesChange = (file) => {
    let valid = true
    const selectedImage = file;

    if (selectedImage) {
      if (uploadedimages.length + images.length == 5) {
        valid = false
        settruckFieldsErr({
          ...truckFieldsErr,
          'images': "You can upload maximum 5 images."
        })
      } else if (allowedImageTypes.includes(selectedImage.type)) {
        valid = true
        const reader = new FileReader();
        reader.onload = () => {
          // setlogo(reader.result);
          setImages(obj => {
            return [
              ...obj,
              reader.result
            ]
          })

        };
        reader.readAsDataURL(selectedImage);
      }
      else {
        valid = false

        settruckFieldsErr({
          ...truckFieldsErr,
          'images': "Invalid file type. Please select a JPEG or PNG image."
        })
      }
    }
    return valid
  };
  const handleFilesUpload = (files) => {
    if ((files.length) > 5) {
      filesValidated = false

      settruckFieldsErr({
        ...truckFieldsErr,
        'images': "You can't upload more than 5 files."
      })
      return false;
    } else {
      let imagedata = []
      {
        files.map((file, i) => {
          let valid = handleImagesChange(file)
          if (valid) {
            setimagesTruck(obj => {
              return [
                ...obj,
                file
              ]
            })
          }
          // imagedata.push(file)


        })
      }
    }

    let filesValidated = true
    if ((files.length) > 10) {
      filesValidated = false
      swal("Error", "You can't upload more than 10 files", "error")
      return false;
    }
    files.map(file => {
      if (file.type.slice(0, 5) != "image" && file.type.slice(0, 5) != "video") {
        filesValidated = false
        swal("Error", "Only video(s) and image(s) are allowed to upload.", "error")
        return false;
      }
      if (file.type.slice(0, 5) == "video" && file.size > 15000000) {
        filesValidated = false
        swal("Error", "Video file can't be more than 15 MB.", "error")
        return false;
      }
    })

  }

  const removeImageAtIndex = (indexToRemove) => {
    const updatedImages = imagesTruck.filter((_, index) => index !== indexToRemove);
    const updatedshowImages = images.filter((_, index) => index !== indexToRemove);
    setimagesTruck(updatedImages);
    setImages(updatedshowImages);
  };
  const delete_truck_image = (id, indexToRemove) => {
    let param = {
      id: id
    }
    apiService.delete_truck_image(param).then((res) => {
      const updatedImages = uploadedimages.filter((_, index) => index !== indexToRemove);
      setuploadedimages(updatedImages)


    })
      .catch((err) => {

        setIsLoader(false)
        //   Swal.fire("Error", "Something went wrong.", "error");
        //  console.log("contact us api error", err.response.data.message);
      });
  }

  const get_truck_detail = (id) => {
    setIsLoader(true)
    apiService.get_truck_detail(id).then((res) => {
      console.log("===detaile", res.data)
      console.log("===tyyyyy", res.data.type)
      setuploadedimages(res.data.images)
      let data = res.data
      data.driver_name = res.data.driver.name
      data.type = res.data.type.id
      data.weight_unit = res.data.weight_unit.id
      delete data["user"];
      delete data["driver"];
      console.log("=====truckdata=====", data)
      settruckFields(data)
      //  get_truck_types()
      //  get_weight_units()
      setIsLoader(false)

    })
      .catch((err) => {

        setIsLoader(false)
        //   Swal.fire("Error", "Something went wrong.", "error");
        //  console.log("contact us api error", err.response.data.message);
      });
  }
  const get_truck_types = () => {
    apiService.truck_types().then((res) => {
      //  console.log("===type",res.data)

      let type = []
      console.log(res.data)
      {
        res.data.map((item, index) => (
          type.push({ value: item.id, label: item.name })
        ))
      }
      settruck_types(type)

    })
      .catch((err) => {

        setIsLoader(false)
        //   Swal.fire("Error", "Something went wrong.", "error");
        //  console.log("contact us api error", err.response.data.message);
      });
  }
  const get_weight_units = () => {
    apiService.weight_units().then((res) => {
      let type = []
      console.log(res.data)
      {
        res.data.map((item, index) => (

          type.push({ value: item.id, label: item.name })
        ))
      }
      if (!truck_id) {
        settruckFields({
          ...truckFields,
          weight_unit: res.data[0].id,
        })
      }

      setweight_units(type)
      //  settruck_types(type)

    })
      .catch((err) => {

        setIsLoader(false)
        //   Swal.fire("Error", "Something went wrong.", "error");
        //  console.log("contact us api error", err.response.data.message);
      });
  }
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setKey("view")
    setShow(false);
  }
  const handleShow = () => setShow(true);

  const handleSubmit = () => {
    // e.preventDefault();
    truckFields.images = imagesTruck;
    console.log("======truckFields=====", truckFields)
    let formValidated
    if (truck_id) {
      let updateimages = uploadedimages
      if (uploadedimages.length > 0) {
        updateimages = uploadedimages

      }
      if (imagesTruck.length > 0) {
        updateimages = imagesTruck
      }
      formValidated = validateService.validateTruckerstep3(settruckFieldsErr, truckFields, updateimages)
    } else {
      formValidated = validateService.validateTruckerstep3(settruckFieldsErr, truckFields, imagesTruck)
    }

    // if(imagesTruck.length==0 && uploadedimages.length==0){
    //   formValidated= false
    //   settruckFieldsErr((obj) => {
    //     return {
    //       ...obj,
    //       type: "",
    //       plat_number:"",
    //       model_year:"",
    //       capacity:"",
    //       length:"",
    //       width:"",
    //       total_cbm:"",
    //       color:"",
    //       driver_name:"",
    //       images:"Select atleast one image"
    //     };
    //   });
    // }

    if (formValidated) {
      setIsLoader(true)
      console.log("=====truck_id====", truck_id)
      apiService.add_truck(truckFields, imagesTruck, truck_id).then((res) => {
        setmessage1("Success!")
        if (truck_id) {
          setmessage2("Truck updated successfully")
        } else {

          setmessage2("Truck added successfully")
        }

        setredirectlink("/dashboard")
        handleShow()
        setIsLoader(false)
      })
        .catch((err) => {
          handleShowErr()

          seterrmessage2(err.response.data.message)
          setIsLoader(false)
          //   Swal.fire("Error", "Something went wrong.", "error");
          console.log("contact us api error", err.response.data.message);
        });
      // }
    }



  }

  const handleNumberChange = (event, maxDigits) => {
    const inputValue = event.target.value;
    // Change this to your desired maximum length
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior (page refresh)
      // Handle your logic here
    }
    if (inputValue.length >= maxDigits && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  return (
    <>
      {isLoader && <Loader />}
      <div className="overflow-x">
        <div className="manage-truck-area add-truck-area">
          <span className="backBtn" onClick={() => setKey("view")} style={{ cursor: "pointer" }}>   <i class="fa fa-arrow-left" aria-hidden="true"></i> Back</span>
          {/* <a href="#"> <span onClick={() => setKey("view")}>Manage truck</span>  / <span>{truck_id?"Update":"Add"} truck</span></a> */}
          <h3>{truck_id ? "Update" : "Add"} truck</h3>
          {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
          <div className="stepper-form-area">
            {console.log("========truckFields=====", truckFields)}
            <Form>

              <Row>

                <Col md={6}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Truck Type</Form.Label>
                    <Select
                      value={truck_types.filter(function (option) {
                        return option.value === truckFields.type;
                      })}
                      // value={registerFields.user_type}
                      onChange={e => onInputChange('type', e.value)}


                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          background: "transparent",
                          height: "50px",
                          borderRadius: "30px",
                          border: "1px solid #00000029",
                          boxShadow: "none",

                          "&:focus": {
                            borderColor: "#00000029",
                          },
                          "&:hover": {
                            borderColor: "#00000029",
                          },
                        }),
                      }} options={truck_types} />



                    <p className="errors">{truckFieldsErr.type}</p>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Plate No.</Form.Label>
                    <Form.Control type="text" value={truckFields.plate_number} maxLength={15} placeholder="Enter Plate No." onChange={e => onInputChange('plate_number', e.target.value)} />
                    <p className="errors">{truckFieldsErr.plate_number}</p>
                  </Form.Group>
                </Col>

              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label> Model Year</Form.Label>
                    <Form.Control onKeyDown={(e) => handleNumberChange(e, 4)} value={truckFields.model_year} onWheel={() => document.activeElement.blur()} maxLength={4} type="number" placeholder="Enter Model Year" onChange={e => onInputChange('model_year', e.target.value)} />
                    <p className="errors">{truckFieldsErr.model_year}</p>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <div className="capacity-area">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Label>Capacity</Form.Label>
                      <Form.Control onKeyDown={(e) => handleNumberChange(e, 10)} value={truckFields.capacity} onWheel={() => document.activeElement.blur()} maxLength={10} type="number" placeholder="Enter Capacity" onChange={e => onInputChange('capacity', e.target.value)} />
                      <p className="errors">{truckFieldsErr.capacity}</p>
                    </Form.Group>

                    <Form.Group
                      className="mb-3 capacity-select-area"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Select options={weight_units}
                        onChange={e => onInputChange('weight_unit', e.value)}
                        value={weight_units.filter(function (option) {
                          return option.value === truckFields.weight_unit;
                        })}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            background: "transparent",
                            height: "50px",
                            borderRadius: "0",
                            border: "none",
                            borderLeft: "1px solid #00000029",
                            boxShadow: "none",

                            "&:focus": {
                              borderColor: "#00000029",
                            },
                            "&:hover": {
                              borderColor: "#00000029",
                            },
                          }),
                        }} />
                    </Form.Group>

                    {/* <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Gram
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Kilo gram</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Gram</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                  </div>
                </Col>

              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Length({length_unit})</Form.Label>
                    <Form.Control onKeyDown={(e) => handleNumberChange(e, 10)} type="number" value={truckFields.length} onWheel={() => document.activeElement.blur()} maxLength={10} placeholder="Enter Length" onChange={e => onInputChange('length', e.target.value)} />
                    <p className="errors">{truckFieldsErr.length}</p>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Width({length_unit})</Form.Label>
                    <Form.Control onKeyDown={(e) => handleNumberChange(e, 10)} type="number" value={truckFields.width} onWheel={() => document.activeElement.blur()} maxLength={10} placeholder="Enter Width" onChange={e => onInputChange('width', e.target.value)} />
                    <p className="errors">{truckFieldsErr.width}</p>
                  </Form.Group>
                </Col>

              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Height ({length_unit})</Form.Label>
                    <Form.Control onKeyDown={(e) => handleNumberChange(e, 10)} type="number" value={truckFields.height} onWheel={() => document.activeElement.blur()} placeholder="Enter Total CBM" onChange={e => onInputChange('height', e.target.value)} />
                    <p className="errors">{truckFieldsErr.height}</p>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Make</Form.Label>
                    <Form.Control type="text" value={truckFields.make} placeholder="Enter Make" onChange={e => onInputChange('make', e.target.value)} />
                    <p className="errors">{truckFieldsErr.make}</p>
                  </Form.Group>
                </Col>

              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Color</Form.Label>
                    <Form.Control type="text" value={truckFields.color} placeholder="Enter Color" onChange={e => onInputChange('color', e.target.value)} />
                    <p className="errors">{truckFieldsErr.color}</p>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Driver Name</Form.Label>
                    <Form.Control type="text" value={truckFields.driver_name} placeholder="Enter Driver Name" onChange={e => onInputChange('driver_name', e.target.value)} />
                    <p className="errors">{truckFieldsErr.driver_name}</p>
                  </Form.Group>
                </Col>

              </Row>
              <Row>
                {!truck_id ?
                  <Col md={6}>
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Label>Truck Investment </Form.Label>
                      <Form.Control onKeyDown={(e) => handleNumberChange(e, 10)} type="number" value={truckFields.investment} onWheel={() => document.activeElement.blur()} placeholder="Enter Truck Investment" onChange={e => onInputChange('investment', e.target.value)} />
                      <p className="errors">{truckFieldsErr.investment}</p>
                    </Form.Group>
                  </Col> : null}

                <Col md={6}>
                  <Form.Group className="mb-2 upload-area" controlId="formBasicEmail">
                    <Form.Label>Upload Trucks Photo</Form.Label>
                    <Form.Control type="text" placeholder="Upload Trucks Photo" />
                    <Form.Control type="file" multiple={true} className="upload-item" onChange={e => handleFilesUpload([...e.target.files])} />
                    {/* <Form.Control type="file" className="upload-item" /> */}
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    <p className="errors">{truckFieldsErr.images}</p>

                  </Form.Group>
                  <div className="upload-image-preview">

                    {images.length > 0 && images.map((image, index) => (
                      <div className="first-preview-image">
                        <img key={index} src={image} alt={`Preview ${index}`} className="image-preview" />
                        <i className="fa fa-trash" aria-hidden="true" onClick={() => removeImageAtIndex(index)}></i>


                      </div>
                    ))}

                    {uploadedimages.length > 0 && uploadedimages.map((image, index) => (
                      <div className="first-preview-image">
                        <img key={index} src={bUrl + image.image} alt={`Preview ${index}`} className="image-preview" />
                        <i className="fa fa-trash" aria-hidden="true" onClick={() => delete_truck_image(image.id, index)}></i>


                      </div>
                    ))}
                  </div>
                </Col>

              </Row>
            </Form>
            <Button className="addtruck-btn" variant="unset" type="button" onClick={handleSubmit}>{truck_id ? "Update" : "Add"} truck</Button>
          </div>
        </div>
      </div>
      <SuccessPopup show={show} handleClose={handleClose} message1={message1} message2={message2} redirectlink={redirectlink} />
      <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} redirectlink={redirectlink} />
      {/* modal */}
      {/* <Modal className="success-box" show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Error</h5>
                    <p>bbnbnbb</p>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={handleClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal> */}
    </>
  );
}
export default AddTruck;