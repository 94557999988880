import { useState, useEffect } from "react";

import { Button, Col, Container, Modal, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { validateService } from "../../../services/validate.services";
import { apiService } from "../../../services/api.services";
import { bUrl } from "../../../config/config"
import Errorpopup from "../../modal/Errorpopup"
import SuccessPopup from "../../modal/SuccessPopup"
import EditBank from "../../modal/EditBank"
import Loader from "../../common/Loader";
const TruckerProfile = () => {
    const [bankModal, setbankModal] = useState(false);
    const [bankId, setbankId] = useState()
    const [bankList, setbankList] = useState(null);
    const handlebankModalClose = () => setbankModal(false);
    const [showPass1, setShowPass1] = useState(false);
    const [showPass2, setShowPass2] = useState(false);
    const [showPass3, setShowPass3] = useState(false);
    let history = useNavigate();

    function TruckerEditProfile() {
        history("/edit_profile");
    }
    const [bankFieldsErrs, setbankFieldsErrs] = useState({
        account_number: "",
        // routing_number: "",
        account_holder_name: "",
        bank_name: ""
    });
    useEffect(() => {
        get_business_profile()
        get_banks()
    }, []);
    const editBank = (id) => {
        setbankId(id)
        setbankModal(true)
    }
    const get_banks = () => {
        apiService.get_banks().then((res) => {
            console.log("=======bank list====>", res.data.data)
            setbankList(res.data.data)

        }).catch((err) => { });

    }

    const [isLoader, setIsLoader] = useState(false)

    const [showpass, setShowpass] = useState(false);
    const [profileFields, setprofileFields] = useState();
    const [bankFields, setbankFields] = useState();
    const [bankFieldshow, setbankFieldshow] = useState();

    const handleClosepass = () => setShowpass(false);
    const handleShowpass = () => setShowpass(true);
    const [show, setShow] = useState(false);
    const [showErr, setShowErr] = useState(false);
    const [errmessage1, seterrmessage1] = useState("Error")
    const [errmessage2, seterrmessage2] = useState("")
    const handleCloseErr = () => {
        setShowErr(false);
    }
    const handleShowErr = () => setShowErr(true);
    const [message1, setmessage1] = useState("")
    const [message2, setmessage2] = useState("")
    const navigate = useNavigate();
    const [redirectlink, setredirectlink] = useState("/")
    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => {
        window.location.href = ("/profile")
        // navigate("/profile")
        setShow(false);
    }
    const [passwordFields, setpasswordFields] = useState({
        old_password: "",
        new_password: "",
        cpassword: "",
    })
    const [passwordFieldsErr, setpasswordFieldsErr] = useState({
        oldpassword: "",
        password: "",
        cpassword: "",
    })
    const handleInputChange = (field, value) => {
        setpasswordFields({
            ...passwordFields,
            [field]: value,

        });
    }
    const handleChangePassword = () => {
        console.log("=====passwordFields====", passwordFields)
        let validform = validateService.resetpasswordvalidate(setpasswordFieldsErr, passwordFields)
        if (validform) {
            apiService.change_password(passwordFields).then((res) => {
                handleClosepass()
                setmessage1("Success")
                setmessage1("Password changed successfully")
                handleShow()


            })
                .catch((err) => {
                    handleShowErr()

                    seterrmessage2(err.response.data.message)
                    setIsLoader(false)
                    //   Swal.fire("Error", "Something went wrong.", "error");
                    //  console.log("contact us api error", err.response.data.message);
                });
        }

    }
    const get_business_profile = () => {

        apiService.get_business_profile().then((res) => {
            console.log("===get_business_profile", res.data.data)
            setbankFields(res.data.data.bank_detail)
            setbankFieldshow(res.data.data.bank_detail)
            setprofileFields(res.data.data)



        })
            .catch((err) => {

                setIsLoader(false)
                //   Swal.fire("Error", "Something went wrong.", "error");
                //  console.log("contact us api error", err.response.data.message);
            });
    }


    return (
        <>
            {isLoader && <Loader />}
            <section className="my-profile-area">
                <Container>
                    <div className="manage-truck-area ">
                        <div className="my-profile-top">
                            <h3>My Profile</h3>
                            <div className="my-profile-top-right">
                                <Button type="button" variant="unset" className="change-password-btn" onClick={handleShowpass}>
                                    Change Password
                                </Button>
                                <Button type="button" variant="unset" className="edit-profile-btn" onClick={TruckerEditProfile}>
                                    Edit Profile
                                </Button>
                            </div>
                        </div>
                        <div className="trucker-image-area">
                            <img src={profileFields && profileFields?.user.image ? bUrl + profileFields.user?.image : require("../../../assets/images/trucker-image.png")} alt="img" />

                        </div>
                        <Row className="mt-4">
                            <Col sm={6} md={6} lg={4} className="col-12">
                                <div className="trucker-info-detail">
                                    <p>First Name: </p>
                                    <h6>{profileFields?.user?.first_name}</h6>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} className="col-12">
                                <div className="trucker-info-detail">
                                    <p>Last Name:  </p>
                                    <h6>{profileFields?.user?.last_name}</h6>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} className="col-12">
                                <div className="trucker-info-detail">
                                    <p>Trucking Name:  </p>
                                    <h6>{profileFields?.business_name}</h6>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} className="col-12">
                                <div className="trucker-info-detail">
                                    <p>Email:  </p>
                                    <h6>{profileFields?.user?.email}</h6>
                                </div>
                            </Col>
                            {/* <Col md={4}>
                                <div className="trucker-info-detail">
                                    <p>Trade Name:  </p>
                                    <h6>{profileFields?.trade_name}</h6>
                                </div>
                            </Col> */}
                            <Col sm={6} md={6} lg={4} className="col-12">
                                <div className="trucker-info-detail">
                                    <p>LTFRB Number: </p>
                                    <h6>{profileFields?.ltfrb_number}</h6>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} className="col-12">
                                <div className="trucker-info-detail">
                                    <p>Marine Insurance:</p>
                                    <h6>{profileFields?.marine_insurance}</h6>
                                </div>
                            </Col>


                            <Col sm={6} md={6} lg={4} className="col-12">
                                <div className="trucker-info-detail">
                                    <p>DTI/Sec Number:</p>
                                    <h6>{profileFields?.dti_sec_number}</h6>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} className="col-12">
                                <div className="trucker-info-detail">
                                    <p>Mayor's Permit Number: </p>
                                    <h6>{profileFields?.permit_number}</h6>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} className="col-12">
                                <div className="trucker-info-detail">
                                    <p>Trucking Logo: </p>
                                    <div className="upload-image-preview">
                                        <div className="first-preview-image">
                                            <img src={bUrl + profileFields?.business_logo} alt="img" />
                                        </div></div>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} className="col-12">
                                <div className="trucker-info-detail">
                                    <p>Business Address:  </p>
                                    <h6>{profileFields?.business_address}</h6>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} className="col-12">
                                <div className="trucker-info-detail">
                                    <p>Contact Number:  </p>
                                    <h6>+{profileFields?.phone}</h6>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} className="col-12">
                                <div className="trucker-info-detail">
                                    <p>Government ID: </p>
                                    <div className="upload-image-preview document-preview">
                                        <div className="first-preview-image">
                                            <img src={bUrl + profileFields?.govt_id}  alt="img" />
                                        </div>
                                        <div className="id-info">
                                            {/* <p>Document Title Here</p> */}
                                            {/* <a>Download</a> */}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>

                                <div className="checkout-cards-area">
                                    <Row className="mt-3">
                                        {console.log("======bankFields====", bankFields)}

                                        {/* {cardList && cardList.length > 0 && cardList.map((card, index) => ( */}

                                        <Col md={12} lg={12} xl={12} >
                                            <div className="credit-cards-area manage-card-area">
                                                <div className="card-detail-top">
                                                    <h5>Bank Detail</h5>
                                                    <h6 onClick={() => editBank(bankFieldshow?.id)} style={{ cursor: "pointer" }}><i class="fa fa-pencil-square-o" aria-hidden="true"></i>Edit Bank Detail</h6>
                                                </div>
                                                <Form>

                                                    {/* <div key={`default-${type}`} className="card-number-area"> */}

                                                    <p>Account Holder Name: <span>{bankFieldshow?.account_holder_name}</span></p>
                                                    <p>Account No: <span>{bankFieldshow?.account_number}</span></p>
                                                    <p>Bank Name: <span>

                                                        {bankList?.map(function (option) {
                                                            if (option.id == bankFieldshow?.bank_id) {
                                                                return option.label
                                                            }
                                                        })}
                                                    </span></p>
                                                    {/* </div> */}

                                                </Form>
                                            </div>
                                        </Col>

                                        {/* ))} */}



                                    </Row>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            {/* modal */}
            <Modal className="success-box change-password-box" show={showpass} onHide={handleClosepass} centered>
                <Modal.Header closeButton>
                    <Modal.Title >
                        Change Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="registration-left change-password-content p-0">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Current Password</Form.Label>
                                <div className="pass-view">
                                    <Form.Control type={showPass1 ? "text" :"password"} placeholder="Enter Current Password" onChange={(e) => handleInputChange("old_password", e.target.value)} />
                                    {!showPass1 ? <i class="fa fa-eye" aria-hidden="true" onClick={() => setShowPass1(!showPass1)}></i> :
                                        <i class="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPass1(!showPass1)}></i>}
                                </div>
                                <p className="errors">{passwordFieldsErr.oldpassword}</p>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>New Password</Form.Label>
                                <div className="pass-view">
                                    <Form.Control type={showPass2 ? "text" :"password"} placeholder="Enter New Password" onChange={(e) => handleInputChange("new_password", e.target.value)} />
                                    {!showPass2 ? <i class="fa fa-eye" aria-hidden="true" onClick={() => setShowPass2(!showPass2)}></i> :
                                        <i class="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPass2(!showPass2)}></i>}
                                </div>
                                <p className="errors">{passwordFieldsErr.password}</p>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Confirm New Password</Form.Label>
                                <div className="pass-view">
                                    <Form.Control type={showPass3 ? "text" :"password"} placeholder="Enter Confirm Password" onChange={(e) => handleInputChange("cpassword", e.target.value)} />
                                    {!showPass3 ? <i class="fa fa-eye" aria-hidden="true" onClick={() => setShowPass3(!showPass3)}></i> :
                                        <i class="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPass3(!showPass3)}></i>}
                                </div>
                                <p className="errors">{passwordFieldsErr.cpassword}</p>
                            </Form.Group>
                        </Form>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleChangePassword}>
                        update
                    </Button>

                </Modal.Footer>
            </Modal>
            <SuccessPopup show={show} handleClose={handleClose} message1={message1} message2={message2} redirectlink={redirectlink} />
            <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} redirectlink={redirectlink} />

            <EditBank bankFields={bankFields} setbankFields={setbankFields} bankFieldsErrs={bankFieldsErrs} setbankFieldsErrs={setbankFieldsErrs} setmessage1={setmessage1} setredirectlink={setredirectlink} setmessage2={setmessage2} handleShow={handleShow} handleShowErr={handleShowErr} handlebankModalClose={handlebankModalClose} bankModal={bankModal} bankList={bankList} bankId={bankId} isLoader={isLoader} setIsLoader={setIsLoader} />
        </>
    );
};
export default TruckerProfile;