import { ThreeDots } from 'react-loader-spinner';

const Loader = () => {
    return (
        <>
            <section className='loader-area'>
                <div className='loader'>
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#EE6F22"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                    />
                    </div>
            </section>
        </>
    );
};
export default Loader;