import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { apiService} from "../../services/api.services";
import { validateService} from "../../services/validate.services";
import { useState,useContext } from "react";
import Loader from "../common/Loader";
import SuccessPopup from "../modal/SuccessPopup"
import { useNavigate } from "react-router-dom";
import Errorpopup from "../modal/Errorpopup"
const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [ isLoader , setIsLoader] = useState(false)
    const [show, setShow] = useState(false);
    const [ message1 , setmessage1] = useState("")
    const [ redirectlink , setredirectlink] = useState("/login")
    const [ message2 , setmessage2] = useState("")
    const navigate = useNavigate();
    const [showErr, setShowErr] = useState(false);
    const [ errmessage1 , seterrmessage1] = useState("Error")
    const [ errmessage2 , seterrmessage2] = useState("")
    const handleCloseErr = () => {
    setShowErr(false);
      }
      const handleShowErr = () => setShowErr(true);
    const handleClose = (link) => {
    navigate(link)
    setShow(false);
  }
  const handleShow = () => setShow(true);
    const handleSubmit = (e) => {
      
        e.preventDefault();
        let formValidated = validateService.validateEmail(email,setEmailErr)
        if (formValidated) {
            setIsLoader(true)
          let data = {
            email: email};
           apiService.forgot_password(data).then((res) => {
               handleShow()
               setmessage1("Success")
               setmessage2(res.data.message)
               setredirectlink("/")
               setIsLoader(false)
            })
            .catch((err) => {
                setIsLoader(false)
                handleShowErr()
        
              seterrmessage2(err.response.data.message)
            //   Swal.fire("Error", "Something went wrong.", "error");
              console.log("contact us api error", err);
            });
        }
      };
    return (
        <>
          {isLoader && <Loader/>}
            <section className="registration-area">
                <Container>
                    <Row>
                        <Col  md={12} lg={6}>
                            <div className="registration-left">
                                <div className="dot-vector">
                                    <img src={require("../../assets/images/dot-vector.png")} />
                                </div>
                                <h4>Forgot password</h4>
                                <p className="password-reset">A password reset link will be sent on the registered email.</p>
                                <Form>
                                    <Form.Group className="mb-4" controlId="formBasicEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="Enter Registered Email" onChange={(e)=>setEmail(e.target.value)}/>
                                        <p className="errors">{emailErr}</p>

                                    </Form.Group>

                                  

                                
                                  
                                    <Button variant="unset" type="button" onClick={handleSubmit}>
                                    reset password
                                    </Button>
                                   
                                </Form>
                            </div>
                        </Col>
                        <Col lg={6}></Col>
                    </Row>
                </Container>
                <SuccessPopup show={show} handleClose={handleClose} message1={message1} message2={message2} redirectlink={redirectlink}/>
                <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} redirectlink={redirectlink}/>
            </section>

        </>
    );
};
export default ForgotPassword;