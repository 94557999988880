import { useState,useContext,useEffect } from "react";
import { Button, Col, Container, Row, Form, Modal } from "react-bootstrap";
const SuccessPopup = ({ show, handleClose,message1,message2,redirectlink }) => {
    
  
    useEffect(() => {
        if(show){
        const close = (e) => {
          console.log(localStorage.getItem("token"),"======token")
          // if(show){
          console.log(e.keyCode,"======keyCode")
          if (e.key === 'Enter') {
           
             handleClose()
          }
        // }
        }
        window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
        }
         
    },[show])
    return (
        <>
         <Modal className="success-box" show={show} onHide={handleClose} centered  >
                <Modal.Header >
                    <Modal.Title>
                        <img src={require("../../assets/images/chemark.png")} alt="img" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                <form >
                    <h5>{message1}</h5>
                    <p>{message2}</p>
                   </form>
                </Modal.Body>
                <Modal.Footer>
                   
                    <Button variant="primary" id="successId" onClick={()=>handleClose(redirectlink)} >
                     Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
            );
};
export default SuccessPopup; 