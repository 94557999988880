import { useEffect, useState,useContext } from "react";
import { Button, Container, Nav, NavDropdown, Navbar } from "react-bootstrap"
import { apiService} from "../../services/api.services";
import { context } from "../../GlobalContext/GlobalContext";
import { retailer_type } from "../../config/config"
import { Link, useLocation } from 'react-router-dom';
const AuthHeader = () => {
    const [scroll, setScroll] = useState(false);
    const globalData = useContext(context);
    const location = useLocation(); // once ready it returns the 'window.location' object
    const [url, setUrl] = useState(null);
    console.log(globalData,"===")
    useEffect(() => {
        get_business_profile()
        // globalData.setuser_name(localStorage.getItem("user_name"))
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 10);
        });
    }, [globalData]);
    useEffect(() => {
        setUrl(location.pathname);
       
      }, [location]);
    const get_business_profile = ()=>{
      
        apiService.get_business_profile().then((res) => {
            globalData.setnoti_count(res.data.data.unread_notifications)
         console.log("===get_business_profile",res.data.data.unread_notifications)
         })
           .catch((err) => {
            if(err.response.status==401){
                localStorage.clear();
                window.location.href="/login"
               }
            
           //   Swal.fire("Error", "Something went wrong.", "error");
            //  console.log("contact us api error", err.response.data.message);
           });
       }
   

       const readAll = ()=>{
      
        apiService.notifications_read().then((res) => {
            window.location.href = "/notifications"
         console.log("===get_business_profile",res.data.data)
         })
           .catch((err) => {
            if(err.response.status==401){
                localStorage.clear();
                window.location.href="/login"
               }
            
           //   Swal.fire("Error", "Something went wrong.", "error");
            //  console.log("contact us api error", err.response.data.message);
           });
       }
    const token = localStorage.getItem("web_token");
    const logout = ()=>{
        globalData.setToken();
        localStorage.clear()
        window.location.href = "/login"
    }
    return (
        <>
            <section className={scroll ? "header scrolled" : "header"}>
                <Navbar expand="lg" className="bg-body-tertiary auth-header">
                    <Container className="">
                        

                        <Navbar.Brand href="/"><img src={require("../../assets/images/logo.png")} alt="" /></Navbar.Brand>
                     

                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <Nav.Link className={url === "/" ?" active" : ""} href="/">Home</Nav.Link>
                                {localStorage.getItem("user_type")!=retailer_type?
                                <Nav.Link className={url === "/dashboard" ?" active" : ""} href="/dashboard">Dashboard</Nav.Link>:
                                <>
                                {localStorage.getItem("user_type")==retailer_type?
                                <>
                                 <Nav.Link  className={url === "/product-listing" ?" active" : ""} href="/product-listing">Shop</Nav.Link>
                                 <div className="notification-top">
                                    <Nav.Link className={url === "/checkout" ?" active" : ""} href="/checkout">Cart</Nav.Link>
                                    <span>{globalData.cart_count}</span>
                                </div>
                                 <Nav.Link className={url === "/order-history" ?" active" : ""} href="/order-history">My Orders</Nav.Link>
                                 </>
                                 :null}
                               
                              
                                </>
                                }
                                <Nav.Link href="/faq">FAQs </Nav.Link>
                                <div className="notification-top">
                                    <Nav.Link href="#" className={url === "/notifications" ?" active" : ""} onClick={readAll}>Notification</Nav.Link>
                                    <span>{globalData.noti_count}</span>
                                </div>
                                <div className="profile-header-top">
                                    <img src={globalData.user_image?globalData.user_image:require("../../assets/images/trucker-image.png") } alt="img" />
                                <NavDropdown title={globalData.user_name?globalData.user_name:"User"} id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/profile">My Profile</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2" onClick={logout}>
                                    Logout
                                    </NavDropdown.Item>
                                    </NavDropdown>
                                    </div>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </section>
        </>
    )
}

export default AuthHeader