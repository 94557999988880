import { useState,useEffect ,useContext} from "react";
import { Button, ButtonToolbar, Col, Form, OverlayTrigger, Row, Tab, Tabs, Tooltip ,Modal} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { apiService} from "../../../../services/api.services";
import Select from "react-select";
import AddLoads from "./AddLoads";
import moment from "moment";
import Loader from "../../../common/Loader";
import Pagination from "../../../common/Pagination";
import Nodata from "../Dashboard/Nodata";
import SuccessPopup from "../../../modal/SuccessPopup"
import Errorpopup from "../../../modal/Errorpopup"
import ContactPopup from "../../../modal/ContactPopup";

import { context } from "../../../../GlobalContext/GlobalContext";
const LoadsManagement = () => {
    const globalData = useContext(context);
    const [ isLoader , setIsLoader] = useState(false)
    const [ message1 , setmessage1] = useState("Success")
    const [ message2 , setmessage2] = useState("")
    const [ message3 , setmessage3] = useState("")
    const [showSucc, setshowSucc] = useState(false);
    const [total_page, settotal_page] = useState()
    const [page, setpage] = useState(1)
    const [type, setType] = useState("accepted")
    const [loadList, setloadList] = useState()
    const [ errmessage1 , seterrmessage1] = useState("Error")
    const [ errmessage2 , seterrmessage2] = useState("")
    const [showErr, setShowErr] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [contactEmail, setcontactEmail] = useState("")
    const [contactPhone, setcontactPhone] = useState("")
    const [contactEmail_2, setcontactEmail_2] = useState()
    const [contactPhone_2, setcontactPhone_2] = useState("")
    const [showdconfirm, setshowconfirm] = useState(false);
    const [ expenses , setexpense] = useState(0)
    const [ status , setstatus] = useState()
    const [ listId , setlistId] = useState()

    const handleCloseconfirm = () => {
        
        setshowconfirm(false);
    }
  
    const statusupdate=()=>{
        statusChange(status,listId)
        handleCloseconfirm()
    }
    const handleShowconfirm = (value,id) => {
        setlistId(id)
        setstatus(value)
        setshowconfirm(true);
     
  }
    const handleCloseErr = () => {
        setShowErr(false);
          }
          const handleCloseContact = () => setShowContact(false);

          const handleShowContact = (email,phone,wemail,wphone) => {
             
              console.log("======ssssss======")
              setcontactEmail(email)
              setcontactPhone(phone)
              setcontactEmail_2(wemail)
              setcontactPhone_2(wphone)
              setShowContact(true);
          
          }
    const handleShowErr = () => setShowErr(true);
    let history = useNavigate();
    const handleShowSucc = () => setshowSucc(true);
    const handleCloseSucc = () => {
      setshowSucc(false);
        }
        const handlePageClick = (event) => {

            setpage(event.selected+1)
            my_loads(event.selected+1,type)
        }
    useEffect(() => {
   
        my_loads(page,type)
       
       }, []);
    function AddLoads() {
      history("/add-loads");
    }
 
    function selcettype(types,pagecount){
        setType(types)
        setpage(pagecount)
        my_loads(page,types)

    }
    function handleSelect(key) {
        selcettype(key, 1)

    }
    const statusChange = (value, id) => {
        console.log("======statusChange===",expenses)
        setIsLoader(true)
        let data ={
            delivery_status : value,
            expenses:expenses
        }

        apiService.change_load_status(data,id).then((res) => {
            console.log("======cancel===",res.data)
            setIsLoader(false)
            setmessage1("Success")
            if(id==8){
                setmessage3("A confirmation email  has been sent to retailer.Once confirmed you will get paid") 
            }
            setmessage2(res.data.message)
            handleShowSucc()
            
             my_loads(page,type)
             }).catch((err) => {
                setIsLoader(false)
                handleShowErr()
            if(err?.response?.data?.message){
            
                seterrmessage2(err?.response?.data?.message)
            }else{
                seterrmessage2("Something went wrong.")
            }
             
             //   Swal.fire("Error", "Something went wrong.", "error");
              //  console.log("contact us api error", err.response.data.message);
             });

    }
    const EditLoad =(id)=>{
        window.location.href = "/edit-loads/"+id

    }
    const my_loads = (page,type)=>{
        setIsLoader(true)
        apiService.my_loads(page,type).then((res) => {
            
            setloadList(res.data.data)
            settotal_page(res.data.total_page)
            setIsLoader(false)
         })
           .catch((err) => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = "/login"
            }
             setIsLoader(false)
           //   Swal.fire("Error", "Something went wrong.", "error");
            //  console.log("contact us api error", err.response.data.message);
           });
       }
    

    const options2 = [
        // { value: 7, label: "Shipped " },
        { value: 8, label: "Delivered" },

    ];
    const options1 = [
        { value: 7, label: "Pick Up " },
        // { value: 8, label: "Delivered" },

    ];
    const tooltip = (
        <Tooltip id="tooltip" >
            Estimated Delivery Date
        </Tooltip>
    )
    const tooltip2 = (
        <Tooltip id="tooltip2" >
            Order will be packed and ready for shipping by
        </Tooltip>
    )

    return (
        <>
           {isLoader && <Loader/>}
      
            <div className="manage-truck-area">
                <div className="recent-loads-top-area manage-truck-top-area">
                    <div>
                        <h3>Loads/Schedule Management</h3>
                        <p>Elevate your logistics game by securing perfectly matched backhaul and backload trips.</p>
                    </div>
                    <Button type="button" variant="unset" onClick={AddLoads}>+ Add Schedule/Load</Button>
                </div>
                <div className="order-management-tab-area">
                    <Tabs
                        defaultActiveKey="accepted"
                        id="uncontrolled-tab-example"
                        className="mb-4"
                        onSelect={handleSelect}
                    >
                        <Tab eventKey="accepted" title="Upcoming Loads" >
                        {!loadList || loadList.length==0?
                            <Nodata message1={"No Data Found"} message2={""}/>:
                            <>
                        {loadList && loadList.map((list, index) => {
                            console.log("======load=list====",list)
                           return (<div className={list.load_type=="schedule"?"manage-truck-detail-box":"manage-truck-detail-box platform-loads"}>
                                <Row>
                                    <Col md={10}>
                                        
                                        <div className="manage-truck-detail-left order-management-content">
                                            <h6 className="brand-name">{list.load_type=="schedule"?list.schedule.primary_client?.name:list.order.product_sku[0].business_profile.business_name} <span>(Client name)</span></h6>
                                            <b className="location">Pick Up</b>:
                                            <p className="mb-0"><img src={require("../../../../assets/images/location-icon.png")} />
      
                                            {list.load_type=="schedule"?list.schedule.primary_client.address[0].address:list.order.product_sku[0].business_profile.business_address}
                                            {/* {list.business_profile.business_address} */}
                                            </p>
                                            <b className="location">Drop Off</b>:
                                            <p><img src={require("../../../../assets/images/location-icon2.png")} />
                                           
                                            { list.load_type=="schedule"?list.schedule.destination:list?.order.address.address}
                                            </p>
                                            <div className="truck-detail-bottom">
                                                <p>Truck: <span>{list.truck.plate_number}</span></p>
                                                { list.load_type=="schedule"?<> <p > Pick Up Date:<span>{" "}<i class="fa fa-calendar" aria-hidden="true"></i></span> {moment(list.schedule.pickup_time).format('DD MMMM YYYY')}</p>
                                                <p><span><i class="fa fa-clock-o" aria-hidden="true"></i></span> {moment(list.schedule.pickup_time).format('hh:mm')}</p></>:  
                                                <>
                                                <p > Estimated Packing Date:<span>{" "}<i class="fa fa-calendar" aria-hidden="true"></i></span> {moment(list.order.estimated_packing).format('DD MMMM YYYY')}</p>
                                                { list.load_type=="schedule"?null: 
                                            <>
                                            {list.order.delivery_date?
                                            <>
                                                <p > Estimated Delivery Date: <span>{" "}<i class="fa fa-calendar" aria-hidden="true"></i></span> {moment(list.order.delivery_date).format('DD MMMM YYYY')}</p>
                                                {/* <p><span><i class="fa fa-clock-o" aria-hidden="true"></i></span> {moment(list.order.delivery_date).format('hh:mm')}</p> */}
                                           </>:null}
                                           </>
                                           
                                           }
                                                {/* <p><span><i class="fa fa-clock-o" aria-hidden="true"></i></span> {moment(list.order.estimated_packing).format('hh:mm')}</p> */}
                                           </>}
                                           { list.load_type=="schedule"?null:  
                                           <>
                                          { list.order.delivery_date?
                                            <>
                                                <p > Estimated Delivery Date: <span>{" "}<i class="fa fa-calendar" aria-hidden="true"></i></span> {list.order.delivery_date?moment(list.order.delivery_date).format('DD MMMM YYYY'):null}</p>
                                                <p><span><i class="fa fa-clock-o" aria-hidden="true"></i></span> {list.order.delivery_date?moment(list.order.delivery_date).format('hh:mm'):null}</p>
                                           </>:null}
                                           </>
                                        }
                                            </div>
                                              {/* <div className="truck-detail-bottom">
                                <p> Total CBM: <span>{list?.order_cbm?.toFixed(2)}</span></p>
                                    <p> Total Weight: <span>{list?.order_weight} KG</span></p>
                                </div> */}
                                            <div className="truck-detail-bottom">
                                                <p>Type:  <span>{list.truck.type.name}</span></p>
                                                <p >Driver:  <span>{list.truck.driver.name}</span> </p>
                                                <p>Load Type:  <span> {list.load_type=="schedule"?"Scheduled Load":"Distrix Load"}</span> </p>
                                                {list.load_type=="schedule"?
                                                  <p>DDR / Trip Ticket No.:  <span> {list.schedule.ddr}</span> </p>:null}
                                             
                                                {/* {list.order? <p>Order Total CBM: <span>{list.order?.order_cbm?.toFixed(2)}</span></p>:null} */}
                                                {list.order? <p>Order ID: <span>{list.order?.id}</span></p>:null}
                                                {list.load_type!=="schedule"?
                                                <>
                                                <p> Total CBM: <span>{list?.order.order_cbm?.toFixed(2)}</span></p>
                                               <p> Total Weight: <span>{list?.order.order_weight} KG</span></p>
                                               </>:null}
                                            </div>
                                        </div>
                                    </Col>
                                   {list.load_type=="schedule"?
                                    <Col md={2}>
                                                <div className="manage-truck-detail-right">
                                            {list.load_type=="schedule"?<Button type="button" variant="unset" className="edit-btn" onClick={()=>EditLoad(list.schedule.id)}>Edit</Button>:null}
</div>
                                        <div className=" order-management-content-right">
                                            
                                    
                                        <Form.Group
                                            className="mb-2"
                                            controlId="exampleForm.ControlInput1"
                                        >

                                            <Select options={options1} placeholder={list.load_type=="schedule"?list.schedule.delivery_status.status:list.order.delivery_status.status}
                                             onChange={e => statusChange(e.value, list.id)}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: "50px",
                                                        background: "transparent",
                                                        borderRadius: "15px",
                                                        border: "none",
                                                        fontWeight: "600",
                                                        boxShadow: "none",
                                                        color: "#000",

                                                        "&:focus": {
                                                            borderColor: "none",
                                                        },
                                                        "&:hover": {
                                                            borderColor: "none",
                                                        },
                                                    }),
                                                    singleValue: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "#000",
                                                    }),
                                                    // menuList: (baseStyles, state) => ({

                                                    // })
                                                }} />
                                        </Form.Group>
                                       
                                            {/* {list.schedule.delivery_status.id == 7?
                                               <div >
                                               <Button type="button" variant="unset" className="shipped">Shipped</Button></div>:
                                        <>
                                          {list.schedule.delivery_status.id== 5?
                                        <div>
                                         
                                           <Button type="button" variant="unset" >Packed</Button>
                                       </div>:<>
                                       
                                       {list.schedule.delivery_status.id == 6?
                                             <div >
                                             <Button type="button" variant="unset" className="ready-to-ship">Ready to ship</Button>
                                         </div>
                                         :
                                         <>
                                          {list.schedule.delivery_status.id == 2?
                                             <div >
                                                <Button type="button" variant="unset" className="accepted-btn">Accepted</Button>
                                         </div>:null}
                                         
                                         </>

                                         
                                         }
                                      
                                       
                                       </>}
                                       </>}
                                       */}
                                    
                                            <h6>₱ {list.load_type=="schedule"?list.schedule.delivery_charge:list.order.delivery_charge}</h6>
                                        </div>
                                    </Col>:   <Col md={2}>
                                        <div className=" order-management-content-right">
                                            {list.order.delivery_status.id == 7?
                                               <div >
                                               <Button type="button" variant="unset" className="shipped">Shipped</Button></div>:
                                        <>
                                          {list.order.delivery_status.id== 5?
                                        <div>
                                         
                                           <Button type="button" variant="unset" >Packed</Button>
                                       </div>:<>
                                       
                                       {list.order.delivery_status.id == 6?
                                             <div >
                                             <Button type="button" variant="unset" className="ready-to-ship">Ready to ship</Button>
                                         </div>:
                                         <>
                                          {list.order.delivery_status.id == 2?
                                             <div >
                                                <Button type="button" variant="unset" className="accepted-btn">Accepted</Button>
                                         </div>:null}
                                         
                                         </>

                                         
                                         }
                                      
                                       
                                       </>}
                                       </>}
                                      
                                    
                                            <h6>₱ {list.load_type=="schedule"?list.schedule.delivery_charge:list.order.delivery_charge}</h6>
                                            {list.load_type!="schedule"?
                                            <div className="contact-btn-area">
                                                    <div className="chat-now-btn-area">
                                                        <Button type="button" variant="unset" onClick={()=>handleShowContact(list.order.user.email,list.order.retailer_business_profile.phone,list.order.product_sku[0].product.user.email,list.order.wholesaler_business_profile.phone)}><i class="fa fa-phone-square" aria-hidden="true"></i>Contact</Button>
                                                    </div>
                                                </div>:null}
                                        </div>
                                    </Col>}
                                </Row>
                            </div>)
                })}
                </>}
  </Tab>
                        <Tab eventKey="progress" title="In-progress Load">
                       {!loadList || loadList.length==0?
                            <Nodata message1={"No Data Found"} message2={""}/>:
                            <>
                            {loadList && loadList.map((list, index) => {
                            console.log("======load=list====",list)
                           return (
                           
                            <div className={list.load_type=="schedule"?"manage-truck-detail-box":"manage-truck-detail-box platform-loads"}>
                            <Row>
                                <Col md={10}>
                                    <div className="manage-truck-detail-left order-management-content">
                                        <h6 className="brand-name">{list.load_type=="schedule"?list.schedule.primary_client?.name:list.order.product_sku[0].business_profile.business_name} {list.load_type=="schedule"?<span>(Client name)</span>:null}</h6>
                                        <b className="location">Pick Up</b>:
                                        <p className="mb-0"><img src={require("../../../../assets/images/location-icon.png")} />{list.load_type=="schedule"?list.schedule.primary_client.address[0].address:list.order.product_sku[0].business_profile.business_address}</p>
                                        <b className="location">Drop Off</b>:
                                        <p><img src={require("../../../../assets/images/location-icon2.png")} />{list.load_type=="schedule"?list.schedule.destination:list.order.address.address}</p>
                                      
                                        <div className="truck-detail-bottom">
                                        <p>Truck: <span>{list.truck.plate_number}</span></p>
                                                { list.load_type=="schedule"?<> <p > Pick Up Date:<span>{" "}<i class="fa fa-calendar" aria-hidden="true"></i></span> {moment(list.schedule.pickup_time).format('DD MMMM YYYY')}</p>
                                                <p><span><i class="fa fa-clock-o" aria-hidden="true"></i></span> {moment(list.schedule.pickup_time).format('hh:mm')}</p></>:  
                                                <>
                                                <p > Estimated Packing Date:<span>{" "}<i class="fa fa-calendar" aria-hidden="true"></i></span> {moment(list.order.estimated_packing).format('DD MMMM YYYY')}</p>
                                                { list.load_type=="schedule"?null: 
                                            <>
                                            {list.order.delivery_date?
                                            <>
                                                <p > Estimated Delivery Date: <span>{" "}<i class="fa fa-calendar" aria-hidden="true"></i></span> {moment(list.order.delivery_date).format('DD MMMM YYYY')}</p>
                                                {/* <p><span><i class="fa fa-clock-o" aria-hidden="true"></i></span> {moment(list.order.delivery_date).format('hh:mm')}</p> */}
                                           </>:null}
                                           </>
                                           
                                           }
                                                {/* <p><span><i class="fa fa-clock-o" aria-hidden="true"></i></span> {moment(list.order.estimated_packing).format('hh:mm')}</p> */}
                                           </>}
                                           { list.load_type=="schedule"?null: 
                                           <>
                                           {list.order.delivery_date?
                                            <>
                                                <p > Estimated Delivery Date: <span>{" "}<i class="fa fa-calendar" aria-hidden="true"></i></span> {moment(list.order.delivery_date).format('DD MMMM YYYY')}</p>
                                                <p><span><i class="fa fa-clock-o" aria-hidden="true"></i></span> {moment(list.order.delivery_date).format('hh:mm')}</p>
                                           </>:null}
                                           </>
                                           }
                                            </div>
                                            <div className="truck-detail-bottom">
                                                <p>Type:  <span>{list.truck.type.name}</span></p>
                                                <p >Driver:  <span>{list.truck.driver.name}</span> </p>
        
                                                <p>Load Type:  <span> {list.load_type=="schedule"?"Scheduled Load":"Distrix Load"}</span> </p>
                                                {/* {list.order? <p>Order Total CBM: <span>{list.order?.order_cbm?.toFixed(2)}</span></p>:null} */}
                                                {list.order? <p>Order ID: <span>{list.order?.id}</span></p>:null}
                                         
                                            {list.load_type!=="schedule"?
                                                <>
                                                <p> Total CBM: <span>{list?.order.order_cbm?.toFixed(2)}</span></p>
                                               <p> Total Weight: <span>{list?.order.order_weight} KG</span></p>
                                               </>:null}
                                </div>
                                            {/* {list.load_type=="schedule"?"":
                                            <>
                                            {list.order.quantity.map((pro,index)=>{
                         
                               return( <div className="truck-detail-bottom">
                                    <p>Quantity:  <span>{pro.quantity}</span></p>
                                    <p>Weight: <span>{pro.product_sku.weight}</span>,Width: <span>{pro.product_sku.width},</span> Height: <span>{pro.product_sku.height}ft,</span> Length: <span>{pro.product_sku.length}ft,</span> </p>
         
                                   
                                </div>)})}
                                </>
                                } */}
                                       
                                    </div>
                                </Col>
                                <Col md={2}>
                                <div className=" order-management-content-right">
                                {(list.load_type!="schedule" && list.order?.delivery_status?.id == 6) || list.schedule?.delivery_status?.id == 6?
                                             <div >
                                             {list.load_type!="schedule" && list.order.delivery_status.id == 6?
                                             <Button type="button" variant="unset" className="ready-to-ship">Ready to ship</Button>:
                                             <>
                                             { list.schedule.delivery_status.id == 6?
                                             <Button type="button" variant="unset" className="ready-to-ship">Ready to ship</Button>:null}
                                             </>}

                                         </div>:
                                        <Form.Group
                                            className="mb-2"
                                            controlId="exampleForm.ControlInput1"
                                        >

                                            <Select options={options2} placeholder={list.load_type=="schedule"?list.schedule.delivery_status.status:list.order.delivery_status.status}
                                             onChange={e =>{list.load_type=="schedule"?handleShowconfirm(e.value, list.id):statusChange(e.value, list.id)}}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: "50px",
                                                        background: "transparent",
                                                        borderRadius: "15px",
                                                        border: "none",
                                                        fontWeight: "600",
                                                        boxShadow: "none",
                                                        color: "#000",

                                                        "&:focus": {
                                                            borderColor: "none",
                                                        },
                                                        "&:hover": {
                                                            borderColor: "none",
                                                        },
                                                    }),
                                                    singleValue: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "#000",
                                                    }),
                                                    // menuList: (baseStyles, state) => ({

                                                    // })
                                                }} />
                                        </Form.Group>}
                                        <h6>₱ {list.load_type=="schedule"?list.schedule.delivery_charge:list.order.delivery_charge}</h6>
                                        {list.load_type!="schedule"?
                                            <div className="contact-btn-area">
                                                    <div className="chat-now-btn-area">
                                                        <Button type="button" variant="unset" onClick={()=>handleShowContact(list.order.user.email,list.order.retailer_business_profile.phone,list.order.product_sku[0].product.user.email,list.order.wholesaler_business_profile.phone)}><i class="fa fa-phone-square" aria-hidden="true"></i>Contact</Button>
                                                    </div>
                                                </div>:null}
                                    </div>
                                </Col>
                            </Row>
                        </div> )
                })}
                </>}
                </Tab>
                        <Tab eventKey="delivered" title="Delivered Loads" >
                        {!loadList || loadList.length==0?
                            <Nodata message1={"No Data Found"} message2={""}/>:
                            <>
                        {loadList && loadList.map((list, index) => {
                            console.log("======load=delilist====",list)
                            
                           return (
                           
                            <div className={list.load_type=="schedule"?"manage-truck-detail-box":"manage-truck-detail-box platform-loads"}>
                            <Row>
                                <Col md={10}>
                                    <div className="manage-truck-detail-left order-management-content">
                                        <h6 className="brand-name">{list.load_type=="schedule"?list.schedule.primary_client?.name:list.order.product_sku[0].business_profile.business_name} <span>(Client name)</span></h6>
                                        <b className="location">Pick Up</b>:
                                        <p className="mb-0"><img src={require("../../../../assets/images/location-icon.png")} />{list.load_type=="schedule"?list.schedule.primary_client.address[0].address:list.order.product_sku[0].business_profile.business_address}</p>
                                        <b className="location">Drop Off</b>:
                                        <p><img src={require("../../../../assets/images/location-icon2.png")} />{list.load_type=="schedule"?list.schedule.destination:list.order.address.address}</p>
                                    
                                        <div className="truck-detail-bottom">
                                        <p>Truck: <span>{list.truck.plate_number}</span></p>
                                                { list.load_type=="schedule"?<> <p > Pick Up Date: <span>{" "}<i class="fa fa-calendar" aria-hidden="true"></i></span> {moment(list.schedule.pickup_time).format('DD MMMM YYYY')}</p>
                                                <p><span><i class="fa fa-clock-o" aria-hidden="true"></i></span> {moment(list.schedule.pickup_time).format('hh:mm')}</p></>:  
                                                <>
                                                <p > Estimated Packing Date:<span>{" "}<i class="fa fa-calendar" aria-hidden="true"></i></span> {moment(list.order.estimated_packing).format('DD MMMM YYYY')}</p>
                                                {/* <p><span><i class="fa fa-clock-o" aria-hidden="true"></i></span> {moment(list.order.estimated_packing).format('hh:mm')}</p> */}
                                           </>}
                                           { list.load_type=="schedule"?null: 
                                            <>
                                            {list.order.delivery_date?
                                            <>
                                                <p > Estimated Delivery Date: <span>{" "}<i class="fa fa-calendar" aria-hidden="true"></i></span> {moment(list.order.delivery_date).format('DD MMMM YYYY')}</p>
                                                {/* <p><span><i class="fa fa-clock-o" aria-hidden="true"></i></span> {moment(list.order.delivery_date).format('hh:mm')}</p> */}
                                           </>:null}
                                           </>
                                           
                                           }
                                            </div>
                                            <div className="truck-detail-bottom">
                                                <p>Type:  <span>{list.truck.type.name}</span></p>
                                                <p >Driver:  <span>{list.truck.driver.name}</span> </p>
                                                <p>Load Type:  <span> {list.load_type=="schedule"?"Scheduled Load":"Distrix Load"}</span> </p>
                                                {/* {list.order? <p>Order Total CBM: <span>{list.order?.order_cbm?.toFixed(2)}</span></p>:null} */}
                                                {list.order? <p>Order ID: <span>{list.order?.id}</span></p>:null}
                                                {list.load_type!=="schedule"?
                                                <>
                                                <p> Total CBM: <span>{list?.order.order_cbm?.toFixed(2)}</span></p>
                                               <p> Total Weight: <span>{list?.order.order_weight} KG</span></p>
                                               </>:null}
                                </div>
                                            {/* {list.load_type=="schedule"?"":
                                            <>
                                            {list.order.quantity.map((pro,index)=>{
                                
                               return( <div className="truck-detail-bottom">
                                    <p>Quantity:  <span>{pro.quantity}</span></p>
                                    <p>Weight: <span>{pro.product_sku.weight}</span>,Width: <span>{pro.product_sku.width},</span> Height: <span>{pro.product_sku.height}ft,</span> Length: <span>{pro.product_sku.length}ft,</span> </p>
         
                                   
                                </div>)})}
                                </>} */}
                                        {/* <div className="truck-detail-bottom">
                                            <p>Number of units:  <span>12/unit</span></p>
                                            <p>Width: <span>3kg,</span> Height: <span>3ft,</span> Width: <span>4ft,</span> Length: <span>4ft</span></p>
                                            <p>Price per unit: <span>1000/-</span></p>
                                            <p>Total units: <span>5000/-</span></p>
                                        </div> */}
                                    </div>
                                </Col>
                                <Col md={2}>
                                <div className=" order-management-content-right">
                                <Button type="button" variant="unset">Delivered</Button>
                                 <h6>₱ {list.load_type=="schedule"?list.schedule.delivery_charge:list.order.delivery_charge}</h6>
                                 {list.load_type!="schedule"?
                                            <div className="contact-btn-area">
                                                    <div className="chat-now-btn-area">
                                                        <Button type="button" variant="unset" onClick={()=>handleShowContact(list.order.user.email,list.order.retailer_business_profile.phone,list.order.product_sku[0].product.user.email,list.order.wholesaler_business_profile.phone)}><i class="fa fa-phone-square" aria-hidden="true"></i>Contact</Button>
                                                    </div>
                                                </div>:null}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                            )
                })}
                </>}
                   </Tab>
                    </Tabs>
                    {loadList && loadList.length>0?
                <Pagination handlePageClick={handlePageClick}
                page={page}
                total_page={total_page} />:null}
                </div>
                <SuccessPopup show={showSucc} handleClose={handleCloseSucc} message1={message1} message2={message2} />
            <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} />
            <ContactPopup showContact={showContact} handleCloseContact={handleCloseContact} email={contactEmail} phone={contactPhone} emailSecond={contactEmail_2} phoneSecond={contactPhone_2} secontType={"w"}/>

            <Modal className="success-box trash-box" show={showdconfirm} onHide={handleCloseconfirm} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                   
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                                        <Form.Label>Trip Expense</Form.Label>
                                        <Form.Control  value={expenses==0?"":expenses} onWheel={() => document.activeElement.blur()}  maxLength={10} type="number" placeholder="Enter Trip Expense" onChange={e => setexpense( e.target.value)}/>
                                        {/* <p className="errors">{truckFieldsErr.capacity}</p> */}
                                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={statusupdate}>
                       Submit
                    </Button>
                    {/* <Button className="trash-no-btn" variant="primary" onClick={setshowconfirm}>
                      Close
                    </Button> */}
                </Modal.Footer>
            </Modal>
            </div>
        </>
    );
};
export default LoadsManagement;