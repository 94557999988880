import { Col, Form, Row } from "react-bootstrap"
import { useState,useEffect } from "react";
import { apiService} from "../../../../services/api.services";
const Step2 = ({bankFieldsErrs,bankFields,onInputChange}) => {
    console.log("====bankFields",bankFields)
    const [bankList, setbankList] = useState(null);
    console.log("====bankFields",bankFields)
    useEffect(() => {
        get_banks()
   }, []);
   const get_banks =()=>{
    apiService.get_banks().then((res) => {
      console.log("=======bank list====>",res.data.data)
      setbankList(res.data.data)
      
      })
       .catch((err) => {
         
       });
    
  
  }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault(); // Prevent the default behavior (page refresh)
          // Handle your logic here
        }
      };
      const handleNumberChange = (event,maxDigits) => {
        const inputValue = event.target.value;
        // Change this to your desired maximum length
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default behavior (page refresh)
            // Handle your logic here
          }
        if (inputValue.length >= maxDigits && event.key !== 'Backspace') {
          event.preventDefault();
        }
      };
    return (
        <>
            <div className="stepper-form-area">

              
                <Row>
               
                    <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Account Holder Name</Form.Label>
                                <Form.Control onKeyDown={handleKeyDown} maxLength={30} type="text"  value={bankFields.account_holder_name} placeholder="Enter Account Holder Name" onChange={e => onInputChange( 'account_holder_name', e.target.value)}/>
                                <span className="errors">{bankFieldsErrs.account_holder_name}</span>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col md={6}>
                    <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Bank Name</Form.Label>
                              
                        <select className="form-control" onChange={e => onInputChange( 'bank_id', e.target.value)}>
                            <option value="">Select Bank</option>
              {bankList && bankList.map(item => {
                  return (<option key={item.id} value={item.id}>{item.label}</option>);
              })}
            </select>
            <span className="errors">{bankFieldsErrs.bank_name}</span>
            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Account Number</Form.Label>
                                <Form.Control onKeyDown={(e)=>handleNumberChange(e,20)} maxLength={20} onWheel={() => document.activeElement.blur()}  type="number"  value={bankFields.account_number} placeholder="Enter Account Number" onChange={e => onInputChange( 'account_number', e.target.value)}/>
                                <span className="errors">{bankFieldsErrs.account_number}</span>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col md={6}>
                    {/* <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Routing Number</Form.Label>
                                <Form.Control onKeyDown={(e)=>handleNumberChange(e,15)} maxLength={15} onWheel={() => document.activeElement.blur()}  type="number"  value={bankFields.routing_number} placeholder="Enter Routing Number" onChange={e => onInputChange( 'routing_number', e.target.value)}/>
                                <span className="errors">{bankFieldsErrs.routing_number}</span>
                            </Form.Group>
                        </Form> */}
                    </Col>
                   
                </Row>
                <p className="note"><span>*</span>DistriX ensures safety and security. Provide your bank account details below for payment transfers/deposits related to each order you receive.</p>
                </div>
        </>
    )
}

export default Step2