export const baseUrl = "https://api.distrix.ai/api"
export const bUrl = "https://api.distrix.ai"
// export const baseUrl = "http://157.245.218.104:9259/api"
// export const bUrl = "http://157.245.218.104:9259"
// export const baseUrl = "http://localhost:8000/api"
// export const bUrl = "http://localhost:8000"
export const userType = [ 
     { value: 0, label: "Trucker" },
    { value: 1, label: "Wholesaler" },
    { value: 2, label: "Retailer" },
    
];
export const allowedImageTypes = ['image/jpeg', 'image/png','image/jpg'];
export const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const length_unit = "Ft"
export const retailer_type = "3"
export const trucker_type = "2"
export const wholeseller_type = "4"
export const gKey = "AIzaSyAkoLLupma_eUwYBuF_364AX-YuA9l_Y3w"
export const payout_percent = 10
export const CLIENT_SECRET = "sk_test_51NLdGwEXsyM13gE78L0z4s0pTDBpcEVCoqoUSNU4LOLzr1HpasOeguExc5pbA683QEyb1Wo5DXwSKuec9AzOXnLK00taCN3XbA"

export const STRIpe_Key = "pk_test_51NLdGwEXsyM13gE7qaBxt1Vs0YKWZxeYWehQ51atu3HFd8j0k3jQqQBhT94vNI9Nc8UHVHCwe943tmmT8h9zDEFc00OSfy5vsS"

export const allDeliveryStatus = [
    // {
    //     "id": 1,
    //     "status": "Pending"
    // },
    // {
    //     "id": 2,
    //     "status": "Accept"
    // },
    // {
    //     "id": 3,
    //     "status": "Reject"
    // },
    // {
    //     "id": 4,
    //     "status": "Cancel"
    // },
    {
        "id": 5,
        "status": "Packed"
    },
    {
        "id": 6,
        "status": "Ready to ship"
    },
    {
        "id": 7,
        "status": "Shipped"
    },
    {
        "id": 8,
        "status": "Deliver"
    }
]


 export const productUnit = [
       { value: "", label: "Select Unit" },
        { value: "Bale", label: "Bale" },
        { value: "Box", label: "Box" },
        { value: "Bundle", label: "Bundle" },
        { value: "Carton", label: "Carton" },
        { value: "Case", label: "Case" },
        { value: "Crate", label: "Crate" },
        { value: "Dozen", label: "Dozen" },
        { value: "Gallon", label: "Gallon" },
        { value: "Kilogram", label: "Kilogram" },
        { value: "Liter", label: "Liter" },
        { value: "Meter", label: "Meter" },
        { value: "Pack", label: "Pack" },
        { value: "Pallet", label: "Pallet" },
        { value: "Piece", label: "Piece" },
        { value: "Ream", label: "Ream" },
        { value: "Roll", label: "Roll" },
        { value: "Ton", label: "Ton" },
        { value: "Others", label: "Others" },

    ];

