import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import { apiService } from "../../../services/api.services";
import { validateService } from "../../../services/validate.services";
import { allowedImageTypes, bUrl, gKey } from "../../../config/config"
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByLatLng } from 'react-google-places-autocomplete';
import { TooltipCommon } from "../../common/tooltip";
import SuccessPopup from "../../modal/SuccessPopup"
import Errorpopup from "../../modal/Errorpopup"
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
import { context } from "../../../GlobalContext/GlobalContext";
import default_imag from "../../../assets/images/trucker-image.png"
// import default_imag from "../../../assets/images/trucker-image.png"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const ct = require("countries-and-timezones");
const TruckerEditProfile = () => {
    const globalData = useContext(context);
    const timezone = ct.getTimezone(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const COUNTRY_CODE = "ph";

    const [logo, setlogo] = useState();
    const [govtId, setgovtId] = useState();
    const [image, setimage] = useState(default_imag);
    const [imageErr, setimageErr] = useState("");
    const [isLoader, setIsLoader] = useState(false)
    const [show, setShow] = useState(false);
    const [showErr, setShowErr] = useState(false);
    const [errmessage1, seterrmessage1] = useState("Error")
    const [errmessage2, seterrmessage2] = useState("")
    const [value, setValue] = useState(null);
    const handleCloseErr = () => {
        setShowErr(false);
    }
    const handleShowErr = () => setShowErr(true);
    const [message1, setmessage1] = useState("")
    const [message2, setmessage2] = useState("")
    const navigate = useNavigate();
    const [redirectlink, setredirectlink] = useState("/")
    const handleShow = () => setShow(true);
    const handleClose = () => {
        window.location.href = "/profile"
        // navigate("/profile")
        setShow(false);
    }
    useEffect(() => {
        if (show) {
            const close = (e) => {

                if (e.key === 'Enter') {

                    handleClose()
                }
                // }
            }
            window.addEventListener('keydown', close)
            return () => window.removeEventListener('keydown', close)
        }

    }, [show])
    const [profileFields, setprofileFields] = useState({
        first_name: "",
        last_name: "",
        business_name: "",
        trade_name: "",
        ltfrb_number: "",
        marine_insurance: "",
        dti_sec_number: "",
        permit_number: "",
        phone: "",
        business_address: "",
        latitude: "",
        longitude: "",
        business_logo: "",

        govt_id:"",
        investment:0,
        image: ""
    });
    const [profileFieldsErrs, setprofileFieldsErrs] = useState({
        first_name: "",
        last_name: "",
        business_name: "",
        trade_name: "",
        ltfrb_number: "",
        marine_insurance: "",
        dti_sec_number: "",
        permit_number: "",
        phone: "",
        business_address: "",
        business_logo: "",
        govt_id:""
    });

    const handleNumberChange = (event, maxDigits) => {
        const inputValue = event.target.value;
        // Change this to your desired maximum length
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default behavior (page refresh)
            // Handle your logic here
        }
        if (inputValue.length >= maxDigits && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };

    const handlelogoChange = (file) => {
        let valid = true
        const selectedImage = file;
        if (selectedImage) {
            if (allowedImageTypes.includes(selectedImage.type)) {
                const reader = new FileReader();
                reader.onload = () => {
                    setlogo(reader.result);
                };
                reader.readAsDataURL(selectedImage);
            } else {
                valid = false

                setprofileFieldsErrs({
                    ...profileFieldsErrs,
                    'business_logo': "Invalid file type. Please select a JPEG or PNG image."
                })
            }
        }
        return valid
    };

    const handleIdChange = (file) => {
        let valid = true
        const selectedImage = file;
        if (selectedImage) {
            if (allowedImageTypes.includes(selectedImage.type)) {
                const reader = new FileReader();
                reader.onload = () => {
                    setgovtId(reader.result);
                };
                reader.readAsDataURL(selectedImage);
            } else {
                valid = false

                setprofileFieldsErrs({
                    ...profileFieldsErrs,
                    'govt_id': "Invalid file type. Please select a JPEG or PNG image."
                })
            }
        }
        return valid
    };
    const handleImageChange = (file) => {
        const selectedImage = file;
        let valid = true
        if (selectedImage) {
            if (allowedImageTypes.includes(selectedImage.type)) {
                valid = true
                const reader = new FileReader();
                reader.onload = () => {
                    setimage(reader.result);
                };
                reader.readAsDataURL(selectedImage);
                setimageErr("")
            } else {
                valid = false
                setimageErr("Invalid file type. Please select a JPEG or PNG image.")

            }
        }
        return valid
    };

    const onInputChange = (field, value) => {
        if (field == "business_logo") {
            let valid = handlelogoChange(value)
            if (valid) {
                setprofileFields({
                    ...profileFields,
                    [field]: value,

                });
            }

        }else if (field == "govt_id") {
            let valid = handleIdChange(value)
            if (valid) {
                setprofileFields({
                    ...profileFields,
                    [field]: value,

                });
            }
        }
         else if (field == "image") {
            let valid = handleImageChange(value)
            if (valid) {
                setprofileFields({
                    ...profileFields,
                    [field]: value,

                });
            }
        } else {
            setprofileFields({
                ...profileFields,
                [field]: value,

            });
        }



    }
    useEffect(() => {
        if (value) {
            geocodeByAddress(value.label)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) =>
                    setprofileFields({
                        ...profileFields,
                        business_address: value.label,
                        latitude: lat,
                        longitude: lng,
                    }));
        }
    }, [value]);
    const getData = (lat, lng, addressadded) => {
        console.log({ lat: lat, lng: lng }, "=====")
        geocodeByLatLng({ lat: lat, lng: lng }).then(
            (response) => {
                console.log(response[0], "=====")
                const address = response[0].formatted_address;
                console.log(address, "====addressaddressaddressaddress=")
                setValue({ label: addressadded, value: response[0] })

            })
    }
    const get_business_profile = () => {
        setIsLoader(true)
        //  get_bank()
        apiService.get_business_profile().then((res) => {
            console.log("===get_business_profile", res.data.data.user.image)
            if (res.data.data.latitude != "" && res.data.data.latitude != null) {
                getData(res.data.data.latitude, res.data.data.longitude, res.data.data.business_address)
            }
            //  setValue({label:res.data.data.business_address})
            let data = res.data.data
            data.first_name = res.data.data.user.first_name
            data.last_name = res.data.data.user.last_name
            data.image = res.data.data.user.image
            data.id = res.data.data.user.id
            setlogo(bUrl + res.data.data.business_logo)
            if(res.data.data.govt_id){
                setgovtId(bUrl + res.data.data.govt_id)
            }
           
            
            if (res.data.data.user.image != null) {
                setimage(bUrl + res.data.data.user.image)
            }

            let user_name = res.data.data.user.first_name + " " + res.data.data.user.last_name
            globalData.setuser_name(user_name)
            localStorage.setItem("user_name", res.data.data.user.first_name + " " + res.data.data.user.last_name)
            delete data["user"];
            delete data["business_logo"];
            delete data["govt_id"];
            
            delete data["image"];
            console.log("===dat====", data)
            setprofileFields(data)
            setIsLoader(false)
        })
            .catch((err) => {

                setIsLoader(false)
                //   Swal.fire("Error", "Something went wrong.", "error");
                //  console.log("contact us api error", err.response.data.message);
            });
    }
    useEffect(() => {
        get_business_profile()

    }, [])
    const handleSubmit = () => {
        // e.preventDefault();
        let formValidated = validateService.validateTruckerstep1(setprofileFieldsErrs, profileFields)

        if (formValidated) {

            setIsLoader(true)
            apiService.update_user_profile(profileFields).then((res) => {
                setIsLoader(false)
                globalData.setuser_name(res.data.data.user.first_name + " " + res.data.data.user.last_name)
                localStorage.setItem("user_name", res.data.data.user.first_name + " " + res.data.data.user.last_name)
                if (res.data.data.user.image != null) {
                    globalData.setuser_image(bUrl + res.data.data.user.image)
                    localStorage.setItem("user_image", bUrl + res.data.data.user.image)
                }
                setmessage1("Success!")
                setmessage2("Profile updated successfully!")
                handleShow()
            })
                .catch((err) => {
                    handleShowErr()

                    seterrmessage2(err.response.data.message)
                    setIsLoader(false)
                    //   Swal.fire("Error", "Something went wrong.", "error");
                    console.log("contact us api error", err.response.data.message);
                });
        }

    }
    return (
        <>
            <>

                {isLoader && <Loader />}
                <div className="overflow-x">
                    <section className="my-profile-area">
                        <Container>
                            <div className="manage-truck-area ">
                                <div className="my-profile-top">
                                    <h3>Edit Profile</h3>

                                </div>
                                <div className="trucker-image-area">

                                    {!image ?
                                        <img src={require("../../../assets/images/trucker-image.png")} alt="img" /> :
                                        <img src={image} alt="img" />}
                                    <div className="profile-edit-area">
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                        <Form.Control type="file" onChange={e => onInputChange('image', e.target.files[0])} />
                                    </div>
                                </div>
                                <p className="errors">{imageErr}</p>
                                <div className="stepper-form-area">
                                    <Form>
                                        <Row>
                                            <Col md={6}>

                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="text" value={profileFields.first_name} placeholder="Enter First Name" onChange={e => onInputChange('first_name', e.target.value)} />
                                                    <span className="errors">{profileFieldsErrs.first_name}</span>
                                                </Form.Group>

                                            </Col>
                                            <Col md={6}>

                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="text" value={profileFields.last_name} placeholder="Enter Last Name" onChange={e => onInputChange('last_name', e.target.value)} />
                                                    <span className="errors">{profileFieldsErrs.last_name}</span>
                                                </Form.Group>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>

                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Trucking Name</Form.Label>
                                                    <Form.Control type="text" value={profileFields.business_name} placeholder="Enter Trucking Name" onChange={e => onInputChange('business_name', e.target.value)} />
                                                    <span className="errors">{profileFieldsErrs.business_name}</span>
                                                </Form.Group>

                                            </Col>
                                            <Col md={6}>

                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>LTFRB Number</Form.Label>
                                                    <Form.Control type="text" value={profileFields.ltfrb_number} placeholder="Enter LTFRB Number" onChange={e => onInputChange('ltfrb_number', e.target.value)} />
                                                    <span className="errors">{profileFieldsErrs.ltfrb_number}</span>
                                                </Form.Group>

                                            </Col>
                                            {/* <Col md={6}>

                                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                                <Form.Label>Trade Name</Form.Label>
                                                <Form.Control type="text" value={profileFields.trade_name} placeholder="Enter Trade Name" onChange={e => onInputChange('trade_name', e.target.value)} />
                                                <span className="errors">{profileFieldsErrs.trade_name}</span>
                                            </Form.Group>

                                        </Col> */}
                                        </Row>
                                        <Row>

                                            <Col md={6}>

                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Marine Insurance</Form.Label>
                                                    <Form.Control type="text" value={profileFields.marine_insurance} placeholder="Enter Marine Insurance" onChange={e => onInputChange('marine_insurance', e.target.value)} />
                                                    <span className="errors">{profileFieldsErrs.marine_insurance}</span>
                                                </Form.Group>

                                            </Col>
                                            <Col md={6}>

                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>DTI/Sec Number</Form.Label>
                                                    <Form.Control type="text" value={profileFields.dti_sec_number} placeholder="Enter DTI/Sec Number" onChange={e => onInputChange('dti_sec_number', e.target.value)} />
                                                    <span className="errors">{profileFieldsErrs.dti_sec_number}</span>
                                                </Form.Group>

                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col md={6}>

                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Mayor's Permit Number</Form.Label>
                                                    <Form.Control type="text" value={profileFields.permit_number} placeholder="Enter Permit Number" onChange={e => onInputChange('permit_number', e.target.value)} />
                                                    <span className="errors">{profileFieldsErrs.permit_number}</span>
                                                </Form.Group>

                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Contact No.</Form.Label>
                                                    <PhoneInput
                                                        country={
                                                            COUNTRY_CODE ? COUNTRY_CODE.toLocaleLowerCase() : "ph"
                                                        }
                                                        className={
                                                            //   contactUsFieldsErrors.phone
                                                            //     ? "border-red phone-input"
                                                            "phone-input "
                                                        }
                                                        placeholder="Enter contact number"
                                                        value={profileFields.phone}
                                                        onChange={(e) => {
                                                            setprofileFields((prevObj) => {
                                                                return {
                                                                    ...prevObj,
                                                                    phone: e,
                                                                };
                                                            });

                                                            //   if (e.trim() === "") {
                                                            //     setContactUsFieldsErrors((prevObj) => {
                                                            //       return {
                                                            //         ...prevObj,
                                                            //         phone: true,
                                                            //       };
                                                            //     });
                                                            //   } else if (e.trim().length < 10) {
                                                            //     setContactUsFieldsErrors((prevObj) => {
                                                            //       return {
                                                            //         ...prevObj,
                                                            //         phone: true,
                                                            //       };
                                                            //     });
                                                            //   } else {
                                                            //     setContactUsFieldsErrors((prevObj) => {
                                                            //       return {
                                                            //         ...prevObj,
                                                            //         phone: false,
                                                            //       };
                                                            //     });
                                                            //   }
                                                        }}
                                                    />
                                                    <span className="errors">{profileFieldsErrs.phone}</span>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col md={6}>

                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Business Address</Form.Label>
                                                    <GooglePlacesAutocomplete
                                                        placeholder={profileFields.business_address}
                                                        apiKey={gKey}
                                                        className="form-control"
                                                        onFail={error => console.log(error)}
                                                        onNotFound={() => console.log('no results')}
                                                        selectProps={{
                                                            placeholder: "Enter Address",
                                                            // defaultInputValue:value ,
                                                            value,
                                                            onChange: setValue,
                                                        }}

                                                    />
                                                    {/* <Form.Control type="text" value={profileFields.business_address} placeholder="Enter Business Address" onChange={e => onInputChange('business_address', e.target.value)} /> */}
                                                    <span className="errors">{profileFieldsErrs.business_address}</span>
                                                </Form.Group>

                                            </Col>
                                            <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label >Capital Investment <TooltipCommon text={"To ensure our system can offer you a precise estimate of your Return On Investment (ROI), kindly input the initial capital you invested when you launched your trucking business. This should encompass all costs, such as the purchase price of your truck, permits, and any other related expenses. This information is crucial for an accurate projection of your investment's performance."}><i role="button" className="fa fa-info-circle"></i></TooltipCommon></Form.Label>
                                <Form.Control onKeyDown={(e) => handleNumberChange(e, 20)} maxLength={20} onWheel={() => document.activeElement.blur()} type="number" value={profileFields.investment} placeholder="Enter  Your Capital Investment" onChange={e => onInputChange('investment', e.target.value)} />

                                <span className="errors">{profileFieldsErrs.investment}</span>
                            </Form.Group>
                        </Form>
                    </Col>
                                            <Col md={6}>


                                                <Form.Group className="mb-4  truck-logo-upload-field" controlId="formBasicEmail">
                                                    <Form.Label>Upload Trucking Logo</Form.Label>
                                                    <div className="upload-box-area">
                                                        <div className="logo-upload-box">
                                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                                            <Form.Control type="text" />
                                                            <Form.Control type="file" className="upload-item" onChange={e => onInputChange('business_logo', e.target.files[0])} />
                                                        </div>
                                                        {/* <i className="fa fa-plus" aria-hidden="true"></i> */}
                                                        <span className="errors">{profileFieldsErrs.business_logo}</span>
                                                        {logo ?
                                                            <div className="upload-image-preview">
                                                                <div className="first-preview-image">
                                                                    <img src={logo} alt={`Preview logo`} className="image-preview" />
                                                                </div>

                                                            </div> : null}
                                                        <span className="errors">{profileFieldsErrs.business_logo}</span>
                                                    </div>
                                                </Form.Group>






                                            </Col>
                                            <Col md={6}>
                        <Form>
                            <Form.Group className="mb-4  truck-logo-upload-field" controlId="formBasicEmail">
                                <Form.Label> Upload Government ID</Form.Label>
                                <div className="upload-box-area">
                                    <div className="logo-upload-box">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <Form.Control type="text" />
                                        <Form.Control type="file" className="upload-item upload-government-id"  onChange={e => onInputChange('govt_id', e.target.files[0])}/>
                                      
                                    </div>
                                    {/* <i className="fa fa-plus" aria-hidden="true"></i> */}
                                    {govtId ?
                                    <div className="upload-image-preview">
                                        <div className="first-preview-image">
                                            <img src={govtId} alt={`Preview logo`} className="image-preview" />
                                        </div>
                                    </div>:null}
                                    
                                </div>
                                <span className="errors">{profileFieldsErrs.govt_id}</span>
                            </Form.Group>
                        </Form>
                    </Col>
                                        </Row>
                                        <Button type="button" onClick={handleSubmit} variant="unset" className="edit-profile-update-btn">update</Button>
                                    </Form>
                                </div>
                            </div>
                        </Container>

                    </section>
                </div>

                <SuccessPopup show={show} handleClose={handleClose} message1={message1} message2={message2} redirectlink={redirectlink} />
                <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} redirectlink={redirectlink} />
            </>
        </>
    );
};
export default TruckerEditProfile;