import { Col, Form, Row, Table, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import Select from "react-select";
import { apiService } from "../../../../services/api.services";
import Loader from "../../../common/Loader";
import moment from "moment";
import Nodata from "../Dashboard/Nodata";
import { baseUrl } from "../../../../config/config"
const RevenueInsights = () => {
    const [allClients, setallClients] = useState();
    const [isLoader, setIsLoader] = useState(false)
    const [page, setpage] = useState(1);
    const [total_page, settotal_page] = useState('')
    const [nodata, setnodata] = useState('Loading data ....')
    const [start_date, setstart_date] = useState('')
    const [end_date, setend_date] = useState(new Date().toISOString().slice(0, -8))
    const [total_revenue, settotal_revenue] = useState(0)
    const [net_profit, setnet_profit] = useState(0)
    const [roi, setroi] = useState(0)

    const [transactions, setTransactions] = useState();
    const [client_id, setclient_id] = useState('');
    useEffect(() => {
        get_client_list()
        revenue_transactions(page, start_date, end_date, client_id)
    }, []);
    const options = [
        { value: "Diamond", label: "Diamond" },
        { value: "Platinum", label: "Platinum" },
        { value: "Gold", label: "Gold" },
        { value: "Silver", label: "Silver" },
    ];
    const download_excel = () => {

        const token = localStorage.getItem("web_token")
        window.open(baseUrl + "/download_excel/" + token + "/?type=schedule")

    }
    const get_client_list = () => {
        setIsLoader(true)
        apiService.get_client_list("").then((res) => {
            setIsLoader(false)
            let type = [{ value: "", label: "All" }]
            // let type1 = []
            console.log(res.data)
            {
                res.data.data.map((item, index) => (
                    // console.log("=====iiiiiii===",item),
                    type.push({ value: item.id, label: item.name, address: item.address[0].address })

                ))
            }
            // setallClientsLocation(type1)
            setallClients(type)

        }).catch((err) => {

            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = "/login"
            }
            //   Swal.fire("Error", "Something went wrong.", "error");
            //  console.log("contact us api error", err.response.data.message);
        });
    }
    const revenue_transactions = (page, start_date, end_date, client_id) => {
        setIsLoader(true)
        apiService.revenue_transactions(page, start_date, end_date, client_id).then((res) => {
            settotal_revenue(res.data?.total_revenue ? res.data?.total_revenue : 0)
            setnet_profit(res.data?.net_profit ? res.data?.net_profit : 0)
            setroi(res.data?.roi ? res.data?.roi : 0)

            setTransactions(res.data.data)
            settotal_page(res.data.total_page)
            setnodata("No data found")
            setIsLoader(false)
        }).catch((err) => {
        });
    }
    const filterbyclient = (id) => {

        setclient_id(id)
        revenue_transactions(page, start_date, end_date, id)

    }
    const filterbydate = () => {


        revenue_transactions(page, start_date, end_date, client_id)

    }
    return (
        <>

            {isLoader && <Loader />}
            <div className="manage-truck-area add-truck-area">

                <Row className=" align-items-center mid-space-area">
                    <Col sm={12} md={7} lg={8} xl={10}>
                        <h3 className="mt-0 mb-2">Revenue Insights</h3>
                        <p>Make informed decisions based on your accurate revenue insights!</p>
                    </Col>
         
                    <Col sm={6} md={5} lg={4} xl={2}>
                        <div className="transaction-top-left roi-top-area">
                            <p>My R.O.I</p>
                            <h4>{roi}</h4>
                        </div>
                    </Col>
                </Row>

                <Row className="align-items-center">
                    <Col md={12} lg={6}>
                        <div className="revenue-summary-left">
                            <h6>My Revenue Summary</h6>
                            <ul>
                                <li>
                                    <p>Gross Revenue All</p>
                                    <h4>Php {total_revenue}</h4>
                                </li>
                                <li className="ptimary-clients">
                                    <p>Net Revenue</p>
                                    <h4>{net_profit}</h4>
                                </li>

                            </ul>
                        </div>
                    </Col>

                    <Col md={12} lg={6} className="mt-4">
                        <div className="revenue-summary-right revenu-content-right-main">
                            <Form.Group
                                className="mb-3 revenue-summary-right-content"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Client name:</Form.Label><br />
                                <Select options={allClients}
                                    onChange={e => filterbyclient(e.value)}
                                />
                            </Form.Group>

                            <Row className="mt-3">
                                <Col md={4}>
                                    <Form.Group
                                        className=" revenue-summary-right-content"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label>From:</Form.Label>
                                        <input className="form-control" placeholder="From Date" id="myLocalDate1" type="date" value={start_date} dateFormat="YYYY-MM-DD" onChange={(e) => setstart_date(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group
                                        className=" revenue-summary-right-content"
                                        controlId="exampleForm.ControlInput2"
                                    >

                                        <Form.Label className="ms-3">To:</Form.Label>

                                        <input className="form-control" id="myLocalDate" type="date" max={moment(end_date).format('YYYY-MM-DD ')} dateFormat="YYYY-MM-DD " onChange={(e) => setend_date(e.target.value)} />

                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Button className="addtruck-btn" variant="unset" type="button" onClick={() => filterbydate()}>Apply</Button>

                                </Col>




                            </Row>
                            {/* <Button onClick={()=>get_transactions(page)}>Filter</Button> */}
                            {/* <Select options={options} /> */}


                        </div>
                    </Col>

                </Row>
                <div className="revenure-list-export-top">
                    <h6 className="revenue-summary-left mt-4">Primary Clients Trips Summary</h6>
                    {transactions && transactions.length >0?
                    <button type="button" variant="unset" className="export-csv" onClick={download_excel}>Export Excel</button>:null}
                </div>

                {!transactions || transactions.length == 0 ?
                    <Nodata message1={nodata} message2={""} /> :
                    <>
                        <div className="one-time-expense">

                            <Table >
                                <thead>
                                    <tr>
                                        <th>Client name</th>
                                        <th>Destination</th>
                                        <th>Date </th>
                                        <th>Plate No </th>
                                        <th>Type </th>
                                        <th>DDR/Trip Ticket </th>
                                        <th>Trip Rate </th>
                                        
                                        <th>Total Expense</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {transactions && transactions.map((trans, index) => {
                                        return (<tr >
                                            
                                            <td > {trans.schedule.primary_client.name}</td>
                                            <td > {trans.schedule.destination}</td>

                                            <td>{moment(trans?.created_at).format('DD MMMM YYYY')}</td>
                                            <td>{trans?.schedule.loaded_truck?.plate_number}</td>
                                            <td>{trans?.schedule.loaded_truck.type.name}</td>
                                            
                                            <td>{trans.schedule.ddr}</td>
                                            <td>{trans.amount}</td>
                                            <td>{trans.schedule.expenses}</td>
                                            

                                            {/* <td className='status'><Button type='button' variant='unset'>{trans.status.toUpperCase()}</Button></td> */}
                                        </tr>)

                                    })}

                                </tbody>
                            </Table>
                        </div>
                    </>}

            </div>
        </>
    );
};
export default RevenueInsights