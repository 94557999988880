import { useState,useContext,useEffect } from "react";
import { Button, Col, Container, Row, Form, Modal } from "react-bootstrap";
const Errorpopup = ({ showErr, handleCloseErr,errmessage1,errmessage2,resend_btn  }) => {
    useEffect(() => {
        if(showErr){
        const close = (e) => {
          console.log(localStorage.getItem("token"),"======token")
          // if(show){
          console.log(e.keyCode,"======keyCode")
          if (e.key === 'Enter') {
           
            handleCloseErr()
          }
        // }
        }
        window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
        }
         
    },[showErr])
    console.log("====showErr",showErr)
    return (
        <>
         <Modal className="success-box" show={showErr} onHide={handleCloseErr} centered>
                <Modal.Header>
                    <Modal.Title>
                        {/* <img src={require("../../assets/images/chemark.png")} alt="img" /> */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>{errmessage1}</h5>
                    
                    {typeof errmessage2=="string"?
                    <p>{errmessage2}</p>:
                    <ul className="checkout_errors">
                          {console.log(errmessage2,"====errmessage2=errmessage2==>>>>>>")}
                    {errmessage2 && errmessage2.map((err,index)=>{
                        {console.log(err,"=====errmessage2==>>>>>>")}
                       return(<li><span>{err.name}</span>{err.message}</li>)

                    })}

                    </ul>}

                </Modal.Body>
                <Modal.Footer>
                  {resend_btn?
                  <Button variant="primary" onClick={handleCloseErr}>
                  Resend Email
              </Button>:

                    <Button variant="primary" onClick={handleCloseErr}>
                        Ok
                    </Button>}
                </Modal.Footer>
            </Modal>
        </>
            );
};
export default Errorpopup; 