import { Col, Form, Row } from "react-bootstrap"
import { useState, useEffect } from "react";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { allowedImageTypes, bUrl, gKey } from "../../../../config/config"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const ct = require("countries-and-timezones");
const Step1 = ({ profileFieldsErrs, setprofileFields, profileFields, onInputChange, logo }) => {
    const [value, setValue] = useState(null);
    const timezone = ct.getTimezone(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    //   const COUNTRY_CODE = timezone.countries[0];
    const COUNTRY_CODE = "ph";

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default behavior (page refresh)
            // Handle your logic here
        }
    };
    const handleKeyDownName = (event) => {
        console.log("=====event====", event.which)
        if (event.which === 32) {
            event.preventDefault(); // Prevent space key action
        }
        if (event.key === 'Enter' || event.which === 13) {
            event.preventDefault(); // Prevent the default behavior (page refresh)
            // Handle your logic here
        }
    };
    useEffect(() => {
        if (value) {
            geocodeByAddress(value.label)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) =>
                    setprofileFields({
                        ...profileFields,
                        business_address: value.label,
                        latitude: lat,
                        longitude: lng,
                    }));
        }
    }, [value]);
    // console.log("=====profileFieldsErrs==step=",profileFieldsErrs)
    return (
        <>
            <div className="overflow-x">
                <div className="stepper-form-area">
                    <Row>
                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control maxLength={30} onKeyDown={handleKeyDownName} type="text" value={profileFields.first_name} placeholder="Enter First Name" onChange={e => onInputChange('first_name', e.target.value)} />
                                    <span className="errors">{profileFieldsErrs.first_name}</span>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control onKeyDown={handleKeyDownName} maxLength={30} type="text" value={profileFields.last_name} placeholder="Enter Last Name" onChange={e => onInputChange('last_name', e.target.value)} />
                                    <span className="errors">{profileFieldsErrs.last_name}</span>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Business Name</Form.Label>
                                    <Form.Control onKeyDown={handleKeyDown} maxLength={30} type="text" value={profileFields.business_name} placeholder="Enter Business Name" onChange={e => onInputChange('business_name', e.target.value)} />
                                    <span className="errors">{profileFieldsErrs.business_name}</span>
                                </Form.Group>
                            </Form>
                        </Col>
                        {/* <Col md={6}>
                    <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Trade Name</Form.Label>
                                <Form.Control onKeyDown={handleKeyDown} maxLength={50} type="text" value={profileFields.trade_name} placeholder="Enter Trade Name"  onChange={e => onInputChange( 'trade_name', e.target.value)}/>
                                <span className="errors">{profileFieldsErrs.trade_name}</span>
                            </Form.Group>
                        </Form>
                    </Col> */}
                        <Col md={6}>
                            <Form>
                                <Form.Group controlId="formFile" className="mb-2">
                                    <Form.Label>Contact No.</Form.Label>
                                    <PhoneInput
                                        country={
                                            COUNTRY_CODE ? COUNTRY_CODE.toLocaleLowerCase() : "ph"
                                        }
                                        className={
                                            //   contactUsFieldsErrors.phone
                                            //     ? "border-red phone-input"
                                            "phone-input "
                                        }
                                        placeholder="Enter contact number"
                                        value={profileFields.phone}
                                        onChange={(e) => {
                                            setprofileFields((prevObj) => {
                                                return {
                                                    ...prevObj,
                                                    phone: e,
                                                };
                                            });

                                            //   if (e.trim() === "") {
                                            //     setContactUsFieldsErrors((prevObj) => {
                                            //       return {
                                            //         ...prevObj,
                                            //         phone: true,
                                            //       };
                                            //     });
                                            //   } else if (e.trim().length < 10) {
                                            //     setContactUsFieldsErrors((prevObj) => {
                                            //       return {
                                            //         ...prevObj,
                                            //         phone: true,
                                            //       };
                                            //     });
                                            //   } else {
                                            //     setContactUsFieldsErrors((prevObj) => {
                                            //       return {
                                            //         ...prevObj,
                                            //         phone: false,
                                            //       };
                                            //     });
                                            //   }
                                        }}
                                    />
                                    <span className="errors">{profileFieldsErrs.phone}</span>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>


                    <Row>

                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Business Address</Form.Label>
                                    <GooglePlacesAutocomplete
                                        apiKey={gKey}
                                        className="form-control"
                                        onFail={error => console.log(error)}
                                        onNotFound={() => console.log('no results')}
                                        selectProps={{
                                            placeholder: "Enter Address",
                                            value,
                                            onChange: setValue,
                                        }}
                                    />
                                    {/* <Form.Control onKeyDown={handleKeyDown} maxLength={50} type="text" value={profileFields.business_address} placeholder="Enter Business Address" onChange={e => onInputChange( 'business_address', e.target.value)}/> */}
                                    <span className="errors">{profileFieldsErrs.business_address}</span>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4  truck-logo-upload-field" controlId="formBasicEmail">
                                    <Form.Label>Upload Business Logo</Form.Label>
                                    <div className="upload-box-area">
                                        <div className="logo-upload-box">
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                            <Form.Control type="text" />
                                            <Form.Control type="file" className="upload-item" onChange={e => onInputChange('business_logo', e.target.files[0])} />
                                        </div>
                                        {/* <i className="fa fa-plus" aria-hidden="true"></i> */}

                                        {logo ?
                                            <div className="upload-image-preview">
                                                <div className="first-preview-image">
                                                    <img src={logo} alt={`Preview logo`} className="image-preview" />
                                                </div>

                                            </div> : null}

                                    </div>
                                </Form.Group>
                                <p className="errors">{profileFieldsErrs.business_logo}</p>
                            </Form>
                            {/* <Row className="align-items-center">
                    <Col md={10}>
                            <Form.Group className="mb-4 upload-area" controlId="formBasicEmail">
                                <Form.Label>Upload Trucking Logo</Form.Label>
                                <Form.Control onKeyDown={handleKeyDown} type="text" placeholder="Upload Trucking Logo" />
                                <Form.Control onKeyDown={handleKeyDown} type="file" className="upload-item"  onChange={e => onInputChange( 'business_logo', e.target.files[0])}/>
                              
                               
                                <i class="fa fa-plus" aria-hidden="true"></i>
                              
                            </Form.Group>
                            </Col>
                            <Col md={2}>
                            {logo?
                            <div className="upload-image-preview">
                                  <div className="first-preview-image">
                                  <img src={logo} alt={`Preview logo`} className="image-preview" />
                                    </div>
                                 
                                </div>:null}
                         
                            </Col>
                        </Row> */}

                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default Step1