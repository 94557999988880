import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CommonLayout from "../components/common/Layout";
import Login from "../components/pages/Login";
import Registration from "../components/pages/Registration";
import ForgotPassword from "../components/pages/ForgotPassword";
import CreatePassword from "../components/pages/CreatePassword";
import TruckerCreateProfile from "../components/pages/trucker/TruckerCreateProfile";
import Dashboard from "../components/pages/trucker/Dashboard";
import ProtectedRoute from "../util/protectedRoute";
import TruckerProfile from "../components/pages/trucker/TruckerProfile";
import WholeseSelerProfile from "../components/pages/wholeseller/Profile";
import WholeSellerCreateProfile from "../components/pages/wholeseller/CreateProfile";
import WholeSellerEditProfile from "../components/pages/wholeseller/EditProfile";
import RetailerProfile from "../components/pages/retailer/Profile";
import RetailerCreateProfile from "../components/pages/retailer/CreateProfile";
import RetailerEditProfile from "../components/pages/retailer/EditProfile";
import AuthHeader from "../components/common/AuthHeader";
import TruckerEditProfile from "../components/pages/trucker/TruckerEditProfile";
import AddTruck from "../components/pages/trucker/Dashboard/AddTruck";
import ComingSoon from "../components/pages/trucker/Dashboard/ComingSoon";
import MainHome from "../components/pages/MainHome";
import PageNotFound from "../components/pages/PageNotFound";
import DashboardW from "../components/pages/wholeseller/DashboardW";
import ProductManagement from "../components/pages/wholeseller/Dashboard/ProductManagement";
import AddNewProduct from "../components/pages/wholeseller/Dashboard/AddNewProduct";
import ProductListing from "../components/pages/retailer/ProductListing";
import ProductDetail from "../components/pages/retailer/ProductDetail";
import {trucker_type,retailer_type,wholeseller_type} from "../config/config";
import Checkout from "../components/pages/retailer/Checkout";
import PrimaryClientManagement from "../components/pages/trucker/Dashboard/PrimaryClientManagement";
import TermCondition from "../components/common/TermCondition";
import PrivacyPolicy from "../components/common/PrivacyPolicy";
import OrderManagement from "../components/pages/wholeseller/Dashboard/OrderManagement";
import TransactionManagement from "../components/pages/wholeseller/Dashboard/TransactionManagement";
import TransactionManagementRetailer from "../components/pages/retailer/transactionMangement";
import Notification from "../components/pages/wholeseller/Notification";
import OrderHistory from "../components/pages/retailer/OrderHistory";
import OrderHistory1 from "../components/pages/retailer/orderManagement";
import FindLoads from "../components/pages/trucker/Dashboard/FindLoads";
import AddLoads from "../components/pages/trucker/Dashboard/AddLoads";
import OrderDetail from "../components/common/OrderDetail";
import RevenueInsights from "../components/pages/trucker/Dashboard/RevenueInsights"
import TruckerTransactionManagement from "../components/pages/trucker/Dashboard/TruckerTransactionManagement";
import Faq from "../components/common/Faq";

const Routing = () => {
    return (
        <>
            <Router>
                    <Routes>
                    <Route path="/register" element={<CommonLayout>{<Registration />}</CommonLayout>} />
                    <Route path="login" element={<CommonLayout>{<Login />}</CommonLayout>} />
                    <Route path="forgot-password" element={<CommonLayout>{<ForgotPassword />}</CommonLayout>} />
                    <Route path="create-password/:address" element={<CommonLayout>{<CreatePassword />}</CommonLayout>} />
                    {/* <Route path="trucker-create-profile" element={<CommonLayout>{ <ProtectedRoute><TruckerCreateProfile/></ProtectedRoute>}</CommonLayout>} /> */}
                    {/* <Route path="dashboard" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><Dashboard/></ProtectedRoute>}</CommonLayout>} /> */}
                    {localStorage.getItem("user_type") === trucker_type ?
                    <>
                    <Route path="transaction-management" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><TruckerTransactionManagement/></ProtectedRoute>}</CommonLayout>} />
                    <Route path="addtruck" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><AddTruck/></ProtectedRoute>}</CommonLayout>} />
                    <Route path="update_truck/:id" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><AddTruck/></ProtectedRoute>}</CommonLayout>} />
                    </>
                    :null}
                    {/* <Route path="trucker-profile" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><TruckerProfile/></ProtectedRoute>}</CommonLayout>} /> */}
                    {/* <Route path="trucker-edit-profile" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><TruckerEditProfile/></ProtectedRoute>}</CommonLayout>} /> */}
                    <Route path="coming-soon" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><ComingSoon/></ProtectedRoute>}</CommonLayout>} />
                    {/* <Route path="wholeseller-profile" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><WholeseSelerProfile/></ProtectedRoute>}</CommonLayout>} />
                    <Route path="wholeseller-edit-profile" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><WholeSellerEditProfile/></ProtectedRoute>}</CommonLayout>} />
                    <Route path="wholeseller-create-profile" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><WholeSellerCreateProfile/></ProtectedRoute>}</CommonLayout>} /> */}

                    <Route path="profile" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute>
                        {localStorage.getItem("user_type") === retailer_type ?
                        
                        <RetailerProfile/>:
                        <>
                         {localStorage.getItem("user_type") === wholeseller_type?
                         <WholeseSelerProfile/>:
                         <>
                          {localStorage.getItem("user_type") === trucker_type?
                         <TruckerProfile/>:<></>}

                         </>}
                        </>}
                        
                        </ProtectedRoute>}</CommonLayout>} />

                        <Route path="edit_profile" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute>
                        {localStorage.getItem("user_type") === retailer_type ?
                        <RetailerEditProfile/>:
                        <>
                         {localStorage.getItem("user_type") === wholeseller_type ?
                         <WholeSellerEditProfile/>:
                         <TruckerEditProfile/>}
                        </>}
                        
                        
                        </ProtectedRoute>}</CommonLayout>} />

                        <Route path="create_profile" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute>
                        {localStorage.getItem("user_type") === retailer_type ?
                        <RetailerCreateProfile/>:
                        <>
                         {localStorage.getItem("user_type") === wholeseller_type ?
                         <WholeSellerCreateProfile/>:
                         <TruckerCreateProfile/>}
                         </>}
                        
                        </ProtectedRoute>}</CommonLayout>} />
                        {localStorage.getItem("user_type") !== retailer_type ?
                        <Route path="dashboard" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute>
                        {localStorage.getItem("user_type") === trucker_type ?
                        <Dashboard/>:
                        <>
                         {localStorage.getItem("user_type") === wholeseller_type ?
                         <DashboardW/>:
                         null}
                         </>}
                        
                        </ProtectedRoute>}</CommonLayout>} />
                        :null}
                    {/* <Route path="retailer-edit-profile" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><RetailerEditProfile/></ProtectedRoute>}</CommonLayout>} />
                    <Route path="retailer-create-profile" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><RetailerCreateProfile/></ProtectedRoute>}</CommonLayout>} /> */}
                    <Route path="/" element={<CommonLayout headerClass="orange-btns" >{<MainHome/>}</CommonLayout>} />
                    <Route path="*" element={<PageNotFound />} />
                    {/* <Route path="/not_found" element={<PageNotFound />} /> */}
                    {/* <Route path="w-dashboard" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><DashboardW/></ProtectedRoute>}</CommonLayout>} /> */}
                    {localStorage.getItem("user_type") === wholeseller_type ?
                    <>

                     <Route path="transaction-management" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><TransactionManagement/></ProtectedRoute>}</CommonLayout>} />
                    <Route path="product-management" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><ProductManagement /></ProtectedRoute>}</CommonLayout>} />
                    <Route path="add-new-product" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><AddNewProduct/></ProtectedRoute>}</CommonLayout>} />
                    </>:null}
                   
                    {localStorage.getItem("user_type") === retailer_type ?
                    <>
                    {console.log("======rrrrrrr====")}
                    <Route path="transaction-management" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><TransactionManagementRetailer/></ProtectedRoute>}</CommonLayout>} />
                    <Route path="product-listing" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><ProductListing/></ProtectedRoute>}</CommonLayout>} />
                    <Route path="checkout" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><Checkout/></ProtectedRoute>}</CommonLayout>} />
                     <Route path="product-detail/:id" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><ProductDetail/></ProtectedRoute>}</CommonLayout>} />
                    </>
                    :null}
                    <Route path="order-detail/:id" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><OrderDetail/></ProtectedRoute>}</CommonLayout>} />
                    <Route path="primary-client-management" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><PrimaryClientManagement /></ProtectedRoute>}</CommonLayout>} />
                    <Route path="term-condition"  element={<CommonLayout headerClass="orange-btns" header={<AuthHeader />}>{<TermCondition/>}</CommonLayout>} />
                    <Route path="privacy-policy" element={<CommonLayout   headerClass="orange-btns" header={<AuthHeader />}>{<PrivacyPolicy />}</CommonLayout>} />
                    <Route path="order-management" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><OrderManagement/></ProtectedRoute>}</CommonLayout>} />

                    <Route path="notifications" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><Notification /></ProtectedRoute>}</CommonLayout>} />

                    {/* <Route path="order-history" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><OrderHistory/></ProtectedRoute>}</CommonLayout>} /> */}
                    <Route path="order-history" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><OrderHistory1/></ProtectedRoute>}</CommonLayout>} />
                    <Route path="find-loads" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><FindLoads/></ProtectedRoute>}</CommonLayout>} />
                    <Route path="loads-management" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><FindLoads/></ProtectedRoute>}</CommonLayout>} />
                    <Route path="add-loads" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><AddLoads /></ProtectedRoute>}</CommonLayout>} />
                    <Route path="edit-loads/:id" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><AddLoads /></ProtectedRoute>}</CommonLayout>} />
                    <Route path="order-detail" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><OrderDetail /></ProtectedRoute>}</CommonLayout>} />
                    <Route path="revenue-insights" element={<CommonLayout header={<AuthHeader />}>{<ProtectedRoute><RevenueInsights/></ProtectedRoute>}</CommonLayout>} />
                    <Route path="faq" element={<CommonLayout headerClass="orange-btns" header={<AuthHeader />}>{<Faq />}</CommonLayout>} />
                </Routes>
            </Router>
        </>
    )
}

export default Routing