import { EMAILREGEX} from "../config/config";
export const validateService = {
    register,
    login,
    passwordvalidate,
    resetpasswordvalidate,
    validateEmail,
    validateTruckerstep1,
    validaeTruckerstep2,
    validateTruckerstep3,
    forgotpasswordvalidate,
    validateRetailerstep1,
    validatewholesellerstep1,
    validateProduct,
    addressValidation,
    validateClient,
    loadValidation





}
function register(setregisterFieldsErrs,registerFields){
    let formValidated = true;
       
    if (registerFields.user_type === "") {
    
        formValidated = false;
        setregisterFieldsErrs((obj) => {
          return {
            ...obj,
            user_type: true,
            user_typeErr:"Please select a user type"
          };
        });
      }else if (registerFields.email.trim() === "") {
    
      formValidated = false;
      setregisterFieldsErrs((obj) => {
        return {
          ...obj,
          email: true,
          emailErr:"Please enter email address",
          user_typeErr:""
        };
      });
    } else if (!EMAILREGEX.test(registerFields.email)) {
        console.log(EMAILREGEX.test(registerFields.email),"valid email field")
      formValidated = false;
      setregisterFieldsErrs((obj) => {
        return {
          ...obj,
          emailErr:"Enter a valid email address",
         
        };
      });
    }else if (registerFields.password.trim() === "") {
      formValidated = false;
      setregisterFieldsErrs((obj) => {
        return {
          ...obj,
          password: true,
          emailErr:"",
          passwordErr:"Enter your password"
        };
      });
    }else if (registerFields.cpassword.trim() === "") {
        formValidated = false;
        setregisterFieldsErrs((obj) => {
          return {
            ...obj,
            password: true,
            emailErr:"",
            passwordErr:"",
            cpasswordErr:"Enter your confirm password"
          };
        });
      }else if (registerFields.cpassword.trim() !== registerFields.password.trim()) {
        formValidated = false;
        setregisterFieldsErrs((obj) => {
          return {
            ...obj,
            password: true,
            emailErr:"",
            passwordErr:"",
            cpasswordErr:"Password and confirm password doesn't match"
          };
        });
      }else if (!registerFields.terms_and_conditions) {
        formValidated = false;
        setregisterFieldsErrs((obj) => {
          return {
            ...obj,
            terms_and_conditions: true,
            emailErr:"",
            passwordErr:"",
            cpasswordErr:"",
            terms_and_conditionsErr:"Please select Terms and conditions."
          };
        });
      }
      return formValidated
}

function login(setloginFieldsErrs,loginFields){
       let formValidated = true;
       if (loginFields.email.trim() === "") {
           formValidated = false;
           setloginFieldsErrs((obj) => {
                return {
                ...obj,
                email: true,
                emailErr:"Please enter email address",
                user_typeErr:""
                };
            });
    } else if (!EMAILREGEX.test(loginFields.email)) {
        console.log(EMAILREGEX.test(loginFields.email),"valid email field")
        formValidated = false;
        setloginFieldsErrs((obj) => {
            return {
            ...obj,
            emailErr:"Enter a valid email address",
            
            };
        });
    }else if (loginFields.password.trim() === "") {
      formValidated = false;
      setloginFieldsErrs((obj) => {
        return {
          ...obj,
          password: true,
          emailErr:"",
          passwordErr:"Enter your password"
        };
      });
    }
  return formValidated
}

function passwordvalidate(setloginFieldsErrs,loginFields){
    let formValidated = true;
    if (loginFields.old_password.trim() === "") {
        formValidated = false;
        setloginFieldsErrs((obj) => {
          return {
            ...obj,
            password: true,
           passwordErr:"Enter your password"
          };
        });
      }else  if (loginFields.password.trim() === "") {
        formValidated = false;
        setloginFieldsErrs((obj) => {
          return {
            ...obj,
            password: true,
           passwordErr:"Enter your password"
          };
        });
      }else if (loginFields.cpassword.trim() === "") {
          formValidated = false;
          setloginFieldsErrs((obj) => {
            return {
              ...obj,
              password: true,
              emailErr:"",
              passwordErr:"",
              cpasswordErr:"Enter your confirm password"
            };
          });
        }else if (loginFields.cpassword.trim() !== loginFields.password.trim()) {
            formValidated = false;
            setloginFieldsErrs((obj) => {
              return {
                ...obj,
                password: true,
                emailErr:"",
                passwordErr:"",
                cpasswordErr:"Password and confirm password doesn't match"
              };
            });
        }
        return formValidated

}
function resetpasswordvalidate(setpasswordFieldsErr,passwordFields){
    let formValidated = true;
     if (passwordFields.old_password.trim() === "") {
        formValidated = false;
        setpasswordFieldsErr((obj) => {
          return {
            ...obj,
            oldpassword:"Enter your current password"
          };
        });
      }else if (passwordFields.new_password.trim() === "") {
          formValidated = false;
          setpasswordFieldsErr((obj) => {
            return {
              ...obj,
              oldpassword:"",
              password:"Enter your  password"
            };
          });
        }else if (passwordFields.cpassword.trim() === "") {
          formValidated = false;
          setpasswordFieldsErr((obj) => {
            return {
              ...obj,
               password:"",
              oldpassword:"",
              cpassword:"Enter your confirm password"
            };
          });
        }else if (passwordFields.cpassword.trim() !== passwordFields.new_password.trim()) {
            formValidated = false;
            setpasswordFieldsErr((obj) => {
              return {
                ...obj,
                password: true,
                emailErr:"",
                password:"",
                cpassword:"Password and confirm password doesn't match"
              };
            });
        }else  {
          formValidated = true;
          setpasswordFieldsErr((obj) => {
            return {
              ...obj,
             oldpassword:"",
              password:"",
              cpassword:""
            };
          });
      }
        return formValidated

}
function forgotpasswordvalidate(setpasswordFieldsErr,passwordFields){
  let formValidated = true;
  if (passwordFields.password.trim() === "") {
        formValidated = false;
        setpasswordFieldsErr((obj) => {
          return {
            ...obj,
           
            password:"Enter your  password"
          };
        });
      }else if (passwordFields.cpassword.trim() === "") {
        formValidated = false;
        setpasswordFieldsErr((obj) => {
          return {
            ...obj,
             password:"",
            
            cpassword:"Enter your confirm password"
          };
        });
      }else if (passwordFields.cpassword.trim() !== passwordFields.password.trim()) {
          formValidated = false;
          setpasswordFieldsErr((obj) => {
            return {
              ...obj,
             
              password:"",
              cpassword:"Password and confirm password doesn't match"
            };
          });
      }else  {
        formValidated = true;
        setpasswordFieldsErr((obj) => {
          return {
            ...obj,
        
            password:"",
            cpassword:""
          };
        });
    }
      return formValidated

}
function validateEmail(email,setEmailErr){
  let formValidated = true
  if (email.trim() === "") {
    
    formValidated = false;
    setEmailErr("Please enter your email address.")
    
  } else if (!EMAILREGEX.test(email)) {
      console.log(EMAILREGEX.test(email),"valid email field")
    formValidated = false;
    setEmailErr("Enter a valid email address.")
    
  }
  return formValidated
}

function validateTruckerstep1(setprofileFieldsErrs,profileFields){
  console.log("========profileFields====",profileFields)
  let formValidated = true
  if (profileFields.first_name.trim() === "") {
     formValidated = false;
     setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name:"Enter your first name"
      };
    });
  } else if (profileFields.last_name.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"Enter your last name"
      };
    });
  }else if (profileFields.business_name.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"Enter business name"
      };
    });
  }else if (profileFields.ltfrb_number.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        ltfrb_number:"Enter ltfrb number"
      };
    });
  }else if (profileFields.marine_insurance.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        ltfrb_number:"",
        marine_insurance:"Enter marine insurance"
      };
    });
  }else if (profileFields.dti_sec_number.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        ltfrb_number:"",
        marine_insurance:"",
        dti_sec_number:"Enter dti/sec number "
      };
    });
  }
  else if (profileFields.permit_number.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        ltfrb_number:"",
        marine_insurance:"",
        dti_sec_number:"",
        permit_number:"Enter permit number "
      };
    });
  } else if (profileFields.phone ==null ) {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        ltfrb_number:"",
        marine_insurance:"",
        permit_number:" ",
        phone:"Enter phone number "
      };
    });
  }
  else if (profileFields.phone.trim() === "" || profileFields.phone.length<=3) {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        ltfrb_number:"",
        marine_insurance:"",
        permit_number:" ",
        phone:"Enter phone number "
      };
    });
  }
  else if (profileFields.business_address.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        ltfrb_number:"",
        marine_insurance:"",
        permit_number:" ",
        phone:" ",
        business_address:"Enter business address "
      };
    });
  }else if (  profileFields.investment === 0 || profileFields.investment == null) {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        ltfrb_number:"",
        marine_insurance:"",
        permit_number:" ",
        business_address:"",
        investment:"Enter your capital investment."
      };
    });
  }
  else if (profileFields.business_logo === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        ltfrb_number:"",
        marine_insurance:"",
        permit_number:" ",
        business_address:"",
        investment:"",
        business_logo:"Enter business logo"
      };
    });
  }
  else if (profileFields.govt_id === "" ) {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        ltfrb_number:"",
        marine_insurance:"",
        permit_number:" ",
        business_address:"",
        investment:"",
        business_logo:"",
        govt_id:"Enter government Id"
      };
    });
  }
  else{
    formValidated = true;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        ltfrb_number:"",
        marine_insurance:"",
        permit_number:" ",
        business_address:"",
        business_logo:""
      };
    });
  }
  return formValidated
}

function validaeTruckerstep2(setbankFieldsErrs,bankFields){
  let formValidated = true
  if (bankFields.account_holder_name.trim() === "") {
    formValidated = false;
    setbankFieldsErrs((obj) => {
     return {
       ...obj,
       account_holder_name:"Enter account holder name"
     };
   });
 } else if (bankFields.bank_id === "") {
  formValidated = false;
  setbankFieldsErrs((obj) => {
     return {
       ...obj,
       account_holder_name: "",
       bank_name:"Enter bank name"
     };
   });
 }else if (bankFields.account_number.trim() === "") {
  formValidated = false;
  setbankFieldsErrs((obj) => {
     return {
       ...obj,
       account_holder_name: "",
       bank_name:"",
       account_number:"Enter account number"
     };
   });
}
else {
  formValidated = true;
  setbankFieldsErrs((obj) => {
     return {
       ...obj,
       account_holder_name: "",
       bank_name:"",
       account_number:"",
       routing_number:""
     };
   });
}
return formValidated
}

function validateTruckerstep3(settruckFieldsErr,truckFields,images){
  // console.log("======truckFields====",truckFields.driver_name.trim() === "")
  let formValidated = true
  if (truckFields.type === "") {
    formValidated = false;
    settruckFieldsErr((obj) => {
     return {
       ...obj,
       type:"Select  truck type"
     };
   });
 } else if (truckFields.plate_number.trim() === "") {
  formValidated = false;
  settruckFieldsErr((obj) => {
     return {
       ...obj,
       type: "",
       plate_number:"Enter your plate number"
     };
   });
 }
 else if (truckFields.model_year.trim() === "") {
  formValidated = false;
  settruckFieldsErr((obj) => {
    return {
      ...obj,
      type: "",
      plat_number:"",
      model_year:"Enter model year"
    };
  });
}
else if (truckFields.capacity === "") {
  formValidated = false;
  settruckFieldsErr((obj) => {
    return {
      ...obj,
      type: "",
      plat_number:"",
      model_year:"",
      capacity:"Enter total capacity",
    };
  });
}
else if (truckFields.length === "") {
  formValidated = false;
  settruckFieldsErr((obj) => {
    return {
      ...obj,
      type: "",
      plat_number:"",
      model_year:"",
      capacity:"",
      length:"Enter length"
    };
  });
}
else if (truckFields.width === "") {
  formValidated = false;
  settruckFieldsErr((obj) => {
    return {
      ...obj,
      type: "",
      plat_number:"",
      model_year:"",
      capacity:"",
      length:"",
      width:"Enter width"
    };
  });
}

else if (truckFields.height === "") {
  formValidated = false;
  settruckFieldsErr((obj) => {
    return {
      ...obj,
      type: "",
      plat_number:"",
      model_year:"",
      capacity:"",
      length:"",
      width:"",
      height:" Enter height"
    };
  });
}
else if (truckFields.make.trim() === "") {
  formValidated = false;
  settruckFieldsErr((obj) => {
    return {
      ...obj,
      type: "",
      plat_number:"",
      model_year:"",
      capacity:"",
      length:"",
      width:"",
      height:"",
      color:"",
      make:"Enter make",
    };
  });
}
else if (truckFields.color.trim() === "") {
  formValidated = false;
  settruckFieldsErr((obj) => {
    return {
      ...obj,
      type: "",
      plat_number:"",
      model_year:"",
      capacity:"",
      length:"",
      width:"",
      height:"",
      make:"",
      color:"Enter color",
    };
  });
}

else if (truckFields.driver_name.trim() === "") {
  formValidated = false;
  settruckFieldsErr((obj) => {
    return {
      ...obj,
      type: "",
      plat_number:"",
      model_year:"",
      capacity:"",
      length:"",
      width:"",
      height:"",
      color:"",
      driver_name:"Enter driver",
    };
  });
  
}else if (images.length === 0) {
  formValidated = false;
  settruckFieldsErr((obj) => {
    return {
      ...obj,
      type: "",
      plat_number:"",
      model_year:"",
      capacity:"",
      length:"",
      width:"",
      height:"",
      color:"",
      make:"",
      driver_name:"",
      images:"Select atleast one image"
    };
  });
}
else{
  formValidated = true;
  settruckFieldsErr((obj) => {
    return {
      ...obj,
      type: "",
      plat_number:"",
      model_year:"",
      capacity:"",
      length:"",
      width:"",
      height:"",
      color:"",
      driver_name:"",
      make:"",
      images:""
    };
  });

}
return formValidated



}


function validatewholesellerstep1(setprofileFieldsErrs,profileFields,trade){
  console.log("========profileFields====",profileFields)
  let formValidated = true
  if (profileFields.first_name.trim() === "") {
     formValidated = false;
     setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name:"Enter your first name"
      };
    });
  } else if (profileFields.last_name.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"Enter your last name"
      };
    });
  }else if (profileFields.business_name.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"Enter business name"
      };
    });
  }else if (profileFields.trade_name.trim() === "" && trade != "trade") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"Enter "+trade+" name"
      };
    });
  } else if (profileFields.phone ==null ) {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        phone:"Enter phone number "
      };
    });
  }else if ( profileFields.phone.trim() === "" || profileFields.phone.length<=3) {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        phone:"Enter phone number "
      };
    });
  }
  else if (profileFields.business_address.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        phone:"",
        business_address:"Enter business address",
       
      };
    });
  }
  else if (profileFields.business_logo === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        business_address:"",
        business_logo:"Select business logo."
      };
    });
  }else{
    formValidated = true;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
     first_name: "",
    last_name: "",
    business_name:"",
    trade_name: "",
    business_address: "",
    business_logo: "",
      };
    });
  }
  return formValidated
}


function validateRetailerstep1(setprofileFieldsErrs,profileFields,trade){
  console.log("========profileFields====",profileFields)
  let formValidated = true
  if (profileFields.first_name.trim() === "") {
     formValidated = false;
     setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name:"Enter your first name"
      };
    });
  } else if (profileFields.last_name.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"Enter your last name"
      };
    });
  }else if (profileFields.business_name.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"Enter business name"
      };
    });
  } else if (profileFields.phone ==null ) {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        phone:"Enter phone number "
      };
    });
  }else if ( profileFields.phone.trim() === "" || profileFields.phone.length<=3) {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        phone:"Enter phone number "
      };
    });
  }
  else if (profileFields.business_address.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        business_name:"",
        trade_name:"",
        phone:"",
        business_address:"Enter business address",
       
      };
    });
  }
else{
    formValidated = true;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
     first_name: "",
    last_name: "",
    business_name:"",
    trade_name: "",
    business_address: "",
    business_logo: "",
      };
    });
  }
  return formValidated
}

function validateProduct(setproductFieldsErr,productFields,images){
  console.log("========profileFields====",productFields)
 

  let formValidated = true
  if (images.length === 0) {
    formValidated = false;
    setproductFieldsErr((obj) => {
     return {
       ...obj,
       images:"Please select atleast one image."
     };
   });

  }else if (productFields.name.trim() === "") {
     formValidated = false;
     setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        name:"Enter your brand name"
      };
    });
  } else if (productFields.category === "") {
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        name: "",
        category:"Select category"
      };
    });
  }else if (productFields.subcategory === "") {
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        name: "",
        category:"",
        subcategory:"Select subcategory"
      };
    });
  }  else if (productFields.units === "") {
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
       category:"",
        subcategory:"",
        min_order:"",
        units:"Enter your unit e.g. Box,Meter.."
      };
    });
  
  }else if (productFields.price === "" || productFields.price === 0) {
  
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        category:"",
        subcategory:"",
        min_order:"",
        units:"",
        price:"Price can't be empty or 0",
      };
    });
  
  }else if (productFields.min_order === "" || productFields.min_order==0) {
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        category:"",
        subcategory:"",
        min_order:"Min order can't be 0 or empty"
      };
    });
  
  
  }
  // else if (productFields.add_stock === "") {
  //   formValidated = false;
  //   setproductFieldsErr((obj) => {
  //     return {
  //       ...obj,
  //       images:"",
  //       category:"",
  //       subcategory:"",
  //       min_order:"",
  //       units:"",
  //       price:"",
  //       current_stock:"",
  //       add_stock:"Add stock Can't be 0",
  //     };
  //   });
  
  // } else if (productFields.deduct_stock === "") {
  //   formValidated = false;
  //   setproductFieldsErr((obj) => {
  //     return {
  //       ...obj,
  //       images:"",
  //       name: "",
  //       category:"",
  //       subcategory:"",
  //       min_order:"",
  //       units:"",
  //       price:"",
  //       current_stock:"",
  //       add_stock:"",
  //       deduct_stock:"Deduct stock Can't be 0",
  //     };
  //   });
  
  
  // } 
   else if (productFields.weight === "" || productFields.weight==0) {
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        name: "",
        category:"",
        subcategory:"",
        min_order:"",
        units:"",
        price:"",
        current_stock:"",
        add_stock:"",
        weight:"Weight can't be 0 or empty"
      };
    });
  }else if (productFields.height === "" || productFields.height==0 ) {
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        name: "",
        category:"",
        subcategory:"",
        min_order:"",
        units:"",
        price:"",
        current_stock:"",
        add_stock:"",
        weight:"",
        height:"Height can't be 0 or empty"
      };
    });
  }else if (productFields.width === "" || productFields.width ==0) {
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        name: "",
        category:"",
        subcategory:"",
        min_order:"",
        units:"",
        price:"",
        current_stock:"",
        add_stock:"",
        weight:"",
        height:"",
        width:"Width can't be  0 or empty"
      };
    });
  
   }else if (productFields.length === "" || productFields.length==0) {
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        name: "",
        category:"",
        subcategory:"",
        min_order:"",
        units:"",
        price:"",
       
       
        weight:"",
        height:"",
        width:"",
        length:"Length can't be  0 or empty"
      };
    });
  
   }else if (productFields.current_stock === "") {
  
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        category:"",
        subcategory:"",
        min_order:"",
        units:"",
        price:"",
        weight:"",
        height:"",
        width:"",
        length:"",
        current_stock:"Current stock can't be 0 or empty"
      };
    });
  }else if (parseInt(productFields.current_stock) < parseInt(productFields.min_order)) {
  
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        category:"",
        subcategory:"",
        min_order:"Min order should less than current stock",
        units:"",
        price:"",
        weight:"",
        height:"",
        width:"",
        length:"",
        current_stock:""
      };
    });
  }
  else{
    formValidated = true;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        name: "",
        category: "",
        subcategory: "",
        weight: "",
        weight_unit: "",
        length: "",
        width: "",
        height: "",
        units: "",
        min_order: "",
        current_stock: "",
        add_stock: "",
        deduct_stock: "",
        price: ""
      };
    });
  }
  return formValidated
}



function addressValidation(setaddressFieldsErr,addressFields){

  let formValidated = true
  if (addressFields.first_name.trim() === "") {
     formValidated = false;
     setaddressFieldsErr((obj) => {
      return {
        ...obj,
        first_name:"Enter your first name"
      };
    });
  } else if (addressFields.last_name.trim() === "") {
    formValidated = false;
    setaddressFieldsErr((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"Enter your last name"
      };
    });
  }else if (addressFields.address.trim() === "") {
    formValidated = false;
    setaddressFieldsErr((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        address:"Enter address"
      };
    });
  }else if (addressFields.city.trim() === "") {
    formValidated = false;
    setaddressFieldsErr((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        address:"",
        city:"Enter city name"
      };
    });
  }else if (addressFields.state.trim() === "") {
    formValidated = false;
    setaddressFieldsErr((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        address:"",
        city:"",
        state:"Enter state name"
      };
    });
  }else if (addressFields.country.trim() === "") {
    formValidated = false;
    setaddressFieldsErr((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        address:"",
        city:"",
        state:"",
        country:"Enter country name"
      };
    });
  }
  // else if (addressFields.pincode.trim() === "") {
  //   formValidated = false;
  //   setaddressFieldsErr((obj) => {
  //     return {
  //       ...obj,
  //       first_name: "",
  //       last_name:"",
  //       address:"",
  //       city:"",
  //       state:"",
  //       country:" ",
  //       pincode:"Enter postal code"
  //     };
  //   });
  // }
    
       
  
 
  else{
    formValidated = true;
    setaddressFieldsErr((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        address:"",
        city:"",
        state:"",
        country:" ",
        pincode:""
      };
    });
  }
  return formValidated
}


function validateClient(setclientFieldErr,clientField){
  let formValidated = true

  if (clientField.name.trim() === "") {
    formValidated = false;
    setclientFieldErr((obj) => {
     return {
       ...obj,
       name:"Enter name"
     };
   });
 }else if (clientField.address=== ""){
  formValidated = false;
    setclientFieldErr((obj) => {
     return {
       ...obj,
       name:"",
       address:"Enter address"
     };
   });
 }else{
  formValidated = true;
  setclientFieldErr((obj) => {
   return {
     ...obj,
     name:"",
     address:""
   };
 });

 }
  
  return formValidated
}




function loadValidation(setloadFieldsErr,loadFields){
  console.log(";;;;;;;;;;;======",loadFields)

  let formValidated = true
  if (loadFields.primary_client === "") {
     formValidated = false;
     setloadFieldsErr((obj) => {
      return {
        ...obj,
        primary_client:"Select primary client"
      };
    });
  } else if (loadFields.destination.trim() === "") {
    formValidated = false;
    setloadFieldsErr((obj) => {
      return {
        ...obj,
        primary_client: "",
        destination:"Select destination"
      };
    });
  }else if (loadFields.pickup_time === "" || loadFields.pickup_time==null) {
    formValidated = false;
    setloadFieldsErr((obj) => {
      return {
        ...obj,
        primary_client: "",
        destination:"",
        pickup_time:"Enter pickup time"
      };
    });
  }else if (loadFields.delivery_charge === "") {
    formValidated = false;
    setloadFieldsErr((obj) => {
      return {
        ...obj,
        primary_client: "",
        destination:"",
        pickup_time:"",
        delivery_charge:"Enter delivery Fee"
      };
    });
  }else if (loadFields.truck === "") {
    formValidated = false;
    setloadFieldsErr((obj) => {
      return {
        ...obj,
        primary_client: "",
        destination:"",
        pickup_time:"",
        delivery_charge:"",
        truck:"Enter truck id"
      };
    });
  }else if (loadFields.ddr === "") {
    formValidated = false;
     setloadFieldsErr((obj) => {
      return {
        ...obj,
        primary_client: "",
        destination: "",
        pickup_time: "",
        delivery_charge: "",
        truck: "",
        ddr: "Enter your DDR/Trip Ticket No."
      };
    });
  }else  {
    formValidated = true;
    setloadFieldsErr((obj) => {
      return {
        ...obj,
        primary_client: "",
        destination:"",
        pickup_time:"",
        delivery_charge:"",
        truck:"",
        ddr:""
      };
    });
  }
  return formValidated
}
