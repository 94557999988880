
import { Button, Col, Container, Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import React, { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Home from "./Dashboard/Home";
import ComingSoon from "./Dashboard/ComingSoon";
import ProductManagement from "./Dashboard/ProductManagement";
import OrderManagement from "./Dashboard/OrderManagement";
import TransactionManagement from "./Dashboard/TransactionManagement";


const DashboardW = (props) => {
  
  
    const history = useNavigate()
    const location = useLocation()
    let [searchParams, setSearchParams] = useSearchParams();
    const [key, setKey] = useState('dashboard');
    const [trucksSection, setTrucksSection] = useState('view');
    const [swiper, setSwiper] = useState()
    const [truck_id, settruckId] = useState(0);
    const [swiperActiveIndex, setSwiperActiveIndex] = useState(0)

    const tabsData = [
        { label: "Dashboard", image: "image1.png", targetId: "dashboard", component: <>{key=="dashboard" || searchParams=="dashboard"?<Home setKey={setKey}/>:null }</>},
        { label: "Order Management", image: "image3.png", targetId: "order_management", component:<>{key=="order_management" || searchParams=="order_management"?<OrderManagement />:null} </>},
        { label: "Product Management", image: "image2.png", targetId: "product_management", component:<>{key=="product_management" || searchParams=="product_management"? <ProductManagement />:null} </> },
        { label: "Transaction Management", image: "image4.png", targetId: "transaction_management", component:<>{key=="transaction_management" || searchParams=="transaction_management"? <TransactionManagement/>:null} </> },
       
    ]

    const tabs = tabsData.map(tab => tab.targetId)

    useEffect(() => {
        const tempTab = searchParams.get("tab")
        console.log("======tempTab",tempTab)
        if (tabs.includes(tempTab)) {
            setKey(tempTab)
        } else {
            setSearchParams({ "tab": "dashboard" })
            setKey("dashboard")
        }
       
    }, [])

    function changeTab(value) {
        history({
            pathname: location.pathname,
            search: "?tab=" + value
        })
        setKey(value)
        let slideIndex = tabs.indexOf(value) - 1
        swiper.slideTo(slideIndex)
        setSwiperActiveIndex(slideIndex)
    }

    return (
        <>
            <section className="dashboard-area">
                <Container>
                    <div className="dashboard-tab-area">
                        <div className=" dashboard-top-tab-area">
                            <Swiper
                                slidesPerView={4}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiperObj) => {
                                    setSwiper(swiperObj)
                                }}
                                breakpoints={{
                                    1600: {
                                        slidesPerView: 4,
                                        spaceBetween: 50,
                                    },
                                    1400: {
                                        slidesPerView: 4,
                                        spaceBetween: 50,
                                    },
                                    1200: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },
                                    1199: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                      },
                                    1024: {
                                      slidesPerView: 3,
                                      spaceBetween: 50,
                                    },
                                    991: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },
                                    850: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                      },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 40,
                                      },
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    575: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    480: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    380: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                      },
                                  }}
                                modules={[Navigation]}
                            >
                                {
                                    tabsData.map((tab, tabIndex) => (
                                        <SwiperSlide>
                                            <div onClick={() => changeTab(tab.targetId)} className={`${key === tab.targetId ? 'active' : ''}`} key={tabIndex} id={`parent-${tab.targetId}`}>
                                                <button id={`tab-${tabIndex}`}>
                                                <img src={`/images/${tab.image}`} alt="img" />
                                                    {tab.label}</button>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                            <button disabled={swiperActiveIndex == 0} className="tab-left-arrow-btn" onClick={() => swiper.slidePrev()}><i class="fa fa-angle-left" aria-hidden="true"></i></button>
                            <button disabled={swiperActiveIndex > 3} className="tab-right-arrow-btn" onClick={() => swiper.slideNext()}><i class="fa fa-angle-right" aria-hidden="true"></i></button>
                        </div>

                        <div className="tab-content" id="myTabContent">
                            {
                                tabsData.map((tab, tabIndex) => (
                                    <div className={`${key === tab.targetId ? '' : 'd-none'}`} id={`${tab.targetId}`} role="tabpanel" aria-labelledby={`${tab.targetId}`} key={tabIndex}>
                                        {tab.component}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};
export default DashboardW;