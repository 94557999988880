import { Col, Container, Row, Form, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { apiService } from "../../../services/api.services";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import Nodata from "./Nodata";
import { useState, useEffect } from "react";
import { bUrl } from "../../../config/config"
import { useNavigate } from "react-router-dom";
const ProductListing = () => {
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState(false)
    const [catlist, setcatlist] = useState([])
    const [selected_cat, setselected_cat] = useState("")
    const [selected_subcat, setselected_subcat] = useState("")
    const [filter, setfilter] = useState("")
    const [search, setsearch] = useState("")
    const [searchErr, setsearchErr] = useState("")
    const [productList, setproductList] = useState()
    const [total_count, settotal_count] = useState()
    const [total_page, settotal_page] = useState()
    const [page, setpage] = useState(1)
    const [subcatlist, setsubcatlist] = useState([])
    const resetcat = () => {

        setselected_cat("")
        setselected_subcat("")
        setsubcatlist([])
        get_product_list(page, "", "", filter, search)
    }
    const resetsubcat = () => {
        setselected_subcat("")

        get_product_list(page, selected_cat, "", filter, search)
    }
    const resetsearch = () => {
        setsearch("")
        get_product_list(page, selected_cat, selected_subcat, filter, "")
    }
    const resetssort = () => {
        setfilter("")

        get_product_list(page, selected_cat, selected_subcat, "", search)
    }
    const handlePageClick = (event) => {
        console.log(event.selected)
        setpage(event.selected + 1)
        get_product_list(event.selected + 1, selected_cat, selected_subcat, filter, search)

    }
    useEffect(() => {

        get_category()
        get_product_list(page, selected_cat, selected_subcat, filter, search)
    }, []);
    const get_product_list = (page, selected_cat, selected_subcat, filter, search) => {
        setIsLoader(true)
        apiService.get__all_product_list(page, selected_cat, selected_subcat, filter, search).then((res) => {
            console.log("===trucks", res.data)
            setproductList(res.data.data)
            settotal_count(res.data.total_count)
            settotal_page(res.data.total_page)
            setIsLoader(false)
        })
            .catch((err) => {
                setIsLoader(false)
                //   Swal.fire("Error", "Something went wrong.", "error");
                //  console.log("contact us api error", err.response.data.message);
            });
    }

    const get_category = () => {
        apiService.get_category().then((res) => {
            let type = []

            {
                res.data.data.map((item, index) => (
                    type.push({ value: item.id, label: item.name })
                ))
            }
            setcatlist(type)
            setsubcatlist([])


        }).catch((err) => {

            setIsLoader(false)
            //   Swal.fire("Error", "Something went wrong.", "error");
            //  console.log("contact us api error", err.response.data.message);
        });
    }
    const get_sub_category = (id) => {
        setsearchErr("")
        apiService.get_sub_category(id).then((res) => {

            let type = []
            console.log(res.data)
            {
                res.data.data.map((item, index) => (
                    type.push({ value: item.id, label: item.name })
                ))
            }

            setsubcatlist(type)


        })
            .catch((err) => {

                setIsLoader(false)
                //   Swal.fire("Error", "Something went wrong.", "error");
                //  console.log("contact us api error", err.response.data.message);
            });
    }


    const onCatChange = (value) => {
        setsearchErr("")
        get_sub_category(value)
        setselected_cat(value)
        get_product_list(page, value, selected_subcat, filter, search)

    }
    const onsubCatChange = (value) => {
        setsearchErr("")
        setselected_subcat(value)
        get_product_list(page, selected_cat, value, filter, search)
    }
    const onfilterCatChange = (value) => {
        setsearchErr("")
        setfilter(value)
        get_product_list(page, selected_cat, selected_subcat, value, search)
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (event.target.value.trim() == "") {
                setsearchErr("Please enter text.")

            } else {
                onsearchChange(event.target.value)
            }
        }
    };
    const onsearchChange = (value) => {


        setsearchErr("")
        setsearch(value)
        get_product_list(page, selected_cat, selected_subcat, filter, value)


    }

    const options = [
        // { value: "featured", label: "Featured" },
        { value: "hightolow", label: "Price high to low" },
        { value: "lowtohigh", label: "Price low to high" },
        { value: "asc", label: "Ascending" },
        { value: "desc", label: "Descending" },
    ];

    const pageDetail = (id) => {
        let link = "/product-detail/" + id
        navigate(link)
    }
    return (
        <>
            {isLoader && <Loader />}
            <section className="product-listing-top-area">
                <Container>
                    <div className="product-listing-banner-area">
                        <Row className="align-items-center">
                            <Col col={6} xl={7}>
                                <div className="product-listing-banner-area-left">
                                    <h2>today’s offer<br></br>
                                        <span> All product & accessories</span>
                                    </h2>
                                    <p>Discover endless possibilities with our vast selection of products from our trusted wholesalers, and supercharge your retail ventures!</p>
                                </div>
                            </Col>
                            <Col col={6} xl={5}>
                                <div className="product-listing-banner-area-right">
                                    <img src={require("../../../assets/images/product-listing-banner-right.png")} alt="img" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <p className="note-dashboard">*To ensure the safety and security of your payments, products, and services, we kindly request that you refrain from conducting transactions outside of the DistriX platform.</p>

                 
                </Container>
            </section>



            <section className="products-listing-area">
                <Container>
                    <Row className="mb-3">
                        <Col lg={8} xl={9}>
                            <h6 className="filter-text">Filter by:</h6>
                            <div className="filter-search-area-left">
                                <div className="category">
                                    <Form.Group
                                        className="position-relative"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        {selected_cat || selected_cat != "" ?
                                            <>
                                                <i class="fa fa-times" aria-hidden="true" onClick={resetcat}></i>
                                            </> : null}

                                        <Select
                                            value={catlist.filter(function (option) {
                                                return option.value === selected_cat;
                                            })}
                                            placeholder="Business Category"
                                            onChange={e => onCatChange(e.value)}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    background: "#ee6f22",
                                                    height: "50px",
                                                    width: "185px",
                                                    borderRadius: "15px",
                                                    border: "none",
                                                    fontWeight: "600",
                                                    boxShadow: "none",
                                                    color: "#fff",

                                                    "&:focus": {
                                                        borderColor: "none",
                                                    },
                                                    "&:hover": {
                                                        borderColor: "none",
                                                    },
                                                }),
                                                singleValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "#fff",
                                                }),
                                                // menuList: (baseStyles, state) => ({

                                                // })
                                            }}
                                            options={catlist} />
                                    </Form.Group>
                                </div>
                                <div className="sub-category">


                                    <Form.Group
                                        className="position-relative"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        {selected_subcat || selected_subcat != "" ?
                                            <>
                                                <i class="fa fa-times" aria-hidden="true" onClick={resetsubcat}></i>
                                            </> : null}
                                        <Select
                                            value={subcatlist.filter(function (option) {
                                                return option.value === selected_subcat;
                                            })}
                                            onChange={e => onsubCatChange(e.value)}
                                            placeholder="Product Category"
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    background: "#ee6f22",
                                                    height: "50px",
                                                    width: "185px",
                                                    borderRadius: "15px",
                                                    border: "none",
                                                    fontWeight: "600",
                                                    boxShadow: "none",
                                                    color: "#fff",

                                                    "&:focus": {
                                                        borderColor: "none",
                                                    },
                                                    "&:hover": {
                                                        borderColor: "none",
                                                    },
                                                }),
                                                singleValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "#fff",
                                                })
                                            }}
                                            options={subcatlist} />
                                    </Form.Group>
                                </div>
                                <div className="search-area">
                                    <p className="errors">{searchErr}</p>
                                    <div class="form-inline ">
                                        {search || search != "" ?
                                            <>
                                                <i class="fa fa-times" aria-hidden="true" onClick={resetsearch}></i>
                                            </> : null}
                                        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" value={search} onKeyDown={(e) => handleKeyDown(e)} onChange={(e) => onsearchChange(e.target.value)} />
                                        {search == "" ?
                                            <img src={require("../../../assets/images/search-icon.svg").default} alt="img" /> : null}

                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} xl={3}>
                            <div className="clear-filter-area-right">
                                <div className="filter-area-right">
                                    <h6 className="filter-text">Sort by:</h6>
                                    <Form.Group
                                        className="position-relative"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        {filter || filter != "" ?
                                            <>
                                                <i class="fa fa-times" aria-hidden="true" onClick={resetssort}></i>
                                            </> : null}

                                        <Select
                                            value={options.filter(function (option) {
                                                return option.value === filter;
                                            })}
                                            onChange={e => onfilterCatChange(e.value)}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    background: "#ee6f22",
                                                    height: "50px",
                                                    width: "185px",
                                                    borderRadius: "15px",
                                                    fontWeight: "600",
                                                    border: "none",
                                                    boxShadow: "none",
                                                    color: "#fff",

                                                    "&:focus": {
                                                        borderColor: "none",
                                                    },
                                                    "&:hover": {
                                                        borderColor: "none",
                                                    },
                                                }),
                                                singleValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "#fff",
                                                })
                                            }}
                                            options={options} />
                                    </Form.Group>
                                </div>
                                {/* {(search!=""||selected_cat!=""|| selected_subcat!=""||filter!="") && !isLoader?
                                <div className="filter-resert-btn">
                                    <Button type="button" variant="unset" onClick={resetbtn}>Reset </Button>
                                </div>:null} */}
                            </div>
                        </Col>
                    </Row>


                    <Row>

                        {productList && productList.length == 0 ? <Nodata message1={"No Products Found"} message2={""} /> :
                            <>
                                {productList && productList.map((list, index) => {
                                    return (<Col md={6} lg={6} xl={3} className="mb-4">
                                        <div className="product-listing-content-area" >
                                            <img src={bUrl + list.images[0]?.image} alt="img" onClick={() => pageDetail(list.id)} />

                                            <div className="product-detail">
                                                <div className="product-detail-area">
                                                    <h5>{list?.product?.name}</h5>
                                                    <p>Category: <span>{list?.product.category.name}</span></p>
                                                    <p>Sub Category: <span>{list?.product.subcategory.name}</span></p>
                                                    <h6>{list?.units}<span> unit</span></h6>
                                                    <h6>₱ {list?.price}<span>/unit</span></h6>
                                                </div>
                                                <Button type="button" variant="unset" onClick={() => pageDetail(list.id)}>View details</Button>
                                            </div>
                                        </div>
                                    </Col>
                                    )
                                })
                                }
                            </>}
                    </Row>
                    {productList && productList.length > 0 ?
                        <Pagination handlePageClick={handlePageClick}
                            page={page}
                            total_page={total_page} /> : null}
                    
                    <div className="recent-loads-area ">
                        <div className="recent-loads-top-area">
                            <h3>Community: <span>Resellers & Retailers</span></h3>
                        </div>
                        <div className="join-group-links-left">
                            <ul>
                                <li>
                                    <p>Join Our Community of Like Minded Resellers & Retailers. <a href="https://www.facebook.com/groups/1510127763142116/?mibextid=oMANbw" target="blank">Click Here!</a></p>

                                </li>
                                <li>
                                    <p>For Tutorials On How to Effectively Use the Platform: <a href="https://tinyurl.com/yh58k2me" target="blank">Try It!</a></p>

                                </li>
                            </ul>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};
export default ProductListing;