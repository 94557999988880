    import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Button, Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore from 'swiper'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
const MainHome = () => {
    SwiperCore.use([Autoplay])
    return (
        <>
            <section className="main-home-area">
                <Container>
                    <Swiper modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={0}
                        loop={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        slidesPerView={1}
                        navigation
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}>
                        <SwiperSlide >
                            <div className="main-home-banner-area">
                                <img src={require("../../assets/images/main-home-banner.svg").default} alt='img' />
                            </div>
                        </SwiperSlide >
                        <SwiperSlide className="main-home-banner-area area_12">
                            <div className="main-home-banner-area">
                                <img src={require("../../assets/images/main-home-banner-1.svg").default}  alt='img'/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="main-home-banner-area area_13">
                            <div className="main-home-banner-area">
                                <img src={require("../../assets/images/main-home-banner-2.svg").default} alt='img' />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="main-home-banner-area area_14">
                            <div className="main-home-banner-area">
                                <img src={require("../../assets/images/main-home-banner-3.svg").default} alt='img' />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="main-home-banner-area area_14">
                            <div className="main-home-banner-area">
                                <img src={require("../../assets/images/main-home-banner-4.svg").default}  alt='img'/>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </Container>
            </section>

            <section className="about-distrix-area">
                <Container>
                    <div className="about-distrix-content">
                        <div className="about-distrix-heading">
                            <h2>About Distrix</h2>
                        </div>
                        <div className="distrix-content-bg-area">
                            <p>Welcome to DistriX AI, a trailblazing platform at the forefront of revolutionizing the trucking and logistics industry. Our journey began with a simple yet powerful vision - to address the empty return trips of the truckers or deadhead problem and unlock new opportunities for truckers, wholesalers, and retailers. Powered by AI-driven solutions, we have created a collaborative ecosystem that seamlessly connects stakeholders, maximizing efficiency and profitability.</p>
                            <p>At DistriX, we take pride in empowering truckers with our cutting-edge technology, optimizing their routes, and filling empty backhauls with profitable cargo. Wholesalers and retailers also benefit from our platform, gaining access to a vast network of partners and expanding their business horizons. Our AI algorithms ensure deadhead trip matching is intelligent and precise, resulting in streamlined logistics, timely deliveries, and increased revenue potential for all involved.</p>
                            <p>With a dedicated focus on excellence, transparency, and customer satisfaction, DistriX continues to reshape the landscape of the logistics industry. We are driven by a commitment to harnessing the power of technology to transform inefficiencies into opportunities and make a positive impact on businesses and the environment. Join us as we revolutionize the way logistics works and pave the way for a more sustainable and prosperous future. Together, we unlock the true potential of trucking, wholesalers, and retailers, fueling growth and success for all stakeholders..</p>
                        </div>
                    </div>
                </Container>
            </section>

            <section className="main-home-third-area">
                <Container>
                    <Row className="align-items-center">
                        <Col md={12} lg={6}>
                            <div className="third-area-left">
                                <h2>Increase Revenue by Taking  <span>BackHauls</span></h2>
                                <p>With DistriX's AI-powered technology, truckers experience a wealth of benefits that revolutionize their operations. Our platform optimizes the empty return trips or deadhead trips, filling empty backhauls with profitable cargo, thereby maximizing revenue potential.
                                </p>
                                <p>The advanced trip matching system ensures efficient routes, timely deliveries, and reduced operational costs, making trucking smoother and more profitable than ever.</p>
                                <ul>
                                    <li>• <b>Optimized Deadhead Trips:</b> AI-driven trip matching fills empty backhauls with compatible cargo, boosting revenue on return journeys.</li>
                                    <li>• <b>Increased Revenue Potential:</b> Truckers can earn more by taking advantage of deadhead opportunities and reducing wasted miles.</li>
                                    <li>• <b>Digitalization:</b> Say goodbye to cumbersome paperwork, manual calculations, and scattered spreadsheets. Our integrated tools allow you to manage your operations effortlessly, eliminating the need for outdated methods.
                                    </li>
                                    <li>• <b>Timely Deliveries:</b> DistriX ensures timely delivery of cargo, improving customer satisfaction and enhancing business relationships.</li>
                                    <li>• <b>Logistics:</b> DistriX simplifies the process of finding compatible loads and managing orders, saving time and effort for truckers.</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={12} lg={6}>
                            <div className="third-area-right">
                                <img src={require("../../assets/images/builtin-marketing-right2.png")} alt="img" />
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>

            <section className="sales-manage-order">
                <Container>
                    <Row className="align-items-center">
                        <Col md={12} lg={6}>
                            <div className="sales-mage-order-left">
                                <img src={require("../../assets/images/sales-manage-left.png")} alt="img" />
                            </div>
                        </Col>
                        <Col md={12} lg={6}>
                            <div className="third-area-left sales-mage-order-right">
                                <h2>Easily Set Up Your <span>Wholesale Store</span></h2>
                                <p>At DistriX, we empower wholesalers with a comprehensive suite of services designed to optimize their business operations and expand their reach in the market. With our user-friendly platform, wholesalers gain access to a wide network of retailers and truckers, creating new business opportunities and driving growth.
                                </p>
                                <p>With DistriX, wholesalers experience streamlined logistics, increased revenue, and enhanced efficiency, revolutionizing their supply chain management.</p>
                                <ul>
                                    <li>• <b>Efficient Product Showcase:</b> Wholesalers can easily upload and showcase their products to a wide audience of retailers and truckers.</li>
                                    <li>• <b>Expanded Business Opportunities:</b> Access to a collaborative ecosystem connects wholesalers with a diverse network of potential buyers, driving business growth.</li>
                                    <li>• <b>AI-Driven Technology:</b> DistriX utilizes advanced AI algorithms to optimize product visibility and improve sales potential.
                                    </li>
                                    <li>• <b>Valuable Market Insights:</b> Our platform provides real-time analytics, enabling wholesalers to make data-driven decisions and respond to market trends.</li>
                                    <li>• <b>Streamlined Logistics:</b> DistriX simplifies supply chain management, reducing administrative tasks and improving overall efficiency.</li>
                                    <li>• <b>Increased Revenue:</b> By reaching more retailers and leveraging deadhead opportunities, wholesalers can boost their revenue potential.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section className="main-home-third-area builtin-marketing-area">
                <Container>
                    <Row className="align-items-center">
                        <Col md={12} lg={6}>
                            <div className="third-area-left">
                                <h2>The Easiest Way to Start a <span>Retail/Resell Business</span></h2>
                                <p>At DistriX, we empower retailers with a suite of services tailored to optimize their retail operations and drive business growth. Our user-friendly platform connects retailers with trusted wholesalers and truckers, providing access to a wide range of high-quality products.
                                </p>
                                <p>With DistriX's AI-driven technology, retailers can discover products tailored to their customers' needs, ensuring a diverse and competitive inventory. Additionally, our advanced analytics provide valuable market insights, enabling retailers to make data-driven decisions and stay ahead of market trends.</p>
                                <p>DistriX streamlines the entire purchasing process, ensuring timely deliveries and efficient logistics, ultimately enhancing customer satisfaction and boosting retail success.</p>
                                <ul>
                                    <li>• <b>Access to Trusted Suppliers:</b> Retailers gain access to a network of reliable wholesalers and truckers, ensuring high-quality products for their customers.</li>
                                    <li>• <b>Tailored Product Offerings:</b> DistriX's AI technology matches retailers with products that align with their target customer demographics and preferences.</li>
                                    <li>• <b>Advanced Market Insights:</b> Real-time analytics offer valuable data on market trends, allowing retailers to make informed business decisions.</li>
                                    <li>• <b>Streamlined Purchasing Process:</b> DistriX simplifies the ordering and transaction process, saving time and effort for retailers.</li>
                                    <li>• <b>Timely Deliveries:</b> Efficient logistics and trip matching ensure timely deliveries, improving customer satisfaction and loyalty.</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={12} lg={6}>
                            <div className="third-area-right">
                                <img src={require("../../assets/images/third-area-rightimage.png")} alt="img" />
                            </div>

                        </Col>

                    </Row>
                </Container>
            </section>

            <section className="features-and-benefits">
                <Container>
                    <div className="feature-benefits-area">
                        <h2>Features & Benefits</h2>
                    </div>
                    <Row>
                        <Col md={6} lg={4} className='mt-3'>
                            <div className="feature-benefits-area-left">
                                <h6>Wholesalers</h6>
                                <ul>
                                    <li>• <b>Intelligent Product Matching:</b> AI algorithms can analyze retailers' preferences, past buying behavior, and market trends to match wholesalers with the most relevant retailers for their products, increasing the chances of successful partnerships.</li>
                                    <li>• <b>Demand Forecasting:</b> AI-powered analytics can analyze historical data, market trends, and external factors to provide wholesalers with accurate demand forecasts. This helps optimize inventory levels, reduce stockouts, and ensure efficient supply chain management.</li>
                                    <li>• <b>Pricing Optimization:</b> AI can analyze market dynamics, competitor pricing, and customer behavior to suggest optimal pricing strategies. Wholesalers can set competitive prices, maximize profitability, and dynamically adjust prices based on demand fluctuations.</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} lg={4} className='mt-3'>
                            <div className="feature-benefits-area-left feature-benefits-area-right ">
                                <h6>Truckers</h6>
                                <ul>
                                    <li>• <b>Deadhead Optimization:</b> AI algorithms can match truckers on deadhead routes with logistics companies or retailers that have scheduled deliveries to the same or nearby destinations. This reduces empty miles, increases revenue potential, and improves overall trucking efficiency.</li>
                                    <li>• <b>Route Optimization:</b> AI-powered route planning and optimization algorithms consider factors like traffic, road conditions, and delivery schedules to suggest the most efficient routes. This reduces fuel costs, saves time, and enhances on-time delivery performance.</li>
                                    <li>• <b>Predictive Maintenance:</b> AI can monitor truck performance data and analyze patterns to predict maintenance requirements. This enables truckers to proactively schedule maintenance, reduce breakdowns, and minimize costly downtime.</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} lg={4} className='mt-3'>
                            <div className="feature-benefits-area-left">
                                <h6>Retailer/Reseller</h6>
                                <ul>
                                    <li>• <b>Personalized Recommendations: </b> AI-driven recommendation engines can analyze customer behavior, purchase history, and preferences to provide personalized product recommendations. This improves customer engagement, enhances the shopping experience, and increases conversion rates.</li>
                                    <li>• <b>Inventory Management:</b> AI algorithms can analyze real-time sales data, market trends, and seasonality to optimize inventory levels. Retailers can ensure they have the right products in stock, minimize excess inventory, and reduce carrying costs.</li>
                                    <li>• <b>Sales Forecasting: </b>AI can analyze historical sales data, external factors, and promotional activities to provide accurate sales forecasts. Retailers can make informed decisions regarding inventory, staffing, and marketing strategies.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );
}
export default MainHome;