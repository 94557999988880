import { useState ,useEffect,useContext} from "react";
import { Button, ButtonToolbar, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { apiService} from "../../../../services/api.services";
import moment from "moment";
import Loader from "../../../common/Loader";
import Pagination from "../../../common/Pagination";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import SuccessPopup from "../../../modal/SuccessPopup"
import ReactPaginate from "react-paginate";
import Nodata from "../Dashboard/Nodata";
// import { useParams } from "react-router-dom";
// import {bUrl,allowedImageTypes,length_unit}  from "../../../../config/config"
import Errorpopup from "../../../modal/Errorpopup"
// import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import ContactPopup from "../../../modal/ContactPopup";

import { context } from "../../../../GlobalContext/GlobalContext";

const FindLoads = () => {
    const globalData = useContext(context);
    const [show, setShow] = useState(false);
    const [dateErr, setDateErr] = useState("");
    const [truckErr, settruckErr] = useState("");
    const handleClose = () => setShow(false);
    const [mindate, setmindate] = useState()
    const handleShow = ((id,date) => {
       let dd = new Date(date).toISOString().slice(0, -8)
       
    //    document.getElementById("myLocalDate").min =new Date(date).toISOString();
       console.log(dd)
        setmindate(dd)
        setorderId(id)
        setShow(true);
    })
    const [showFeature, setShowFeature] = useState(false);
    const handleCloseFeature = () => setShowFeature(false);
    const handleShowFeature = () => setShowFeature(true);
    const [ isLoader , setIsLoader] = useState(false)
    const [ orderId , setorderId] = useState()
    const [ truckId , setruckId] = useState()
    const [page, setpage] = useState(1)
    const [truckList, settruckList] = useState()
    const [loadList, setloadList] = useState()
    const [deliverydate, setdeliverydate] = useState()
    const [ message1 , setmessage1] = useState("Success")
    const [ message2 , setmessage2] = useState("")
    const [showErr, setShowErr] = useState(false);
    const [showSucc, setshowSucc] = useState(false);
    const [ errmessage1 , seterrmessage1] = useState("Error")
    const [errmessage2, seterrmessage2] = useState("")
    const [showContact, setShowContact] = useState(false);
    const [contactEmail, setcontactEmail] = useState("")
    const [contactPhone, setcontactPhone] = useState("")
    const [contactEmail_2, setcontactEmail_2] = useState()
    const [contactPhone_2, setcontactPhone_2] = useState("")
    const [total_page, settotal_page] = useState()
  
    const handlePageClick = (event) => {

        setpage(event.selected+1)
        find_load(event.selected+1)
    }
    const handleCloseContact = () => setShowContact(false);

    const handleShowContact = (email,phone,wemail,wphone) => {
       
        console.log("======ssssss======")
        setcontactEmail(email)
        setcontactPhone(phone)
        setcontactEmail_2(wemail)
        setcontactPhone_2(wphone)
        setShowContact(true);
    
    }

    const handleCloseErr = () => {
    setShowErr(false);
      }
      const handleShowSucc = () => setshowSucc(true);
      const handleCloseSucc = () => {
        setshowSucc(false);
          }
          const handleShowErr = () => setShowErr(true);
    const options2 = [
        { value: "Shipped ", label: "Shipped " },
        { value: "Delivered", label: "Delivered" },

    ];
    useEffect(() => {
    
        find_load(page)
        get_truck_list()
       }, []);
       const get_truck_list = () => {
        // setIsLoader(true)
        apiService.truck_list().then((res) => {
            console.log("======trucks==tytyty===>>>", res.data)
            let type = []
            console.log(res.data)
            {res.data.data.map((item, index) => (
             type.push({ value: item.id, label: item.plate_number })
             ))}
           
            // setIsLoader(false)
         settruckList(type)
           
        }).catch((err) => {

                // setIsLoader(false)
                //   Swal.fire("Error", "Something went wrong.", "error");
                //  console.log("contact us api error", err.response.data.message);
            });
    }
    const find_load = (page)=>{
        setIsLoader(true)
        apiService.find_load(page).then((res) => {
         console.log("===find_load",res.data)
         setloadList(res.data.data)
         settotal_page(res.data.total_page)
         setIsLoader(false)
         }).catch((err) => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = "/login"
            }
            
             setIsLoader(false)
           //   Swal.fire("Error", "Something went wrong.", "error");
            //  console.log("contact us api error", err.response.data.message);
           });
       }
    
const acceptLoad =()=>{
    if(!truckId){
        settruckErr("Please select truck")
     }else if(deliverydate=="" || !deliverydate){
        settruckErr("")
    setDateErr("Please select  delevery date time")
    }else{
        setIsLoader(true)
        settruckErr("")
        setDateErr("")
   let dDate =  new Date(deliverydate)
   let dateDel = moment(new Date(deliverydate)).format("yyyy-mm-DD hh:mm")
   let data = {
    estimated_delivery : dDate,
    truck:truckId
   }
   handleClose()
   apiService.accept_load(data,orderId).then((res) => {
     setmessage2(res.data.message)
     find_load()
     handleShowSucc()
    }).catch((err) => {
        setIsLoader(false)
        handleShowErr()
       if(err?.response?.data?.message){
         find_load()
         seterrmessage2(err?.response?.data?.message)
       }else{
         seterrmessage2("Something went wrong.")
       }
  
       });
    }
   
//    console.log(moment(dDate).format("yyyy-mm-DD hh:mm"))
}
    const tooltip = (
        <Tooltip id="tooltip" >
            Order will be packed and ready for shipping by
        </Tooltip>
    )
    return (
        <>
           {isLoader && <Loader/>}
         
            <div className="manage-truck-area find-loads-area">
                <div className="recent-loads-top-area manage-truck-top-area">
                    <div>
                        <h3>Find BackHauls/BackLoads</h3>
                        <p>Discover lucrative backhauls and backloads tailored to your schedule - seize opportunities that perfectly align with your capacity!</p>
                    </div>
                    <Button type="button" variant="unset" onClick={handleShowFeature}>Consolidate Loads</Button>
                </div>
                {loadList && loadList.length==0?
                            <Nodata message1={"No Load Found"} message2={""}/>:
                            <>
                {loadList && loadList.map((list, index) => {

                    {console.log("=====loadlist====",list)}
                  return(<div className="manage-truck-detail-box">
                   <Row>
                        <Col md={10}>
                            <div className="manage-truck-detail-left order-management-content">
                                <h6>{list.product_sku[0]?.business_profile.business_name}</h6>
                                 <b className="location">Pick Up</b>:
                                <p className="mb-0"><img src={require("../../../../assets/images/location-icon.png")} />{list.product_sku[0]?.business_profile.business_address}</p>
                                 <b className="location">Drop Off</b>:
                                <p><img src={require("../../../../assets/images/location-icon2.png")} />{list.address.address}</p>

                                <div className="truck-detail-bottom">
                                    <p>Order ID: <span>{list.id}</span></p>
                                
                                    
                                    <p className="date-area">Packed By:&nbsp;
                                    <ButtonToolbar  >
                                                        <OverlayTrigger placement="top" overlay={tooltip} >
                                                            <Button bsStyle="default">  <span><i class="fa fa-calendar" aria-hidden="true"></i></span></Button>
                                                        </OverlayTrigger>
                                                    </ButtonToolbar>
                                                      {moment(list.estimated_packing).format('DD MMMM YYYY')}</p>
                                    <p><span><i class="fa fa-clock-o" aria-hidden="true"></i></span> {moment(list.estimated_packing).format('hh:mm A')}</p>

                                    <p>Delivery Charge: <span>₱ {list.delivery_charge}</span></p>
                                 
                                </div>
                                <div className="truck-detail-bottom">
                                {/* <p> Total CBM: <span>{list?.order_cbm?.toFixed(2)}</span></p> */}
                                <p> Total CBM: <span>{list?.order_cbm?.toFixed(2)}</span></p>
                                    <p> Total Weight: <span>{list?.order_weight} KG</span></p>
                               
                                {list.quantity.map((pro,index)=>{
                                // {console.log(pro,"========propropropro====")}
                               return( 
                                <>
                                    <p>Unit Type:  <span>{pro.product_sku.units}</span></p>{index>0?",":""}
                                   
                                    {/* <p>Business Category: <span>{pro.product_sku.weight}</span>,Width: <span>{pro.product_sku.width},</span> Height: <span>{pro.product_sku.height}ft,</span> Length: <span>{pro.product_sku.length}ft,</span> </p> */}
         
                                    <p>Business Category: <span>{pro.product_sku.product.category.name}</span></p>{index>0?",":""}
                                    </>)
                                })}
                                 </div>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className="order-management-content-right find-load-right">
                                 <Button type="button" variant="unset " onClick={()=>handleShow(list.id,list.estimated_packing)}>Accept</Button>
                                  <h6>₱ {list.delivery_charge}</h6>
                                  <div className="contact-btn-area">
                                                    <div className="chat-now-btn-area">
                                                        <Button type="button" variant="unset" onClick={()=>handleShowContact(list.user.email,list.retailer_business_profile.phone,list.product_sku[0].product.user.email,list.wholesaler_business_profile.phone)}><i class="fa fa-phone-square" aria-hidden="true"></i>Contact</Button>
                                                    </div>
                                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>)})}

                {loadList && loadList.length>0?
               <Pagination handlePageClick={handlePageClick}
               page={page}
               total_page={total_page} /> :null}
                </>}
               

            </div>
            <SuccessPopup show={showSucc} handleClose={handleCloseSucc} message1={message1} message2={message2} />
            <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} />
          
            {/* modal */}
            <Modal className="success-box choose-option-area" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="select-date-heading">Please select estimated date & time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="distrix-truck-area select-truck-field">
                    <Form.Group className="mb-2"  controlId="exampleForm.ControlInput1">
                                                  <label htmlFor="">Select Truck</label>

                                                <Select options={truckList} placeholder="Select Truck"
                                                onChange={(e)=>setruckId(e.value)}
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            height: "50px",
                                                            background: "transparent",
                                                            borderRadius: "15px",
                                                            border: "none",
                                                            fontWeight: "600",
                                                            boxShadow: "none",
                                                            color: "#000",

                                                            "&:focus": {
                                                                borderColor: "none",
                                                            },
                                                            "&:hover": {
                                                                borderColor: "none",
                                                            },
                                                        }),
                                                        singleValue: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            color: "#000",
                                                        }),
                                                        // menuList: (baseStyles, state) => ({

                                                        // })
                                                    }} />
                                                      <p className="errors">{truckErr}</p>
                                            </Form.Group>
                                           
                                <Form.Group className="" controlId="formBasicPassword">
                                    <label htmlFor="">Deliver By<span className="note">(Ensure the products are delivered with in the time frame)</span></label>
                                    <input className="form-control"  id="myLocalDate" type="date"  min={mindate}  dateFormat="YYYY-MM-DD hh:mm a" onChange={(e)=>setdeliverydate(e.target.value)}/>
                               <p className="errors">{dateErr}</p>
                                </Form.Group>
                    
                    </div>
               
                </Modal.Body>
                <Modal.Footer>
              
                        <Button variant="secondary" onClick={acceptLoad}>
                          Save
                        </Button>
               

                </Modal.Footer>
            </Modal>


            {/* feature-modal */}
            <Modal className="success-box feature-available" show={showFeature} onHide={handleCloseFeature}  centered>
                <Modal.Header>
                    <Modal.Title>
                        <img src={require("../../../../assets/images/chemark.png")} alt="img" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Feature will be available soon</h5>
                </Modal.Body>
                <Modal.Footer>
                   
                    <Button variant="primary" onClick={handleCloseFeature}>
                     Ok
                    </Button>
                </Modal.Footer>
            </Modal>

            <ContactPopup showContact={showContact} handleCloseContact={handleCloseContact} email={contactEmail} phone={contactPhone} emailSecond={contactEmail_2} phoneSecond={contactPhone_2} secontType={"w"}/>
        </>
    );
};
export default FindLoads;