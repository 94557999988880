
import { Button, Col, Container, Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Navigation } from 'swiper/modules';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Trucks from "./Dashboard/Trucks";
import AddTruck from "./Dashboard/AddTruck";
import Home from "./Dashboard/Home";
import ComingSoon from "./Dashboard/ComingSoon";
import PrimaryClientManagement from "./Dashboard/PrimaryClientManagement";
import FindLoads from "./Dashboard/FindLoads";
import LoadsManagement from "./Dashboard/LoadsManagement";
import RevenueInsights from "./Dashboard/RevenueInsights";
import TruckerTransactionManagement from "./Dashboard/TruckerTransactionManagement";


const Dashboard = () => {
    const history = useNavigate()
    const location = useLocation()
    let [searchParams, setSearchParams] = useSearchParams();
    const [key, setKey] = useState('dashboard');
    const [trucksSection, setTrucksSection] = useState('view');
    const [swiper, setSwiper] = useState()
    const [truck_id, settruckId] = useState(0);
    const [swiperActiveIndex, setSwiperActiveIndex] = useState(0)

    const tabsData = [
        { label: "Dashboard", image: "image1.png", targetId: "dashboard", component: <>{key == "dashboard" || searchParams == "dashboard" ? <Home /> : <></>}</> },
        { label: "Find Loads", image: "image2.png", targetId: "find_loads", component: <>{key == "find_loads" || searchParams == "find_loads" ? <FindLoads /> : <></>}</> },
        { label: "Loads Management", image: "image3.png", targetId: "loads_management", component: <>{key == "loads_management" || searchParams == "loads_management" ? <LoadsManagement /> : <></>}</> },
        { label: "Primary Clients Management", image: "image4.png", targetId: "clients", component: <>{key == "clients" || searchParams == "clients" ? <PrimaryClientManagement /> : <></>}</> },
        { label: "Manage Truck", image: "image5.png", targetId: "manage_trucks", component: <>{trucksSection === 'add' ? <AddTruck setKey={setTrucksSection} truck_id={truck_id} key={trucksSection} /> : <Trucks setKey={setTrucksSection} settruckId={settruckId} />}</> },
        { label: "Revenue Insights", image: "image6.png", targetId: "revenue", component: <>{key == "revenue" || searchParams == "revenue" ? <RevenueInsights /> : null}</> },
        { label: "Transaction Management", image: "image7.png", targetId: "transactions", component: <>{key == "transactions" || searchParams == "transactions" ? <TruckerTransactionManagement /> : null} </> },
    ]

    const tabs = tabsData.map(tab => tab.targetId)

    useEffect(() => {
        const tempTab = searchParams.get("tab")
        if (tabs.includes(tempTab)) {
            let tempIndex = tabsData.findIndex((tab) => tab.targetId == tempTab)
            setSwiperActiveIndex(tempIndex)
            setKey(tempTab)
        } else {
            setSearchParams({ "tab": "dashboard" })
            setKey("dashboard")
        }

    }, [])

    function changeTab(value) {
        history({
            pathname: location.pathname,
            search: "?tab=" + value
        })
        setKey(value)
        let slideIndex = tabs.indexOf(value) - 1
        swiper.slideTo(slideIndex)
        setSwiperActiveIndex(slideIndex)
    }

    return (
        <>
            <section className="dashboard-area">
                <Container>
                    <div className="dashboard-tab-area">
                        <div className=" dashboard-top-tab-area">
                            <Swiper
                            
                                slidesPerView={5}
                                onSlideChange={() => console.log('slide change')}
                                
                                onSwiper={(swiperObj) => {
                                    setSwiper(swiperObj)
                                    
                                }}
                                breakpoints={{
                                    1600: {
                                        slidesPerView: 4,
                                        spaceBetween: 50,
                                    },
                                    1400: {
                                        slidesPerView: 4,
                                        spaceBetween: 50,
                                    },
                                    1200: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },
                                    1199: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                      },
                                    1024: {
                                      slidesPerView: 3,
                                      spaceBetween: 50,
                                    },
                                    991: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },
                                    850: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                      },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 40,
                                      },
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    575: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    480: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    380: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                      },
                                  }}
                                modules={[Navigation]}
                                
                            >
                                {tabsData.map((tab, tabIndex) => (
                                    <SwiperSlide>
                                        <div onClick={() => changeTab(tab.targetId)} className={`${key === tab.targetId ? 'active' : ''}`} key={tabIndex} id={`parent-${tab.targetId}`}>
                                            <button id={`tab-${tabIndex}`}>
                                            <img src={`/images/${tab.image}`} alt="img" />
                                                {tab.label}
                                            </button>
                                        </div>
                                    </SwiperSlide>))
                                }
                            </Swiper>
                            <button disabled={swiperActiveIndex == 0} className="tab-left-arrow-btn" onClick={() => swiper.slidePrev()}><i class="fa fa-angle-left" aria-hidden="true"></i></button>
                            <button className="tab-right-arrow-btn" onClick={() => swiper.slideNext()}><i class="fa fa-angle-right" aria-hidden="true"></i></button>
                        </div>

                        <div className="tab-content" id="myTabContent">
                            {
                                tabsData.map((tab, tabIndex) => (
                                    <div className={`${key === tab.targetId ? '' : 'd-none'}`} id={`${tab.targetId}`} role="tabpanel" aria-labelledby={`${tab.targetId}`} key={tabIndex}>
                                        {tab.component}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};
export default Dashboard;