import { useContext } from "react";
import { context } from "../../GlobalContext/GlobalContext";
import { Navbar, Nav, Button } from "react-bootstrap";
import { useLocation } from "react-router";

const CommonHeader = () => {
    const location = useLocation();
    const globalData = useContext(context);
    let homepage = "/"
    if(location.pathname === "/create_profile"){
        homepage= "/create_profile"}
    const token = localStorage.getItem("web_token");
    const logout = ()=>{
        globalData.setToken();
        localStorage.clear()
        window.location.href = "/login"
    }
    return (
        <>
            <Navbar.Brand className={location.pathname === "/forgot-password" || location.pathname === "/login" || location.pathname === "/register"?"hide_on_mobile":""  } href={homepage}><img src={require("../../assets/images/logo.png")} alt="" /></Navbar.Brand>
            {!globalData.token && !token && (location.pathname !== "/faq" && location.pathname != "/privacy-policy" && location.pathname !== "/" && location.pathname !== "/term-condition")?
                <Navbar.Brand className="logo-white" href={homepage}><img src={require("../../assets/images/logo-white.png")} alt="" /></Navbar.Brand> : null}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
               
            {!globalData.token && !token?
                    (<Nav className="ms-auto align-items-center guest-user-header">
                        <Nav.Link className="navbar-link" href="/">Home</Nav.Link>
                        <Nav.Link className="navbar-link" href="/faq">FAQs</Nav.Link>
                        <Nav.Link className="navbar-link" href="/">News</Nav.Link>
                    <Nav.Link href="/login"><Button type="button" variant="unset" className="sign-in-btn">SIGN IN</Button></Nav.Link>
                    <Nav.Link href="/register"><Button type="button" variant="unset" className="sign-up-btn">SIGN UP</Button></Nav.Link>
                </Nav>): (<Nav className="ms-auto">
                     <Nav.Link onClick={()=>logout()}><Button type="button" variant="unset" className="sign-in-btn logout-btn-top" >Logout</Button></Nav.Link>
                   </Nav>)}
            </Navbar.Collapse>
        </>
    )
}

export default CommonHeader