import { Col, Form, Row } from "react-bootstrap"
import { useState, useEffect } from "react";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { gKey } from "../../../../config/config"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const ct = require("countries-and-timezones");
const Step1 = ({ setprofileFields, profileFieldsErrs, profileFields, onInputChange, logo }) => {
    const [value, setValue] = useState(null);
    const timezone = ct.getTimezone(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const COUNTRY_CODE = "ph";

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default behavior (page refresh)
            // Handle your logic here
        }
    };
    useEffect(() => {
        if (value) {
            geocodeByAddress(value.label)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) =>
                    setprofileFields({
                        ...profileFields,
                        business_address: value.label,
                        latitude: lat,
                        longitude: lng,
                    }));
        }
    }, [value]);
    const handleKeyDownName = (event) => {
        console.log("=====event====", event.which)
        if (event.which === 32) {
            event.preventDefault(); // Prevent space key action
        }
        if (event.key === 'Enter' || event.which === 13) {
            event.preventDefault(); // Prevent the default behavior (page refresh)
            // Handle your logic here
        }
    };
    // console.log("=====profileFieldsErrs==step=",profileFieldsErrs)
    return (
        <>
            <div className="overflow-x">
                <div className="stepper-form-area">
                    <Row>
                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control maxLength={30} onKeyDown={handleKeyDownName} type="text" value={profileFields.first_name} placeholder="Enter First Name" onChange={e => onInputChange('first_name', e.target.value)} />
                                    <span className="errors">{profileFieldsErrs.first_name}</span>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control onKeyDown={handleKeyDownName} maxLength={30} type="text" value={profileFields.last_name} placeholder="Enter Last Name" onChange={e => onInputChange('last_name', e.target.value)} />
                                    <span className="errors">{profileFieldsErrs.last_name}</span>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4" controlId="formBasicEmail">

                                    <Form.Label>Contact No.</Form.Label>
                                    <PhoneInput
                                        country={
                                            COUNTRY_CODE ? COUNTRY_CODE.toLocaleLowerCase() : "ph"
                                        }
                                        className={
                                            //   contactUsFieldsErrors.phone
                                            //     ? "border-red phone-input"
                                            "phone-input "
                                        }
                                        placeholder="Enter contact number"
                                        value={profileFields.phone}
                                        onChange={(e) => {
                                            setprofileFields((prevObj) => {
                                                return {
                                                    ...prevObj,
                                                    phone: e,
                                                };
                                            });

                                            //   if (e.trim() === "") {
                                            //     setContactUsFieldsErrors((prevObj) => {
                                            //       return {
                                            //         ...prevObj,
                                            //         phone: true,
                                            //       };
                                            //     });
                                            //   } else if (e.trim().length < 10) {
                                            //     setContactUsFieldsErrors((prevObj) => {
                                            //       return {
                                            //         ...prevObj,
                                            //         phone: true,
                                            //       };
                                            //     });
                                            //   } else {
                                            //     setContactUsFieldsErrors((prevObj) => {
                                            //       return {
                                            //         ...prevObj,
                                            //         phone: false,
                                            //       };
                                            //     });
                                            //   }
                                        }}
                                    />
                                    <span className="errors">{profileFieldsErrs.phone}</span>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Business Name</Form.Label>
                                    <Form.Control onKeyDown={handleKeyDown} maxLength={50} type="text" value={profileFields.business_name} placeholder="Enter Business Name" onChange={e => onInputChange('business_name', e.target.value)} />

                                    <span className="errors">{profileFieldsErrs.business_name}</span>
                                </Form.Group>
                            </Form>
                        </Col>


                    </Row>
                    <Row>
                        {/* <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Retailer Name</Form.Label>
                                    <Form.Control onKeyDown={handleKeyDown} maxLength={50} type="text" value={profileFields.trade_name} placeholder="Enter Retailer Name" onChange={e => onInputChange('trade_name', e.target.value)} />
                                    <span className="errors">{profileFieldsErrs.trade_name}</span>
                                </Form.Group>
                            </Form>
                        </Col> */}
                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Business Address<span className="note_text" >{"(Please enter complete address for delivery purpose"}</span></Form.Label>
                                    <GooglePlacesAutocomplete
                                        apiKey={gKey}
                                        className="form-control"
                                        onFail={error => console.log(error)}
                                        onNotFound={() => console.log('no results')}
                                        selectProps={{
                                            placeholder: "Enter Address",
                                            value,
                                            onChange: setValue,

                                        }}
                                    />
                                    <span className="errors">{profileFieldsErrs.business_address}</span>
                                </Form.Group>
                            </Form>
                        </Col>

                    </Row>


                    {/* <Row>

                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4  truck-logo-upload-field" controlId="formBasicEmail">
                                    <Form.Label>Upload Business Logo</Form.Label>
                                    <div className="upload-box-area">
                                        <div className="logo-upload-box">
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                            <Form.Control type="text" />
                                            <Form.Control type="file" className="upload-item" onChange={e => onInputChange('business_logo', e.target.files[0])} />
                                        </div>
                                     

                                        {logo ?
                                            <div className="upload-image-preview">
                                                <div className="first-preview-image">
                                                    <img src={logo} alt={`Preview logo`} className="image-preview" />
                                                </div>

                                            </div> : null}

                                    </div>
                                </Form.Group>
                                <p className="errors">{profileFieldsErrs.business_logo}</p>
                            </Form>
                         </Col>
                    </Row> */}
                </div>
            </div>
        </>
    )
}

export default Step1