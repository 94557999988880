import { Container } from "react-bootstrap";

const TermCondition = () => {
    return (
        <>
            <section className="privacy-policy">
                <Container>
                    <div className="privacy-policy-content-area">
                        <h2>TERMS AND CONDITIONS</h2>
                        <h3>Trucker Terms and Conditions of Use</h3>
                        <p>Please read this page carefully. It contains the Terms and Conditions governing your access to and use of the DistriX Software, DistriX Services and Transport (as each defined below) provided by DistriX and/or the Operators who are independent contractors.</p>
                        <p>If you do not accept these Terms and Conditions or you do not meet or comply with their provisions, you may not use the DistriX Software and/or the Services. By using the Services, you hereby expressly agree to these Terms and Conditions.</p>
                        <p>These Terms and Conditions are effective as of 28 June 2023. If you have any questions, feel free to email us at hello@distrix.ai.
                        </p>
                        <ul>
                            <h5>1. Introduction </h5>
                            <li>Welcome to DistriX, your trusted platform for efficient product distribution. These Terms and Conditions ("Agreement") outline your rights and responsibilities as a Trucker when using our services. By accessing or using DistriX, you agree to abide by these terms and conditions. </li>
                        </ul>

                        <ul>
                            <h5>2. Registration and User Profile
                            </h5>
                            <li><b> Registration Process:</b> Truckers can join DistriX by registering, confirming their email, and completing their user profiles. Registration is free for Truckers. </li>
                            <li><b>User Responsibility:</b>Truckers are responsible for providing accurate and lawful information during registration.</li>
                        </ul>

                        <ul>
                            <h5>3. Empty Return Trips and Delivery Matching
                            </h5>
                            <li><b> Empty Return Trip Scheduling:</b> Truckers can upload their daily or weekly delivery schedules from their principal clients. DistriX's technology captures this data to identify empty return trips. </li>
                            <li><b> Delivery Matching:</b> DistriX matches Truckers with delivery opportunities that coincide with their empty return trips, aiming to help truckers fill those trips, generate additional revenue, and digitize their trucking operations. </li>
                            <li><b> Choosing Own Trucks:</b> Wholesalers have the option to choose their own trucks for delivery when no matching empty return trips are available. In such cases, the delivery fee is credited to the Wholesalers, with a 7.5% commission to DistriX. </li>
                        </ul>

                        <ul>
                            <h5>4. Trucker Responsibility
                            </h5>
                            <li><b> Safe and On-Schedule Delivery:</b> Truckers are responsible for ensuring the safe and timely delivery of goods. Failure to do so may result in account deactivation.
                            </li>
                            <li><b> Liability for Damaged, Lost, or Stolen Products:</b> Truckers shall assume full responsibility for any incurred penalties, restitution, or acquisition costs pertaining to products that sustain damage, loss, or theft during their custody while in the course of the delivery process. The allocation of such financial responsibilities may be determined in favor of the Wholesalers or the Retailers, as applicable.
                            </li>
                        </ul>

                        <ul>
                            <h5>5. Truck Ownership and Responsibility
                            </h5>
                            <li><b> Ownership Clarification:</b> DistriX Trucks refer to user-owned trucks. DistriX assumes no responsibility for Truckers' trucks or their operation.
                            </li>
                        </ul>

                        <ul>
                            <h5>6. Liability for Product Issues
                            </h5>
                            <li><b>Platform's Role:</b> DistriX operates solely as a platform connecting users and assumes no liability for issues related to the transported products
                            </li>
                            <li><b> Dispute Resolution:</b> While DistriX may mediate disputes between users, it holds no financial responsibility in such cases.
                            </li>
                        </ul>

                        <ul>
                            <h5>7. Commission and Fees
                            </h5>
                            <li><b> Commission on Delivery Fee:</b> DistriX charges Truckers a commission of 7.5% on the total delivery fee for services provided through the platform.
                            </li>

                        </ul>

                        <ul>
                            <h5>8. Data Privacy and Security
                            </h5>
                            <li><b> Data Handling:</b> DistriX takes data privacy and security seriously. Trucker data is protected and used only for platform-related purposes.
                            </li>
                            <li><b>Data Retention:</b> Inactive Trucker accounts and data will be deleted after 6 months.
                            </li>
                            <li><b> Data Deletion Requests:</b> Truckers can request the deletion of their data by emailing hello@distrix.ai.
                            </li>
                        </ul>

                        <ul>
                            <h5>9. Termination of Accounts
                            </h5>
                            <li><b>Account Termination:</b> DistriX reserves the right to terminate Trucker accounts for violations of these Terms and Conditions. Truckers can appeal within 15 days of termination via email to hello@distrix.ai.
                            </li>
                        </ul>

                        <ul>
                            <h5>10. Notifications and Changes
                            </h5>
                            <li><b> Notification of Changes:</b> DistriX will notify Truckers of significant changes to the platform with a 30-day notice period.
                            </li>

                        </ul>

                        <ul>
                            <h5>11. Geographical Jurisdiction
                            </h5>
                            <li><b> Dispute Resolution Location:</b> Disputes will be handled in the Philippines, in the jurisdiction of Bonifacio Global City, Taguig, Metro Manila.
                            </li>

                        </ul>

                        <ul>
                            <h5>12. DistriX Disclaimer
                            </h5>
                            <li> DistriX and its Services, including but not limited to its Services, Software, and customer service are provided to the User on an “as is” basis.
                            </li>
                            <li>DistriX provides no warranty, assurance, or promise that its Services, Software, or customer service will work as intended on User’s phone, computer, tablet or other device.
                            </li>
                            <li>DistriX does not warrant or guarantee the availability, reliability, timeliness, accuracy, or quality of the Services, Software, or the Drivers.
                            </li>
                            <li>DistriX is not liable for any losses, damages, claims, or costs including any consequential, indirect, or incidental losses or damages to the User’s mobile device or any applications stored therein as a result of installation or use of DistriX Software.</li>
                            <li>DistriX is not liable for any losses or damages, including but not limited to any injury which the User or any person may suffer, any damage to property, including the Goods, owned by or in possession of the User or any parties, or any Indirect Loss and Damage, resulting from the Services, matters relating to truckers, or the process of transporting the Goods. Any movement of the Goods involving a User and a Driver is a direct contract for hire between those parties to which DistriX is not a party.
                            </li>
                            <li> In providing the Services, DistriX does not act as an agent or representative for the Wholesaler or Trucker or Retailer and is not a party to the contract for hire between a Wholesaler, Retailer and Trucker.</li>
                            <li> DistriX reserves the right to suspend or terminate any engagement with the User or User’s account, created in order to use the Services or Software should DistriX believe that the User violates any provisions of this Terms and Conditions and any other policy related to it.</li>
                        </ul>
                        <ul>
                            <h5>13. Intellectual Property Rights
                            </h5>
                            <li>The Website, the Software, and all rights, title, and interest in and related thereto are the sole property of DistriX , its licensors, and are protected by Intellectual Property Laws. Except for, when applicable, the limited licenses expressly granted to you in these Terms and Conditions, DistriX reserves for itself and its licensors all other rights, title to, and interest.</li>
                            <li>Third party materials, if any, and all rights, title, and interest pertaining thereto, are the sole property of the relevant third parties and are subject to any applicable licenses, conditions and/or reservations.</li>
                            <li> Without limitation on the foregoing, the User may not reproduce, copy, modify, display, sell or distribute the Software, or any content displayed on the Software, or use them in any other way for public or commercial purpose without securing the consent of DistriX , its affiliates, and or the relevant third party. Violation of this provision would automatically terminate your permission to use the Software or access the Services, as such you must immediately destroy any and all copies you made of the Software or any content displayed therein.</li>
                            <li>Notwithstanding anything to the contrary contained herein, this prohibition includes: (a) copying or adapting the HTML code used to generate web pages on the sites; (b) using or attempting to use engines, manual or automated software, tools, devices, agents, scripts robots or other means, devices, mechanisms or processes (including, but not limited to, browsers, spiders, robots, avatars or intelligent agents) to navigate, search, access, “scrape,” “crawl,” or “spider,” any web pages or any Services provided on the Software other than any search engine and search agents available from DistriX on the Software and other than generally available third party web browsers (e.g., Internet Explorer, Firefox, Safari, Opera, Chrome, etc.); and (c) aggregating, copying or duplicating in any manner any of the content or information available from the Software, without the express written consent of DistriX . The use of the content from the Software on any other website, intranet, or in a networked computer environment for any purpose is strictly prohibited.
                            </li>
                            <li> “DistriX ,” “DistriX  Logo,” “t logos,” “welcome to the REVOLUTION,” “DistriX ” and certain other names or logos are service marks or trademarks of DistriX , and all related product and service names, design marks and slogans are the service marks or trademarks of DistriX . The “look and feel” of the Software and Website (including, without limitation, the color combinations, button shapes, layout, design, and all other geographical elements) are also protected by DistriX trademarks, service marks, and copyrights). Any code that DistriX creates or may have created to generate or display the Software, Website or the pages of the Website are also protected by intellectual property rights. All other trademarks and service marks contained on the Software and Websites are the trademarks or service marks of their respective owners.
                            </li>
                        </ul>
                        <ul>
                            <h5>14. User Submitted Content and Feedback
                            </h5>
                            <li> DistriX welcomes comments, and suggestions regarding the Services, Transport, and the Software and Website. Please note however, that DistriX does not accept nor consider creative ideas, suggestions, inventions or materials other than those which it has specifically solicited or requested.</li>
                            <li>If you submit feedback regarding the Software, Website, Services or Transport, please be specific in your comments and do not submit creative ideas, inventions, suggestions, or materials. If you proceed, despite this notice, in sending DistriX creative suggestions, ideas, drawings, concepts, inventions, or other content or information, you understand and agree that these submissions shall become property of DistriX . Further, by submitting the same, you irrevocably assign the right to these submissions and DistriX shall own exclusively all rights to these submissions and shall be entitled to the unrestricted use of these submissions for any purpose whatsoever, without compensation to you or any other person.
                            </li>
                        </ul>
                        <ul>
                            <h5>15. Miscellaneous
                            </h5>
                            <li>DistriX may amend these Terms and Conditions from time to time and it reserves the right to do so without prior notice. Any amendments of the Terms and Conditions shall be posted on DistriX Software.</li>
                            <li>DistriX may, with User’s express consent, send any information regarding the Service or promotion to the User in the form of electronic messages which include, but not limited to, e-mail, SMS, automatic telephone message, or push notification on Software. The User permits DistriX to send these electronic messages to the User via the contact channels provided to DistriX by the User at the time of registering its account.
                            </li>
                            <li> The User accepts fully and unconditionally the current form of the Terms and Conditions as represented on the DistriX website at each use of the Services.
                            </li>
                            <li>These Terms and Conditions shall be governed by the laws of the Republic of the Philippines and the User submits to the jurisdiction of the competent courts of Pasig City, to the exclusion of any other court or venue.</li>
                        </ul>
                        <ul>
                            <h5>16. Refund and Cancellation Policy</h5>

                            <p>DistriX offers refunds in certain circumstances according to the following guidelines:</p>
                            <li>If a fee has been deemed to be applied incorrectly to your account; and</li>
                            <li>If you request a cancellation of a service, prior to an attempt for collection and where DistriX has not incurred a cost for the booking.</li>
                            <p>DistriX does not offer refunds:</p>
                            <li> If you have pre-purchased DistriX credit, DistriX will not issue a refund for any portion that has not been used once it expires or if you elect to close your account</li>
                            <li>If the service delivery has been delayed to the extent that it is outside the estimated time of delivery (ETD). Delivery times are an estimate and do not form part of your contract.
                            </li>
                            <li>Refunds may be issued in the same method of payment used for the original booking
                            </li>
                            <li>At the time of booking, you agree to the terms outlined contained within this user and refund policy. If you don’t agree, then discontinue use of the DistriX system and do not finalize your booking.</li>
                            <li>DistriX reserves the right to modify any provisions of the user and policy without any notice to you.</li>
                            <p>If you have any questions, comments or concerns about our refund and cancellation policy, you may contact us.</p>
                        </ul>
                        <ul>
                            <h5>17. User’s Personal Data</h5>
                            <p>Users confirm that the personal data provided by him/her are true and up to date.
                            </p>
                            <li>User agrees to allow DistriX use of his/her personal data (including but not limited to name, contact number, email, and address) and share such data to the Driver for the purpose of providing the Services and for the Driver to provide Transport. User also grants DistriX the freedom authority to compile, collect, store, and update his/her personal data to such extent, for and at such time period as may be necessary during the time DistriX provides the Services.</li>
                            <li>User agrees and acknowledges that DistriX compilation, collection, storage, use and transfer of his/her personal data is subject to DistriX Privacy and Personal Data Collection Policy (the “Privacy Policy”) as posted and periodically updated on DistriX the Website. User also acknowledges that he/she has read and understood the Privacy Policy before agreeing to the Terms and Conditions.</li>
                        </ul>
                        <h3>Wholesalers Terms and Conditions of Use</h3>
                        <p>Please read this page carefully. It contains the Terms and Conditions governing your access to and use of the DistriX Software, DistriX Services and Transport (as each defined below) provided by DistriX and/or the Operators who are independent contractors.</p>
                        <p>If you do not accept these Terms and Conditions or you do not meet or comply with their provisions, you may not use the DistriX Software and/or the Services. By using the Services, you hereby expressly agree to these Terms and Conditions.</p>
                        <p>These Terms and Conditions are effective as of 28 June 2023. If you have any questions, feel free to email us at hello@distrix.ai.</p>
                        <ul>
                            <h5>1. Introduction</h5>
                            <li>These Terms and Conditions ("Agreement") constitute a legally binding agreement between you ("User" or "you") and DistriX ("DistriX," "we," "us," or "our"). By accessing or using our platform, you agree to abide by these terms and conditions. Please read this Agreement carefully.</li>
                        </ul>
                        <ul>
                            <h5>2. Registration and User Profiles</h5>
                            <li>Registration on DistriX is free and open to Wholesalers, Retailers, and Truckers.
                            </li>
                            <li>Users are responsible for the accuracy and legality of the information provided during registration.</li>
                            <li>Wholesalers are prohibited from listing certain products (e.g., sex toys, firearms) on the platform.</li>
                        </ul>
                        <ul>
                            <h5>3. Retailer Access</h5>
                            <li>Retailers can access Wholesalers' products by registering on the platform.
                            </li>
                            <li>There is no approval process for retailer registration; a valid email confirmation is sufficient.
                            </li>
                        </ul>
                        <ul>
                            <h5>4. Liability for Product Issues</h5>
                            <li>DistriX operates as a platform connecting users and assumes no liability for product-related issues.</li>
                            <li>DistriX may mediate disputes between users but is not financially responsible.
                            </li>
                        </ul>
                        <ul>
                            <h5>5. Truckers and Delivery Matching</h5>
                            <li>DistriX matches truckers with Wholesalers for delivery based on empty return trips.</li>
                            <li> Truckers must ensure safe and on-schedule deliveries. Failure may result in account deactivation.
                            </li>
                            <ul>6. Truck Ownership and Responsibility</ul>
                            <li>DistriX Trucks refer to user-owned trucks. DistriX assumes no responsibility for them.
                            </li>
                        </ul>
                        <ul>
                            <h5>7. Delivery Options </h5>
                            <li>Wholesalers can choose between "Use DistriX Truck" or "Use Own Trucks" during order acceptance.</li>
                            <li>Wholesalers using their own trucks are charged a 7.5% commission on the delivery fee.
                            </li>
                        </ul>
                        <ul>
                            <h5>
                                8. Dispute Resolution  </h5>
                            <li>DistriX may appoint a third party for dispute mediation.
                            </li>
                            <li>Dispute resolution should be completed within 3 to 6 months.
                            </li>

                        </ul>
                        <ul>
                            <h5>
                                9. User Notifications    </h5>
                            <li>Users will be notified of significant changes with a 30-day notice period.
                            </li>

                        </ul>
                        <ul>
                            <h5>
                                10. Commission & Fees
                            </h5>
                            <li><b>Commission on Product Sale:</b> DistriX charges Wholesalers a commission of 7.5% on the total sale amount on the product listed on the platform.</li>
                            <li><b>Commission on Delivery Fee Using Wholesaler’s Own Vehicle:</b> DistriX charges a commission of 7.5% on the total delivery collected from the retailers upon purchase.
                            </li>
                        </ul>
                        <ul>
                            <h5>11. Termination of Accounts</h5>
                            <li>DistriX can terminate accounts without warning for violations. Users can appeal within 15 days via email hello@distrix.ai
                            </li>
                        </ul>
                        <ul>
                            <h5>12. User Agreement Acceptance</h5>
                            <li>Acceptance of these terms is mandatory during registration.</li>
                        </ul>
                        <ul>
                            <h5>13. User Obligations during Disputes</h5>
                            <li> Users must cooperate in good faith during dispute resolution.
                            </li>
                        </ul>
                        <ul>
                            <h5>14. Force Majeure</h5>
                            <li>In cases of unforeseeable circumstances affecting operations, DistriX will act in the best interests of users and the platform.
                            </li>
                        </ul>
                        <ul>
                            <h5>15. User Reviews and Ratings</h5>
                            <li>Only registered users who have completed transactions can provide reviews and ratings.
                            </li>
                            <li>Fake or malicious reviews may result in account termination.
                            </li>
                        </ul>
                        <ul>
                            <h5>16. Data Retention and Deletion</h5>
                            <li>Inactive user accounts and data will be deleted after 6 months.
                            </li>
                            <li>Users can request data deletion by emailing hello@distrix.ai.
                            </li>
                        </ul>
                        <ul>
                            <h5>17. Geographical Jurisdiction</h5>
                            <li>Disputes will be handled in the Philippines, in the jurisdiction of Bonifacio Global City, Taguig, Metro Manila.</li>
                        </ul>
                        <ul>
                            <h5> 18. DistriX Disclaimer</h5>
                            <li> DistriX and its Services, including but not limited to its Services, Software, and customer service are provided to the User on an “as is” basis.</li>
                            <li>DistriX provides no warranty, assurance, or promise that its Services, Software, or customer service will work as intended on User’s phone, computer, tablet or other device.
                            </li>
                            <li>DistriX does not warrant or guarantee the availability, reliability, timeliness, accuracy, or quality of the Services, Software, or the Drivers.</li>
                            <li>DistriX is not liable for any losses, damages, claims, or costs including any consequential, indirect, or incidental losses or damages to the User’s mobile device or any applications stored therein as a result of installation or use of DistriX Software.</li>
                            <li> DistriX is not liable for any losses or damages, including but not limited to any injury which the User or any person may suffer, any damage to property, including the Goods, owned by or in possession of the User or any parties, or any Indirect Loss and Damage, resulting from the Services, matters relating to truckers, or the process of transporting the Goods. Any movement of the Goods involving a User and a Driver is a direct contract for hire between those parties to which DistriX is not a party.</li>
                            <li>In providing the Services, DistriX does not act as an agent or representative for the Wholesaler or Trucker or Retailer and is not a party to the contract for hire between a Wholesaler, Retailer and Trucker.</li>
                            <li> DistriX reserves the right to suspend or terminate any engagement with the User or User’s account, created in order to use the Services or Software should DistriX believe that the User violates any provisions of this Terms and Conditions and any other policy related to it.</li>
                        </ul>
                        <ul>
                            <h5>19. Intellectual Property Rights</h5>
                            <li>The Website, the Software, and all rights, title, and interest in and related thereto are the sole property of DistriX , its licensors, and are protected by Intellectual Property Laws. Except for, when applicable, the limited licenses expressly granted to you in these Terms and Conditions, DistriX reserves for itself and its licensors all other rights, title to, and interest.</li>
                            <li>Third party materials, if any, and all rights, title, and interest pertaining thereto, are the sole property of the relevant third parties and are subject to any applicable licenses, conditions and/or reservations.</li>
                            <li>Without limitation on the foregoing, the User may not reproduce, copy, modify, display, sell or distribute the Software, or any content displayed on the Software, or use them in any other way for public or commercial purpose without securing the consent of DistriX , its affiliates, and or the relevant third party. Violation of this provision would automatically terminate your permission to use the Software or access the Services, as such you must immediately destroy any and all copies you made of the Software or any content displayed therein.
                            </li>
                            <li>Notwithstanding anything to the contrary contained herein, this prohibition includes: (a) copying or adapting the HTML code used to generate web pages on the sites; (b) using or attempting to use engines, manual or automated software, tools, devices, agents, scripts robots or other means, devices, mechanisms or processes (including, but not limited to, browsers, spiders, robots, avatars or intelligent agents) to navigate, search, access, “scrape,” “crawl,” or “spider,” any web pages or any Services provided on the Software other than any search engine and search agents available from DistriX on the Software and other than generally available third party web browsers (e.g., Internet Explorer, Firefox, Safari, Opera, Chrome, etc.); and (c) aggregating, copying or duplicating in any manner any of the content or information available from the Software, without the express written consent of DistriX . The use of the content from the Software on any other website, intranet, or in a networked computer environment for any purpose is strictly prohibited.
                            </li>
                            <li> “DistriX ,” “DistriX  Logo,” “t logos,” “welcome to the REVOLUTION,” “DistriX ” and certain other names or logos are service marks or trademarks of DistriX , and all related product and service names, design marks and slogans are the service marks or trademarks of DistriX . The “look and feel” of the Software and Website (including, without limitation, the color combinations, button shapes, layout, design, and all other geographical elements) are also protected by DistriX trademarks, service marks, and copyrights). Any code that DistriX creates or may have created to generate or display the Software, Website or the pages of the Website are also protected by intellectual property rights. All other trademarks and service marks contained on the Software and Websites are the trademarks or service marks of their respective owners.
                            </li>
                        </ul>
                        <ul>
                            <h5>20. User Submitted Content and Feedback
                            </h5>
                            <li>. DistriX welcomes comments, and suggestions regarding the Services, Transport, and the Software and Website. Please note however, that DistriX does not accept nor consider creative ideas, suggestions, inventions or materials other than those which it has specifically solicited or requested.</li>
                            <li> If you submit feedback regarding the Software, Website, Services or Transport, please be specific in your comments and do not submit creative ideas, inventions, suggestions, or materials. If you proceed, despite this notice, in sending DistriX creative suggestions, ideas, drawings, concepts, inventions, or other content or information, you understand and agree that these submissions shall become property of DistriX . Further, by submitting the same, you irrevocably assign the right to these submissions and DistriX shall own exclusively all rights to these submissions and shall be entitled to the unrestricted use of these submissions for any purpose whatsoever, without compensation to you or any other person.
                            </li>
                        </ul>
                        <ul>
                            <h5>21. Miscellaneous</h5>
                            <li> DistriX may amend these Terms and Conditions from time to time and it reserves the right to do so without prior notice. Any amendments of the Terms and Conditions shall be posted on DistriX Software.</li>
                            <li> DistriX may, with User’s express consent, send any information regarding the Service or promotion to the User in the form of electronic messages which include, but not limited to, e-mail, SMS, automatic telephone message, or push notification on Software. The User permits DistriX to send these electronic messages to the User via the contact channels provided to DistriX by the User at the time of registering its account.
                            </li>
                            <li>The User accepts fully and unconditionally the current form of the Terms and Conditions as represented on the DistriX website at each use of the Services.
                            </li>
                            <li>These Terms and Conditions shall be governed by the laws of the Republic of the Philippines and the User submits to the jurisdiction of the competent courts of Pasig City, to the exclusion of any other court or venue.</li>
                        </ul>
                        <ul>
                            <h5>22. Refund and Cancellation Policy
                            </h5>
                            <p>DistriX offers refunds in certain circumstances according to the following guidelines:</p>
                            <li>If a fee has been deemed to be applied incorrectly to your account; and
                            </li>
                            <li> If you request a cancellation of a service, prior to an attempt for collection and where DistriX has not incurred a cost for the booking.</li>
                            <p>DistriX does not offer refunds:</p>
                            <li>If you have pre-purchased DistriX credit, DistriX will not issue a refund for any portion that has not been used once it expires or if you elect to close your account</li>
                            <li>If the service delivery has been delayed to the extent that it is outside the estimated time of delivery (ETD). Delivery times are an estimate and do not form part of your contract.</li>
                            <li> Refunds may be issued in the same method of payment used for the original booking
                                16.6 At the time of booking, you agree to the terms outlined contained within this user and refund policy. If you don’t agree, then discontinue use of the DistriX system and do not finalize your booking.
                            </li>
                            <li>DistriX reserves the right to modify any provisions of the user and policy without any notice to you.
                            </li>
                            <p>If you have any questions, comments or concerns about our refund and cancellation policy, you may contact us.</p>
                        </ul>
                        <ul>
                            <h5>23. User’s Personal Data</h5>
                            <p>Users confirm that the personal data provided by him/her are true and up to date.
                            </p>
                            <li>User agrees to allow DistriX use of his/her personal data (including but not limited to name, contact number, email, and address) and share such data to the Driver for the purpose of providing the Services and for the Driver to provide Transport. User also grants DistriX the freedom authority to compile, collect, store, and update his/her personal data to such extent, for and at such time period as may be necessary during the time DistriX provides the Services.</li>
                            <li>User agrees and acknowledges that DistriX compilation, collection, storage, use and transfer of his/her personal data is subject to DistriX Privacy and Personal Data Collection Policy (the “Privacy Policy”) as posted and periodically updated on DistriX the Website. User also acknowledges that he/she has read and understood the Privacy Policy before agreeing to the Terms and Conditions.</li>
                        </ul>
                        <h3>Retailers Terms and Conditions of Use</h3>
                        <p> Please read this page carefully. It contains the Terms and Conditions governing your access to and use of the DistriX Software, DistriX Services and Transport (as each defined below) provided by DistriX and/or the Operators who are independent contractors.</p>
                        <p>If you do not accept these Terms and Conditions or you do not meet or comply with their provisions, you may not use the DistriX Software and/or the Services. By using the Services, you hereby expressly agree to these Terms and Conditions.</p>
                        <p>These Terms and Conditions are effective as of 28 June 2023. If you have any questions, feel free to email us at hello@distrix.ai.   </p>
                        <ul>
                            <h5>
                                1. Introduction
                            </h5>
                            <li>Welcome to DistriX, your trusted platform for efficient product sourcing. These Terms and Conditions ("Agreement") outline your rights and responsibilities as a Retailer when using our services. By accessing or using DistriX, you agree to abide by these terms and conditions.</li>
                        </ul>
                        <ul>
                            <h5>2. Registration and User Profile</h5>
                            <li><b>Registration Process:</b> Retailers can join DistriX by registering, confirming their email, and completing their user profiles. Registration is free for Retailers.</li>
                            <li><b>User Responsibility:</b>  Retailers are responsible for providing accurate and lawful information during registration.
                            </li>
                        </ul>
                        <ul>
                            <h5>3. Access to Wholesalers' Products</h5>
                            <li><b>Accessing Products:</b> Retailers gain access to Wholesalers' products by registering on DistriX.</li>
                            <li><b>Registration Approval:</b> There is no approval process for Retailer registration. A valid email confirmation is sufficient to access the platform.</li>
                        </ul>
                        <ul>
                            <h5>4. Product Purchases</h5>
                            <li><b>Product Availability:</b>Retailers can browse and purchase products listed by Wholesalers on DistriX.
                            </li>
                        </ul>
                        <ul>
                            <h5>5. Liability for Product Issues</h5>
                            <li>
                                <b>Platform's Role:</b> DistriX operates solely as a platform connecting users and assumes no liability for issues related to the purchased products.
                            </li>
                            <li><b>Dispute Resolution:</b> While DistriX may mediate disputes between users, it holds no financial responsibility in such cases.</li>
                        </ul>
                        <ul>
                            <h5>6. User Reviews and Ratings</h5>
                            <li><b>Review and Rating System:</b> Retailers have the option to provide reviews and ratings for Wholesalers and their products. These reviews should accurately reflect the Retailer's experience.</li>
                            <li><b>Fake or Malicious Reviews:</b> Providing fake or malicious reviews is not tolerated and may result in account termination.</li>
                        </ul>
                        <ul>
                            <h5>7. Data Privacy and Security</h5>
                            <li><b> Data Handling:</b> DistriX takes data privacy and security seriously. Retailer data is protected and used only for platform-related purposes.
                            </li>
                            <li><b>Data Retention:</b> Inactive Retailer accounts and data will be deleted after 6 months.
                            </li>
                            <li><b>Data Deletion Requests:</b> Retailers can request the deletion of their data by emailing hello@distrix.ai.</li>
                        </ul>
                        <ul>
                            <h5>8. Termination of Accounts</h5>
                            <li><b>Account Termination:</b> DistriX reserves the right to terminate Retailer accounts for violations of these Terms and Conditions. Retailers can appeal within 15 days of termination via email to hello@distrix.ai.</li>
                        </ul>
                        <ul>
                            <h5>Notifications and Changes</h5>
                            <li><b>Notification of Changes:</b> DistriX will notify Retailers of significant changes to the platform with a 30-day notice period.</li>
                        </ul>
                        <ul><h5>10. Geographical Jurisdiction
                        </h5>
                            <li><b>Dispute Resolution Location:</b> Disputes will be handled in the Philippines, in the jurisdiction of Bonifacio Global City, Taguig, Metro Manila.</li>
                        </ul>
                        <ul>
                            <h5>11. DistriX Disclaimer  </h5>
                                <li>DistriX and its Services, including but not limited to its Services, Software, and customer service are provided to the User on an “as is” basis.</li>
                                <li> DistriX provides no warranty, assurance, or promise that its Services, Software, or customer service will work as intended on User’s phone, computer, tablet or other device.</li>
                                <li>DistriX does not warrant or guarantee the availability, reliability, timeliness, accuracy, or quality of the Services, Software, or the Drivers.
                                </li>
                                <li>DistriX is not liable for any losses, damages, claims, or costs including any consequential, indirect, or incidental losses or damages to the User’s mobile device or any applications stored therein as a result of installation or use of DistriX Software.</li>
                                <li>DistriX is not liable for any losses or damages, including but not limited to any injury which the User or any person may suffer, any damage to property, including the Goods, owned by or in possession of the User or any parties, or any Indirect Loss and Damage, resulting from the Services, matters relating to truckers, or the process of transporting the Goods. Any movement of the Goods involving a User and a Driver is a direct contract for hire between those parties to which DistriX is not a party.
                                </li>
                                <li>In providing the Services, DistriX does not act as an agent or representative for the Wholesaler or Trucker or Retailer and is not a party to the contract for hire between a Wholesaler, Retailer and Trucker.</li>
                                <li>DistriX reserves the right to suspend or terminate any engagement with the User or User’s account, created in order to use the Services or Software should DistriX believe that the User violates any provisions of this Terms and Conditions and any other policy related to it.
                                </li>
                          
                        </ul>
                        <ul>
                            <h5>12. Intellectual Property Rights
                            </h5>
                            <li>The Website, the Software, and all rights, title, and interest in and related thereto are the sole property of DistriX , its licensors, and are protected by Intellectual Property Laws. Except for, when applicable, the limited licenses expressly granted to you in these Terms and Conditions, DistriX reserves for itself and its licensors all other rights, title to, and interest.
                            </li>
                            <li>Third party materials, if any, and all rights, title, and interest pertaining thereto, are the sole property of the relevant third parties and are subject to any applicable licenses, conditions and/or reservations.
                            </li>
                            <li>Without limitation on the foregoing, the User may not reproduce, copy, modify, display, sell or distribute the Software, or any content displayed on the Software, or use them in any other way for public or commercial purpose without securing the consent of DistriX , its affiliates, and or the relevant third party. Violation of this provision would automatically terminate your permission to use the Software or access the Services, as such you must immediately destroy any and all copies you made of the Software or any content displayed therein.
                            </li>
                            <li>Notwithstanding anything to the contrary contained herein, this prohibition includes: (a) copying or adapting the HTML code used to generate web pages on the sites; (b) using or attempting to use engines, manual or automated software, tools, devices, agents, scripts robots or other means, devices, mechanisms or processes (including, but not limited to, browsers, spiders, robots, avatars or intelligent agents) to navigate, search, access, “scrape,” “crawl,” or “spider,” any web pages or any Services provided on the Software other than any search engine and search agents available from DistriX on the Software and other than generally available third party web browsers (e.g., Internet Explorer, Firefox, Safari, Opera, Chrome, etc.); and (c) aggregating, copying or duplicating in any manner any of the content or information available from the Software, without the express written consent of DistriX . The use of the content from the Software on any other website, intranet, or in a networked computer environment for any purpose is strictly prohibited.
                            </li>
                            <li>“DistriX ,” “DistriX  Logo,” “t logos,” “welcome to the REVOLUTION,” “DistriX ” and certain other names or logos are service marks or trademarks of DistriX , and all related product and service names, design marks and slogans are the service marks or trademarks of DistriX . The “look and feel” of the Software and Website (including, without limitation, the color combinations, button shapes, layout, design, and all other geographical elements) are also protected by DistriX trademarks, service marks, and copyrights). Any code that DistriX creates or may have created to generate or display the Software, Website or the pages of the Website are also protected by intellectual property rights. All other trademarks and service marks contained on the Software and Websites are the trademarks or service marks of their respective owners.
                            </li>
                        </ul>
                        <ul>
                            <h5>13. User Submitted Content and Feedback</h5>
                            <li>DistriX welcomes comments, and suggestions regarding the Services, Transport, and the Software and Website. Please note however, that DistriX does not accept nor consider creative ideas, suggestions, inventions or materials other than those which it has specifically solicited or requested.
                            </li>
                            <li>If you submit feedback regarding the Software, Website, Services or Transport, please be specific in your comments and do not submit creative ideas, inventions, suggestions, or materials. If you proceed, despite this notice, in sending DistriX creative suggestions, ideas, drawings, concepts, inventions, or other content or information, you understand and agree that these submissions shall become property of DistriX . Further, by submitting the same, you irrevocably assign the right to these submissions and DistriX shall own exclusively all rights to these submissions and shall be entitled to the unrestricted use of these submissions for any purpose whatsoever, without compensation to you or any other person.
                            </li>
                        </ul>
                        <ul>
                            <h5>14. Miscellaneous</h5>
                            <li>DistriX may amend these Terms and Conditions from time to time and it reserves the right to do so without prior notice. Any amendments of the Terms and Conditions shall be posted on DistriX Software.</li>
                            <li>DistriX may, with User’s express consent, send any information regarding the Service or promotion to the User in the form of electronic messages which include, but not limited to, e-mail, SMS, automatic telephone message, or push notification on Software. The User permits DistriX to send these electronic messages to the User via the contact channels provided to DistriX by the User at the time of registering its account.
                            </li>
                            <li>The User accepts fully and unconditionally the current form of the Terms and Conditions as represented on the DistriX website at each use of the Services.
                            </li>
                            <li>These Terms and Conditions shall be governed by the laws of the Republic of the Philippines and the User submits to the jurisdiction of the competent courts of Pasig City, to the exclusion of any other court or venue.
                            </li>
                        </ul>
                        <ul>
                            <h5>15. Refund and Cancellation Policy
                            </h5>
                            <p>DistriX offers refunds in certain circumstances according to the following guidelines:
                            </p>
                            <li> If a fee has been deemed to be applied incorrectly to your account; and</li>
                            <li> If you request a cancellation of a service, prior to an attempt for collection and where DistriX has not incurred a cost for the booking.
                            </li>
                            <p>DistriX does not offer refunds:</p>
                            <li>If you have pre-purchased DistriX credit, DistriX will not issue a refund for any portion that has not been used once it expires or if you elect to close your account
                            </li>
                            <li> If the service delivery has been delayed to the extent that it is outside the estimated time of delivery (ETD). Delivery times are an estimate and do not form part of your contract.
                            </li>
                            <li>Refunds may be issued in the same method of payment used for the original booking
                            </li>
                            <li>At the time of booking, you agree to the terms outlined contained within this user and refund policy. If you don’t agree, then discontinue use of the DistriX system and do not finalize your booking.
                            </li>
                            <li>DistriX reserves the right to modify any provisions of the user and policy without any notice to you.
                            </li>
                            <li>If you have any questions, comments or concerns about our refund and cancellation policy, you may contact us at hello@distrix.ai
</li>
                        </ul>
                        <ul>
                            <h5>16. User’s Personal Data</h5>
                            <p> Users confirm that the personal data provided by him/her are true and up to date.
                            </p>
                            <li>User agrees to allow DistriX use of his/her personal data (including but not limited to name, contact number, email, and address) and share such data to the Driver for the purpose of providing the Services and for the Driver to provide Transport. User also grants DistriX the freedom authority to compile, collect, store, and update his/her personal data to such extent, for and at such time period as may be necessary during the time DistriX provides the Services.
                            </li>
                            <li>User agrees and acknowledges that DistriX compilation, collection, storage, use and transfer of his/her personal data is subject to DistriX Privacy and Personal Data Collection Policy (the “Privacy Policy”) as posted and periodically updated on DistriX the Website. User also acknowledges that he/she has read and understood the Privacy Policy before agreeing to the Terms and Conditions.
</li>
                        </ul>
                    </div>
                </Container>
            </section>
        </>
    );
};
export default TermCondition;