import { Accordion, Col, Container, Row } from "react-bootstrap";

const Faq = () => {
    return (
        <>
            <section className="notification-area">
                <Container>
                    <div className="manage-truck-area faq-area">
                        <div className="product-management-top">
                            <h3>Frequently <span>Asked</span> Questions</h3>
                        </div>
                        <Row>
                            <Col md={12} lg={6}>
                                <h5>For Wholesalers</h5>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Do I have to register to become a user?</Accordion.Header>
                                        <Accordion.Body>
                                            Yes, you must register to be able to use the DistriX services. Registration is simple and would only take 2 minutes of your time.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="01">
                                        <Accordion.Header>Can I sell any product?</Accordion.Header>
                                        <Accordion.Body>
                                        Yes, for as long as your products fits in our business category and product category. The only exception is if your product is tagged by the government as dangerous and illegal, such as: Explosives, Ammunition, Poisons, Lethal Weapons and the such.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>How can DistriX help me find retailers for my products?</Accordion.Header>
                                        <Accordion.Body>
                                            DistriX uses AI algorithms to match your products with retailers whose interests align with your offerings, expanding your customer base and boosting sales.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Can I track my inventory and manage orders on DistriX?</Accordion.Header>
                                        <Accordion.Body>
                                        Yes, DistriX provides tools for inventory management and order processing, allowing you to streamline your operations efficiently.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Is my data safe on the platform?</Accordion.Header>
                                        <Accordion.Body>
                                        DistriX prioritizes data security and employs robust encryption and security measures to protect your information.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>What if I have questions or need assistance?</Accordion.Header>
                                        <Accordion.Body>
                                        DistriX offers customer support to assist you with any inquiries or issues you may encounter while using the platform.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>How does DistriX handle payment transactions?</Accordion.Header>
                                        <Accordion.Body>
                                        DistriX offers secure payment processing to ensure smooth and reliable financial transactions between wholesalers and retailers.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>Can I customize pricing and discounts for retailers?</Accordion.Header>
                                        <Accordion.Body>
                                        Yes, you have the flexibility to set pricing and discounts tailored to your preferences and business strategy.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header>What types of products are available on DistriX?</Accordion.Header>
                                        <Accordion.Body>
                                        DistriX features a diverse range of products from various wholesalers, providing a wide selection for retailers.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                            <Col md={12} lg={6}>
                                <h5>For Truckers</h5>
                                <Accordion>
                           
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Do I have to register to become a user?</Accordion.Header>
                                        <Accordion.Body>
                                        Yes, you must register to be able to use the DistriX services. Registration is simple and would only take 2 minutes of your time.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Can I use DistriX as a full-time solution for my trucking business?</Accordion.Header>
                                        <Accordion.Body>
                                        No, we do not recommend becoming a full-time trucker at DistriX. Our goal is to fill up your empty return trips or commonly called "Deadhead." Our target truckers are those with regular trips from their principal clients, and we capture your empty return trip and match it with available deliveries from our wholesalers to the retailers that match your return trip.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>How does DistriX match me with delivery opportunities?</Accordion.Header>
                                        <Accordion.Body>
                                        DistriX uses AI algorithms to match your empty return trips with scheduled deliveries to your destination, optimizing your routes and increasing your revenue potential.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>What types of cargo can I transport with DistriX?</Accordion.Header>
                                        <Accordion.Body>
                                        DistriX offers a variety of cargo transportation opportunities, from dry goods to perishables, depending on your vehicle's capacity and capabilities.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>How do I get paid for my services?</Accordion.Header>
                                        <Accordion.Body>
                                        Payment for your transportation services is facilitated securely through the DistriX platform, ensuring reliable and timely compensation.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>Can I choose the routes I want to take?</Accordion.Header>
                                        <Accordion.Body>
                                        You have the flexibility to select routes based on your preferences and availability, and DistriX will match you with suitable delivery opportunities accordingly.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header>What if I encounter issues during a delivery?</Accordion.Header>
                                        <Accordion.Body>
                                        DistriX provides customer support to assist you with any issues or questions you may have while fulfilling deliveries.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header>Is there a rating system for truckers on DistriX?</Accordion.Header>
                                        <Accordion.Body>
                                        Yes, there is a rating system that allows retailers and wholesalers to provide feedback based on their experience with your services, helping you build a positive reputation on the platform.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                            <Col md={12} lg={6}>
                                <h5>For Retailers</h5>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Do I have to register to become a user?</Accordion.Header>
                                        <Accordion.Body>
                                        Yes, you must register to be able to use the DistriX services. Registration is simple and would only take 2 minutes of your time.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>How can DistriX help me find products for my retail business?</Accordion.Header>
                                        <Accordion.Body>
                                        DistriX uses AI algorithms to match your retail needs with products from wholesalers, offering you a wide selection of goods to choose from.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Can I track my orders and manage my inventory on DistriX?</Accordion.Header>
                                        <Accordion.Body>
                                        Yes, DistriX provides tools for order tracking and inventory management, helping you streamline your retail operations.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Is my data secure on the platform?</Accordion.Header>
                                        <Accordion.Body>
                                        DistriX employs robust security measures to ensure the safety and confidentiality of your data.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>What if I have questions or need assistance?</Accordion.Header>
                                        <Accordion.Body>
                                        DistriX offers customer support to assist you with any inquiries or issues you may encounter while using the platform.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>How do payments work on DistriX?</Accordion.Header>
                                        <Accordion.Body>
                                        DistriX offers secure payment processing to facilitate seamless and reliable financial transactions between retailers and wholesalers.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>Can I negotiate pricing and discounts with wholesalers?</Accordion.Header>
                                        <Accordion.Body>
                                        Yes, you have the flexibility to set pricing and discounts tailored to your preferences and business strategy.
                                        </Accordion.Body>
                                    </Accordion.Item>
                              
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header>What types of products are available on DistriX?</Accordion.Header>
                                        <Accordion.Body>
                                        DistriX features a diverse range of products from various wholesalers, providing you with a wide array of options for your retail business.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>

                    </div>
                </Container>
            </section>
        </>
    );
};
export default Faq;