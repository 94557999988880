import { Col, Container, Row } from "react-bootstrap"

const Footer = () => {
    
    return (
        <>
            <section className="footer">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="footer-left">
                                <img src={require("../../assets/images/logo.png")} />
                                <p>DistriX is the first e-commerce platform design for wholesale in the Philippines. It is powered by AI that connects the wholesalers to the truckers and to the retailers to drive innovation and efficiency.</p>
                                <ul>
                                    <li><a href="https://www.facebook.com/distrixai" target="blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    {/* <li><i className="fa fa-behance-square" aria-hidden="true"></i></li> */}
                                    <li><a href="https://www.linkedin.com/company/distrixofficial/" target="blank"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    {/* <li><i className="fa fa-instagram" aria-hidden="true"></i></li> */}
                                    <li><a href="https://www.youtube.com/@DistriXAI" target="blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="footer-center">
                                <h6>quick links</h6>
                                <ul>
                                    <li><a href="/privacy-policy" target="_blank">Privacy policy</a></li>
                                    <li><a href="/term-condition" target="_blank">Terms & Conditions</a></li>
                                    {/* <li>Cookies policy</li> */}
                                  </ul>
                            </div>
                        </Col>
                        <Col md={3}>
                        <div className="footer-center footer-right">
                                <h6>Contact Us</h6>
                                <ul>
                                    {/* <li><i className="fa fa-phone" aria-hidden="true"></i> +63 345 566 4567</li> */}
                                    <li><i className="fa fa-envelope" aria-hidden="true"></i> hello@distrix.ai</li>
                                    <li><i className="fa fa-map-marker" aria-hidden="true"></i>San Miguel Bulacan Philippines 3011</li>
                                  </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className="footer-bottom">
                <Container>
                    <p>Distrix 2023. © Copyright reserved.</p>
             </Container>
            </div>
        </>
    )
}
export default Footer