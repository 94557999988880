import { Button, Container, Nav, Navbar } from "react-bootstrap"
import { context } from "../../GlobalContext/GlobalContext";
import { useState, useContext, useEffect } from "react";
import CommonHeader from "./CommonHeader";

const Header = ({headerClass}) => {

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 10);
      });
    }, []);
  
    return (
        <>
            <section  className={scroll ? "header scrolled" : "header"}>
                <Navbar expand="lg" className="bg-body-tertiary">
                    <Container className={`${headerClass ? headerClass : ""}`}>
                        <CommonHeader />
                    </Container>
                </Navbar>
            </section>
        </>
    )
}
export default Header