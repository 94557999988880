import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { useState,useContext,useEffect } from "react";
import {bUrl,trucker_type,wholeseller_type,retailer_type} from "../../config/config";
import { apiService} from "../../services/api.services";
import { validateService} from "../../services/validate.services";
import { context } from "../../GlobalContext/GlobalContext";
import Loader from "../common/Loader";
import Swal from "sweetalert2";
import SuccessPopup from "../modal/SuccessPopup"
import Errorpopup from "../modal/Errorpopup"
import { useNavigate } from "react-router-dom";
const Login = () => {
   
    const [ isLoader , setIsLoader] = useState(false)
    const [show, setShow] = useState(false);
    const [ message1 , setmessage1] = useState("")
    const [ message2 , setmessage2] = useState("")
    const [showErr, setShowErr] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [ errmessage1 , seterrmessage1] = useState("Error")
    const [ errmessage2 , seterrmessage2] = useState("")
    const [ redirectlink , setredirectlink] = useState("/")
    const [ resend_btn , setresendbt] = useState(false)
    const navigate = useNavigate();
    const handleClose = () => {
      window.location.href = redirectlink
     
      setShow(false);
    }
    const handleCloseErr = () => {
      if(resend_btn){
        resendLink()
      }
   
     
      setShowErr(false);
    }
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
       handleSubmit()
      }
    };
    const handleShowErr = () => setShowErr(true);
    const handleShow = () => setShow(true);
    const globalData = useContext(context);
    useEffect(() => {
      if(show){
      const close = (e) => {
        console.log(localStorage.getItem("token"),"======token")
        // if(show){
        console.log(e.keyCode,"======keyCode")
        if (e.key === 'Enter') {
         
           handleClose()
        }
      // }
      }
      window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
      }
       
  },[show])
    // console.log("======globalData====",globalData)
   
    const [loginFields, setloginFields] = useState({
        email: "",
        password: "",
       
       });
       const [loginFieldsErrs, setloginFieldsErrs] = useState({
        email: false,
        emailErr:"",
        password: false,
        passwordErr:"",
       
       });
       const resendLink = (e) => {
        setresendbt(false)
        setIsLoader(true)
      
         let data = {
            email: loginFields.email,
            
          }
          apiService .resendLink(data).then((res) => {
                handleShow()
                setmessage1("Success")
               setmessage2(res.data.message)
           }) .catch((err) => {
              seterrmessage2(err.response.data.message)
              handleShowErr()
              setIsLoader(false)
             console.log("contact us api error", err.response);
            })
        
      };
       
       const handleSubmit = () => {
      
        // e.preventDefault();
       let formValidated =  validateService.login(setloginFieldsErrs,loginFields)
        if (formValidated) {
          setIsLoader(true)
          let data = {
            email: loginFields.email,
            password: loginFields.password,
            
          };
          // console.log("====login",data)
          apiService .login(data).then((res) => {
 
            globalData.setToken(res.data.data.token);
            globalData.setprofileStep(res.data.data.step)
         
            localStorage.setItem("web_token",res.data.data.token)

            localStorage.setItem("cart_count",res.data.data.cart_count)
       
            localStorage.setItem("user_type",res.data.data.groups[0])
    
            globalData.setuser_name(res.data.data.first_name+" "+res.data.data.last_name )
            setIsLoader(false)
   
            localStorage.setItem("user_name", res.data.data.first_name+" "+res.data.data.last_name)
            if(res.data.data.image!=null){
                    globalData.setuser_image(bUrl+res.data.data.image )
                    localStorage.setItem("user_image", bUrl+res.data.data.image)
            }
          if(res.data.data.groups[0]==parseInt(trucker_type)){
           
           
               if(res.data.data.is_profile_completed){
                localStorage.setItem("is_profile_completed","yes")
               setredirectlink("/dashboard?tab=dashboard")
              }else{
                localStorage.setItem("is_profile_completed","no")
                localStorage.setItem("profile_step",res.data.data.step)
                setredirectlink("/create_profile")
              }
            }else if(res.data.data.groups[0]==parseInt(wholeseller_type)){
              if(res.data.data.is_profile_completed){
                setredirectlink("/dashboard")
               }else{
                 localStorage.setItem("profile_step",res.data.data.step)
                 setredirectlink("/create_profile")
               }


            }else{
              // console.log("=======res.data.data.is_profile_completed===",res.data.data.is_profile_completed)
              if(res.data.data.is_profile_completed){
                localStorage.setItem("is_profile_completed","yes")
                setredirectlink("/product-listing")
               }else{
                localStorage.setItem("is_profile_completed","no")
                setredirectlink("/create_profile")
                 localStorage.setItem("profile_step",res.data.data.step)
               
               }
            //   handleShow()
            //   setmessage1("Success")
            //  setmessage2("This user  type functionality will implement later.Please login with trucker type user")
            }
                handleShow()
                setmessage1("Success")
               setmessage2(res.data.message)
                setloginFields({
                    email: "",
                    password: "",
                });
                setloginFieldsErrs({
                    email: false,
                    emailErr:"",
                    password: false,
                    passwordErr:"",
                   
                  });
               
            })
            .catch((err) => {
              // if(err.response.data.message=="Please Verify your email first"){

              // }
               console.log(err)
         
            
              if (err.response.data.message == "Please Verify your email first") {
                seterrmessage2("Please verify your email first.")
                setresendbt(true)
                setredirectlink("/login")
              } else {
                seterrmessage2(err.response.data.message)
              }
              handleShowErr()
              //  Swal.fire("Error", err.response.data.message, "error")
              setIsLoader(false)
            //   Swal.fire("Error", "Something went wrong.", "error");
              console.log("contact us api error", err.response);
            });
        }
      };
    return (
        <>
          {isLoader && <Loader/>}
            <section className="registration-area">
                <Container>
                    <Row>
                        <Col md={12} lg={6}>
                            <div className="registration-left">
                                <div className="dot-vector">
                                    <img src={require("../../assets/images/dot-vector.png")} />
                                </div>
                                <h4>Sign in</h4>
                                <Form>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="Enter Email" 
                                         value={loginFields.email}
                                         maxLength={100}
                                         onChange={(e) => {
                                           setloginFields((prevObj) => {
                                             return {
                                               ...prevObj,
                                               email: e.target.value,
                                             };
                                           });
                                        }}
                                        onKeyDown={handleKeyDown}
                                        />
                                        <p className="errors">{loginFieldsErrs.emailErr}</p>
                                    </Form.Group>

                                    <Form.Group className="mb-4" controlId="formBasiPassword">
                                        <Form.Label>Password</Form.Label>
                                        <div className="pass-view">
                                        <Form.Control type={showPass ? "text" :"password"} placeholder="Enter Password" 
                                         value={loginFields.password}
                                         maxLength={50}
                                         onChange={(e) => {
                                           setloginFields((prevObj) => {
                                             return {
                                               ...prevObj,
                                               password: e.target.value,
                                             };
                                           });
                                        }}
                                        onKeyDown={handleKeyDown}
                                        />
                                        {!showPass ? <i class="fa fa-eye" aria-hidden="true" onClick={() => setShowPass(!showPass)}></i> :
                                        <i class="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPass(!showPass)}></i>}
                                        </div>

                                        <a href="/forgot-password" className="forgot-password-area">Forgot Password?</a>
                                        <p className="errors">{loginFieldsErrs.passwordErr}</p>
                                    </Form.Group>

                                
                                  
                                    <Button variant="unset" type="button" onKeyDown={handleKeyDown} onClick={handleSubmit}>
                                       sign in
                                    </Button>
                                    <p className="bottom-text" onClick={()=>window.location.href="/register"}>Don’t have an Account? <span>Sign up</span></p>
                                </Form>
                            </div>
                        </Col>
                        <Col lg={6}></Col>
                    </Row>
                </Container>
                <SuccessPopup show={show} handleClose={handleClose} message1={message1} message2={message2} redirectlink={redirectlink}/>
                <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} resend_btn={resend_btn}/>
            </section>
            {/* <Modal className="success-box" show={showErr} onHide={handleCloseErr} centered>
                <Modal.Header>
                    <Modal.Title>
                      
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Error</h5>
                    <p>bbnbnbb</p>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={handleClose}>
                        Resend Link
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </>
    );
};
export default Login;