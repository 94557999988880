import { useState, useEffect,useContext } from "react";
import { Button, Col, Container, Row, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { context } from "../../GlobalContext/GlobalContext";
import { EMAILREGEX } from "../../config/config";
import { apiService } from "../../services/api.services";
import { validateService } from "../../services/validate.services";
import Loader from "../common/Loader";
import SuccessPopup from "../modal/SuccessPopup"
import Errorpopup from "../modal/Errorpopup"

const Registration = () => {


  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [isLoader, setIsLoader] = useState(false)
  const [show, setShow] = useState(false);
  const [message1, setmessage1] = useState("")
  const [redirectlink, setredirectlink] = useState("/")
  const [message2, setmessage2] = useState("")
  const [userType, setuserType] = useState([]);
  const [ errmessage1 , seterrmessage1] = useState("Error")
  const [ errmessage2 , seterrmessage2] = useState("")
  const [showErr, setShowErr] = useState(false);
  const globalData = useContext(context);
  const handleCloseErr = () => {
    setShowErr(false)
  }
  const handleShowErr = () => setShowErr(true);
  const handleClose = (link) => {
    window.location.href = link
    setShow(false);
  }
  const handleShow = () => setShow(true);
  const [registerFields, setregisterFields] = useState({
    email: "",
    password: "",
    cpassword: "",
    user_type: "",
    terms_and_conditions: "",
  });
  const [registerFieldsErrs, setregisterFieldsErrs] = useState({
    email: false,
    emailErr: "",
    password: false,
    passwordErr: "",
    cpasswordErr: false,
    cpasswordErr: "",
    user_type: false,
    user_typeErr: "",
    terms_and_conditions: false,
    terms_and_conditionsErr: "",
  });

  const user_groups = () => {
    apiService.user_groups().then((res) => {
      let type = []
      console.log(res.data)
      {
        res.data.map((item, index) => (
          type.push({ value: item.id, label: item.name })
        ))
      }
      setuserType(type)



    })
  }
  useEffect(() => {
    user_groups()
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    let formValidated = validateService.register(setregisterFieldsErrs, registerFields)
    if (formValidated) {
      setIsLoader(true)
      let data = {
        email: registerFields.email,
        user_type: registerFields.user_type,
        password: registerFields.password,
        terms_and_conditions: registerFields.terms_and_conditions
        // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };

      apiService.register(data).then((res) => {
        handleShow()
        setIsLoader(false)
        setregisterFields({
          user_type: "",
          password: "",
          email: "",
          cpassword: "",
          terms_and_conditions: false,
        });
        setregisterFieldsErrs({
          terms_and_conditionsErr: "",
        });
        console.log("=====",res.data.data.token)
        localStorage.setItem("web_token",res.data.data.token)
        globalData.setToken(res.data.data.token);
        globalData.setprofileStep(1)
        localStorage.setItem("user_type",res.data.data.groups[0])
        localStorage.setItem("profile_step",1)
        localStorage.setItem("is_profile_completed","no")
        setredirectlink("/create_profile")
         setmessage1("Registration has been completed successfully.")
        // setmessage2("Please check your email and click on the verification URL to complete the registration.")
        // setredirectlink("/login")
      })
        .catch((err) => {
          setIsLoader(false)
          seterrmessage2(err.response.data.message)
          handleShowErr()
          //   Swal.fire("Error", "Something went wrong.", "error");
          console.log("contact us api error", err);
        });
    }
  };
  return (
    <>
      {isLoader && <Loader />}
      <section className="registration-area">
        <Container>
          <Row>
            <Col  md={12} lg={6}>
              <div className="registration-left">
                <div className="dot-vector">
                  <img src={require("../../assets/images/dot-vector.png")} />
                </div>
                <h4>Registration</h4>
                <Form>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>User Type</Form.Label>
                    <Select
                      // value={registerFields.user_type}
                      onChange={(e) => {
                        console.log(e)
                        setregisterFields((prevObj) => {
                          return {
                            ...prevObj,
                            user_type: e.value,
                          };
                        });
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          background: "transparent",
                          height: "50px",
                          borderRadius: "30px",
                          border: "1px solid #00000029",
                          boxShadow: "none",

                          "&:focus": {
                            borderColor: "#00000029",
                            backgroundColor: "#00000029",
                          },
                          "&:hover": {
                            borderColor: "#00000029",
                          },
                          
                        }),
                      }} options={userType} />
                    <p className="errors">{registerFieldsErrs.user_typeErr}</p>
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>

                    <Form.Control type="email" placeholder="Enter Email"
                      value={registerFields.email}
                      maxLength={100}
                      onChange={(e) => {
                        setregisterFields((prevObj) => {
                          return {
                            ...prevObj,
                            email: e.target.value,
                          };
                        });
                      }}
                    />
                    <p className="errors">{registerFieldsErrs.emailErr}</p>
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="formBasiPassword">
                    <Form.Label>Password</Form.Label>
                    <div className="pass-view">
                      <Form.Control type={showPass1 ? "text" :"password"} placeholder="Enter Password"
                        value={registerFields.password}
                        minLength={8} maxLength={32}
                        onChange={(e) => {
                          setregisterFields((prevObj) => {
                            return {
                              ...prevObj,
                              password: e.target.value,
                            };
                          });
                        }}
                      />
                      {!showPass1 ? <i class="fa fa-eye" aria-hidden="true" onClick={() => setShowPass1(!showPass1)}></i> :
                        <i class="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPass1(!showPass1)}></i>}
                      </div>
                    <p className="errors">{registerFieldsErrs.passwordErr}</p>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <div className="pass-view">
                    <Form.Control minLength={8} maxLength={32} type={showPass2 ? "text" :"password"} placeholder="Enter Confirm Password"
                      value={registerFields.cpassword}
                     
                      onChange={(e) => {
                        setregisterFields((prevObj) => {
                          return {
                            ...prevObj,
                            cpassword: e.target.value,
                          };
                        });
                      }}
                    />
                    {!showPass2 ? <i class="fa fa-eye" aria-hidden="true" onClick={() => setShowPass2(!showPass2)}></i> :
                    <i class="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPass2(!showPass2)}></i>}
                    </div>
                    <p className="errors">{registerFieldsErrs.cpasswordErr}</p>

                  </Form.Group>

                  <Form.Group className="mb-4" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label={
                      <>
                        <span>I accept the <a className="terms-condition" href="/term-condition" target="_blank">Terms & Conditions</a>.</span>
                      </>
                    }
                      onChange={(e) => {
                        setregisterFields((prevObj) => {
                          return {
                            ...prevObj,
                            terms_and_conditions: e.target.checked
                          };
                        });
                      }}
                    />
                    <p className="errors">{registerFieldsErrs.terms_and_conditionsErr}</p>
                  </Form.Group>
                  <Button variant="unset" type="button" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Form>
              </div>
            </Col>
            <Col lg={6}></Col>
          </Row>
        </Container>
      </section>
      <SuccessPopup show={show} handleClose={handleClose} message1={message1} message2={message2} redirectlink={redirectlink} />
      <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} redirectlink={redirectlink}/>
      {/* modal */}
      {/* <Modal className="success-box" show={show} onHide={handleClose}  centered>
                <Modal.Header>
                    <Modal.Title>
                        <img src={require("../../assets/images/chemark.png")} alt="img" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Great! Let’s verify your email.</h5>
                    <p>Please check your email and click on the verification URL to complete the registration.</p>
                </Modal.Body>
                <Modal.Footer>
                   
                    <Button variant="primary" onClick={handleClose}>
                    Verify
                    </Button>
                </Modal.Footer>
            </Modal> */}
    </>
  );
};
export default Registration