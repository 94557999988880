import Routing from './routing/Routing';
import "./assets/css/style.css"

function App() {
  return (
   <Routing />
  );
}

export default App;
