
import Table from 'react-bootstrap/Table';
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { useEffect, useState, useContext } from "react";
import { apiService } from "../../services/api.services";
import { useNavigate, useParams } from "react-router-dom";

import Nodata from "../pages/trucker/Dashboard/Nodata";
import Loader from "./Loader";
import moment from "moment";
import Pagination from "./Pagination";
import { baseUrl, trucker_type } from "../../config/config"
const Transaction = ({ type }) => {
    const [transactions, setTransactions] = useState();
    const [isLoader, setIsLoader] = useState();
    const [page, setpage] = useState(1);
    const [total_page, settotal_page] = useState('')
    const [start_date, setstart_date] = useState('')
    const [end_date, setend_date] = useState()
    const [total_revenue, settotal_revenue] = useState(0)


    useEffect(() => {
        console.log("===trannnnnnnn=======")
        get_transactions(page)
    }, []);
    const get_transactions = (page) => {
        setIsLoader(true)
        apiService.transactions(page, start_date, end_date).then((res) => {
            console.log("===trannnnnnnn=======")
            settotal_revenue(res.data.total_revenue ? res.data.total_revenue : 0)
            setTransactions(res.data.data)
            settotal_page(res.data.total_page)
            setIsLoader(false)
        })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.clear();
                    window.location.href = "/login"
                }
                setIsLoader(false)
                //   Swal.fire("Error", "Something went wrong.", "error");
                //  console.log("contact us api error", err.response.data.message);
            });
    }
    const download_excel = () => {

        const token = localStorage.getItem("web_token")
        window.open(baseUrl + "/download_excel/" + token + "/?type=order")

    }
    const orderDetail = (id) => {
        window.open("/order-detail/" + id)
    }
    const handlePageClick = (event) => {
        setpage(event.selected + 1)
        get_transactions(event.selected + 1)
    }
    return (
        <>
            {isLoader && <Loader />}
            <div className="manage-truck-area">
                <div className=" product-management-area">
                    <div className="product-management-top">
                        <h3>Transaction Management</h3>
                        <p>Easily monitor & generate reports on your business performance.</p>
                        <Row className="mt-3 spacing-left">
                            <Col md={4}>
                                <Form.Group
                                    className=" revenue-summary-right-content"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>From:</Form.Label>
                                    <input className="form-control" placeholder="From Date" id="myLocalDate1" type="date" value={start_date} dateFormat="YYYY-MM-DD" onChange={(e) => setstart_date(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group
                                    className=" revenue-summary-right-content"
                                    controlId="exampleForm.ControlInput2"
                                >

                                    <Form.Label className="ms-3">To:</Form.Label>

                                    <input className="form-control" id="myLocalDate" type="date" max={moment(end_date).format('YYYY-MM-DD ')} dateFormat="YYYY-MM-DD " onChange={(e) => setend_date(e.target.value)} />

                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <div className='transaction-top-btns'>
                                    <Button type='button' variant='unset' onClick={() => get_transactions(page)}>Apply</Button>
                                    {/* <Button className="addtruck-btn" style={{color:"#fff"}} variant="unset" type="button"  onClick={()=>get_transactions(page)}>Apply</Button> */}

                                </div>
                            </Col>
                        </Row>
                        {/* <input className="form-control"  id="myLocalDate1" type="date"   dateFormat="YYYY-MM-DD" onChange={(e)=>setstart_date(e.target.value)}/>
                        <input className="form-control"  id="myLocalDate" type="date"  dateFormat="YYYY-MM-DD " onChange={(e)=>setend_date(e.target.value)}/>
                        <Button onClick={()=>get_transactions(page)}>Filter</Button> */}
                    </div>
                    {type ?
                        <div>
                            {transactions && transactions.length > 0 ?
                                <div className="transaction-top-left">
                                    <p>Total Earnings: </p>
                                    <h4>Php {total_revenue ? total_revenue : null}</h4>
                                </div> : null}
                        </div> : null}
                </div>
                <div className='export-csv-btn'>
                    {localStorage.getItem("user_type") == trucker_type && transactions && transactions.length > 0 ? <button type="button" variant="unset" className="export-csv" onClick={download_excel}>Export Excel</button> : null}
                </div>
                <div className="product-management-detail">
                    {!transactions || transactions.length == 0 ?
                        <Nodata message1={"No Data Found"} message2={""} /> :
                        <>
                            <Table className='inner-product-table'>
                                <thead>
                                    {localStorage.getItem("user_type") == trucker_type ?
                                        <tr className='table-content-top  '>
                                            <th>S.NO</th>
                                            <th>Date</th>
                                            <th>Plate No.</th>
                                            <th>Truck Type</th>
                                            <th>Order ID</th>
                                            <th className='address-transation'>Destination</th>
                                            <th className='credit'>Rate</th>
                                            <th className='status '>Status</th>

                                        </tr> :
                                        <tr className='table-content-top wholesaler-side-transaction-top  '>
                                            <th>S.NO</th>
                                            <th>Date</th>
                                            {/* <th>Plate No.</th>
                                      <th>Truck Type</th> */}
                                            <th>Order ID</th>
                                            <th>Amount</th>
                                            <th className='status '>Status</th>

                                        </tr>}

                                </thead>
                                <tbody>
                                    {transactions && transactions.map((trans, index) => {
                                        return (<tr className={localStorage.getItem("user_type") == trucker_type ? 'table-content-order transaction-action ' : 'table-content-order transaction-action wholesaler-side-transaction-content  '}>
                                            <td >{index + 1}</td>
                                            <td>{moment(trans?.created_at).format('DD MMMM YYYY')}</td>
                                            {localStorage.getItem("user_type") == trucker_type ? <>
                                                <td> {trans.order.loaded_truck.plate_number}</td>
                                                <td>{trans.order.loaded_truck.type.name}</td>
                                            </> : null}


                                            <td className="orderId" onClick={() => orderDetail(trans.order.id)}> {trans.order.id}</td>
                                            {localStorage.getItem("user_type") == trucker_type ? <td className='address-transation'>  {trans.order.address.address}</td> : null}
                                            <td className={trans.transaction_type}>{trans.amount}</td>


                                            <td className='status transaction-action-btn'><Button type='button' variant='unset'>{trans.status=="failed"?"Pending":trans.status.toUpperCase()}</Button></td>

                                        </tr>)

                                    })}

                                </tbody>
                            </Table>
                        </>}
                </div>
                {transactions && transactions.length > 0 ?
                    <Pagination handlePageClick={handlePageClick}
                        page={page}
                        total_page={total_page} />
                    : null}
            </div>
        </>
    );
};
export default Transaction