import { useState } from "react"
import { context } from "./GlobalContext"


const Context = (props) => {
    const [token, setToken] = useState("");
    const [name, setName] = useState("");
    const [profileStep, setprofileStep] = useState(1);
    const [user_name, setuser_name] = useState(localStorage.getItem("user_name"));
    const [user_image, setuser_image] = useState(localStorage.getItem("user_image"));
    const [cart_count, setcart_count] = useState(localStorage.getItem("cart_count"));
    const [user_type, setuser_type] = useState(localStorage.getItem("user_type"));
    const [noti_count, setnoti_count] = useState(0);
    
    var data={
        token, setToken,
        name,setName,
        profileStep,setprofileStep,
        user_name,setuser_name,
        user_image, setuser_image,
        cart_count, setcart_count,
        noti_count, setnoti_count
      }
    return (
        <context.Provider value={data}>
            {props.children}
        </context.Provider>
    );
}
export default Context;