import { useState ,useEffect} from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { apiService } from "../../../../services/api.services";
import { validateService } from "../../../../services/validate.services";
import GooglePlacesAutocomplete,{ geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {gKey} from "../../../../config/config"
import Loader from "../../../common/Loader";
import Pagination from "../../../common/Pagination";

const { default: ReactPaginate } = require("react-paginate")
const PrimaryClientManagement = () => {
    const [total_page, settotal_page] = useState()
    const [show, setShow] = useState(false);
    const [clientId, setclientId] = useState();
    const [allClients, setallClients] = useState();
    const [showdelete, setShowdelete] = useState(false);
    const [page, setpage] = useState(1);
    const [value, setValue] = useState(null);
    const [isLoader, setIsLoader] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = (action) => {
       if(action=="add"){
            setclientFields({
                name:"",
                address:"",
                latitude:"",
                longitude:""
               })
            }
      setShow(true);
    }
    const handleClosedelete = () => setShowdelete(false);
    const handleShowdelete = (id) => {
        setShowdelete(true);
        setclientId(id)
    }
    const handlePageClick = (event) => {
        console.log(event.selected)
        setpage(event.selected + 1)
        get_client_list(event.selected + 1)

    }
    const [clientFields, setclientFields] = useState({
        name:"",
        address:"",
        latitude:"",
        longitude:""
        
       });
       const [clientFieldsErr, setclientFieldsErr] = useState({
        name:"",
        address:""
        
       });
       const onInputChange = (field,value)=>{
        setclientFields({
              ...clientFields,
                [field]: value,
             
            });
       }
    const   add_client = ()=>{
    let formValidated =  validateService.validateClient(setclientFieldsErr,clientFields)
       if(formValidated){
        handleClose()
        setIsLoader(true)
        apiService.add_client(clientFields).then((res) => {
            setIsLoader(false)
             get_client_list()
           }).catch((err) => {
             setIsLoader(false)
          });
        }
    
    }
       
         const   get_client = (clientId)=>{
            setclientFieldsErr({
                name:"",
                address:"",
                
               })
            setIsLoader(true)
            apiService.get_client(clientId).then((res) => {
                console.log(res.data)
                let data = res.data
                data.id= res.data.id
                data.name= res.data.name
                data.address= res.data.address[0].address
                setclientFields(data)
                handleShow("edit")
                  setIsLoader(false)
                
                }).catch((err) => {
                  
                   setIsLoader(false)
                 //   Swal.fire("Error", "Something went wrong.", "error");
                  //  console.log("contact us api error", err.response.data.message);
                 });
                 
             }
        
        const   del_client = ()=>{
            setShowdelete(true);
            setIsLoader(true)
            apiService.del_client(clientId).then((res) => {
                  handleClosedelete()
                  setIsLoader(false)
                  get_client_list()
                }).catch((err) => {
                  setIsLoader(false)
                 //   Swal.fire("Error", "Something went wrong.", "error");
                  //  console.log("contact us api error", err.response.data.message);
                 });
             }

       useEffect(() => {
        get_client_list(page)
    }, []);

    useEffect(() => {
        if(value){
             geocodeByAddress(value.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) =>
            setclientFields({
                ...clientFields,
                address: value.label,
                  latitude: lat,
                  longitude: lng,   })  ); 
        }
    }, [value]);

       const   get_client_list = (page)=>{
        setIsLoader(true)
        apiService.get_client_list(page).then((res) => {
            setIsLoader(false)
            setallClients(res.data.data)
            // console.log(res.data,"======get_client_list")
            settotal_page(res.data.total_page)
           }).catch((err) => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = "/login"
            }
              setIsLoader(false)
            //   Swal.fire("Error", "Something went wrong.", "error");
             //  console.log("contact us api error", err.response.data.message);
            });
       }
    return (
        <>
         {isLoader && <Loader/>}
         <div className="main-spacing">
            <div className="manage-truck-area">
                <div className="recent-loads-top-area manage-truck-top-area">
                    <div>
                        <h3>Primary Clients Management</h3>
                        <p>Revolutionize your efficiency and revenue with ease by saving your primary client's name and addresses, powering seamless matching of backhaul and backload trips.</p>
                    </div>
                    <Button type="button" variant="unset" onClick={()=>handleShow("add")}>+ Add Primary Client</Button>
                </div>
                {allClients && allClients.map((list, index) => {
                return(<div className="manage-truck-detail-box">
                    <Row className="align-items-center">
                        <Col md={8}>
                            <div className="manage-truck-detail-left primary-card-detail">
                                <h6>{list.name}</h6>
                                <p><img src={require("../../../../assets/images/location-icon.png")} />{list?.address[0]?.address}</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="manage-truck-detail-right">
                                <Button className="edit-btn" variant="unset" type="button" onClick={()=>get_client(list.id)}>Edit</Button>
                                <Button className="delete-btn" variant="unset" type="button" onClick={()=>handleShowdelete(list.id)}>Delete</Button>
                            </div>
                        </Col>
                    </Row>
                </div>)
                })}

{allClients && allClients.length > 0 ?
                  <Pagination handlePageClick={handlePageClick}
                  page={page}
                  total_page={total_page} />  : null}
             </div>
            </div>

            {/* add-primary-client-modal */}
            <Modal className="success-box add-primary-client-box" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{clientFields.id?"Edit ":"Add "}Primary Client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="registration-left change-password-content ps-0">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Client Name</Form.Label>
                                <Form.Control maxLength={30} onChange={(e)=>onInputChange("name",e.target.value)} type="text" placeholder="Enter Client Name" value={clientFields.name}/>
                                <p className="errors">{clientFieldsErr.name}</p>
                                
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Client’s Pickup Location</Form.Label>
                                <GooglePlacesAutocomplete
                                
                                apiKey={gKey}
                                onFail={error => console.log(error)}
                                onNotFound={() => console.log('no results')}
                                selectProps={{  
                                    placeholder:"Enter Address",
                                    value,
                                    onChange: setValue, }}
                                />
                                {/* <Form.Control type="text" onChange={(e)=>onInputChange("address",e.target.value)}  placeholder="Enter Client’s Pickup Location"  value={clientFields.address}/> */}
                                <p className="errors">{clientFieldsErr.address}</p>
                            </Form.Group>
                        
                        </Form>
                     
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={add_client}>
                  Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="success-box trash-box" show={showdelete} onHide={handleClosedelete} centered>
                <Modal.Header >
                    <Modal.Title>
                        <img src={require("../../../../assets/images/trash.png")} alt="img" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>del_client()}>
                        YES
                    </Button>
                    <Button className="trash-no-btn" variant="primary" onClick={handleClosedelete}>
                        NO
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};
export default PrimaryClientManagement;