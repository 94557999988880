import { useState, useEffect } from "react";
import {
  Button,
  ButtonToolbar,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import { apiService } from "../../../../services/api.services";
import { validateService } from "../../../../services/validate.services";
import swal from "sweetalert2";
import Loader from "../../../common/Loader";
import SuccessPopup from "../../../modal/SuccessPopup";
// import { useParams } from "react-router-dom";
import {
  bUrl,
  allowedImageTypes,
  length_unit,
  productUnit,
  payout_percent
} from "../../../../config/config";
import Errorpopup from "../../../modal/Errorpopup";

const AddNewProduct = ({ product_id, setShowAdd }) => {
  const [isLoader, setIsLoader] = useState(false);
  const [catlist, setcatlist] = useState([]);
  const [subcatlist, setsubcatlist] = useState([
    { value: "", label: "Please select Category first" },
  ]);
  const [imagesProduct, setimagesProduct] = useState([]);
  const [images, setImages] = useState([]);
  const [message1, setmessage1] = useState("");
  const [message2, setmessage2] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [errmessage1, seterrmessage1] = useState("Error");
  const [errmessage2, seterrmessage2] = useState("");
  const [show, setShow] = useState(false);
  const [currentStock, setcurrentStock] = useState();

  const handleCloseErr = () => {
    setShowErr(false);
  };
  const handleClose = () => {
    setShowAdd(false);
    setShow(false);
  };
  const tooltip = (
    <Tooltip id="tooltip">
      Indicate the selling format: "Box" for per box, "Palette" for per palette,
      "Meter" for per meter, etc
    </Tooltip>
  );
  const tooltip1 = (
    <Tooltip id="tooltip1">
    A 7.5% Commissions & 2.5% Transaction Fee will be deducted for every successful purchase
    </Tooltip>
  );
  const handleShow = () => setShow(true);
  const handleShowErr = () => setShowErr(true);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default behavior (page refresh)
      // Handle your logic here
    }
  };

  const [uploadedimages, setuploadedimages] = useState([]);
  const [productFields, setproductFields] = useState({
    name: "",
    category: "",
    subcategory: "",
    weight: "",
    weight_unit: "",
    length: "",
    width: "",
    height: "",
    units: "",
    min_order: "",
    current_stock: "",
    add_stock: "",
    deduct_stock: "",
    price: "",
    net_payout:"",
    description: "",
    item_per_unit: "",
    suggested_selling_price: "",
    retail_tip: "",
  });

  const [productFieldsErr, setproductFieldsErr] = useState({
    images: "",
    name: "",
    category: "",
    subcategory: "",
    weight: "",
    weight_unit: "",
    length: "",
    width: "",
    height: "",
    units: "",
    min_order: "",
    current_stock: "",
    add_stock: "",
    deduct_stock: "",
    price: "",
  });
  useEffect(() => {
    if (product_id) {
      get_product_detail(product_id);
    } else {
      setproductFields({
        name: "",
        category: "",
        subcategory: "",
        weight: "",
        weight_unit: "",
        length: "",
        width: "",
        height: "",
        units: "",
        min_order: "",
        current_stock: "",
        add_stock: "",
        deduct_stock: "",
        price: "",
        net_payout:""
      });
    }
    // console.log(localStorage.getItem("profile_step"),"=====profile_step=")
    get_category();
  }, [product_id]);

  const get_category = () => {
    apiService
      .get_category()
      .then((res) => {
        let type = [];
        // console.log(res.data)
        {
          res.data.data.map((item, index) =>
            type.push({ value: item.id, label: item.name })
          );
        }
        setcatlist(type);
        setsubcatlist([]);
      })
      .catch((err) => {
        setIsLoader(false);
        //   Swal.fire("Error", "Something went wrong.", "error");
        //  console.log("contact us api error", err.response.data.message);
      });
  };
  const get_sub_category = (id) => {
    apiService
      .get_sub_category(id)
      .then((res) => {
        let type = [];
        console.log(res.data);
        {
          res.data.data.map((item, index) =>
            type.push({ value: item.id, label: item.name })
          );
        }
        setsubcatlist(type);
      })
      .catch((err) => {
        setIsLoader(false);
        //   Swal.fire("Error", "Something went wrong.", "error");
        //  console.log("contact us api error", err.response.data.message);
      });
  };
  const handleImagesChange = (file) => {
    let valid = true;
    const selectedImage = file;
    if (selectedImage) {
      console.log(
        "===imagelength====",
        uploadedimages.length + images.length + 1
      );
      if (uploadedimages.length + images.length + 1 >10) {
        valid = false;
        setproductFieldsErr({
          ...productFieldsErr,
          images: "You can upload maximum 10 images.",
        });
      } else if (allowedImageTypes.includes(selectedImage.type)) {
        valid = true;
        const reader = new FileReader();
        reader.onload = () => {
          setImages((obj) => {
            return [...obj, reader.result];
          });
        };
        reader.readAsDataURL(selectedImage);
      } else {
        valid = false;
        setproductFieldsErr({
          ...productFields,
          images: "Invalid file type. Please select a JPEG or PNG image.",
        });
      }
    }
    return valid;
  };
  const handleFilesUpload = (files) => {
    console.log("handlefile upload---------", files);
    let imagedata = [];
    let filesValidated = true;
    if (files.length > 5) {
      filesValidated = false;

      setproductFieldsErr({
        ...productFields,
        images: "You can't upload more than 5 files.",
      });
      return false;
    } else {
      {
        files.map((file, i) => {
          let valid = handleImagesChange(file);
          console.log("====file===", files[0]);
          console.log("====valid===", valid);
          if (valid) {
            console.log("====file===", files[0]);
            setimagesProduct((obj) => {
              return [...obj, file];
            });
          }
          console.log("====imagesProduct===", imagesProduct);
          // imagedata.push(file)
        });
      }
    }
    // files.map(file => {
    //   if (file.type.slice(0, 5) != "image" && file.type.slice(0, 5) != "video") {
    //     filesValidated = false
    //     swal("Error", "Only video(s) and image(s) are allowed to upload.", "error")
    //     return false;
    //   }
    //   if (file.type.slice(0, 5) == "video" && file.size > 15000000) {
    //     filesValidated = false
    //     swal("Error", "Video file can't be more than 15 MB.", "error")
    //     return false;
    //   }
    // })
  };

  const removeImageAtIndex = (indexToRemove) => {
    const updatedImages = imagesProduct.filter(
      (_, index) => index !== indexToRemove
    );
    const updatedshowImages = images.filter(
      (_, index) => index !== indexToRemove
    );
    setimagesProduct(updatedImages);
    setImages(updatedshowImages);
  };
  const delete_product_image = (id, indexToRemove) => {
    let param = {
      id: id,
    };
    apiService
      .delete_product_image(param)
      .then((res) => {
        const updatedImages = uploadedimages.filter(
          (_, index) => index !== indexToRemove
        );
        setuploadedimages(updatedImages);
      })
      .catch((err) => {
        setIsLoader(false);
        //   Swal.fire("Error", "Something went wrong.", "error");
        //  console.log("contact us api error", err.response.data.message);
      });
  };
  const onInputChange = (field, value) => {
    if (field == "category") {
      get_sub_category(value);
    }
    if(field=="current_stock"){
      setcurrentStock(value)
    }
   
    setproductFields({
      ...productFields,
      [field]: value,
    });
 
  };
  const onBlurInput = (field, value) => {
    // console.log("=====blur====",value)
    if (field == "add_stock" && value != "") {
      let current_stock = productFields.current_stock;
      current_stock = parseInt(current_stock) + parseInt(value);
      // console.log("=====current_stock====", current_stock);
      setcurrentStock(current_stock);
      let thisField = "current_stock";
      // setproductFields({
      //   ...productFields,
      //   [thisField]: current_stock,
      // });
      // setproductFieldsErr({
      //   ...productFieldsErr,
      //   deduct_stock: "Deduct stock should not be less than or equal to currrent stock.",
      // });
    }
    if (field == "price") {
      let payoutval = (payout_percent / 100) * value;
      console.log(value)
      console.log(payoutval)
      
      let finalval =  value-payoutval
      console.log(finalval)
      console.log(finalval)
     
      setproductFields({
        ...productFields,
        ["net_payout"]: finalval,
      });
    }
    if (field == "deduct_stock" && value != "") {
      if(value<productFields.current_stock){
        setproductFieldsErr({
          ...productFieldsErr,
          deduct_stock: "",
        });
      let current_stock = productFields.current_stock;
      if (currentStock) {
        current_stock = productFields.current_stock;
      }
      console.log("==nnnnn===current_stock====", value);
      console.log(parseInt(productFields.current_stock)+"==nnnnn===current_stock====", parseInt(value));
      current_stock = parseInt(productFields.current_stock) - parseInt(value);

      setcurrentStock(current_stock);
     }else{
      
      setproductFieldsErr({
        ...productFieldsErr,
        deduct_stock: "Deduct stock should not be less than or equal to currrent stock.",
      });
     }
      // let thisField = "current_stock";
      // setproductFields({
      //   ...productFields,
      //   [thisField]: current_stock,
      // });
    }
  };
  const handleSubmit = () => {
    // e.preventDefault();
    productFields.images = imagesProduct;
 let thisField = "current_stock";
      setproductFields({
        ...productFields,
        [thisField]: currentStock,
      });
    let formValidated = true;
    if (product_id) {
      let updateimages = uploadedimages;
      if (uploadedimages.length > 0) {
        updateimages = uploadedimages;
      }
      if (imagesProduct.length > 0) {
        updateimages = imagesProduct;
      }
console.log(productFields)
      formValidated = validateService.validateProduct(
        setproductFieldsErr,
        productFields,
        updateimages
      );
    } else {
      formValidated = validateService.validateProduct(
        setproductFieldsErr,
        productFields,
        imagesProduct
      );
    }
    if (formValidated) {
      setIsLoader(true);

      apiService
        .add_product(productFields, imagesProduct, product_id)
        .then((res) => {
          setmessage1("Success!");

          if (product_id) {
            setmessage2("Product updated successfully");
          } else {
            setmessage2("Product added successfully");
          }
          handleShow();
          setIsLoader(false);
        })
        .catch((err) => {
          handleShowErr();

          seterrmessage2(err.response.data.message);
          setIsLoader(false);
          //   Swal.fire("Error", "Something went wrong.", "error");
          console.log("contact us api error", err.response.data.message);
        });
      // }
    }
  };
  const get_product_detail = (id) => {
    setIsLoader(true);
    apiService
      .get_product_detail(id)
      .then((res) => {
        setuploadedimages(res.data.images);
        let data = res.data;
        data.name = res.data.product.name;
        data.category = res.data.product.category.id;
        data.subcategory = res.data.product.subcategory.id;
        get_sub_category(res.data.product.category.id);
        delete data["product"];
        delete data["images"];
        setproductFields(data);
        setIsLoader(false);
      })
      .catch((err) => {
        setIsLoader(false);
        //   Swal.fire("Error", "Something went wrong.", "error");
        //  console.log("contact us api error", err.response.data.message);
      });
  };

  const handleKeyDownnumber = (event) => {
    console.log("=====event====", event.which);
    if (event.which === 32) {
      event.preventDefault(); // Prevent space key action
    }
    if (event.key === "Enter" || event.which === 13) {
      event.preventDefault(); // Prevent the default behavior (page refresh)
      // Handle your logic here
    }
  };
  return (
    <>
      {isLoader && <Loader />}

      <div className="manage-truck-area add-truck-area add-new-product">
        <span
          className="backBtn"
          onClick={() => setShowAdd(false)}
          style={{ cursor: "pointer" }}
        >
          {" "}
          <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
        </span>
        {/* <a href="#" onClick={() => setShowAdd(false)}> Product Management  / <span>{product_id ? "Update" : "Add New"} Product</span></a> */}
        <h3>{product_id ? "Update" : "Add new"} product</h3>
        {/* <a href="#"> <span onClick={() => setKey("view")}>Manage truck</span>  / <span>{truck_id?"Update":"Add"} truck</span></a> */}
        {/* <h3>{product_id ? "Update" : "Add new"} product</h3> */}
        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
        <div className="stepper-form-area">
          <Form>
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-2 truck-logo-upload-field"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Upload Photo</Form.Label>
                  <div className="upload-box-area upload-photo-top-area">
                    <div className="logo-upload-box">
                      <i class="fa fa-plus" aria-hidden="true"></i>
                      <Form.Control type="text" />
                      <Form.Control
                        type="file"
                        multiple={true}
                        className="upload-item"
                        onChange={(e) => handleFilesUpload([...e.target.files])}
                      />
                    </div>

                    <div className="upload-image-preview">
                      {images.length > 0 &&
                        images.map((image, index) => (
                          <div className="first-preview-image product-images-area">
                            <img
                              key={index}
                              src={image}
                              alt={`Preview ${index}`}
                              className="image-preview"
                            />
                            <i
                              className="fa fa-trash"
                              aria-hidden="true"
                              onClick={() => removeImageAtIndex(index)}
                            ></i>
                          </div>
                        ))}

                      {uploadedimages.length > 0 &&
                        uploadedimages.map((image, index) => (
                          <div className="first-preview-image product-images-area">
                            <img
                              key={index}
                              src={bUrl + image.image}
                              alt={`Preview ${index}`}
                              className="image-preview"
                            />
                            <i
                              className="fa fa-trash"
                              aria-hidden="true"
                              onClick={() =>
                                delete_product_image(image.id, index)
                              }
                            ></i>
                          </div>
                        ))}
                    </div>
                  </div>
                  <p className="errors">{productFieldsErr.images}</p>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Brand Name</Form.Label>
                  <Form.Control
                    onKeyDown={handleKeyDown}
                    maxLength={50}
                    type="text"
                    placeholder="Enter Brand Name"
                    value={productFields.name}
                    onChange={(e) => onInputChange("name", e.target.value)}
                  />
                  <p className="errors">{productFieldsErr.name}</p>
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Category</Form.Label>
                  <Select
                    value={catlist.filter(function (option) {
                      return option.value === productFields.category;
                    })}
                    onChange={(e) => onInputChange("category", e.value)}
                    placeholder="Category"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        background: "transparent",
                        height: "50px",
                        borderRadius: "30px",
                        border: "1px solid #00000029",
                        boxShadow: "none",

                        "&:focus": {
                          borderColor: "#00000029",
                        },
                        "&:hover": {
                          borderColor: "#00000029",
                        },
                      }),
                    }}
                    options={catlist}
                  />
                  <p className="errors">{productFieldsErr.category}</p>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Sub-category</Form.Label>
                  <Select
                    value={subcatlist.filter(function (option) {
                      return option.value === productFields.subcategory;
                    })}
                    placeholder="Sub Category"
                    onChange={(e) => onInputChange("subcategory", e.value)}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        background: "transparent",
                        height: "50px",
                        borderRadius: "30px",
                        border: "1px solid #00000029",
                        boxShadow: "none",

                        "&:focus": {
                          borderColor: "#00000029",
                        },
                        "&:hover": {
                          borderColor: "#00000029",
                        },
                      }),
                    }}
                    options={subcatlist}
                  />
                  <p className="errors">{productFieldsErr.subcategory}</p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label className="unit-area">
                    Unit
                    <ButtonToolbar>
                      <OverlayTrigger placement="top" overlay={tooltip}>
                        <Button bsStyle="default"><i class="fa fa-info-circle" aria-hidden="true"></i></Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </Form.Label>
               
                  <Select
                    value={productUnit.filter(function (option) {
                      return option.value === productFields.units;
                    })}
                    placeholder="Select Unit"
                    onChange={(e) => onInputChange("units", e.value)}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        background: "transparent",
                        height: "50px",
                        borderRadius: "30px",
                        border: "1px solid #00000029",
                        boxShadow: "none",

                        "&:focus": {
                          borderColor: "#00000029",
                        },
                        "&:hover": {
                          borderColor: "#00000029",
                        },
                      }),
                    }}
                    options={productUnit}
                  />
                  {/* <Form.Control
                    onKeyDown={handleKeyDownnumber}
                    maxLength={10}
                    type="text"
                    placeholder="Enter product unit e.g. Meter,Box"
                    value={productFields.units}
                    onChange={(e) => onInputChange("units", e.target.value)}
                  /> */}
                  <p className="errors">{productFieldsErr.units}</p>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label className="unit-area">Price per unit
                  <ButtonToolbar>
                      <OverlayTrigger placement="top" overlay={tooltip1}>
                        <Button bsStyle="default"><i class="fa fa-info-circle" aria-hidden="true"></i></Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </Form.Label>
                  <Form.Control
                    onKeyDown={handleKeyDownnumber}
                    maxLength={4}
                    onWheel={() => document.activeElement.blur()}
                    type="number"
                    placeholder="Enter Price per unit"
                    value={productFields.price}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        onInputChange("price", e.target.value);
                      } else {
                        return false;
                      }
                    }}
                    onKeyUp={(e) => onBlurInput("price", e.target.value)}
                  />
                  <p className="errors">{productFieldsErr.price}</p>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>Net Payout</Form.Label>
                  <Form.Control
                    onKeyDown={handleKeyDownnumber}
                    maxLength={4}
                    onWheel={() => document.activeElement.blur()}
                    type="number"
                    placeholder="Net payout"
                    value={productFields.net_payout}
                    disabled
                    // onChange={(e) => {
                    //   if (e.target.value.length <= 10) {
                    //     onInputChange("min_order", e.target.value);
                    //   } else {
                    //     return false;
                    //   }
                    // }}
                  />
                  <p className="errors">{productFieldsErr.min_order}</p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col md={4}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>Min Order</Form.Label>
                  <Form.Control
                    onKeyDown={handleKeyDownnumber}
                    maxLength={4}
                    onWheel={() => document.activeElement.blur()}
                    type="number"
                    placeholder="Enter Min Order"
                    value={productFields.min_order}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        onInputChange("min_order", e.target.value);
                      } else {
                        return false;
                      }
                    }}
                  />
                  <p className="errors">{productFieldsErr.min_order}</p>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>No of Items per unit</Form.Label>
                  <Form.Control
                    onKeyDown={handleKeyDownnumber}
                    maxLength={4}
                    onWheel={() => document.activeElement.blur()}
                    type="text"
                    placeholder="Enter Number of item per unit"
                    value={
                      productFields.item_per_unit
                        ? productFields.item_per_unit
                        : ""
                    }
                    onChange={(e) => {
                      // if (e.target.value.length <= 10) {
                        onInputChange("item_per_unit", e.target.value);
                      // } else {
                      //   return false;
                      // }
                    }}
                  />
                  {/* <p className="errors">{productFieldsErr.min_order}</p> */}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label className="unit-area">
                    Suggested Retail Price
                    {/* <ButtonToolbar  >
                      <OverlayTrigger placement="top" overlay={tooltip} >
                        <Button bsStyle="default">?</Button>
                      </OverlayTrigger>
                    </ButtonToolbar> */}
                  </Form.Label>

                  <Form.Control
                    onKeyDown={handleKeyDownnumber}
                    maxLength={10}
                    type="text"
                    placeholder="Enter Suggest Retail Price"
                    value={
                      productFields.suggested_selling_price
                        ? productFields.suggested_selling_price
                        : ""
                    }
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        onInputChange(
                          "suggested_selling_price",
                          e.target.value
                        );
                      } else {
                        return false;
                      }
                    }}
                  />
                  {/* <p className="errors">{productFieldsErr.units}</p> */}
                </Form.Group>
              </Col>
             
            </Row>
            <Row>
            <Col md={4}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>Weight(KG)</Form.Label>
                  <Form.Control
                    onKeyDown={handleKeyDownnumber}
                    maxLength={4}
                    onWheel={() => document.activeElement.blur()}
                    type="number"
                    placeholder="Enter Weight"
                    value={productFields.weight}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        onInputChange("weight", e.target.value);
                      } else {
                        return false;
                      }
                    }}
                  />
                  <p className="errors">{productFieldsErr.weight}</p>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>Height({length_unit})</Form.Label>
                  <Form.Control
                    onKeyDown={handleKeyDownnumber}
                    maxLength={4}
                    onWheel={() => document.activeElement.blur()}
                    type="number"
                    placeholder="Enter Height"
                    value={productFields.height}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        onInputChange("height", e.target.value);
                      } else {
                        return false;
                      }
                    }}
                  />
                  <p className="errors">{productFieldsErr.height}</p>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>Width({length_unit})</Form.Label>
                  <Form.Control
                    onKeyDown={handleKeyDownnumber}
                    maxLength={4}
                    onWheel={() => document.activeElement.blur()}
                    type="number"
                    placeholder="Enter Width"
                    value={productFields.width}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        onInputChange("width", e.target.value);
                      } else {
                        return false;
                      }
                    }}
                  />
                  <p className="errors">{productFieldsErr.width}</p>
                </Form.Group>
              </Col>
             
            </Row>
            <Row>
            <Col md={4}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>Length({length_unit})</Form.Label>
                  <Form.Control
                    onKeyDown={handleKeyDownnumber}
                    maxLength={4}
                    onWheel={() => document.activeElement.blur()}
                    type="number"
                    placeholder="Enter Length"
                    value={productFields.length}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        onInputChange("length", e.target.value);
                      } else {
                        return false;
                      }
                    }}
                  />
                  <p className="errors">{productFieldsErr.length}</p>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>Current Stock </Form.Label>
                  <Form.Control
                    className={product_id ? "disabled" : ""}
                    disabled={product_id ? true : false}
                    value={
                      currentStock ? currentStock : productFields.current_stock
                    }
                    onKeyDown={handleKeyDownnumber}
                    maxLength={4}
                    onWheel={() => document.activeElement.blur()}
                    type="number"
                    placeholder="Enter Current Stock"
                    onChange={(e) => {
                       if (e.target.value.length <= 10) {
                        onInputChange("current_stock", e.target.value);
                      } else {
                        return false;
                      }
                    }}
                  />
                  <p className="errors">{productFieldsErr.current_stock}</p>
                </Form.Group>
              </Col>
              {product_id ? (
                <>
                  <Col md={4}>
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Label>Add Stock</Form.Label>
                      <Form.Control
                        onKeyDown={handleKeyDownnumber}
                        maxLength={4}
                        onWheel={() => document.activeElement.blur()}
                        type="number"
                        placeholder="Enter Add Stock"
                        value={productFields.add_stock}
                        onChange={(e) => {
                          if (e.target.value.length <= 10) {
                            onInputChange("add_stock", e.target.value);
                          } else {
                            return false;
                          }
                        }}
                        onKeyUp={(e) => onBlurInput("add_stock", e.target.value)}
                      />
                      <p className="errors">{productFieldsErr.add_stock}</p>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Label>Deduct Stock</Form.Label>
                      <Form.Control
                        onKeyDown={handleKeyDownnumber}
                        maxLength={4}
                        onWheel={() => document.activeElement.blur()}
                        type="number"
                        placeholder="Enter Deduct Stock"
                        value={productFields.deduct_stock}
                        onChange={(e) => {
                          if (e.target.value.length <= 10) {
                            onInputChange("deduct_stock", e.target.value);
                          } else {
                            return false;
                          }
                        }}
                        onKeyUp={(e) =>
                          onBlurInput("deduct_stock", e.target.value)
                        }
                      />
                      <p className="errors">{productFieldsErr.deduct_stock}</p>
                    </Form.Group>
                  </Col>
                </>
              ) : null}
            </Row>

            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3 scroll-box"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Product Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={1}
                    maxLength={1000}
                    type="textarea"
                    placeholder="E.g. Calamba Mango | Fresh Harvest | Box Wholesale | 50kg per Box"
                    value={productFields.description && productFields.description!="undefined"?productFields.description:""}
                    onChange={(e) =>
                      onInputChange("description", e.target.value)
                    }
                  />
                  <p className="errors">{productFieldsErr.description}</p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-4  scroll-box"
                  controlId="formBasicEmail"
                >
                  <Form.Label> Retails tip</Form.Label>

                  <Form.Control
                    as="textarea"
                    name="postContent"
                    rows={3}
                    maxLength={5000}
                    onWheel={() => document.activeElement.blur()}
                    type="textarea"
                    placeholder="Give your buyers some retail tips to inspire them to start or expand their retail venture
Example : This product is frequently requested/search – there is a high demand.
 Here are some retail tips: 
- Collect Pre-order from your community, groups, family, friends, etc. 
- Reach out to your local market, groceries, retail stores and make a deal to supply them this product 
- Grab the photos used here and use them on your online presence 
- Apply a competitive mark up so your pricing stays competitive 
- Leverage Facebook Marketplace and other online store platforms 
- Hire Resellers/Agents in your area and nearby areas to widen your sales network "
                    value={
                      productFields.retail_tip  && productFields.retail_tip != "undefined"? productFields.retail_tip : ""
                    }
                    onChange={(e) => {
                      onInputChange("retail_tip", e.target.value);
                    }}
                  />
                  {/* <p className="errors">{productFieldsErr.price}</p> */}
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Button
            className="addtruck-btn"
            variant="unset"
            type="button"
            onClick={handleSubmit}
          >
            {product_id ? "Update" : "Add"}{" "}
          </Button>
        </div>
        <SuccessPopup
          show={show}
          handleClose={handleClose}
          message1={message1}
          message2={message2}
        />
        <Errorpopup
          showErr={showErr}
          handleCloseErr={handleCloseErr}
          errmessage1={errmessage1}
          errmessage2={errmessage2}
        />
      </div>
    </>
  );
};
export default AddNewProduct;
